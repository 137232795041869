import React from 'react';
import {Toolbar, useTheme} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS, SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboLayoutHeader from "@jumbo/hooks/useJumboLayoutHeader";

import User from 'fit/system/User';
const JumboLayoutHeader = ({children, sx}) => {
    const {sidebarOptions} = useJumboLayoutSidebar();
    const {headerOptions} = useJumboLayoutHeader();
    const theme = useTheme();

    const extraSx = React.useMemo(() => {
        if (sx) {
            const {width, ml, transition, zIndex, ...restSx} = sx;
            return restSx;
        }
    }, [sx]);

    const headerMarginLeft = React.useMemo(() => {
        if (!headerOptions.fixed) {
            return 0;
        }

        if (sidebarOptions?.variant === SIDEBAR_VARIANTS.TEMPORARY) {
            return 0;
        } else {
            if (sidebarOptions?.view === SIDEBAR_VIEWS.MINI) {
                return sidebarOptions?.minWidth;
            } else {
                if (sidebarOptions?.style === SIDEBAR_STYLES.FULL_HEIGHT) {
                    return sidebarOptions?.open ? sidebarOptions?.width : 0;
                }
                return 0;
            }
        }
    }, [
        sidebarOptions?.open,
        sidebarOptions?.width,
        sidebarOptions?.minWidth,
        sidebarOptions?.view,
        sidebarOptions?.style,
        sidebarOptions?.variant,
        headerOptions?.fixed,
    ]);

    if (headerOptions?.hide)
        return null;


    const user = new User();
    const authorized = user.getLoggedInStatus();
    if(authorized === false){
        //No header when not signed in
        return null;
    }
    const darkMode = theme.palette.mode === 'dark';
    const colorSelected = darkMode ? 'dark' : 'main';
    const color = theme.palette.primary[colorSelected];
    let localSx = {
        width: {sm: `calc(100% - ${headerMarginLeft}px)`},
        ml: {sm: `${headerMarginLeft}px`},
        transition: theme => theme.transitions.create(['width']),
        zIndex: (theme) =>
            (sidebarOptions?.variant === SIDEBAR_VARIANTS.TEMPORARY || sidebarOptions?.style === SIDEBAR_STYLES.FULL_HEIGHT)
                ? theme.zIndex.drawer - 1 : theme.zIndex.drawer + 1,
        ...extraSx,
        '.MuiAppBar-colorDefault' : color,
        '.MuiAppBar-colorInherit' : color,
    }
    return (
        <div>
            <AppBar
                elevation={0}
                enableColorOnDark={true}
                position={headerOptions?.fixed ? "fixed" : "relative"}
                sx={localSx}
                color={'primary'}
            >
                <Toolbar sx={{height: '100%', px: {lg: 6, xs: 4}}}>
                    {children}
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default JumboLayoutHeader;