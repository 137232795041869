import React, {useState} from "react";
import {Paper, Button} from "@mui/material";
import DynamicDataTable from "../DynamicDataTable";
import {PersonAdd} from "@mui/icons-material";
import {OPERATIONS_LEVEL, PAPER_STYLE} from "fit/system/FITConstants";
import User from 'fit/system/User';
import Header from 'fit/components/Header/';
import CreateUserForm from "./CreateUserForm";
import SecureConnect from "fit/system/SecureConnect";

const UserDirectory=()=>{
    const [loadData, setLoadData] = useState(true);
    const [displayUserForm, setDisplayUserForm] = useState(false);

    const newUserCreated=(receivedJSON)=>{
        let sc = new SecureConnect('');
        if(sc.getCompleted(receivedJSON)){
            setLoadData(true);
            setDisplayUserForm(false);
        }
    }

    const user = new User();
    const allowUserCreation = user.isCoach(OPERATIONS_LEVEL);

    return (
        <div>
            <Header header={'User Directory'}>
                {
                    allowUserCreation ?
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            onClick={()=>setDisplayUserForm(true)}
                        ><PersonAdd/> Create User</Button>
                        :
                        null
                }
            </Header>
            <Paper style={PAPER_STYLE}>
                <DynamicDataTable
                    title={'Completed Workouts'}
                    formatURL={'workout.php?action=listCompletedWorkoutSummaries'}
                    loadData={loadData}
                    dataLoaded={()=>setLoadData(false)}
                />
            </Paper>
            <CreateUserForm
                display={displayUserForm}
                handleClose={()=>setDisplayUserForm(false)}
                completedFunction={(json)=>newUserCreated(json)}
            />
        </div>
    )

}
export default UserDirectory;