import React, {useState, useEffect} from 'react';
import {Paper, Grid, Button, IconButton, Tooltip, Breadcrumbs} from "@mui/material";
import {OPERATIONS_LEVEL, PAPER_STYLE, URL_TEAMS} from "fit/system/FITConstants";
import DynamicDataTable from "fit/components/DynamicDataTable";
import {setPageTitle} from "fit/system/UtilityFunctions";
import User from 'fit/system/User';
import Header from 'fit/components/Header/';
import {ArrowBack, NavigateNext, PersonAdd} from "@mui/icons-material";
import SecureConnect from "../../system/SecureConnect";
import CreateUserForm from "../User/CreateUserForm";
import {Link} from "react-router-dom";


const initTeam=(teamURLKey)=>{
    const noTeam = teamURLKey == null || teamURLKey.length === 0;
    return noTeam ? {} : {teamURLKey, lockedTeam: true};
}

const TeamsList =({teamURLKey})=>{
    const [loadData, setLoadData] = useState(false);
    const [displayUserForm, setDisplayUserForm] = useState(false);
    const [variablePresets, setVariablePresets] = useState(initTeam(teamURLKey));

    useEffect(()=>{
        if(checkTeamSet()){
            setVariablePresets({teamURLKey, lockedTeam: true});
        } else{
            setVariablePresets({});
        }
        setLoadData(true);
    },[teamURLKey])

    const newUserCreated=(receivedJSON)=>{
        let sc = new SecureConnect('');
        if(sc.getCompleted(receivedJSON)){
            setLoadData(true);
            setDisplayUserForm(false);
        }
    }
    const checkTeamSet=()=>{
        const user = new User();
        return teamURLKey != null ; //user.managerTeamLevel(teamID,0)
    }

    const user = new User();
    const allowUserCreation = user.isSportSpecificCoach(OPERATIONS_LEVEL);
    const teamCount = user.getTeams().length;
    const teamSet = checkTeamSet();
    let teamName;
    let title
    let url = 'team.php?action=listTeam';
    if(teamSet){
        const teamDetails = user.getTeamDetails(teamURLKey);
        teamName = teamDetails != null ? `${teamDetails.orgShortName} ${teamDetails.label}` : '';
        title = `${teamName} Roster `;
    } else{
        title = teamCount > 1 ? 'Athletes/Rosters' : 'Athletes/Rosters';
    }
    setPageTitle(title);


    return (
        <div>
            <Header header={title}>
                {allowUserCreation &&
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>setDisplayUserForm(true)}
                    ><PersonAdd/> Create Athlete</Button>
                }
            </Header>
            <Breadcrumbs separator={<NavigateNext color={'secondary'}/>} aria-label={'breadcrumb'}>
                <Tooltip title={'View All Teams/Athletes'}>
                 <Button
                    component={Link}
                    to={URL_TEAMS}
                >
                     Athletes/Rosters
                </Button>
                </Tooltip>
                {teamSet ? <span>{teamName}</span> : null}
            </Breadcrumbs>
            <Paper style={PAPER_STYLE}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DynamicDataTable
                            title={'Team Table'}
                            formatURL={url}
                            loadData={loadData}
                            dataLoaded={()=>setLoadData(false)}
                            rowFunctions={{}}
                            presetVariables={variablePresets}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <CreateUserForm
                display={displayUserForm}
                handleClose={()=>setDisplayUserForm(false)}
                completedFunction={(json)=>newUserCreated(json)}
            />
        </div>
    )
}

export default TeamsList;