import {alpha, darken} from "@mui/material";
import User from './User';
import {isEmpty} from "./UtilityFunctions";

//Dark Theme Colors
const darkModePaper = '#26324D'; //'#222'; //;
const darkModeBackground =  '#19243B'; //'#111';//;
//Light Mode Colors
const lightModePaper = '#FFF';
const lightModeBackground = '#F5F7FA';
const cT = lightModePaper; //Contrast Text


const darkNavy = '#16284c'; //darkModePaper; //
const shadowRed = 0;
const shadowGreen = 0;
const shadowBlue = 0;
const alphaOne = .2;
const alphaTwo = .14;
const alphaThree = .12;
const defaultTextColor = '#475259';
const buteoPrimary = '#2196f3';


const shadows = [
    "none",
    `0px 2px 1px -1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 1px 1px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 1px 3px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 3px 1px -2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 2px 2px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 1px 5px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 3px 3px -2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 3px 4px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 1px 8px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 2px 4px -1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 4px 5px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 1px 10px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 3px 5px -1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 5px 8px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 1px 14px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 3px 5px -1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 6px 10px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 1px 18px 0px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 4px 5px -2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 7px 10px 1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 2px 16px 1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 5px 5px -3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 8px 10px 1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 3px 14px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 5px 6px -3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 9px 12px 1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 3px 16px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 6px 6px -3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 10px 14px 1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 4px 18px 3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 6px 7px -4px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 11px 15px 1px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 4px 20px 3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 7px 8px -4px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 12px 17px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 5px 22px 4px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 7px 8px -4px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 13px 19px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 5px 24px 4px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 7px 9px -4px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 14px 21px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 5px 26px 4px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 8px 9px -5px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 15px 22px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 6px 28px 5px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 8px 10px -5px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 16px 24px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 6px 30px 5px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 8px 11px -5px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 17px 26px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 6px 32px 5px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 9px 11px -5px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 18px 28px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 7px 34px 6px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 9px 12px -6px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 19px 29px 2px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 7px 36px 6px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 10px 13px -6px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 20px 31px 3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 8px 38px 7px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 10px 13px -6px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 21px 33px 3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 8px 40px 7px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 10px 14px -6px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 22px 35px 3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 8px 42px 7px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 11px 14px -7px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 23px 36px 3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 9px 44px 8px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0px 11px 15px -7px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaOne}), 0px 24px 38px 3px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaTwo}), 0px 9px 46px 8px rgba(${shadowRed},${shadowGreen},${shadowBlue},${alphaThree})`,
    `0 5px 10px rgba(0, 0, 0, 0.085)`,
    `0 5px 10px rgba(0, 0, 0, 0.175)`,
];

const originalShadows = [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
    "0 5px 10px rgba(0, 0, 0, 0.085)",
    "0 5px 10px rgba(0, 0, 0, 0.175)",
]

export default class Theme {
    constructor() {
        const breakPoints = {
            values: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
            }
        }
        this.defaultThemeID = 1;
        this.primaryColorString = '#1c4a59';
        this.primaryLightColorString = '#1c4a58';
        this.secondaryColorString= '#1c4a57';
        this.secondaryLightColorString = '#1c4a56';
        this.statusColors = {
            success: {
                main: '#3bcc00',
                light: '#41e500',
                dark: '#33b300',
                contrastText: cT,
            },
            info: {
                main: '#0AC',
                light: '#00bfe5',
                dark: '#0095b3',
                contrastText: cT,
            },
            warning: {
                main: '#e59900',
                light: '#ffaa00',
                dark: '#cc8800',
                contrastText: cT,
            },
            error: {
                main: '#e53a36',
                light: '#ff403d',
                dark: '#cc3431',
                contrastText: cT,
            },
            noAction: {
                main: '#999',
                light: '#b3b3b3',
                dark: '#808080',
                contrastText: cT,
            }
        };
        this.footerTheme = {
            palette: {
                mode: 'light',
                text: {
                    primary: defaultTextColor,
                    secondary: '#8595A6',
                    disabled: '#A2B2C3',
                },
                divider : '#DEE2E6',
                background: {
                    paper: lightModePaper,
                    default: lightModeBackground,
                },
                action: {
                    active: defaultTextColor,
                    hover: '#F5F7FA',
                },
            }
        };
        this.footerThemeDark = {
            palette: {
                mode: 'dark',
                text: {
                    primary: '#C5CDE6',
                    secondary: '#8595A6',
                    disabled: '#A2B2C3',
                },
                divider : darkNavy,
                background: {
                    paper: darkModePaper,
                    default: darkModeBackground,
                },
                action: {
                    active: defaultTextColor,
                    hover: '#F5F7FA',
                },
            }
        };
        this.headerTheme = {
            /* --- HEADER STUFF --- */
            components: {
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            color: cT,
                            background: buteoPrimary
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            background: alpha('#FFFFFF', .25)
                        }
                    }
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            color: 'inherit',
                            background: alpha('#FFFFFF', .25),
                            '&:hover': {
                                background: alpha('#FFFFFF', .125),
                            }
                        }
                    }
                }
            },
            /* --- HEADER STUFF --- */

            palette: {
                mode: 'light',
                text: {
                    primary: defaultTextColor,
                    secondary: '#8595A6',
                    disabled: '#A2B2C3',
                },
                divider : '#DEE2E6',
                background: {
                    paper: lightModePaper,
                    default: '#f00',
                },
                action: {
                    active: defaultTextColor,
                    hover: '#F5F7FA',
                }
            }
        };
        this.headerThemeDark = {

            components: {
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            color: '#FFFFFF',
                            background: '#f00' //STARTING THEME?? WTF
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            background: '#3C475F'
                        }
                    }
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            color: 'inherit',
                            background: '#3C475F',
                            '&:hover': {
                                background: '#46536f',
                            }
                        }
                    }
                }
            },


            palette: {
                mode: 'dark',
                text: {
                    primary: defaultTextColor,
                    secondary: '#8595A6',
                    disabled: '#A2B2C3',
                },
                divider : alpha('#FFFFFF', 0.1),
                background: {
                    paper: this.primaryColorString, //STARTING HEADER WTF?
                    default: '#3C475F',
                },
                action: {
                    active: '#C5CDE6',
                    hover: '#323b4f',
                }
            }
        };
        this.mainTheme = {
            breakpoints: breakPoints,
            palette: {
                mode: 'light',
                text: {
                    primary: defaultTextColor,
                    secondary: '#8595A6',
                    disabled: '#A2B2C3',
                },
                divider : '#DEE2E6',
                background: {
                    paper: lightModePaper,
                    default: lightModeBackground,
                },
                action: {
                    active: defaultTextColor,
                    hover: '#F5F7FA',
                }
            },
            shadows: shadows,
            typography: {
                fontFamily: 'NoirPro, Arial',
                fontSize: 14,
                textDecoration: 'none',
                h1: {
                    fontSize: '1.5rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#37373C',
                    margin: '0 0 .5rem'
                },
                h2: {
                    fontSize: '1.4rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#37373C',
                    margin: '0 0 .5rem'
                },
                h3: {
                    fontSize: '1.25rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#37373C',
                    margin: '0 0 .5rem'
                },
                h4: {
                    fontSize: '1.1rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#37373C',
                    margin: '0 0 .5rem'
                },
                h5: {
                    fontSize: '1rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#37373C',
                    margin: '0 0 .5rem'
                },
                h6: {
                    fontSize: '.875rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#37373C',
                    margin: '0 0 .5rem'
                },
                body1: {
                    fontSize: '.875rem',
                }
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: alpha('#000', 0.1),
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            minHeight: 'auto',
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderRadius: 12,
                            boxShadow: `0 0.5rem 1.25rem ${alpha('#7352C7', .175)}`
                        },
                    },
                },
                MuiCardContent: {
                    styleOverrides: {
                        root: {
                            padding: 24
                        },
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        root: {
                            padding: '18px 24px'
                        },
                        title: {
                            fontSize: '1.1rem',
                            marginBottom: 0
                        },
                        subheader: {
                            margin: '4px 0 0'
                        },
                        action: {
                            margin: 0
                        }
                    }
                },
                MuiCardActions: {
                    styleOverrides: {
                        root: {
                            padding: '8px 24px'
                        }
                    }
                },
                MuiChip: {
                    styleOverrides: {
                        sizeSmall: {
                            height: 22,
                            fontSize: 12,
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontWeight: 400,
                            letterSpacing: 1
                        },
                        sizeSmall: {
                            fontSize: '12px'
                        }
                    }
                },
                MuiPopover: {
                    styleOverrides: {
                        paper: {
                            borderRadius: 8
                        }
                    }
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: {
                            fontSize: 18
                        }
                    }
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            padding: '16px 24px'
                        }
                    }
                },
                MuiAvatarGroup: {
                    styleOverrides: {
                        avatar: {
                            backgroundColor: '#757575',
                            fontSize: 16
                        }
                    }
                },
            },
            jumboComponents: {
                JumboSearch: {
                    background: '#F5F5F5'
                }
            }
        };
        this.mainThemeDark = {
            breakpoints: breakPoints,
            palette: {
                mode: 'dark',
                text: {
                    primary: '#C5CDE6',
                    secondary: '#8595A6',
                    disabled: '#A2B2C3',
                },
                divider : alpha('#FFFFFF', 0.1),
                background: {
                    paper: darkModePaper,
                    default: darkModeBackground,
                },
                action: {
                    active: '#C5CDE6',
                    hover: '#323b4f',
                    disabled: '#E0E0E0', // 8595A6
                    disabledBackground: '#8595A6'
                },
            },
            shadows: shadows,
            typography: {
                fontFamily: 'NoirPro, Arial',
                fontSize: 14,
                h1: {
                    fontSize: '1.5rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#f6f6f6',
                    margin: '0 0 .5rem'
                },
                h2: {
                    fontSize: '1.4rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#f6f6f6',
                    margin: '0 0 .5rem'
                },
                h3: {
                    fontSize: '1.25rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#f6f6f6',
                    margin: '0 0 .5rem'
                },
                h4: {
                    fontSize: '1.1rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#f6f6f6',
                    margin: '0 0 .5rem'
                },
                h5: {
                    fontSize: '1rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#f6f6f6',
                    margin: '0 0 .5rem'
                },
                h6: {
                    fontSize: '.875rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: '#f6f6f6',
                    margin: '0 0 .5rem'
                },
                body1: {
                    fontSize: '.875rem',
                }
            },
            components: {
                MuiAccordion:{
                    styleOverrides: {
                        paper: {
                            backgroundColor: '#f00'
                        }
                    }

                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: alpha('#000', 0.1),
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            minHeight: 'auto',
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderRadius: 12,
                            boxShadow: `0 0.5rem 1.25rem ${alpha('#000000', .15)}`
                        },
                    },
                },
                MuiCardContent: {
                    styleOverrides: {
                        root: {
                            padding: 24
                        },
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        root: {
                            padding: '18px 24px'
                        },
                        title: {
                            fontSize: '1.1rem',
                            marginBottom: 0
                        },
                        subheader: {
                            margin: '4px 0 0'
                        },
                        action: {
                            margin: 0
                        }
                    }
                },
                MuiCardActions: {
                    styleOverrides: {
                        root: {
                            padding: '8px 24px'
                        }
                    }
                },
                MuiChip: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#3C475F'
                        },
                        sizeSmall: {
                            height: 22,
                            fontSize: 12,
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontWeight: 400,
                            letterSpacing: 1
                        },
                        sizeSmall: {
                            fontSize: '12px'
                        },
                        colorInherit: {
                            backgroundColor: '#3C475F',

                            '&:hover': {
                                backgroundColor: '#323b4f'
                            }
                        }
                    }
                },
                MuiPopover: {
                    styleOverrides: {
                        paper: {
                            borderRadius: 8,
                            backgroundColor: '#3C475F',
                        }
                    }
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: {
                            fontSize: 18
                        }
                    }
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            padding: '16px 24px'
                        }
                    }
                },
                MuiAvatarGroup: {
                    styleOverrides: {
                        avatar: {
                            color: '#C5CDE6',
                            backgroundColor: '#323b4f',
                            fontSize: 16,
                            borderColor: alpha('#FFFFFF', 0.1),
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            background: '#3C475F',
                        }
                    }
                },
            },
            jumboComponents: {
                JumboSearch: {
                    background: '#3C475F'
                }
            }
        };
        this.sidebarTheme ={
            palette:{
                text: {
                    primary: this.primaryColorString,
                    secondary: '#8595A6',
                    disabled: '#ccc',
                },
                nav: {
                    action: {
                        active: this.primaryColorString,
                        hover: this.primaryColorString,
                    },
                    background: {
                        active: alpha(this.primaryColorString, .15),
                        hover: "#E9ECEF"
                    },
                    tick: {
                        active: this.primaryColorString,
                        hover: "#ADB5BD"
                    }
                },
                divider : '#DEE2E6',
                background: {
                    paper: lightModePaper,
                    default: lightModeBackground,
                },
                action: {
                    active: defaultTextColor,
                    hover: '#F5F7FA',
                },
            }
        };
        this.sidebarThemeDark = {
            palette:{
                text: {
                    primary: this.primaryLightColorString,
                    secondary: '#8595A6',
                    disabled: '#A2B2C3',
                },
                nav: {
                    action: {
                        active: '#FFFFFF',
                        hover: '#FFFFFF',
                    },
                    background: {
                        active: this.primaryColorString,
                        hover: alpha('#FFFFFF', .15),
                    },
                    tick: {
                        active: this.primaryColorString,
                        hover: alpha('#FFFFFF', .25),
                    }
                },
                divider : '#DEE2E6',
                background: {
                    paper: darkModePaper,
                    default: darkNavy,
                },
                action: {
                    active: defaultTextColor,
                    hover: '#F5F7FA',
                },
            }
        };
        this.themeList = [
            {
                id: 1,
                name: 'Buteo',
                dark: false,
                primary: {
                    main: buteoPrimary,
                    light: '#249cff',
                    dark: '#1e85d9',
                    contrastText: cT,
                },
                secondary: {
                    main: '#ee1133',
                    light: '#ff1235',
                    dark: '#d40f2c',
                    contrastText: cT,
                },
                ...this.statusColors,
            },{
                id: 2,
                name: 'Buteo (Dark)',
                dark: true,
                primary: {
                    main: buteoPrimary,
                    light: '#249cff',
                    dark: '#1e85d9',
                    contrastText: cT,
                },
                secondary: {
                    main: '#ee1133',
                    light: '#ff1235',
                    dark: '#d40f2c',
                    contrastText: cT,
                },
                ...this.statusColors,
            },{
                id: -1,
                name: 'UNC',
                dark: false,
                primary: {
                    main: '#56a1d5',
                    light: '#95c6e6',
                    dark: '#2e73b1',
                    contrastText: cT
                },
                secondary: {
                    main: '#1e3864',
                    light: '#335285',
                    dark: darkNavy,
                    contrastText: cT
                },
                ...this.statusColors
            },{id: -2,
                name: 'UNC (Dark)',
                dark: true,
                primary: {
                    main: '#56a1d5',
                    light: '#95c6e6',
                    dark: '#2e73b1',
                    contrastText: cT
                },
                secondary: {
                    main: '#1e3864',
                    light: '#335285',
                    dark: darkNavy,
                    contrastText: cT
                },
                ...this.statusColors
            },{
                id: 3,
                name: 'Amber',
                dark: false,
                primary: {
                    main: '#FFC107',
                    light: '#FFC107',
                    dark: '#e5ae07',
                    contrastText: cT
                },
                secondary: {
                    main: '#FF3D00',
                    light: '#FF3D00',
                    dark: '#e53600',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 4,
                name: 'Amber (Dark)',
                dark: true,
                primary: {
                    main: '#FFC107',
                    light: '#FFC107',
                    dark: '#e5ae07',
                    contrastText: cT
                },
                secondary: {
                    main: '#FF3D00',
                    light: '#FF3D00',
                    dark: '#e53600',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 5,
                name: 'Blue',
                dark: false,
                primary: {
                    main: buteoPrimary,
                    light: '#249cff',
                    dark: '#1e85d9',
                    contrastText: cT,
                },
                secondary: {
                    main: '#FF6E40',
                    light: '#FF6E40',
                    dark: '#e56239',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 6,
                name: 'Blue (Dark)',
                dark: true,
                primary: {
                    main: buteoPrimary,
                    light: '#249cff',
                    dark: '#1e85d9',
                    contrastText: cT,
                },
                secondary: {
                    main: '#FF6E40',
                    light: '#FF6E40',
                    dark: '#e56239',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 7,
                name: 'Cyan',
                dark: false,
                primary: {
                    main: '#00bcd4',
                    light: '#00d1ed',
                    dark: '#00bbd4',
                    contrastText: cT,
                },
                secondary: {
                    main: '#e91e63',
                    light: '#ff216b',
                    dark: '#cf1b57',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 8,
                name: 'Cyan (Dark)',
                dark: true,
                primary: {
                    main: '#00bcd4',
                    light: '#00d1ed',
                    dark: '#00bbd4',
                    contrastText: cT,
                },
                secondary: {
                    main: '#e91e63',
                    light: '#ff216b',
                    dark: '#cf1b57',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 9,
                name: 'Green',
                dark: false,
                primary: {
                    main: '#4CAF50',
                    light: '#57c95a',
                    dark: '#419644',
                    contrastText: cT,
                },
                secondary: {
                    main: '#FF6D00',
                    light: '#FF6D00',
                    dark: '#e56300',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 10,
                name: 'Green (Dark)',
                dark: true,
                primary: {
                    main: '#4CAF50',
                    light: '#57c95a',
                    dark: '#419644',
                    contrastText: cT,
                },
                secondary: {
                    main: '#FF6D00',
                    light: '#FF6D00',
                    dark: '#e56300',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 11,
                name: 'Indigo',
                dark: false,
                primary: {
                    main: '#3f51b5',
                    light: '#485ccf',
                    dark: '#3f51b5',
                    contrastText: cT,
                },
                secondary: {
                    main: '#FF4081',
                    light: '#FF4081',
                    dark: '#e53973',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 12,
                name: 'Indigo (Dark)',
                dark: true,
                primary: {
                    main: '#3f51b5',
                    light: '#485ccf',
                    dark: '#3f51b5',
                    contrastText: cT,
                },
                secondary: {
                    main: '#FF4081',
                    light: '#FF4081',
                    dark: '#e53973',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 13,
                name: 'Pink',
                dark: false,
                ...this.statusColors,
                primary: {
                    main: '#e91e63',
                    light: '#ff216b',
                    dark: '#cf1b57',
                    contrastText: cT,
                },
                secondary: {
                    main: '#00BFA5',
                    light: '#00d9bc',
                    dark: '#00a690',
                    contrastText: cT
                },
            },{
                id: 14,
                name: 'Pink (Dark)',
                dark: true,
                primary: {
                    main: '#e91e63',
                    light: '#ff216b',
                    dark: '#cf1b57',
                    contrastText: cT,
                },
                secondary: {
                    main: '#00BFA5',
                    light: '#00d9bc',
                    dark: '#00a690',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 15,
                name: 'Purple',
                dark: false,
                primary: {
                    main: '#673ab7',
                    light: '#7743d1',
                    dark: '#5a339e',
                    contrastText: cT,
                },
                secondary: {
                    main: '#FFAB00',
                    light: '#FFAB00',
                    dark: '#e59900',
                    contrastText: cT
                },
                ...this.statusColors,
            },{
                id: 16,
                name: 'Purple (Dark)',
                dark: true,
                primary: {
                    main: '#673ab7',
                    light: '#7743d1',
                    dark: '#5a339e',
                    contrastText: cT,
                },
                secondary: {
                    main: '#FFAB00',
                    light: '#FFAB00',
                    dark: '#e59900',
                    contrastText: cT
                },
                ...this.statusColors,
            }
        ];
    }
    buildTheme(themeID, section){
        const themeList = this.themeList;
        const findTheme = themeList.find(item => parseInt(item.id) === parseInt(themeID));
        const theme = findTheme ?? this.themeList[0];
        //console.log('GETTING COLOR', themeID, ':: SECTION ::', section);
        return this.#insertColors(section, theme);
    }
    #insertColors(section, theme){
        //Inject colors from a user specified theme into the section theme
        let t = structuredClone(theme);
        let themeSection = this.#getSection(section, t.dark);
        /*
        delete(t.id);
        delete(t.name);
        delete(t.dark);

         */
        const primaryColor = t.primary.main;
        let newPalette = {
            ...t,
            ...themeSection.palette
        };
        themeSection.palette = newPalette;
        if(section === 'sidebar'){
            //Replace all |PRIMARYCOLOR| color strings with the actual primary color;
            let stringed = JSON.stringify(themeSection);
            stringed = stringed.replaceAll(this.primaryColorString, primaryColor);
            stringed = stringed.replaceAll(this.primaryLightColorString, t.primary.light);
            stringed = stringed.replaceAll(this.secondaryColorString, t.secondary.main);
            themeSection = JSON.parse(stringed);
        }
        return themeSection;
    }
    #getSection(section, dark){
        let themeSection;

        const mainSection = dark ? this.mainThemeDark : this.mainTheme;
        switch(section){
            case 'footer':
                themeSection = dark ? this.footerThemeDark : this.footerTheme;
                break;
            case 'header':
                themeSection = dark ? this.headerThemeDark : this.headerThemeDark;
                break;
            case 'main':
                themeSection = dark ? this.mainThemeDark : this.mainTheme;
                break;
            case 'sidebar':
                themeSection = dark ? this.sidebarThemeDark : this.sidebarTheme;
                break;
            default:
                themeSection = this.mainTheme;
                break;
        }
        //Insert/color paletes specified by the user
        return {...mainSection, ...themeSection};
    }
    getStatusColors(id){
        const theme = this.themeList.find(theme => parseInt(theme.id) === parseInt(id));
        const {success, info, warning, error, noAction} = theme;
        const colors ={
            success,
            info,
            warning,
            error,
            noAction
        };
        return colors;
    }
    getUserTheme(){
        const user = new User;
        const loggedIn = user.getLoggedInStatus()
        const data = user.getUserInfo();
        //console.log('THEME DATA', data);
        const themeFound = loggedIn && !isEmpty(data) && data.preferences.themeColorID != null;
        const colorID = themeFound? data.preferences.themeColorID : this.defaultThemeID;
        if(!isEmpty(data)) {
            //console.log('USER THEME FOUND?', themeFound, colorID);
        }
        return {
            main: this.buildTheme(colorID, 'main'),
            header: this.buildTheme(colorID, 'header'),
            footer: this.buildTheme(colorID, 'footer'),
            sidebar: this.buildTheme(colorID, 'sidebar')
        }
    }
}

