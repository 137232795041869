import React from 'react';
import User from 'fit/system/User';
import UserProfile from "fit/components/User/UserProfile";

const MyProfileRoute =({section})=>{
    const user = new User();
    const urlKey = user.getUserUrlKey();
    return <UserProfile urlKey={urlKey} self={true} section={section} />
}

export default MyProfileRoute;