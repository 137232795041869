import React, {useState} from 'react';
import Modal from 'fit/components/Dialogs/Modal';
import {Grid, Typography, Button, Slider, Paper} from '@mui/material';
import { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import {useTheme} from "@mui/material";
import { styled } from '@mui/material/styles';
import {getRPEScaleString, getRPEColor, getSpacing} from 'fit/system/UtilityFunctions';
import {PAPER_STYLE} from "fit/system/FITConstants";

const valuetext=(value)=>{
    return `${value}`;
}


const ColoredSlider = styled(Slider)(({ styleProps }) => ({
    color: styleProps.color,
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        color: styleProps.fontColor,
        backgroundColor: styleProps.color,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
}));


const RPEModal=({name, lowRange, highRange, open, closeFunction, score, handleScoreFunction, handleSubmitFunction})=>{
    const [sliderTouched, setSliderTouched] = useState(false);
    const score100 = parseInt(score)*5;

    const theme = useTheme();
    const backgroundColor=getRPEColor(score100);
    const fontColor = score < 6 || score >= 17 ? '#fff' : theme.palette.text.primary;
    const paddingDefault = getSpacing('iconAlignment');
    const paddingSides = getSpacing('small');

    //console.log('THEME PALETTE', theme.palette);

    const markTouched=()=>{
        if(sliderTouched === false){
            setSliderTouched(true);
        }
    }

    const psm = getSpacing('small');

    const divStyle = {
        borderRadius: '4px',
        display: 'block',
        background: 'inherit', //backgroundColor,
        padding: getSpacing('small'),
        paddingTop: getSpacing('large'),

    }

    const styleProps = {color: backgroundColor, fontColor};

    return (
        <Modal
            open={open}
            title={'Rate of Perceived Exertion'}
            closeFunction={closeFunction}
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant={'h2'}>
                        {name}, How Hard Was That?
                    </Typography>
                    <Typography variant={'body1'}>
                        On a scale from <strong>{lowRange}</strong> to <strong>{highRange}</strong> please rate how much you exerted yourself.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={PAPER_STYLE}>
                        {/*
                        <Slider
                            defaultValue={lowRange}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            onFocus={()=>markTouched()}

                            step={1}
                            marks
                            min={lowRange}
                            max={highRange}
                            onChange={handleScoreFunction}
                            value={score}
                        />
                        */}
                        <ColoredSlider
                            styleProps={styleProps}
                            defaultValue={lowRange}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            onFocus={()=>markTouched()}


                            step={1}
                            marks
                            min={lowRange}
                            max={highRange}
                            onChange={handleScoreFunction}
                            value={score}
                        />

                        <Typography variant={'body1'} align={'center'}>
                            <strong>{score}</strong>: <span style={{fontStyle: 'italic'}}>"{getRPEScaleString(score, false)}"</span>
                        </Typography>
                    </Paper>

                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={sliderTouched === false}
                        fullWidth={true}
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleSubmitFunction}
                    >Submit Feedback</Button>
                </Grid>
            </Grid>
        </Modal>
    );
}

export default RPEModal;