import React from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {DOMAIN_NAME, URL_DEVICES} from "fit/system/FITConstants";
import Link from 'fit/components/System/Link';
import {QrCode} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import User from 'fit/system/User';

const Footer = () => {
    const startYear = 2024;
    const year = new Date().getFullYear();
    const yearString = year > startYear ? `${startYear}-${year}` : startYear;
    const user = new User();
    const loggedIn = user.getLoggedInStatus();
    return (
        <Div sx={{
            py: 2,
            px: {lg: 6, xs: 4},
            borderTop: 2,
            borderColor: 'divider',
            bgcolor: 'background.paper',
        }}>
            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant={"body1"} color={"text.primary"}>Copyright {DOMAIN_NAME.toUpperCase()} &copy; {yearString} All Rights Reserved</Typography>
                <Div sx={{display: 'flex', alignItems: 'center'}}>
                    {
                        !loggedIn ?
                            <Link to={`${URL_DEVICES}login/`} aria-label={"Scan QR Code for Fast Device Logins"} title={'Device/Kiosk Login'}>
                                <QrCode/>
                            </Link>
                        : null
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default Footer;
