import React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {getSpacing} from 'fit/system/UtilityFunctions';


const Modal=({size, open, closeFunction, title, children, modalActions})=>{
	const width = size != null ? size : 'md';
	const style = {paddingTop: getSpacing('iconAlignment')};
	return (
		<Dialog
			open={open}
			onClose={closeFunction}
			fullWidth={true}
			maxWidth={width}
		>
			<DialogTitle onClose={closeFunction}>
				{title}
			</DialogTitle>
			<DialogContent>
				<div style={style}>
					{children}
				</div>
			</DialogContent>
			<DialogActions>
				{modalActions}
			</DialogActions>
		</Dialog>
	);
}

export default Modal;