import React from 'react';
import {createRoot} from "react-dom/client";
import App from "./app/App";
import './app/config/i18n';
//import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));
root.render(<React.StrictMode><App/></React.StrictMode>); // <React.StrictMode><App/></React.StrictMode>


// .createRoot() was .render() until upgrade to React 18
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
