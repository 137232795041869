import React from 'react';
import {Paper, Tooltip, Button} from "@mui/material";
import {PAPER_STYLE_SMALL} from "fit/system/FITConstants";
import Theme from "fit/system/Theme";
import User from 'fit/system/User';
import {getSpacing} from "fit/system/UtilityFunctions";
import SecureConnect from "fit/system/SecureConnect";


import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme';
import useJumboFooterTheme from "@jumbo/hooks/useJumboFooterTheme";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";


const UserThemes=()=>{
    const user = new User();


    //const {theme, setTheme} = useJumboTheme();
    //const {setHeaderTheme} = useJumboHeaderTheme();
    //const {setFooterTheme} = useJumboFooterTheme();
    const {sidebarTheme, setSidebarTheme} = useJumboSidebarTheme();


    const doStuff=(id)=>{
        const t = new Theme();

        const header = t.buildTheme(id, 'header');
        const footer = t.buildTheme(id, 'footer');
        const sidebar = t.buildTheme(id, 'sidebar');
        const main = t.buildTheme(id, 'main');
        //setHeaderTheme({...theme, header});
        //setFooterTheme({...theme, footer});
        //setTheme(main);
        setSidebarTheme({...sidebarTheme, ...sidebar});
        //console.log('STATUS COLORS', t.getStatusColors(id));
    }
    const updateThemeColor=(id)=>{
        let sc = new SecureConnect('user.php','post');
        sc.setAction('updateThemeColor');
        sc.setFormData({themeColorID: id})
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                //Update Client Store with new id
                user.updatePreference('themeColorID', parseInt(id));
                doStuff(id);
            }
        })
    }

    const gridStyle={
        display: 'inline-grid',
        gridTemplateColumns: 'auto auto auto auto auto auto',
        columnGap: getSpacing(),
        rowGap: getSpacing(),
        padding: getSpacing('small'),
    }
    const size = 48;
    const colorStyle = {height: size, width: '50%'};
    const buttonStyle = {
        display: 'block',
        border: '2px dashed #f00',
        height: `${size}px`,
        overflow: 'hidden',
        width: `${size}px`,
        cursor: 'pointer'
    }
    const borderWidth = 3;
    const t = new Theme();
    return (
        <Paper style={PAPER_STYLE_SMALL}>
            ThemeColorID: {user.getUserInfo().preferences.themeColorID}
            <br/>
            <Button variant={'contained'} color={'primary'}>
                Primary
            </Button>
            <Button variant={'contained'} color={'secondary'}>
                Secondary
            </Button>
            <div style={gridStyle}>
                {t.themeList.map((theme, k) => {
                    const primary = theme.primary != null ? theme.primary.main : '#00c';
                    const secondary = theme.secondary != null ? theme.secondary.main: '#003';
                    let border;
                    const selected = false;
                    if(selected){
                        border = `${borderWidth}px solid ${primary}`
                    } else if(theme.dark){
                        border = `${borderWidth}px solid #333`;
                    } else{
                        border = 'none';
                    }
                    return (

                            <div onClick={()=>updateThemeColor(theme.id)} key={k}>
                                <Tooltip title={theme.name} placement={'bottom'}>
                                    <div style={{...buttonStyle, border}}>
                                        <div style={{background: primary, ...colorStyle, float: 'left'}}/>
                                        <div style={{background: secondary, ...colorStyle, float: 'left'}}/>
                                    </div>
                                </Tooltip>
                            </div>

                    )
                })
                }
            </div>
        </Paper>
    )
}

export default UserThemes;