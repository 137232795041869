import React, {useState, useEffect} from 'react';
import {Grid, TextField, MenuItem} from "@mui/material";
import User from 'fit/system/User';
import {setPageTitle, isEmpty} from "fit/system/UtilityFunctions";
import ExerciseLeaderPost from "./ExerciseLeaderPost";
import SecureConnect from "fit/system/SecureConnect";
import {Button, IconButton, Tooltip} from "@mui/material";
import {AutoAwesome} from "@mui/icons-material";
import TeamSelectorField from "fit/components/Form/TeamSelectorField";
import Alert from "fit/components/Alert";

import LeaderConfetti from "./LeaderConfetti";

const Leaderboard=({teamSettings, athleteLeaderboard})=>{
    setPageTitle('Team Leaderboard');
    const user = new User();
    const [teamID, setTeamID] = useState(user.initTeamSetting);
    const [allTime, setAllTime] = useState(0);
    const [leaderboard, setLeaderboard] = useState([]);
    const [pullAttempted, setPullAttempted] = useState(false);
    const [confettiToggle, setConfettiToggle] = useState(true);

    useEffect(()=>{
        const teamID = user.initTeamSetting();
        if(teamID != null){
            getLeaderboard(teamID, allTime);
        }
    },[]);

    useEffect(()=>{
        if(teamID != null){
            getLeaderboard();
        }
    },[teamID]);


    const getLeaderboard=(teamID, allTime)=>{
        if(teamID == null || allTime == null){
            return;
        }
        let sc = new SecureConnect(`stat.php?action=getTeamLeaderboard&teamID=${teamID}&allTime=${allTime}`);
        sc.connect().then(json=>{
            const data = sc.getCompleted(json) ? sc.getData(json) : [];
            setLeaderboard(data);
            setPullAttempted(true);
        })
    }
    const handleTeam=(teamID)=>{
        setTeamID(teamID);
        getLeaderboard(teamID, allTime);
    }
    const handleAllTimeSetting=(value)=>{
        setAllTime(parseInt(value));
        getLeaderboard(teamID, value);
    }

    const completed = {backgroundColor: '#00cd1d', color: '#eee', fontWeight: 'bold'};
    const inProcess = {backgroundColor: '#ee0', color: '#000', textDecoration: 'underline'};
    const stretch = {backgroundColor: '#0ff', color: '#000'};


    return(
        <div>
            {/**
             <Button color={'secondary'} variant={'outlined'} onClick={()=>getLeaderboard(31, 1)}
             >CACHE TEST</Button>
            <Tooltip title={'Toggle Confetti'}>
                <span>
                    <IconButton variant={'contained'} color={confettiToggle ? 'primary' : 'inherit'} onClick={()=>setConfettiToggle(!confettiToggle)}><AutoAwesome/></IconButton>
                </span>
            </Tooltip>
             */}

            <LeaderConfetti active={confettiToggle} place={1}/>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    {
                        athleteLeaderboard ?
                            <TextField
                                select
                                fullWidth
                                value={teamID}
                                label={'Select Team'}
                                onChange={(e)=>handleTeam(e.target.value)}
                            >
                                {teamSettings.map((team, k)=>{
                                    return <MenuItem key={k} value={team.teamID}>{team.teamName}</MenuItem>
                                })
                                }
                            </TextField>
                            :
                        <TeamSelectorField
                            fullWidth
                            label={'Select Team'}
                            onChange={(e)=>handleTeam(e.target.value)}
                        />
                    }

                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        select
                        fullWidth
                        label={'Leaderboard'}
                        value={allTime}
                        onChange={(e)=>handleAllTimeSetting(e.target.value)}
                    >
                        <MenuItem value={0}>Current Leaderboard</MenuItem>
                        <MenuItem value={1}>All Time</MenuItem>
                    </TextField>
                </Grid>
                {
                    leaderboard.map((board,key) =>{
                        const {name, athletes, moreBetter} = board;
                        return (
                            <Grid item xs={12} key={key} lg={6}>
                                <ExerciseLeaderPost
                                    masterConfettiToggle={confettiToggle}
                                    moreBetter={moreBetter}
                                    exerciseName={name}
                                    teamID={teamID}
                                    leaders={athletes}
                                />
                            </Grid>
                        )
                    })
                }
                <Grid item xs={12}>
                    <Alert
                        status={'warning'}
                        visible={pullAttempted && isEmpty(leaderboard)}
                        headline={'No Leaderboard At This Time'}
                        message={'The leaderboard is currently unavailable for this team. Check back soon.'}
                    />
                </Grid>
            </Grid>

            {/*
            <div style={{marginTop: getSpacing()}}>
            <Accordion>
                <AccordionSummary expandIcon={'^'}>Leaderboard Specs: DELETE WHEN HAPPY WITH COMPONENT</AccordionSummary>
                <AccordionDetails>
                    <Typography variant={'h3'}>Specification</Typography>
                    <ul>
                        <li style={inProcess}>Pull Entire team list Including Squads & Positions (based on athlete's assignments)</li>
                        <li style={completed}>ALLow Pull for now and all time</li>
                        <li style={completed}>Allow coaches to pick which stats/exercises metrics appear on their leaderboard</li>
                        <li style={completed}>Have top 3 athletes with their stats for each exercise/stat</li>
                        <li style={completed}>If viewer is an athlete, pull athlete's stats and compare (percentages) to the leader's score</li>
                        <li style={completed}>If the user is an athlete, pull their current stats for the selected exercises</li>
                        <li style={stretch}>Have Stars/celebrations/explosions/trophies/sounds? etc if the user is on the leaderboard (TS CONFETTI)</li>
                        <li style={stretch}>Allow Coaches to select different squads/positions</li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            </div>
            */}
        </div>
    )
}

export default Leaderboard;


