import {getSpacing, getStatusColor} from "fit/system/UtilityFunctions";
const cellPadding = '3px';
export const BORDER_COLOR = '#aaa';
const borderStyle = `1px solid ${BORDER_COLOR}`;
const selectedThickness = '4px';


export const WBGStyles ={
    cellPadding,
    borderColor: BORDER_COLOR,
    accordionSummaryStyle:{
        marginTop: '3px'
    },
    alertStyle: {
        marginBottom: getSpacing('small')
    },
    topAlertStyle: {marginTop: getSpacing('small'), marginBottom: getSpacing('small')},
    dayTableStyle: {
        display: 'grid',
        gap: '0',
        gridTemplateColumns: 'repeat(5, 1fr)',
        border: `1px solid ${BORDER_COLOR}`,
        justifyItems: 'center',
        marginBottom: getSpacing()
    },
    dateLabelColumn: {
        //Header for Exercise Dates 00-00-0000
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        borderBottom: borderStyle,
        borderRight: borderStyle,
        padding: cellPadding,
    },
    selectedDateLabelColumn: {
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        borderRightWidth: selectedThickness,
        borderLeftWidth: selectedThickness,
        borderTopThickness: selectedThickness,
        borderBottom: `${borderStyle} !important`,
    },

    dayLabelStyle: {
        //Mon, Tuesday, Weds: Top Left hand corner
        fontWeight: 'bold',
        gridColumnStart: 1,
        gridColumnSpan: 2,
        width: '100%',
        padding: cellPadding,
        borderBottom: borderStyle,
        borderRight: borderStyle,
        textTransform: 'uppercase'
    },
    deleteSetStyle: {color: getStatusColor('error')},
    dragDropStyle: {
        width: getSpacing(),
        padding: cellPadding,
        borderBottom: borderStyle
    },
    exerciseLabelColumnStyle: {
        //For ExerciseLabels
        gridColumnStart: 1,
        width: '100%',
        padding: cellPadding,
        borderBottom: borderStyle,
        borderRight: `5px solid ${BORDER_COLOR}`,
    },
    paperSelectStyle: {
        padding: getSpacing('small')
    },
    repStyle: {width: '33%'},
    setStyle: {display: 'flex', alignItems: 'center'},

    liftLabelCell: {marginTop: cellPadding},

    //ExerciseStyles
    fastRenderInputStyle: {
        border: 'none',
        background: 'transparent',
        borderBottom: '1px solid #aaa',
        height: '1.75em',
        padding: '10px 5px 5px 5px',
        letterSpacing: 'inherit',
        font: 'inherit',
        /*boxsizing: 'content-box',
        display: 'block',*/
        margin: 0
    },
    singleDayColumnStyle: {
        //Exercise Section Where reps get posted
        width: '100%',
        padding: cellPadding,
        borderRight: borderStyle,
        borderBottom: borderStyle,
    },
    noExerciseColumnStyle:{
        width: '100%',
        padding: cellPadding,
        borderRight: borderStyle,
        borderBottom: borderStyle,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedDayColumnStyle: {
        width: '100%',
        borderStyle: 'solid',
        borderTop: 'none',
        borderLeftWidth: selectedThickness,
        borderRightWidth: selectedThickness,
        borderBottom: borderStyle,
    },
    selectedDayColumnLastExercise: {
        width: '100%',
        borderStyle: 'solid',
        borderTop: 'none',
        borderLeftWidth: selectedThickness,
        borderRightWidth: selectedThickness,
        borderBottom: selectedThickness
    },
    percentageStyle: {width: '33%'},
    inCellStyle: {marginTop: cellPadding, width: '33%'},
    noPercentageInputStyle: {width: '50%'},
    labelHeadStylePercent: {display: 'block', width: '33%'},
    labelHeadMainStyle: {display: 'flex', width: '100%'},
    labelHeadStyle: {display: 'block', width: '50%'},
    disabledOpacity: {opacity: '33%'},

    //New Exercise Blocks

    groupUnorderList: {margin: 0, padding: 0},
    groupListItem: {listStyleType: 'none'},


    //SuperSetting
    superSetStyles:{
        //No GroupIndex of 0
        1: {color: '#F00', rgb: {r: 255, g: 0, b: 0}},
        2: {color: '#00F', rgb: {r: 0, g: 0, b: 255}},
        3: {color: '#ee0', rgb: {r: 238, g: 238, b: 0}},
        4: {color: '#0F0', rgb: {r: 0, g: 255, b: 0}},
        5: {color: '#F90', rgb: {r: 255, g: 153, b: 0}},
        6: {color: '#90F', rgb: {r: 153, g: 0, b: 255}},
        7: {color: '#FF4500', rgb: {r: 255, g: 69, b: 0}},
        8: {color: '#F0F', rgb: {r: 255, g: 0, b: 255}},
        9: {color: '#9F0', rgb: {r: 153, g:255, b: 0}}
    }


};



export default WBGStyles;

export const getSupersetGroupColor=(groupIndex)=>{
    return WBGStyles.superSetStyles[groupIndex];
}