import React from 'react';
import {getSpacing, getStatusColor} from "../../system/UtilityFunctions";
import {MobileFriendly, RememberMe, Smartphone, MobileOff} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import moment from 'moment';
import UserPreferences from "fit/system/UserPreferences";
import {MOMENT_DATE_TIME_FORMAT} from "../../system/FITConstants";


const DeviceWorkoutStatus=({active = true, assignedWorkout = false, personalWorkout = false, screenValue = 0, updated})=>{
    //updated = UTC Zulu Timestamp YYYY-MM-DD HH:MM:SS of when the device was last updated
    //use moment to show when last update occurred



    const getUpdated=()=>{
        const updateProvided = updated != null && updated.length > 0;
        if(!updateProvided){
            return null;
        }
        const userPrefs = new UserPreferences()
        const formattedTime = userPrefs.convertServerDate(updated);
        const timeString = `Updated: ${moment.utc(updated).local().fromNow()}`;
        return (
            <Tooltip title={`Last Updated: ${formattedTime}`} placement={'right'}>
                <span>{timeString}</span>
            </Tooltip>
        )
    }

    active = active === true || parseInt(active) === 1;
    //Set the icon based on assigned workout and personal workout settings
    let icon, statusLabel;
    const warningColor = getStatusColor('warning');
    const iconStyle = {position: 'relative', top: getSpacing('iconAlignment')}
    const successStyle = {color: getStatusColor('success'), ...iconStyle}
    const personStyle = {color: warningColor, ...iconStyle};
    if(active) {
        if (assignedWorkout && personalWorkout) {
            icon = <RememberMe style={personStyle}/>;
        } else if (assignedWorkout && !personalWorkout) {
            icon = <MobileFriendly style={successStyle}/>;
        } else {
            icon = <Smartphone style={{...iconStyle, opacity: '.70'}}/>
        }

        //Determine the status Label
        const status = assignedWorkout ? personalWorkout ? 'Personal Workout' : 'Active' : 'Ready';
        let label;
        switch (parseInt(screenValue)) {
            case -1:
                label = 'Pending';
                break;
            case 0:
                label = !personalWorkout ? 'Waiting' : 'Workout Selection';
                break;
            case 1:
                label = 'Athlete Selection'
                break;
            case 2:
                label = 'In Progress';
                break;
            case 3:
                label = 'Completed';
                break;
            default:
                label = 'Pending';
                break;
        }
        statusLabel = `${status}: ${label}`;
    } else{
        //Inactive
        icon = <MobileOff style={personStyle}/>;
        statusLabel = <span style={{color: warningColor}}>Inactive</span>;
    }

    return (
        <div>
            <span>{icon} {statusLabel}</span>
            <br/><small>{getUpdated()}</small>
        </div>
    );

}

export default DeviceWorkoutStatus;