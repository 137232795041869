import React,{useState, useEffect} from 'react'
import Link from 'fit/components/System/Link';
import SecureConnect from 'fit/system/SecureConnect';
import {getStatusByCode, setPageTitle} from 'fit/system/UtilityFunctions';
import RequestResetForm from "./RequestResetForm";
import ResetForm from "./ResetForm";
import {Typography} from "@mui/material";
import User from 'fit/system/User';

const initState=(type)=>{
    switch(type){
        case 'checks':
            return {
                minChars: false,
                number: false,
                capital: false,
                lower: false,
                special: false,
                match: false
            }
        case 'form':
            return {
                email: '',
                originalPassword: '',
                password: '',
                passwordMatch: '',
                validToken: '', //Token that gets set from pass reset email
            }

        case 'response':
            return {
                status: 'error',
                headline: '',
                message: '',
            }
        case 'status':
            return {
                tokenChecked: false, //For component mounting
                submitDisabled: false, //Allow someone to press the button after it's already been pressed.
                validResetRequest: false, //Determines whether to display reset form or password form
                passwordVisible: false, //make the password visible
                requested: false,
                resetCompleted: false,
            }

    }
}


const ResetPassword=({resetKey, profileReset, deviceMasterID})=> {
    const [form, setForm] = useState(initState('form'));
    const [statuses, setStatuses] = useState(initState('status'));
    const [response, setResponse] = useState(initState('response'));
    const [checks, setChecks] = useState(initState('checks'));

    //Mounting
    const user = new User();
    const authorized = user.getLoggedInStatus();
    useEffect(() => {
        const resetToken = resetKey && resetKey !== '' ? resetKey : false;
        if (!resetToken || statuses.tokenChecked === true) {
            return;
        }
        const url = `system.php?action=checkPasswordResetRequest&token=${resetToken}`;
        let sc = new SecureConnect(url);
        sc.setDisplayNotifications(true);
        sc.connect().then(json => {
            let s = structuredClone(statuses);
            s.tokenChecked = true;
            if (sc.getCompleted(json)) {
                //Token is valid - set passwordResetRequest
                let f = structuredClone(form);
                let r = structuredClone(response);

                //Set Status
                s.validResetRequest = true;

                //Set Response Code
                r.status = getStatusByCode(sc.getResponseCode(json));
                r.headline = sc.getResponse(json).headline;
                r.message = sc.getResponseMessage(json);
                setResponse(r);
                //Set the Token
                f.validToken = resetKey;
                setForm(f);
            }
            setStatuses(s);
        }, [statuses.tokenChecked]);
    })

    //password checks when updating password, passwordmatch fields
    useEffect(() => {
        regexPasswordCheck();
    }, [form.password, form.passwordMatch]);

    const setAlert = (code, headline, message, statusUpdates = {}) => {
        //Set set server response
        setResponse({status: getStatusByCode(code), headline, message});
        //update statuses
        let s = {...structuredClone(statuses), ...statusUpdates, requested: true};

        setStatuses(s);
    }
    const requestReset = () => {
        let sc = new SecureConnect('system.php', 'post');
        sc.setAction('requestPasswordReset');
        sc.setFormData({email: form.email});
        sc.setDisplayNotifications(true);
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json => {
            const completed = sc.getCompleted(json);
            const response = sc.getResponse(json);
            const headline = completed ? 'Email Sent' : 'Failed';
            //Set SuccessMessage
            setAlert(response.code, headline, response.msg, {requestCompleted: true});
        });
    }
    const regexPasswordCheck = () => {
        const p = form.password;
        let lengthCheck = p.length >= 12;
        let capitalCheck = p.search(/[A-Z]/) > -1;
        let lowercaseCheck = p.search(/[a-z]/) > -1;
        let numberCheck = p.search(/\d/) > -1;
        let specialCheck = p.search(/[!@#$%^&*()+=\-\[\]\';,.\/{}|":<>?~\\\\]/) > -1;
        let passMatch = lengthCheck && p === form.passwordMatch;
        const obj = {
            minChars: lengthCheck,
            number: numberCheck,
            capital: capitalCheck,
            lower: lowercaseCheck,
            special: specialCheck,
            match: passMatch
        }
        setChecks(obj);
    }
    const toggleVisibility = () => {
        let s = structuredClone(statuses);
        s.passwordVisible = !statuses.passwordVisible;
        setStatuses(s);
    }
    const resetPassword = () => {
        const passMatch = form.password === form.passwordMatch;
        if (!passMatch) {
            setAlert(3, 'Password mistyped?', 'Your new passwords don\'t match.');
            return;
        }

        let action = 'resetPassword';
        let formData = {token: form.validToken, password: form.password}
        if (profileReset) {
            action = 'resetPasswordFromProfile';
            formData = {currentPassword: form.originalPassword, password: form.password};
            if(deviceMasterID != null && parseInt(deviceMasterID) > 0){
                formData.masterID = parseInt(deviceMasterID);
            }
        }


        if (false) {
            setAlert(1, 'Test Completed', 'Yay Successful', {submitDisabled: true, resetCompleted: true});
            return;
        }

        let sc = new SecureConnect('system.php', 'post');
        sc.setAction(action);
        sc.setFormData(formData);
        sc.connect().then(json => {
            const response = sc.getResponse(json);
            let headline = 'Password Reset';
            let submitDisabled = true;
            const resetCompleted = sc.getCompleted(json);
            let formMessage = '';
            if (resetCompleted) {
                //Password reset. Update the message
                if (profileReset) {
                    formMessage = "Success! You've updated your password.";
                } else {
                    const link = <Link to={`/login/`}>Log into your account</Link>;
                    formMessage = <span>Success! You've updated your password. {link} now.</span>;
                }
                //Update session storage - will tell sign-in to unset user information
                sessionStorage.setItem('resetPasswordComplete', 1);
            } else {
                headline = 'Whoops!';
                formMessage = response.msg;
                submitDisabled = false;
            }

            setAlert(response.code, headline, formMessage, {submitDisabled, resetCompleted});
        });
    }
    const handleChange = (e) => {
        const {name, value} = e.currentTarget;
        let f = structuredClone(form);
        f[name] = value;
        setForm(f);
    }

    if (profileReset === true) {
        //From the profile settings page within the permissions
        return (
            <div>
                <ResetForm
                    handleChange={(e) => handleChange(e)}
                    profileReset={true}
                    form={form}
                    passwordChecks={checks}
                    passwordVisible={statuses.passwordVisible}
                    resetPassword={() => resetPassword()}
                    resetCompleted={statuses.resetCompleted}
                    requested={statuses.requested}
                    serverResponse={response}
                    toggleVisibility={() => toggleVisibility()}
                />
            </div>
        )
    }/* else if(authorized && profileReset === false){
        //Trying to access reset-password url while logged in
        //Forward to profile
        return (<Navigate to={URL_MY_PROFILE}/>);
    }*/ else if(statuses.validResetRequest){
        setPageTitle('Reset Your Password');
        return (
            <div>
                <Typography variant={'h1'}>Reset Your Password</Typography>
                <ResetForm
                    handleChange={(e)=>handleChange(e)}
                    form={form}
                    profileReset={false}
                    passwordChecks={checks}
                    passwordVisible={statuses.passwordVisible}
                    resetPassword={()=>resetPassword()}
                    resetCompleted={statuses.resetCompleted}
                    requested={statuses.requested}
                    serverResponse={response}
                    toggleVisibility={()=>toggleVisibility()}
                />
            </div>
        )
    } else{
        setPageTitle('Reset Your Password');
        return (
            <div>
                <Typography variant={'h1'}>Reset Your Password</Typography>
                <RequestResetForm
                    disabled={statuses.submitDisabled}
                    email={form.email}
                    handleChange={(e)=>handleChange(e)}
                    requested={statuses.requested}
                    requestReset={()=>requestReset()}
                    serverResponse={response}
                />
            </div>
        )
    }


}

export default ResetPassword;