import React from 'react';
import {TextField} from '@mui/material';
import InputMask from "react-input-mask";

class MaskedInput extends React.Component{
	getPattern(pattern){
		const patterns = {
			phone: "(999) 999-9999",
			phoneExtension: '999999',
			zip: '99999',
			creditCard: '9999  9999  9999  9999',
			americanExpress: '9999  9999999  99999',
			canadianZip: 'a9a 9a9',
			dateTime: '99-99-9999 99:99',
			csc: '9999'
		};
		return patterns[pattern];
	}
	getPlaceHolder(pattern){
		const phs = {
			phone: "(888) 643-2583",
			phoneExtension: '1234',
			zip: '49525',
			creditCard: '0000  0000  0000  0000',
			americanExpress: '0000  0000000  00000',
			canadianZip: 'H0H 0H0',
			dateTime: '10-31-2020 12:15',
			csc: '0000'
		};
		return phs[pattern] != null ? phs[pattern] : '';
	}
	setFunction(fn){
		//initialize: Set the functions as the functions or null, probably unnecessary
		//return fn;
		return fn != null ? fn.bind(this) : null;
	}
	wrapChange(e){
		//wrapper for handlechange
		if(this.undefinedEvent(e)){
			return;
		}
		const change = this.setFunction(this.props.onChange);
		if(change != null){
			change(e);
		}
	}
	wrapBlur(e){
		//wrapper for handleBlur
		if(this.undefinedEvent(e)){
			return;
		}
		const blur = this.setFunction(this.props.onBlur);
		if(blur != null){
			blur(e);
		}
	}
	undefinedEvent(e){
		return e.currentTarget == null;
	}

	render(){
		const defaultPattern = '******************************';
		let {label, error, touched, helperText, name, type, defaultValue, disabled} = this.props;
		const pattern = this.props.pattern != null ? this.getPattern(this.props.pattern) : defaultPattern;
		let value = this.props.value || '';
		let shrinkValue = true;
		//Probably defunct
		if(defaultValue != null){
			//Using defaultValue/Onblur pattern - get rid of value
			value = null;
		}
		const placeholder = this.props.placeholder != null ? this.props.placeholder : this.getPlaceHolder(this.props.pattern);
		//ORIGINAL

		//Format case for canadian zip codes
		const propPattern = this.props.pattern;
		value = propPattern === 'canadianZip' && value != null ? value.toUpperCase() : value;

		return (
			<InputMask
				mask={pattern}
				value={value}
				defaultValue={defaultValue}
				onChange={this.wrapChange.bind(this)}
				onBlur={this.wrapBlur.bind(this)}
				maskChar={""}
				disabled={disabled}
			>
				{() => <TextField
					fullWidth={this.props.fullWidth}
					label={label}
					error={error != null && error !== '' && (touched == null || touched)}
					helperText={error || helperText || ''}
					defaultValue={defaultValue}
					value={value}
					name={name}
					placeholder={placeholder}
					type={type || 'text'}
					InputProps={this.props.InputProps}
					InputLabelProps={{ shrink: shrinkValue }}
					disabled={disabled}
				/>}
			</InputMask>
		);
	}
};

export default MaskedInput;