import React,{useState, useRef, useEffect} from 'react';
import useTimeout from 'fit/components/Functional/useTimeout'
import QrScanner from 'qr-scanner';
import './qr-scan.css';
import SecureConnect from "fit/system/SecureConnect";
import Modal from "../Dialogs/Modal";
import {Button, Typography, Paper, Collapse} from "@mui/material";
import {Close, NoPhotography} from "@mui/icons-material";
import Alert from "../Alert";
import{getSpacing} from "fit/system/UtilityFunctions";

const QRCodeScanner = ({userProfile = true}) => {
    const videoRef = useRef(null);
    const [initialized, setInitialized] = useState(false);
    const [qrScanner, setQrScanner] = useState(null);
    const [paused, setPaused] = useState(false);
    const [scannerOn, setScannerOn] = useState(true);
    const [camHeight, setCamHeight] = useState('auto');
    const [camWidth, setCamWidth] = useState('auto');
    const [noCamera, setNoCamera] = useState(false);
    const [displayNoCamera, setDisplayNoCamera] = useState(false);
    const [pending, setPending] = useState(false);
    const [handshakeComplete, setHandshakeComplete] = useState(false);

    useTimeout(()=>{
        if(handshakeComplete) {
            setHandshakeComplete(false);
        }
    },30000, [handshakeComplete]);

    async function wait(){
        await new Promise (resolve => setTimeout(resolve, 750));
        return true;
    }

    async function contactServer(loginCode = '', pendingStatus){
        if(loginCode === '' || loginCode.substr(0,4) !== 'BT0_' || pending || handshakeComplete){
            const prefix = loginCode.substr(0,4)
            console.log('PREFIX::', prefix, 'CODE Valid', prefix === 'BT0_', 'PENDING', pending, 'COMPLETED', handshakeComplete);
            return;
        }

        setPending(true);

        await wait();
        //console.log('DELAY COMPLETE');
        //console.log('PENDING', pending, 'STATUS', pendingStatus, 'COMPLETE', handshakeComplete, loginCode);
        const sc = new SecureConnect('system.php', 'post');
        sc.setAction('completeDeviceLogin');
        sc.setCacheExpiration(15000);
        sc.setFormData({code: loginCode});
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                setHandshakeComplete(true);
                setPending(false);
                if(userProfile){
                    toggleStop();
                }
            }
        })
    }

    useEffect(() => {
        try{
            if (videoRef.current) {
                const newQrScanner =  new QrScanner(videoRef.current, onScanSuccess, {
                    onDecodeError: onScanFail,
                    // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
                    preferredCamera: "environment",
                    // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
                    highlightScanRegion: true,
                    // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
                    highlightCodeOutline: true,
                });

                const originalQrScanner = new QrScanner(
                    videoRef.current,
                    result => {
                        contactServer(result.data, pending);
                    },
                    {
                        onDecodeError: error => {
                            console.error(error);
                        },
                        highlightScanRegion: true,
                        highlightCodeOutline: true,
                        scanRegion: {
                            className: 'scan-region-highlight',
                        },
                        codeOutline: {
                            className: 'code-outline-highlight',
                        },
                    }
                );
                setQrScanner(newQrScanner);
                newQrScanner.start();
                manageCamHeight();
                return () => {
                    newQrScanner.destroy();
                };
            }
        } catch (error){
            setNoCamera(true);
            setDisplayNoCamera(true);
        }
        setInitialized(true);
    }, []);


    // Fail
    const onScanFail = (err: string | Error) => {
        // 🖨 Print the "err" to browser console.
        //console.log(err);
    };
// Success
    const onScanSuccess = (result: QrScanner.ScanResult) => {
        // 🖨 Print the "result" to browser console.
        //console.log('RESULT ==>', result);
        // ✅ Handle success.
        // 😎 You can do whatever you want with the scanned result.
        if(result?.data) {
            //console.log(result.data);
            contactServer(result.data, pending);
        }
    };
    const toggleStart = () => {
        if (qrScanner) {
            qrScanner.start();
            setPaused(false);
            setScannerOn(true);
            manageCamHeight();
        }
    };

    const manageCamHeight=()=>{
        let height = 'auto';
        let width = 'auto';
        if(videoRef && videoRef.current) {
            height = `${videoRef.current.offsetHeight}px`;
            width = `${videoRef.current.offsetWidth}px`;
        }
        setCamHeight(height);
        setCamWidth(width);
    }

    const toggleStop = () => {
        if (qrScanner) {
            //qrScanner.stop();
            setScannerOn(false);
        }
        manageCamHeight();
    };

    const togglePause = () => {
        if (qrScanner) {
            if (paused) {
                qrScanner.start();
            } else {
                qrScanner.pause();
            }
            setPaused(!paused);
        }
    };

    const successHeadline = 'Login Complete!'
    const successMessage = 'You have successfully logged in. The device will update momentarily.';
    const debug = true;

    return (
        <div>
            <Alert
                status={'error'}
                visible={initialized && (noCamera || displayNoCamera || videoRef == null || videoRef.current == null)}
                headline={'Camera Error/No Camera Available'}
                message={'There was a problem loading the QR Code scanner. Try restarting the camera.'}
            />
            <Collapse in={scannerOn}>
            <div style={{
                overflow: 'hidden',
                position: 'relative',
                borderRadius: '4px',
                height: camHeight
            }}>
                <video
                    ref={videoRef}
                    style={{
                        width: '100%',
                        height: 'auto',
                        position: 'relative',
                        overflow: 'scroll',
                        borderRadius: '4px',
                    }}
                />
            </div>
            </Collapse>

            {debug &&
            <div style={{marginTop: getSpacing()}}>
                <button onClick={toggleStart}>Start Scan</button>
                <button onClick={toggleStop}>Stop Scan</button>
                <button onClick={togglePause}>
                    {paused ? 'Resume' : 'Pause'}
                </button>
                <Button
                    color={handshakeComplete ? 'secondary' : 'primary'}
                    variant={'contained'}
                    onClick={() => setHandshakeComplete(!handshakeComplete)}
                >Update Handshake</Button>
                <Button
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={() => {
                        scannerOn ? toggleStop() : toggleStart()
                    }}
                >Toggle Camera</Button>

                <br/>HANDSHAKE: {handshakeComplete ? 1 : 0}
                <br/>PENDING: {pending ? 1 : 0}
                <br/>PROFILE: {userProfile ? 1 : 0}
                <br/>Dims: W: {camWidth}, H: {camHeight}
                <br/>CAM ?: {noCamera ? 0 : 1}
                <br/>Display NO Cam: {displayNoCamera ? 1 : 0}
            </div>
            }


            <Modal
                open={handshakeComplete}
                title={'Login Completed'}
                size={'sm'}
                closeFunction={(()=>setHandshakeComplete(false))}
                modalActions={
                    <Button color={'secondary'} onClick={()=>setHandshakeComplete(false)} variant={'contained'}>
                        <Close/> Close
                    </Button>
                }
            >
                <Alert
                    status={'success'}
                    visible={true}
                    headline={successHeadline}
                    message={successMessage}
                >

                </Alert>
            </Modal>

            <Modal
                open={noCamera && displayNoCamera}
                title={'Camera Disabled'}
                size={'sm'}
                closeFunction={()=>setDisplayNoCamera(false)}
                modalActions={
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        onClick={()=>setDisplayNoCamera(false)}
                    ><Close/> OK</Button>
                }
            >
                <Typography variant={'body1'}>
                    Your camera is currently disabled. You will need to enable your camera to scan the QR code
                </Typography>
            </Modal>


        </div>
    );
};

export default QRCodeScanner;