import React from 'react';
import {AppBar, Toolbar, IconButton, Typography, Slide, Dialog} from "@mui/material";
import {getSpacing} from "fit/system/UtilityFunctions";
import {Close} from '@mui/icons-material';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const FullscreenDialog=({noMargin, open, closeFunction, title, children})=>{
	const renderInteriorContent=()=>{
		if(noMargin != null || noMargin === true){
			return (
				children
			);
		}
		return (
			<div style={{padding: getSpacing('')}}>
				{children}
			</div>
		)
	}
	return (
		<Dialog fullScreen
				open={open}
				TransitionComponent={Transition}>
			<AppBar position="relative">
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={closeFunction} aria-label="Close">
						<Close/>
					</IconButton>
					<Typography variant="h6" color="inherit" style={{position: 'relative', top: '4px'}}>
						{title}
					</Typography>
				</Toolbar>
			</AppBar>
			<div style={{padding: getSpacing('normal')}}>
				{children}
			</div>
		</Dialog>
	);
}


export default FullscreenDialog;