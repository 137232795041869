import React from 'react';
import Div from "@jumbo/shared/Div";
import useJumboFooterTheme from "@jumbo/hooks/useJumboFooterTheme";
import useJumboLayoutFooter from "@jumbo/hooks/useJumboLayoutFooter";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {useTheme} from '@mui/material';

const JumboLayoutFooter = ({children}) => {
   /*
    const {footerTheme} = useJumboFooterTheme();
    const {footerOptions} = useJumboLayoutFooter();
    const theme = useTheme();
    console.log('THEME = ', theme);
    if(footerOptions?.hide) {
        return null;
    }
    //console.log('FOOTER THEME', footerTheme);



    console.log('*****');
    console.log('*****');
    console.log('*****');
    console.log('*****');
    console.log('FOOTER');
    console.log('FOOTER THEME BACKGROUND', footerTheme.palette.background);
    console.log(footerTheme.footer);
    console.log('*****');
    console.log('*****');


    return (
        <ThemeProvider theme={footerTheme}>
            <Div className="CmtLayout-footer">
                {children}
            </Div>
        </ThemeProvider>
    );

    */
    return (<Div className="CmtLayout-footer">
        {children}
    </Div>)
};

export default JumboLayoutFooter;
