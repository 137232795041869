import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Grid,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs, TextField, MenuItem,
    Tooltip, IconButton
} from "@mui/material";
import TabBox from "../TabBox";
import {List, MobileFriendly, MobileOff, Smartphone, Backspace, CheckCircle, Cancel} from "@mui/icons-material";
import {getSpacing, getStatusByCode, isEmpty} from "fit/system/UtilityFunctions";
import AthleteRowSearch from "../AthleteRow/AthleteRowSearch";
import DeviceSelectionRow from "./DeviceSelectionRow";
import SecureConnect from "fit/system/SecureConnect";
import {SEARCH_MINIMUM_LENGTH, DEVICE_CONSTANTS} from "fit/system/FITConstants";
import Alert from "../Alert";
import Modal from "../Dialogs/Modal";


const initDeviceGroups =()=>{
    return [{name: 'All Devices', value: -1}];
}
const initServerAlert=()=>{
    return {
        display: false,
        status: 'success',
        headline: 'headline',
        message: 'message',
    }
}

const initTab=(selectionTab)=>{
    const {assignedDevices} = DEVICE_CONSTANTS;
    const selectionTabValue = parseInt(selectionTab);
    return selectionTab != null && selectionTabValue >= 0 && selectionTabValue <= 3 ? selectionTabValue : assignedDevices;
}


const DeviceSelectionInterface=({
    action,
    closeOnCompletion = false, //Close the confirmation modal when finished
    displayInactive = true,
    reloadDevices,
    selectionTab,
    workoutID,
    teamID,
    completeFunction,
    reloadCompletedFunction,
    })=>{
    const {availableDevices, assignedDevices, allDevices, inactiveDevices, noWorkoutID, NO_ACTION, PULL_ACTION, PUSH_ACTION, MANAGE_ACTION} = DEVICE_CONSTANTS;
    workoutID = parseInt(workoutID);
    const workoutSelected = workoutID !== noWorkoutID;
    const startingTab = initTab(selectionTab);
    /*
        -localDeviceAction required.
        -The true action the user is performing is dependent on the status of the device lists
        -If they're pushing/managing. The action will be dependent on whether a device has already been assigned.
        -If the workout hasn't already been assigned. They're pushing. Otherwise they're managing with an already active list
        -This will disable devices assigned (when push/manage)  and unassigned (pulling) outside the workout id
    */
    const [localAction, setLocalAction] = useState(action);
    const [deviceList, setDeviceList] = useState([]);
    const [deviceTab, setDeviceTab] = useState(startingTab);
    const [deviceGroups, setDeviceGroups] = useState(initDeviceGroups);
    const [selectedFilter, setSelectedFilter] = useState(-1);
    const [deviceGroupsFilter, setDeviceGroupsFilter] = useState(-1); //All Devices default
    const [deviceListRequested, setDeviceListRequested] = useState(false);
    const [preList, setPreList] = useState([]); //list of pre-assigned devices that will get added to pull list
    const [pushList, setPushList] = useState([]); //list of devices to push
    const [pullList, setPullList] = useState([]); //list of devices to pull
    const [searchText, setSearchText] = useState('');
    const [serverAlert, setServerAlert] = useState(initServerAlert);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    //Component Did Mount
    useEffect(()=>{
        if(isEmpty(deviceList) && deviceListRequested === false){
            getDeviceList();
        }
    },[]);

    //changing selection tab from parent component
    useEffect(()=>{
        setDeviceTab(initTab(selectionTab));
    },[selectionTab]);

    //Clear selection when the action changes
    useEffect(()=>{
        setPullList([]);
        setPushList([]);
        setPreList([]);
        setDisplayConfirmation(false);
    },[action])

    //Reload the device list from a parent component
    //Mark completed once finished
    useEffect(()=>{
        if(reloadDevices === true){
            getDeviceList();
            if(reloadCompletedFunction != null){
                reloadCompletedFunction();
            }
        }
    },[reloadDevices]);

    //Prefill selected DeviceIDs based on workoutID/action
    useEffect(()=>{
        const pushingActions = [PUSH_ACTION, MANAGE_ACTION];
        const assigningWorkout = pushingActions.includes(action);
        if(assigningWorkout && workoutID !== noWorkoutID && !isEmpty(deviceList)){
            //workout ID selected. Prefill preList if action is MANAGE_ACTION or PULL_ACTION
            let list = [];
            deviceList.forEach(d =>{
                const userID = parseInt(d.userID);
                if(parseInt(d.workoutID) === workoutID){
                    list.push(userID);
                }
            })
            setPreList(list);
            const interfaceAction = !isEmpty(list) ? MANAGE_ACTION : PUSH_ACTION;
            setLocalAction(interfaceAction);
        }
        if(!assigningWorkout){
            setLocalAction(action);
        }
    },[workoutID, deviceList, action]);

    const commitAction=()=>{
        if(action === NO_ACTION){
            return; //Die
        }
        let sc = new SecureConnect('devices.php', 'post');
        sc.setAction('manageWorkoutDevices');
        const pushing = localAction === PUSH_ACTION || localAction === MANAGE_ACTION;
        let formData = {pullList, pushList};
        if(pushing){
            formData.workoutID = workoutID;
        }
        sc.setFormData(formData);
        sc.connect().then(json=>{
            let message = sc.getResponseMessage(json);
            let headline = 'Error Occurred';
            const status = getStatusByCode(sc.getResponseCode(json))
            if(sc.getCompleted(json)){
                //Device Assignments changed. Update deviceList,
                getDeviceList();
                //Reset selected DeviceIDs (previous job complete)
                setPushList([]);
                setPullList([]);
                setPreList([]);
                //Reset User filters
                resetUserFilters();
                //Process any other completion function from parent component
                if(completeFunction != null){
                    //Run any additional function upon completion of the localAction
                    completeFunction();
                }
                if(closeOnCompletion === true){
                    closeConfirmation();
                }

                const noun = workoutSelected ? 'workout' : 'workouts';
                const verb = workoutSelected ? 'has' : 'have';
                switch (localAction){
                    case PULL_ACTION:
                        headline = 'Workouts Successfully Deactivated';
                        message = `The ${noun} ${verb} been successfully pulled from the selected devices.`;
                        break;
                    case PUSH_ACTION:
                        headline = 'Workouts Successfully Activated';
                        message = `Your ${noun} ${verb} been successfully published to the selected devices.`;
                        break;
                    case MANAGE_ACTION:
                        headline = 'Modifications Successful';
                        message = `The device workout assignments have been adjusted.`;
                        break;
                    default:
                        headline = 'Success!';
                        message = 'Action completed.';
                        break;
                }
            }
            const alert = {
                display: true,
                status,
                headline,
                message
            }
            setServerAlert(alert);
        })
    }
    const filterDevices=()=>{
        let list = deviceList;
        const activeAction = [PUSH_ACTION, PULL_ACTION, MANAGE_ACTION].includes(localAction);
        if(searchText.length >= SEARCH_MINIMUM_LENGTH) {
            list = deviceList.filter(d => `${d.deviceName}${d.teamName}${d.blockTitle}${parseInt(d.dayIndex) + 1}`.toLowerCase().includes(searchText.toLowerCase()));
        }
        if(deviceGroupsFilter > -1){
            list = list.filter(d=> parseInt(d.parentID) === deviceGroupsFilter);
        }
        if(activeAction && selectedFilter > -1){
            const selectValue = selectedFilter === 1;
            let selectList;
            if(localAction === PUSH_ACTION || localAction === PULL_ACTION){
                selectList = localAction === PUSH_ACTION ? pushList : pullList;
            }
            else{
               //MANAGE: Group pre-existing list with the push list
               selectList = pushList.concat(preList);
            }
            list = list.filter(d => selectList.includes(parseInt(d.userID)) === selectValue);
        }
        let active = 1;
        let workoutAssigned = 0;
        const all = deviceTab === allDevices;
        switch (deviceTab) {
            case assignedDevices:
                workoutAssigned = 1;
                break;
            case availableDevices:
                workoutAssigned = 0;
                if(localAction === MANAGE_ACTION && workoutSelected){
                    //return both devices that haven't been assigned or devices that have been assigned that match the existing workout
                    return list.filter(d=> (parseInt(d.workoutAssigned) === workoutAssigned || (parseInt(d.workoutID) === workoutID) && parseInt(d.deviceAssigned) === active));
                }
                break;
            case inactiveDevices:
                active = 0;
                workoutAssigned = 0;
                break;
            default:
                break;
        }
        return all ? list : list.filter(d=>parseInt(d.workoutAssigned) === workoutAssigned && parseInt(d.deviceAssigned) === active);
    }
    const getDisplayedDeviceIDList=()=>{
        let userList = [];
        const deviceList = getToggleFilter();
        if(deviceList.length === 0){
            return userList;
        }
        deviceList.forEach(d=>{
            userList.push(parseInt(d.userID));
        })
        return userList;
    }
    const checkDeviceListMatch=(checkList)=>{
        const deviceList = getToggleFilter();
        if(isEmpty(deviceList) || isEmpty(checkList)){
            //No devicelist or nothing to check. Return defaults.
            return {toggleChecked: false, conflictCount: 0, overwritingWorkouts: false};
        }
        let toggleChecked = deviceList.length <= checkList.length;
        let conflictCount = 0;
        deviceList.forEach(d=>{
            const userID = parseInt(d.userID);
            const workoutAssigned = parseInt(d.workoutAssigned) === 1;
            const activeWorkoutID = parseInt(d.workoutID);
            //console.log('CONFLICT CHECK', userID, workoutAssigned, activeWorkoutID, 'WORKOUT', workoutID, '____CONFLICT', workoutAssigned && activeWorkoutID !== workoutID);
            if(!checkList.includes(userID)){
                toggleChecked = false;
            }
            if(workoutAssigned && activeWorkoutID !== workoutID){
                conflictCount++;
            }
        });
        return {toggleChecked, overwritingWorkouts: conflictCount > 0, conflictCount};
    }
    const closeConfirmation=()=>{
        setServerAlert(initServerAlert);
        setDisplayConfirmation(false);
    }
    const getToggleFilter=()=>{
        return filterDevices().filter(d =>{
            const assigned = parseInt(d.deviceAssigned) === 1;
            const workoutAssigned = parseInt(d.workoutAssigned) === 1;
            const workoutMismatch = workoutAssigned && parseInt(d.workoutID) !== workoutID;
            const pushingWorkout = localAction === PUSH_ACTION || localAction === MANAGE_ACTION;
            const pullingWorkout = localAction === PULL_ACTION;
            const selectable = (pushingWorkout && !workoutMismatch) || (pullingWorkout && workoutAssigned);
            const teamAccessible = pullingWorkout || d.teams.includes(parseInt(teamID));
            return (assigned && selectable && teamAccessible);
        })
    }
    const getToggleAllSettings=()=>{
        //determines toggle All
        const noPulls = pullList.length === 0;
        const noPush = pushList.length === 0;
        const preExists =  preList.length > 0
        let toggleColor = 'primary';
        let data;
        const preChecked = preExists && noPulls && noPush;
        if(localAction === PUSH_ACTION && !noPush){
            //PUSHING WORKOUTS
            data = checkDeviceListMatch(pushList);
        } else if(localAction === PULL_ACTION && !noPulls){
            //PULLING WORKOUTS
            data =  checkDeviceListMatch(pullList);
        } else if(localAction === MANAGE_ACTION){
            //MANAGING WORKOUTS
            toggleColor = noPulls && noPush || deviceTab === assignedDevices ? 'secondary' : 'primary';
            data = checkDeviceListMatch(pushList.concat(preList));
            console.log('MANAGE :: PRE CHECKED ? ', preChecked , data);
            if(noPulls === false || isEmpty(preList)){
                //Toggle OFF when pulling a pre-existing workout
                data.toggleChecked = false;
            } else {
                data.toggleChecked = preChecked || data.toggleChecked;
            }
        } else {
            //NO ACTION - Return defaults
            data = {toggleChecked: false, overwritingWorkouts: false, conflictCount: 0};
        }
        return {...data, toggleColor}
    }
    const toggleAllDevices=(toggleAll)=>{
        const pushing = localAction === PUSH_ACTION
        let newDeviceList = pushing ? [...pushList] : [...pullList];
        //console.log('TOGGLE ALL DEVICES', toggleAll, 'ACTION ? ', localAction);
        if(toggleAll){
            //Add devices to the selected list
            const devices = getToggleFilter();
            devices.forEach(d =>{
                const userID = parseInt(d.userID);
                if(!newDeviceList.includes(userID)) {
                    newDeviceList.push(userID);
                }
            })
        } else{
            newDeviceList = [];
        }
        if(localAction === PUSH_ACTION){
            setPushList(newDeviceList);
        } else if(localAction === PULL_ACTION){
            setPullList(newDeviceList);
        } else{
            if(toggleAll){
                //Push devices that aren't already active
                const clearedPreExisting = newDeviceList.filter(d=>!preList.includes(d));
                setPushList(clearedPreExisting);
                setPullList([]);
            } else{
                //remove devices that are already active
                setPushList([]);
                setPullList([...preList]);
            }
        }
    }
    const toggleSelectedDevice=(deviceID, assignToPullList = false)=>{
        console.log('TOGGLING', deviceID, assignToPullList);
        const pulling = assignToPullList || localAction === PULL_ACTION;
        let newDeviceList = pulling  ? [...pullList] : [...pushList];
        if(newDeviceList.includes(deviceID) === false){
            newDeviceList.push(parseInt(deviceID));
        } else{
            const index = newDeviceList.indexOf(deviceID);
            if(index > -1){
                newDeviceList.splice(index, 1);
            }
        }
        if(pulling){
            setPullList(newDeviceList)
        } else{
            setPushList(newDeviceList);
        }
    }
    const getDeviceList=()=>{
        setDeviceListRequested(true);
        let sc = new SecureConnect('devices.php?action=listDevices&getAllDevices=1');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                const data = sc.getData(json);
                setDeviceList(data);
                let groups = initDeviceGroups();
                data.forEach(d =>{
                    const parentID = parseInt(d.parentID);
                    const parent = parentID === parseInt(d.userID);
                    if(parent){
                        groups.push({name: d.parentName, value: parentID});
                    }
                })
                setDeviceGroups(groups);
            }
        })
    }

    const resetUserFilters=()=>{
        setDeviceGroupsFilter(-1);
        setSearchText('');
        setSelectedFilter(-1);
    }

    const overwriteAlert =
        <Alert
            status={'warning'}
            headline={'Overwriting Active Workout'}
            message={'One or more of your selected devices currently have an active workout. Assigning a new workout will reset the device to the new one.'}
            visible={false}
            style={{marginTop: getSpacing('small'), marginBottom: getSpacing('small')}}
        />;

    //Action Button Text
    let buttonText;
    if(localAction === PUSH_ACTION){
        buttonText = 'Activate Workout';
    } else if(localAction === PULL_ACTION){
        buttonText = 'Deactivate Workout';
    } else if(localAction === MANAGE_ACTION){
        buttonText = 'Manage Workout';
    } else{
        buttonText = 'Select Action';
    }
    let buttonDisabled;
    if(isEmpty(pushList) && isEmpty(pullList)){
        buttonDisabled = true;
    } else if((localAction === PUSH_ACTION || localAction === MANAGE_ACTION) && workoutID === noWorkoutID){
        buttonDisabled = true;
    } else{
        buttonDisabled = false;
    }

    const submitButton = <Button
        fullWidth
        color={'primary'}
        variant={'contained'}
        disabled={buttonDisabled}
        onClick={()=>setDisplayConfirmation(true)}
    >{buttonText}</Button>


    //Creating Device Tables
    const noDevices = isEmpty(deviceList);
    const pushCount = pushList.length;
    const pullCount = pullList.length;
    const rows = filterDevices();
    const {toggleChecked, toggleColor, overwritingWorkouts, conflictCount} = getToggleAllSettings();
    //Determine Toggle All Check status
    const hasRows = !isEmpty(rows);
    const noDeviceText = noDevices ? 'No Devices Found' : !hasRows ? 'No Devices Found' : 'No Devices';
    const alertStyle = {marginTop: getSpacing('small'), marginBottom: getSpacing('small')};
    //Confirmation modal variables
    let confirmStatus, confirmMessage, confirmHeadline;
    const overwriteClause = `Existing workout assignments (totaling ${conflictCount}) will be overwritten.`;
    const pulldevice = pullCount > 1 ? 'devices' : 'device';
    const pushDevice = pushCount > 1 ? 'devices' : 'device';
    const overwriteDevice = conflictCount > 1 ? 'devices' : 'device';
    if(localAction === PUSH_ACTION){
        confirmStatus = overwritingWorkouts ? 'warning' : 'info'
        confirmHeadline = 'Confirm Workout Activation'
        const additionalStatement = overwritingWorkouts ? overwriteClause: '';
        confirmMessage = `You are about to push your workout to ${pushCount} ${pushDevice}. ${additionalStatement} Are you sure?`
    } else if(localAction === PULL_ACTION){
        confirmStatus = 'warning';
        confirmHeadline = 'Confirm Workout Deactivation';
        confirmMessage = `You are about to pull workouts from ${pullCount} ${pulldevice}. Are you sure?`
    } else if(localAction === MANAGE_ACTION){
        console.log('FINAL CHECK :: TOGGLE CHECK', toggleChecked);
        confirmStatus = overwritingWorkouts || pullCount > 0 ? 'warning' : 'info';
        const pushStatement = pushCount > 0 ? `You are about to push your workout to ${pushList.length} ${pushDevice}. ` : '';
        const pullStatement = pullCount > 0 ? `${pullCount} ${pulldevice} will have their workouts removed. ` : '';
        const overwriteStatement = overwritingWorkouts ? `${conflictCount} ${overwriteDevice} will have their workouts overwritten. ` : '';
        confirmHeadline = 'Confirm Changes';
        confirmMessage = `${pushStatement}${pullStatement}${overwriteStatement} Are you sure?`;
    }
    return (
        <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TabBox>
                    <Tabs value={deviceTab} variant={'fullWidth'}>
                        <Tab icon={<Smartphone/>} onClick={()=>setDeviceTab(availableDevices)} label={'Available Devices'}/>
                        <Tab icon={<MobileFriendly/>} onClick={()=>setDeviceTab(assignedDevices)} label={'Assigned Devices'}/>
                        <Tab icon={<List/>} onClick={()=>setDeviceTab(allDevices)} label={'All Devices'}/>
                        {
                            displayInactive &&
                            <Tab icon={<MobileOff/>} onClick={() => setDeviceTab(inactiveDevices)}
                                 label={'Inactive Devices'}/>
                        }
                    </Tabs>
                </TabBox>
            </Grid>
            <Grid item xs={12}>
                <div style={{paddingTop: getSpacing('normal')}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7} lg={7}>
                            <AthleteRowSearch
                                label={'Devices'}
                                value={searchText}
                                onChangeFn={(value)=>setSearchText(value)}
                                clearSearchFn={()=>setSearchText('')}
                                placeHolderText={'Search For Devices'}
                                searchToolTip={'Device Name, Team, Workout Title'}
                            />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <TextField
                                select
                                fullWidth
                                label={'Device Groups'}
                                value={deviceGroupsFilter}
                                onChange={(e)=>setDeviceGroupsFilter(e.target.value)}
                            >
                                {
                                    deviceGroups.map(d =>{
                                        const parentID = parseInt(d.value);
                                        return (
                                            <MenuItem key={parentID} value={parentID}>{d.name}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={11} md={2} lg={2}>
                            <TextField
                                select
                                fullWidth
                                label={'Selected Devices'}
                                value={selectedFilter}
                                onChange={(e)=>setSelectedFilter(parseInt(e.target.value))}
                            >
                                <MenuItem value={-1}>All Devices</MenuItem>
                                <MenuItem value={1}>Selected</MenuItem>
                                <MenuItem value={0}>Unselected</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1}>
                            <Tooltip title={'Reset Filters'} placement={'top'}>
                                <IconButton color={'secondary'} onClick={()=>resetUserFilters()} sx={{position: "relative", top: getSpacing('iconAlignment')}}>
                                    <Backspace/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            {submitButton}
                        </Grid>
                    </Grid>

                </div>
                {overwriteAlert}
            </Grid>
            <Grid item xs={12}>
                <Alert
                    status={'warning'}
                    visible={deviceTab === inactiveDevices}
                    headline={'Inactive Devices Can Not Receive Workouts'}
                    message={'Workouts can not be pushed to these accounts until they have been logged in.'}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Tooltip title={'Toggle All Devices'} placement={'bottom'}>
                                    <Checkbox
                                        checked={toggleChecked}
                                        color={toggleColor}
                                        onClick={()=>toggleAllDevices(!toggleChecked)}
                                        disabled={deviceList.length === 0 || deviceTab === inactiveDevices || localAction === NO_ACTION}
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell>Device</TableCell>
                            <TableCell>Account Status</TableCell>
                            <TableCell>Workout Status</TableCell>
                            <TableCell>Team</TableCell>
                            <TableCell>Current Workout</TableCell>
                            <TableCell>Coach</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map(d => {
                                const userID = parseInt(d.userID);
                                const deviceWorkoutID = parseInt(d.workoutID);
                                const selected = pushList.includes(userID);
                                const pullSelected = workoutID != null && deviceWorkoutID > 0 && parseInt(workoutID) === deviceWorkoutID;
                                const checked = selected || pullSelected;
                                return (
                                    <DeviceSelectionRow
                                        key={userID}
                                        action={localAction}
                                        activeWorkoutID={workoutID}
                                        teamID={teamID}
                                        deviceData={d}
                                        preList={preList}
                                        pushList={pushList}
                                        pullList={pullList}
                                        checked={checked}
                                        toggleSelectedDevice={(assignPull)=>toggleSelectedDevice(userID, assignPull)}
                                    />
                                );
                            })
                        }
                        {
                            !hasRows ? <TableRow hover><TableCell colSpan={7} align={'center'}><em>{noDeviceText}</em></TableCell></TableRow> : null

                        }
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                {submitButton}
            </Grid>
        </Grid>
        <Modal
            open={displayConfirmation}
            size={'sm'}
            title={'Confirmation Required'}
            closeFunction={serverAlert.display === true ? ()=>closeConfirmation(false) : null}
            modalActions={
                    <Button color={'secondary'} variant={'contained'} onClick={()=>closeConfirmation(false)}><Cancel/> Close</Button>
            }
        >
            <Alert
               visible={serverAlert.display !== true}
               status={confirmStatus}
               headline={confirmHeadline}
               message={confirmMessage}
            />
            <Alert
                status={serverAlert.status}
                visible={serverAlert.display}
                headline={serverAlert.headline}
                message={serverAlert.message}
                style={alertStyle}
            />
           <Button
               fullWidth
               sx={{marginTop: getSpacing()}}
               color={'primary'}
               variant={'contained'}
               disabled={buttonDisabled}
               onClick={()=>commitAction()}><CheckCircle/> {buttonText}</Button>
        </Modal>

        </div>
    );

}

export default DeviceSelectionInterface;