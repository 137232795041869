import React, {useEffect, useState} from 'react';
import LinearProgress from "@mui/material/LinearProgress";




const RefreshStatusBar=({startTimestamp, refreshSeconds, interval, restart = true})=>{

    let start = startTimestamp ? startTimestamp*1000 : Date.now();
    let refreshInterval = interval ? parseInt(interval) : 150; //ms
    const end = start + parseInt(refreshSeconds*1000);

    async function getProgress(){
        const interval = setInterval(()=>{
            return new Promise(resolve =>{
                const now = Date.now();
                let progress = parseInt(now/end)*100;
                console.log('PROGRESS = ' , progress);
            })
        }, refreshInterval);
    }

    const progress = 5; //getProgress();

    return (
        <LinearProgress variant='determinate' value={progress}/>
    );
}

export default RefreshStatusBar;