import React from 'react';
import {useState, useEffect} from 'react';
import {Table, TableCell, TableBody, TableRow, TableHead, Button, Tooltip} from "@mui/material";
import SecureConnect from "fit/system/SecureConnect";
import Modal from 'fit/components/Dialogs/Modal';
import Feedback from '@mui/icons-material/Feedback'
import {getSpacing, isEmpty, getStatusIcon, getStatusColor} from "fit/system/UtilityFunctions";
import WBGStyles from 'fit/components/Workout/WorkoutBlockGenerator/WBGStyles';
import Alert from "fit/components/Alert";
import {CheckCircle, Star} from "@mui/icons-material";
import RPEStatusBar from "../../Workout/RPEStatusBar";
import MeasurementUnits from 'fit/system/MeasurementUnits';
import UserPreferences from "fit/system/UserPreferences";
import ActiveUnitLabel from "fit/components/Form/ActiveUnitLabel";
import MeasurementOptionsMenu from "../../Form/MeasurementField/MeasurementOptionsMenu";

const exStyle = {width: '33%'};
const resultStyle = {width: '33%'};

const exerciseHeaderStyle={
    marginTop: getSpacing('small'),
    marginBottom: 0,
    borderTop: '2px solid #000',
}

const alertStyle={
    marginTop: getSpacing('small')
}

const percentageCheck=(percentageValue)=>{
    return percentageValue != null && parseFloat(percentageValue) > 0;
}

const CompletedSet=({exerciseName})=>{
    return (
        <span><CheckCircle style={{position: 'relative', top: getSpacing('iconAlignment')}}/> Completed</span>
    )
}

const DifferentUnitSubmissionIcon=({value, unitName})=>{
    return (
        <Tooltip
            title={`Submitted Different Units: ${value} ${unitName}`}
            placement={'bottom'}
        >
            <Feedback style={{
                color: getStatusColor('info'),
                position: 'relative',
                top: getSpacing('iconAlignment')
            }}
            />
        </Tooltip>
    )
}

const NewMaxAlert=({athleteName, exerciseName, visible})=>{
    return <Alert style={alertStyle} status={'success'} visible={visible} headline={'New Max Achieved'} message={`${athleteName} achieved a new max for ${exerciseName} per NASM calculations.`} />
}
const MissedSetAlert=({athleteName, visible})=>{
    return <Alert style={alertStyle} status={'warning'} visible={visible} headline={`${athleteName} Failed Set Requirements`} message={`${athleteName} could not complete a set as directed.`} />
}
const ExceededSetAlert=({athleteName, visible})=>{
    return <Alert style={alertStyle} status={'info'} visible={visible} headline={`${athleteName} Exceeded Set Requirements`} message={`${athleteName} exceeded prescribed set instructions.`} />
}

const ExerciseTableHead=({athleteName, exerciseHeaderStyle, exerciseName, exceededReps, exceededWeight, failedReps, failedWeight, newMax, exerciseUnitIDs, repUnitIDs})=>{
    const initializeColumnArray=(variable)=>{
        return Array.isArray(variable) ? variable : [variable];
    }
    const getColumnHeaders=(list, type = 'resistance')=>{
        //Create the column labels using the measurement unitTypes for each unit
        list = initializeColumnArray(list);
        if(list.length > 3){
            //Too many different units. Simplify
            return type === 'resistance' ? 'Reps/Cycles' : 'Weight/Resistance';
        }
        let columnList = [];
        const mUnits = new MeasurementUnits();
        list.forEach(unitID =>{
            const {type} = mUnits.getMeasurementUnitByID(unitID);
            if(!columnList.includes(type)){
                columnList.push(type);
            }
        });
        return columnList.length > 1 ? columnList.join(', ') : columnList[0];
    }

    const repHeader = getColumnHeaders(repUnitIDs);
    const exHeader = getColumnHeaders(exerciseUnitIDs);
    return (
            <TableHead>
                <TableRow>
                    <TableCell colSpan={3} style={exerciseHeaderStyle}>
                        {exerciseName}
                        <MissedSetAlert
                            visible={failedReps || failedWeight}
                            athleteName={athleteName}
                        />
                        <ExceededSetAlert
                            visible={exceededReps || exceededWeight}
                            athleteName={athleteName}
                        />
                        <NewMaxAlert
                            athleteName={athleteName}
                            exerciseName={exerciseName}
                            visible={newMax}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{repHeader}</TableCell>
                    <TableCell>{exHeader}</TableCell>
                </TableRow>
            </TableHead>
    )
}

const SetRow=({set, key, exerciseConversions, findExercise, getResistanceData, handleMenu})=>{
    const userPrefs = new UserPreferences();
    const getRepStatusIcon=(usingReps, difference, type)=>{
        //Types: 'reps' or 'weight'
        if(usingReps === false || difference === 0){
            return null;
        }
        let message, status;
        if(difference < 0){
            message = type === 'reps' ? "Didn't Complete All Reps" : "Didn't Lift Targeted Weight"
            status = 'warning';
        } else{
            message = type === 'reps' ? 'Exceeded Required Reps' : 'Exceeded Targeted Weight';
            status = 'info';
        }
        const style = {color: getStatusColor(status), position: 'relative', top: getSpacing('iconAlignment')};
        return <Tooltip title={message} placement={'bottom'}>
            {getStatusIcon(status, style)}
        </Tooltip>
    }
    const getMaxStar=()=>{
        let newMaxStar = null;
        const newMaxStyle = {color: getStatusColor('success')};
        if(set.newMax != null){
            newMaxStar = <Tooltip title={'New Calculated Max Achieved'} placement={'bottom'}>
                <span><Star style={{...newMaxStyle, position: 'relative', top: getSpacing('iconAlignment')}}/><strong style={newMaxStyle}>New Max!</strong></span>
            </Tooltip>
        }
        return newMaxStar;
    }

    const mUnits = new MeasurementUnits();

    //New Max Star
    const newMaxStar = getMaxStar();
    let {dataRecorded, unitID, resistanceValue, unitData, unitDifference} = getResistanceData(set);
    let unitName = unitData.abbreviation;
    const feedBackIcon = unitDifference && dataRecorded ? <DifferentUnitSubmissionIcon value={set.dataValue} unitName={set.unitName}/> : null;
    const repNumber = isNaN(set.reps) === false;
    const reps = repNumber ? parseInt(set.reps) : set.reps;
    let displayedResistance = <CompletedSet exerciseName={set.exerciseName}/>;
    let displayedResistanceValue = resistanceValue;
    if(dataRecorded){
        //CONVERT RESISTANCE VALUE EITHER BASED ON customExerciseConversions or userPrefs
        const index = findExercise(set.exerciseID, unitID);
        if(index > -1){
            //Custom conversion
            const {originalUnitID, conversionUnitID} = exerciseConversions[index];
            displayedResistanceValue = userPrefs.convertNumber(mUnits.convertUnits(resistanceValue, originalUnitID, conversionUnitID, 1));
            unitData = mUnits.getMeasurementUnitByID(conversionUnitID);
        } else{
            //User prefs conversion
            displayedResistanceValue = userPrefs.convertNumber(userPrefs.convertToPreferredMeasurement(resistanceValue, unitID, 1));
            unitData = userPrefs.getPreferredUnit(unitID);
        }
        unitName = unitData.abbreviation;
        displayedResistance = <span>{displayedResistanceValue} <ActiveUnitLabel onClick={(e)=>handleMenu(true, e, set.exerciseID, unitID)}>{unitName}</ActiveUnitLabel></span>;
    }

    let resistanceIcon, resistanceText = null;
    const repStatusIcon = getRepStatusIcon(set.usingReps, set.repDifference, 'reps');
    const repStatusText = repStatusIcon != null ? `(Target: ${set.whRepValue})` : null;
    if(percentageCheck(set.percentageValue)){
        const difference = parseFloat(resistanceValue)-(set.targetValue);
        resistanceIcon = getRepStatusIcon(true, difference, 'weight');
        resistanceText = resistanceIcon != null ? `(Target: ${parseFloat(set.targetValue)} ${unitName})` : null;
    }

    return (
        <TableRow key={key}>
            <TableCell sx={exStyle}>{set.exerciseName}</TableCell>
            <TableCell sx={resultStyle}>{reps} {repStatusIcon}{repStatusText}</TableCell>
            <TableCell sx={resultStyle}>{displayedResistance} {resistanceIcon}{resistanceText}{newMaxStar}</TableCell>
        </TableRow>
    )
}

/*
    -Return the athlete's workout log for a specific workout ID
    -Display exercises sets/reps (percentages), discrepancies (missing/exceeding rep/weight numbers)
 */
const AthleteWorkoutResults=({display, workoutID, userID, hideFn})=>{
    const [displayModal, setDisplayModal] = useState(false);
    const [workoutResults, setWorkoutResults] = useState([]);
    const [exerciseGroupSets, setExerciseGroupSets] = useState([]);
    const [RPEData, setRPEData] = useState({'score': 'Undetermined', 'raw': null});
    const [loadedUserID, setLoadedUserID] = useState(null);
    const [title, setTitle] = useState('');
    const [athleteName, setAthleteName] = useState('');
    const [submitterName, setSubmitterName] = useState('');
    const [finalSubmissionTime, setFinalSubmissionTime] = useState(''); //loggedTime from last workout row
    const [menuElement, setMenuElement] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [exerciseConversions, setExerciseConversions] = useState([]);
    const [activeExerciseMenu, setActiveExerciseMenu] = useState({exerciseID: null, unitID: null});
    useEffect(()=>{
        if(display && userID != null && loadedUserID == null) {
            loadAthleteData();
        }
        if(display === false){
            //Clear conversions
            setExerciseConversions([]);
        }

    },[display, userID]);

    const loadAthleteData=()=>{
        let sc = new SecureConnect(`workout.php?action=getAthleteWorkoutResults&workoutID=${workoutID}&userID=${userID}`);
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                const {RPE, workoutData} = sc.getData(json);
                const row = workoutData[0];
                const lastRow = workoutData[workoutData.length-1];
                const title = `${row.title} Day ${parseInt(row.dayIndex)+1}: ${row.athleteName}`;
                //console.log('LAST ROW', lastRow);
                const userPrefs = new UserPreferences();
                setTitle(title);
                setAthleteName(row.athleteName);
                setSubmitterName(lastRow.submitterName);
                setFinalSubmissionTime(userPrefs.convertServerDate(lastRow.loggedTime));
                setDisplayModal(true);
                setWorkoutResults(workoutData);
                setRPEData(RPE);
                let exerciseIDs = [];
                let exerciseConversions = [];
                let exSets = {};
                //For Supersets and unit conversion

                workoutData.forEach(ex =>{
                   const exerciseID = parseInt(ex.exerciseID);
                   const groupIndex = parseInt(ex.groupIndex);

                   //Build unit conversion table
                   let {unitID} = getResistanceData(ex);
                   const preferredUnit = userPrefs.getPreferredUnit(unitID);
                   const index = findExerciseConversion(exerciseID, preferredUnit, exerciseConversions, true);
                   if(index < 0){
                       exerciseConversions.push(createExerciseConversion(exerciseID, unitID, preferredUnit.id));
                   }

                   //Build Superset groups
                   let set = [exerciseID, groupIndex];
                   if(exSets[exerciseID] == null || exSets[exerciseID].includes(groupIndex) === false){
                       if(exSets[exerciseID] == null){
                           exSets[exerciseID] = [];
                       }
                       exSets[exerciseID].push(groupIndex);
                       exerciseIDs.push(set);
                   }
                });
                setExerciseGroupSets(exerciseIDs);
                setExerciseConversions(exerciseConversions);
            }
        });
    }
    const closeModal=()=>{
        setDisplayModal(false);
        setLoadedUserID(null);
        hideFn();
    }
    const handleMenu=(open, e, exerciseID = null, unitID = null)=>{
        if(e == null){
            setMenuElement(e);
        } else{
            setMenuElement(e.currentTarget);
        }
        setMenuOpen(open);
        setActiveExerciseMenu({exerciseID: exerciseID != null ? parseInt(exerciseID) : null, unitID: unitID != null ? parseInt(unitID): null});
    }
    const updateMeasurement=(conversionUnitID)=>{
        conversionUnitID = parseInt(conversionUnitID);
        const {exerciseID, unitID} = activeExerciseMenu;
        if(exerciseID !== null) {
            const index = findExerciseConversion(exerciseID, unitID);
            const data = createExerciseConversion(exerciseID, unitID, conversionUnitID);
            let e = structuredClone(exerciseConversions);
            if (index > -1) {
                //Update with new conversion unitID
                e[index] = data;
            } else {
                //Create record
                e.push(data);
            }
            setExerciseConversions(e);
        }
        handleMenu(false);
    }
    const createExerciseConversion=(exerciseID, originalUnitID, conversionUnitID)=>{
        return {exerciseID, originalUnitID, conversionUnitID};
    }
    const findExerciseConversion=(exerciseID, unitID, list = [], useProvided = false)=>{
        const conversionList = !isEmpty(list) || useProvided === true ? list : exerciseConversions;
        return conversionList.findIndex(ex => parseInt(ex.exerciseID) === parseInt(exerciseID) && parseInt(ex.originalUnitID) === parseInt(unitID));
    }
    const getResistanceData=(set)=>{
        const hasConvertedValue = set.convertedDataValue != null;
        const mUnits = new MeasurementUnits();
        const dataRecorded = parseInt(set.dataValue) !== -1;
        const resistanceValue = hasConvertedValue ? set.convertedDataValue : set.dataValue;
        const unitID = hasConvertedValue ? set.targetUnitID : set.whExMeasurementUnitID;
        const unitDifference = parseInt(set.whExMeasurementUnitID) !== parseInt(set.exerciseMeasurementUnitID);
        const unitData = mUnits.getMeasurementUnitByID(unitID);
        return {dataRecorded, resistanceValue, unitDifference, unitID, unitData}
    }
    const findWeightSetDiscrepancies=(sets)=>{
        let exceededReps = false;
        let failedReps = false;
        let failedWeight = false;
        let exceededWeight = false;
        let newMax = false;
        let newMaxExerciseName = '';
        for(let k = 0; k < sets.length; k++){
            const s = sets[k];
            if(s.usingReps === true){
                if(s.repDifference > 0){
                    exceededReps = true;
                }
                if(s.repDifference < 0){
                    failedReps = true;
                }
                if(percentageCheck(s.percentageValue)){
                    const difference = parseFloat(s.convertedDataValue)-parseFloat(s.targetValue);
                    if(difference > 0){
                        exceededWeight = true;
                    }
                    if(difference < 0){
                        failedWeight = true;
                    }
                }
                if(s.newMax != null){
                    newMax = true;
                    newMaxExerciseName = s.exerciseName;
                }
            }
        }

        return {
            exceededReps,
            failedReps,
            failedWeight,
            exceededWeight,
            newMax,
            newMaxExerciseName
        }
    }
    const renderSuperset=(results, key)=>{
        let usedExerciseIDs = [];
        let exercisesNames = [];
        let repUnits = [];
        let exUnits = [];
        results.forEach((row, key)=>{
            //console.log('SUPERSET ROW', row);
            const exerciseID = parseInt(row.exerciseID);
            if(usedExerciseIDs.includes(exerciseID) === false){
                usedExerciseIDs.push(exerciseID);
                exercisesNames.push(row.exerciseName);
                repUnits.push(row.repUnitID);
                exUnits.push(row.measurementUnitID);
            }
        });

        let exerciseTitle;
        if(exercisesNames.length === 1){
            exerciseTitle = exercisesNames[0];
        } else if(exercisesNames.length === 2){
            exerciseTitle = `Superset: ${exercisesNames.join('/')}`;
        } else{
            exerciseTitle = `Circuit: ${exercisesNames.join(', ')}`;
        }
        const {exceededReps, failedReps, failedWeight, exceededWeight, newMax, newMaxExerciseName} = findWeightSetDiscrepancies(results);
        return (
            <React.Fragment key={key}>
                <ExerciseTableHead
                    athleteName={athleteName}
                    exerciseHeaderStyle={exerciseHeaderStyle}
                    exerciseName={exerciseTitle}
                    exceededReps={exceededReps}
                    failedReps={failedReps}
                    failedWeight={failedWeight}
                    exceededWeight={exceededWeight}
                    newMax={newMax}
                    exerciseUnitIDs={exUnits}
                    repUnitIDs={repUnits}
                />
                <TableBody>
                    {
                        results.map((set,k)=>{
                            return (
                                <SetRow
                                    key={k}
                                    set={set}
                                    exerciseConversions={exerciseConversions}
                                    findExercise={(exericseID, unitID)=>findExerciseConversion(exericseID, unitID)}
                                    getResistanceData={(set)=>getResistanceData(set)}
                                    handleMenu={(open, e, exerciseID, unitID)=>handleMenu(open, e, exerciseID, unitID)}
                                />
                            )
                        })
                    }
                </TableBody>
            </React.Fragment>
        )

    }
    const renderSingleSet=(results, key)=>{
        const exerciseName = results[0].exerciseName;
        //Get the Workout Specified RepUnitID and ExerciseMeasurementUnitID: Supply to the ExerciseTableHead
        const {whExRepUnitID, whExMeasurementUnitID} = results[0];
        //console.log('SINGLE SET RESULT', results);
        const {exceededReps, failedReps, failedWeight, exceededWeight, newMax} = findWeightSetDiscrepancies(results);
        return (
            <React.Fragment key={key}>
                <ExerciseTableHead
                    athleteName={athleteName}
                    exerciseHeaderStyle={exerciseHeaderStyle}
                    exerciseName={exerciseName}
                    exceededReps={exceededReps}
                    failedReps={failedReps}
                    failedWeight={failedWeight}
                    exceededWeight={exceededWeight}
                    newMax={newMax}
                    exerciseUnitIDs={whExMeasurementUnitID}
                    repUnitIDs={whExRepUnitID}
                />
                <TableBody>
                    {
                        results.map((set,k)=>{
                            return (
                                <SetRow
                                    key={k}
                                    set={set}
                                    exerciseConversions={exerciseConversions}
                                    findExercise={(exerciseID, unitID)=>findExerciseConversion(exerciseID,unitID)}
                                    getResistanceData={(set)=>getResistanceData(set)}
                                    handleMenu={(open, e, exerciseID, unitID)=>handleMenu(open, e, exerciseID, unitID)}
                                />
                            )
                        })
                    }
                </TableBody>
            </React.Fragment>
        )

    }

    let exerciseGroupElements = [];
    exerciseGroupElements.push(
        <TableHead>
            <TableRow>
                <TableCell>Submitted By</TableCell>
                <TableCell colSpan={2}>{submitterName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Completed</TableCell>
                <TableCell colSpan={2}>{finalSubmissionTime}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={1}>
                    Exertion (RPE)
                </TableCell>
                <TableCell colSpan={2}>
                    {RPEData.raw != null ?
                        <RPEStatusBar score={RPEData.score}/>
                        :
                        RPEData.score
                    }
                </TableCell>
            </TableRow>
        </TableHead>
    );
    let displayedGroups = [];
    for(let k=0; k<exerciseGroupSets.length; k++){
        const exerciseSet = exerciseGroupSets[k];
        const exerciseID = exerciseSet[0];
        const groupIndex = exerciseSet[1];
        const withinGroup = groupIndex > 0;
        let results;
        if(withinGroup && displayedGroups.includes(groupIndex) === false){
            results = workoutResults.filter(set=> parseInt(set.groupIndex) === groupIndex);
            displayedGroups.push(groupIndex);
            if(!isEmpty(results)){
                exerciseGroupElements.push(renderSuperset(results, k));
            }
        }
        if(withinGroup === false){
            results = workoutResults.filter(set=> parseInt(set.exerciseID) === exerciseID && parseInt(set.groupIndex) === groupIndex);
            if(!isEmpty(results)) {
                exerciseGroupElements.push(renderSingleSet(results, k));
            }
        }
    }

    const mUnits = new MeasurementUnits();

    const exIndex = findExerciseConversion(activeExerciseMenu.exerciseID, activeExerciseMenu.unitID);
    let measurementUnitSetting = 2;
    if(exIndex > -1){
        measurementUnitSetting = exerciseConversions[exIndex].conversionUnitID;
    }

    return (
        <Modal
            open={displayModal}
            title={title}
            closeFunction={()=>closeModal()}
            modalActions={
                <React.Fragment>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={()=>closeModal()}
                    >Close</Button>
                    {/*clearButton*/}
                </React.Fragment>
            }
        >
            <Alert
                visible={isEmpty(workoutResults)}
                status={'warning'}
                headline={'No Workout Results To Display'}
                message={'No workout results found for the athlete/workout.'}
                style={WBGStyles.alertStyle}
            />
            <Table style={{width: '100%'}}>
                {exerciseGroupElements}
            </Table>
            <MeasurementOptionsMenu
                measurementID={measurementUnitSetting}
                anchorEl={menuElement}
                open={menuOpen}
                allowWeightMixer={false}
                measurementOptions={mUnits.getMeasurementAlternatives(measurementUnitSetting)}
                onClick={(unitID)=>updateMeasurement(unitID)}
                onClose={()=>handleMenu(false)}
            />

        </Modal>
    );
};

export default AthleteWorkoutResults;