
const initState ={
    loaded: false,
    unitData: {}
}


const MeasurementUnits=(state = initState, action)=>{
    switch(action.type) {
        case 'SET_UNITS':
            //Update user permissions occurs when user account is acted on
            return {
                ...state,
                loaded: true,
                unitData: action.unitData
            };
        default:{
            return state;
        }
    }
}



export default MeasurementUnits;