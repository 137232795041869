import React, {Suspense, useState} from 'react';
import {IconButton, InputAdornment, TextField, Tooltip, Button} from "@mui/material";
import {Search, Cancel} from "@mui/icons-material";
import {connect} from 'react-redux';
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import {DrawerHeader} from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import {SIDEBAR_STYLES, SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import {RoutePermissions} from "fit/system/PermissionsList";
import {getSpacing, isEmpty, getStatusColor} from "fit/system/UtilityFunctions";
import {PermissionMenuTranslator} from "fit/system/FitJumboTranslators";
import User from 'fit/system/User';
import Alert from 'fit/components/Alert/';
import DebounceTextField from "fit/components/Form/DebounceTextField";
import {SEARCH_DEBOUNCE_DELAY, SEARCH_MINIMUM_LENGTH} from "fit/system/FITConstants";




const Sidebar = () => {
    const [searchText, setSearchText] = useState('');
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();

    const user = new User();
    const deviceAccount = user.getDeviceAccount();
    const loggedIn = user.getLoggedInStatus();

    //const {sidebarTheme, setSidebarTheme} = useJumboSidebarTheme();
    //const [loggedIn, setLoggedIn] = useState(user.getLoggedInStatus());
    //const [deviceAccount, setDeviceAccount] = useState(user.getDeviceAccount());
    //const [shittyThemeSet, setShittyThemeSet] = useState(false);
    const filterMenuOptions=(menuList)=>{
        let list = [];
        menuList.forEach(entry =>{
           const found = entry.label.toLowerCase().includes(searchText);
           let childrenOptions = entry.children != null ? entry.children.filter(child => child.label.toLowerCase().includes(searchText)) : [];
           const hasChildren = childrenOptions.length > 0;
           if(found || hasChildren){
               let newEntry = {...entry};
               if(hasChildren){
                   newEntry.children = childrenOptions;
               }
               list.push(newEntry);
           }
        })
        return list;
    }

    const clearSearch=()=>{
        setSearchText('');
    }

    /*
    useEffect(()=>{
        const u =  new User();
        const loggedIn = u.getLoggedInStatus();
        const deviceAccount = u.getDeviceAccount();
        console.log('SIDEBAR', loggedIn, deviceAccount);
        if(loggedIn && deviceAccount === false && shittyThemeSet !== true){
            console.log('SET THE THEME BITCH');
            setSidebarTheme({
                ...sidebarTheme,
                overlay: {
                    // backgroundImage: `linear-gradient(${activeBgColorOption?.colors[0]}, ${activeBgColorOption?.colors[1]})`,
                    bgColor: ['#56a1d5','#212121'],
                    opacity: 0.85
                }
            })
            setShittyThemeSet(true);
        }
    }, loggedIn);
    */

    let menuItems = PermissionMenuTranslator(RoutePermissions);
    const searching = searchText.length >= SEARCH_MINIMUM_LENGTH;

    if(sidebarOptions.open && (loggedIn === false || (loggedIn && deviceAccount))){
        //Close the sidebar/menu
        setSidebarOptions({open: false});
    }
    if(searching && !isEmpty(menuItems)){
       let menu = [];
       menuItems.forEach((group,j) =>{
           const options = filterMenuOptions(group.children);
           if(options.length){
               let entry = {...group};
               entry.children = options.length ? options : [];
               menu.push(entry);
           }
       })
       menuItems = menu;
    }
    const searchStyle = {paddingLeft: getSpacing(), paddingRight: getSpacing(), paddingTop: getSpacing()}
    const noResultsStyle = {padding: getSpacing('small'), backgroundColor: getStatusColor('warning')};



    return (
        <React.Fragment>
            <SidebarHeader/>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3
                            }}
                        >
                            <SidebarSkeleton/>
                        </Div>
                    }
                >
                    <div style={searchStyle}>
                        <Tooltip title={'Search Menu'} placement={'bottom-start'}>
                            <DebounceTextField
                                fullWidth
                                delay={SEARCH_DEBOUNCE_DELAY}
                                size={'small'}
                                value={searchText}
                                placeholder={'Search'}
                                onChange={(value)=>setSearchText(value)}
                                InputProps={{startAdornment: <InputAdornment position={'start'}><Search color={'primary'}/></InputAdornment>}}
                            />
                        </Tooltip>
                    </div>
                    <JumboVerticalNavbar translate items={menuItems} searching={searching}/>

                    <div style={{paddingLeft: getSpacing(), paddingRight: getSpacing()}}>
                        <Alert
                            status={'warning'}
                            visible={searching && isEmpty(menuItems)}
                            headline={'Nothing Found'}
                        >
                            <Button
                                fullWidth
                                size={'small'}
                                color={'primary'}
                                variant={'contained'}
                                onClick={()=>clearSearch()}
                            ><Cancel/>Clear Search</Button>
                        </Alert>
                    </div>
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {sidebarTheme, setSidebarTheme} = useJumboSidebarTheme();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader>
                    <Logo mini={isMiniAndClosed} mode={sidebarTheme.type}/>
                    {
                        sidebarOptions?.view !== SIDEBAR_VIEWS.MINI &&
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ml: 0, mr: -1.5}}
                                onClick={() => setSidebarOptions({open: false})}
                            >
                                <MenuOpenIcon/>
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};

const mapStateToProps =(state)=>{
    return {FITUser : state.FITUser}
}

export default connect(mapStateToProps)(Sidebar);
