import React from 'react';
import {
    Paper,
    Typography,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    TextField,
    MenuItem,
    Accordion, AccordionSummary, AccordionDetails,
    Table, TableHead, TableBody, TableRow, TableCell
} from "@mui/material";
import {CheckCircle, Block, PanoramaFishEye, ExpandMore} from "@mui/icons-material";
import {TYPOGRAPHY_BODY_STYLE} from "fit/system/FITConstants";
import {getStatusColor, getSpacing} from "fit/system/UtilityFunctions";
import UserPreferences from "fit/system/UserPreferences";
import moment from "moment";


const WorkoutSettings=({deviceAccount, autoComplete, validateWeight, lbUnit, kgUnit, onChange, headerVariant})=>{
    const variant = headerVariant ? headerVariant : 'h3';
    const validatingWeight = validateWeight === true || parseInt(validateWeight) === 1;
    const deviceSetting = deviceAccount === true;

    const getWeightValidationTable=()=>{
        const options = [.5,1,2,2.5,3,4,5,7.5,10];
        const decimalPlace = 1;
        const kg = validatingWeight ? parseFloat(parseFloat(kgUnit).toFixed(decimalPlace)) : 1;
        const lb = validatingWeight ? parseFloat(parseFloat(lbUnit).toFixed(decimalPlace)) : 1;
        let table = [];
        for(let k = 0; k< options.length; k++){
            const value = options[k];
            const lbValid = value%lb === 0;
            const kgValid = value%kg === 0;
            table.push({weight: value, lbValid, kgValid});
        }
        return table;
    }

    //Weight Validation Table Settings
    const iconStyle = {position: 'relative', top: getSpacing('iconAlignment')};
    const errorColor = getStatusColor('error');
    const successColor = getStatusColor('success');
    const disabledColor = getStatusColor('noAction');
    const validationTable = getWeightValidationTable();
    const validWeightIcon = <CheckCircle sx={{...iconStyle, color: successColor}} />
    const failWeightIcon = <Block sx={{...iconStyle, color: errorColor}}/>
    const disabledIcon = <PanoramaFishEye sx={{...iconStyle, color: disabledColor}}/>
    const validText = <span style={{color: successColor}}>Passed</span>
    const failText = <span style={{color: errorColor}}>Failed</span>
    const disabledText = <span style={{color: disabledColor}}>Disabled</span>
    const sx={minWidth: '200px'};

    const userPrefs = new UserPreferences();
    const decimal = userPrefs.decimalChar;

    return (
        <Grid container spacing={2}>
            {!deviceSetting ?
                <Grid item xs={12}>
                    <Typography variant={variant}>Autocomplete Workout Fields</Typography>
                    <Typography variant={'body1'} sx={TYPOGRAPHY_BODY_STYLE}>Buteo can prepopulate your sets and reps when logging your personal workouts. Alternatively you may want to type in these values manually.
                        <br/><em>Note: Autocomplete settings for team workouts are determined by the strength and conditioning coach.</em>
                    </Typography>
                    <FormControl>
                        <FormLabel>Autocomplete Sets: Reps & Weight</FormLabel>
                        <RadioGroup>
                            <FormControlLabel control={<Radio color={'primary'} checked={autoComplete === true} onChange={()=>onChange('autoCompleteWorkout', true)}/>} label={'On: Buteo will fill out my reps & any precalculated weight for me.'}/>
                            <FormControlLabel control={<Radio color={'secondary'} checked={autoComplete === false} onChange={()=>onChange('autoCompleteWorkout', false)}/>} label={'Off: Let me fill out my reps & weight.'}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                :
                null
            }

            <Grid item xs={12}>
                <Typography variant={'h3'}>Weight Validation</Typography>
                <Typography variant={'body1'} sx={TYPOGRAPHY_BODY_STYLE}>Buteo has the ability to validate your weights to verify you entered the correct amounts. To take advantage of this feature, enter the smallest weight increment available for each unit. You can use the validation table to verify your settings are correct.</Typography>
                <FormControl>
                    <FormLabel>Validate Weights</FormLabel>
                    <RadioGroup>
                        <FormControlLabel control={<Radio color={'primary'} checked={validatingWeight} onChange={()=>onChange('validateWeight', true)}/>} label={'On: I want Buteo to help verify the weight I enter.'}/>
                        <FormControlLabel control={<Radio color={'secondary'} checked={!validatingWeight} onChange={()=>onChange('validateWeight', false)}/>} label={'Off: I don\'t want Buteo to verify the accuracy of my data.'}/>
                    </RadioGroup>
                </FormControl>

                <Accordion expanded={validatingWeight} disabled={!validatingWeight}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>Weight Validation Table</AccordionSummary>
                    <AccordionDetails>

                        <Typography variant={'h4'}>Weight Validation Table</Typography>
                        <Typography variant={'body1'}>The following table provides potential values for you to verify your increments will correctly validate entered weights.</Typography>

                        <Table size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Weight Increment</TableCell>
                                    <TableCell>Pounds (lbs)</TableCell>
                                    <TableCell>Kilograms (kg)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    validationTable.map((set, key)=>{
                                        const {weight, lbValid, kgValid} = set;
                                        const lbIcon = !validatingWeight ? disabledIcon : lbValid ? validWeightIcon : failWeightIcon;
                                        const kgIcon = !validatingWeight ? disabledIcon : kgValid ? validWeightIcon : failWeightIcon;
                                        const lbText = !validatingWeight ? disabledText : lbValid ? validText : failText;
                                        const kgText = !validatingWeight ? disabledText : kgValid ? validText : failText;
                                        return (
                                            <TableRow key={key}>
                                                <TableCell>{weight}</TableCell>
                                                <TableCell>{lbIcon} {lbText}</TableCell>
                                                <TableCell>{kgIcon} {kgText}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                <TableRow>
                                    <TableCell>Smallest Increment</TableCell>
                                    <TableCell>
                                        <TextField
                                            select
                                            sx={sx}
                                            label={'Pounds (lbs)'}
                                            value={lbUnit}
                                            onChange={(e)=>onChange('lbUnit', e.target.value)}
                                            placeholder={'5'}
                                            disabled={!validateWeight}
                                            helperText={!validateWeight ? 'Disabled' : ' '}
                                        >
                                            <MenuItem value={.5}>{decimal}5 Pound (lb)</MenuItem>
                                            <MenuItem value={1}>1 Pound (lb)</MenuItem>
                                            <MenuItem value={2.5}>2{decimal}5 Pounds (lbs)</MenuItem>
                                            <MenuItem value={5}>5 Pounds (lbs)</MenuItem>
                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            select
                                            label={'Kilograms (kg)'}
                                            sx={sx}
                                            value={kgUnit}
                                            onChange={(e)=>onChange('kgUnit', e.target.value)}
                                            placeholder={'5'}
                                            disabled={!validateWeight}
                                            helperText={!validateWeight ? 'Disabled' : ' '}
                                        >
                                            <MenuItem value={.5}>{decimal}5 Kilogram (kg)</MenuItem>
                                            <MenuItem value={1}>1 Kilogram (kg)</MenuItem>
                                            <MenuItem value={2.5}>2{decimal}5 Kilograms (kgs)</MenuItem>
                                            <MenuItem value={5}>5 Kilograms (kgs)</MenuItem>
                                        </TextField>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>


            </Grid>
        </Grid>
    )
}


export default WorkoutSettings;