import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import Header from "../shared/headers/Header";
import Sidebar from "../shared/sidebars/Sidebar";
import Footer from "../shared/footers/Footer";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import JumboCustomizer from "../../shared/JumboCustomizer/JumboCustomizer";
import JumboCustomizerButton from "../../shared/JumboCustomizer/JumboCustomizerButton";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS, SIDEBAR_VIEWS} from "@jumbo/utils/constants";
import {headerTheme as theme4} from "../../themes/header/theme4";
import {headerTheme as defaultTheme} from "../../themes/header/default";
import useApp from "../../hooks/useApp";
import layoutConfig from "./layoutConfig";
import {useMediaQuery} from "@mui/material";
import User from 'fit/system/User';
import {connect} from "react-redux";

const VerticalDefault = ({children}) => {
    const {setJumboLayoutOptions} = useJumboLayout();
    const {headerTheme, setHeaderTheme} = useJumboHeaderTheme();
    const {theme} = useJumboTheme();
    const appBarBgColor = headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background;
    const {sidebarOptions} = useJumboLayoutSidebar();
    const appData = useApp();
    const user = new User();
    const signedIn = user.getLoggedInStatus();

    const debug = false;
    const navy = "#16284c";
    const offWhite = "#F5F7FA";

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig)
    }, []);

    //console.log('Vertical Default Header Theme', theme);


    React.useEffect(() => {
        //console.log('app/layouts/vertical-default/VerticalDefault.js', 'RUNNING USEEFFECT');
        if (appBarBgColor === offWhite && sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER) {
            console.log('DOING SOMETHING WITH THE THEME --- 1');
            setHeaderTheme({...theme, ...theme4});
            appData.setAppState({
                prevHeaderBgColor: theme?.mode === "dark" ? navy  : offWhite
            });
        } else if (appData.prevHeaderBgColor && appData.prevHeaderBgColor === offWhite) {
            console.log('...doing something with the theme --- 2');
            setHeaderTheme({...theme, ...defaultTheme});
        }
    }, [sidebarOptions.style]);


    return (
        <JumboLayout
            header={<Header/>}
            sidebar={<Sidebar/>}
            footer={<Footer/>}
            headerSx={{
                height: 80,
            }}
        >
            {children}

            {
                debug ?
                    <div>
                        <JumboCustomizer/>
                        <JumboCustomizerButton/>
                    </div>
                    :null
            }

        </JumboLayout>
    );
};

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(VerticalDefault);
