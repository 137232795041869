import { LinkProps, Link as MuiLink } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";

import React, { FC } from "react";

const Link: FC<LinkProps> = props => {
    const underline = props.underline != null ? props.underline : 'none';
    const path = props.to ?? "#"
    return (
        <MuiLink {...props} component={ReactRouterLink} to={path} underline={underline}/>
    );
};

export default Link;