import React from "react";
import User from 'fit/system/User';
import {PermissionRouteTranslator} from "fit/system/FitJumboTranslators";
import {connect} from 'react-redux'
import {Routes, Route} from 'react-router-dom';
import DeniedRoute from "fit/routes/system/DeniedRoute";


const FitAppRoutes = () => {
    let user = new User();
    const loggedIn = user.getLoggedInStatus();
    const deviceAccount = user.getDeviceAccount();
    const permittedRoutes = PermissionRouteTranslator(loggedIn, deviceAccount);
    return (
        <Routes>
            {
                permittedRoutes.map((route,key) =>{
                    return <Route path={route.path} element={route.element} key={key}/>
             })
            }
            <Route path={'*'} element={<DeniedRoute/>}/>
        </Routes>
    );
};

const mapStateToProps = (state) => {
    return {FITUser: state.FITUser};
};
export default connect(mapStateToProps)(FitAppRoutes);