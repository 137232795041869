import React from 'react';
import {useEffect, useState} from 'react';
import {Grid, Typography, Paper} from "@mui/material";
import {
    getSpacing,
    isEmpty,
    getStatusColor,
    getRPEColor,
    calculatePercentage,
    setPageTitle
} from "fit/system/UtilityFunctions";
import SecureConnect from "fit/system/SecureConnect";
import Statistics from 'fit/system/Statistics';
import AthleteWorkoutResults from "./AthleteWorkoutResults";
import {SEARCH_DEBOUNCE_DELAY, SEARCH_MINIMUM_LENGTH, PAPER_STYLE_SMALL} from "fit/system/FITConstants";
import DynamicDataTable from "fit/components/DynamicDataTable";
import AthleteRowSearch from "fit/components/AthleteRow/AthleteRowSearch";
import NavigationBar from "fit/components/Analytics/WorkoutNavigationBar";
import StatusAccordion from "fit/components/StatusAccordion";
import Chart from 'chart.js/auto';
import {ArcElement, CategoryScale} from 'chart.js'
import {Bar, Doughnut} from 'react-chartjs-2';
Chart.register(CategoryScale);
Chart.register(ArcElement);



const initAthleteResultsModal=()=>{
    return {
        display: false,
        userID: null
    }
}

const initNavigation=()=>{
    return {
        previousWorkoutID: null,
        nextWorkoutID: null,
        blockID: null,
        workoutID: null,
        workoutDate: null,
        workoutDayIndex: null,
        blockTitle: '',
        teamID: null,
        teamName: '',
        subGroupName: '',
        blockList: [],
        workoutList: []
    }
}

const CompletedWorkoutReport =({workoutID})=>{
    const [navigationData, setNavigationData] = useState(initNavigation);
    const [workoutTitle, setWorkoutTitle] = useState('');
    const [RPEData, setRPEData] = useState([]);
    const [completedList, setCompletedList] = useState([]);
    const [unfinishedList, setUnfinishedList] = useState([]);
    const [completionPercentages, setCompletionPercentages] = useState({completed: 0, unfinished: 0});
    const [missingAthleteList, setMissingAthleteList] = useState([]);
    const [athleteResultsModal, setAthleteResultsModal] = useState(initAthleteResultsModal);
    const [searchText, setSearchText] = useState('');
    const displayAthleteResults=(userID)=>{
        setAthleteResultsModal({
            display: true,
            userID: userID
        });
    }
    const hideAthleteResults =()=>{
        setAthleteResultsModal({
            display: false,
            userID: null
        })
    }

    useEffect(()=>{
        if(workoutID != null && workoutID !== ''){
            let sc = new SecureConnect(`workout.php?action=getWorkoutReport&workoutID=${workoutID}`);
            sc.setDisplaySuccessMessages(false);
            sc.connect().then(json=>{
                if(sc.getCompleted(json)){
                    const data = sc.getData(json);
                    const {completedList, missingAthleteList, unfinishedList} = data;
                    //console.log('COMPLETED LIST', completedList); console.log('MISSING ATHLETE', missingAthleteList);
                    const firstRow = completedList[0];
                    setWorkoutTitle(`${firstRow.title} Day ${parseInt(firstRow.dayIndex)+1}`);
                    const completedCount = completedList.length;
                    const unfinishedCount = unfinishedList.length;
                    let completionPercentage = 0;
                    let unfinishedPercentage = 0;
                    let rosterCount = 0;
                    let RPEData = [];
                    if(!isEmpty(completedList)){
                        rosterCount = completedList[0].rosterCount;
                        completionPercentage = calculatePercentage(completedCount, rosterCount,1);
                        unfinishedPercentage = calculatePercentage(unfinishedCount, rosterCount, 1);
                        completedList.forEach(w => {
                            RPEData.push(parseInt(w.RPE));
                        })

                    }
                    //console.log('COMPLETED', completedCount, 'TOTAL', totalAthletes, '% COMPLETED', completionPercentage);
                    setRPEData(RPEData);
                    setCompletedList(completedList);
                    setUnfinishedList(unfinishedList);
                    setMissingAthleteList(missingAthleteList);
                    setCompletionPercentages({completed: completionPercentage, unfinished: unfinishedPercentage});
                    //console.log('ROSTER', rosterCount, 'C RAW', completedList.length,'U RAW', unfinishedList.length, 'C%', completionPercentage, 'U%', unfinishedPercentage);

                }
            });

            //Set the navigation
            sc = new SecureConnect(`workout.php?action=getWorkoutNavigation&type=workout&workoutID=${workoutID}`);
            sc.setDisplayNotifications(false);
            sc.connect().then(json =>{
                if(sc.getCompleted(json)){
                    const data = sc.getData(json);
                    const {workoutList, nextID, previousID, blockList} = data;
                    const cb = data.currentBlock;
                    let dayIndex = null;
                    let workoutDate = null;
                    const workoutRow = workoutList.find(w => w.workoutKey === workoutID);
                    if(!isEmpty(workoutRow)){
                        dayIndex = workoutRow.dayIndex;
                        workoutDate = workoutRow.dateScheduled;
                    }
                    setNavigationData({
                        previousWorkoutID: previousID,
                        nextWorkoutID: nextID,
                        blockID: cb.blockKey,
                        workoutID: workoutID,
                        workoutDate,
                        workoutDayIndex: dayIndex,
                        blockTitle: cb.title,
                        teamName: cb.teamLabel,
                        teamID: cb.teamID,
                        subGroupName: cb.squadLabel,
                        blockList: blockList,
                        workoutList: workoutList
                    });

                }
            });
        }
    },[workoutID]);


    const getFilterNotice=(searching, array)=>{
        if(!searching){
            return '';
        } else{
            return array.length > 1 ? ' Search Results' : ' Search Result';
        }
    }
    const getExpandedSetting=(searching, array)=>{
        if(!searching){
            return null;
        } else{
            return array.length >= 1;
        }
    }

    let barLabels = [];
    let barDataset = [];
    let barColorSet = [];
    let total = 0;
    if(!isEmpty(completedList)) {
        for (let k = 5; k <= 100; k += 5) {
            barLabels.push(`${k}%`);
            const RPECount = completedList.filter(ath => (parseInt(ath.RPE) * 5) === k).length;
            barDataset.push(RPECount);
            total += (RPECount * k);
            barColorSet.push(getRPEColor(k));
        }
    }

    const stat = new Statistics(completedList);
    const statMean = stat.getMean('RPEScore');
    const statDev = stat.getStandardDeviation('RPEScore');


    const defaultSort = 'athleteName';
    const completedWorkoutTableStructure = {
        tableID:"completedWorkouts",
        rowComponent:"AthleteCompletedWorkout",
        paginationEnabled:false,
        sortEnabled:true,
        searchEnabled:false,
        searchTips:"",
        tableHeaders:[
            ["id","View"],
            ["name","Athlete"],
            ["RPEScore","Exertion (RPE)"],
            ["scheduled","Scheduled"],
            ["time","Date\/Time Completed"],
            ["submitter","Submitter"]
        ],
        defaultSort: defaultSort,
        defaultDirection:"up",
        dateRangeEnabled:false
    };
    const unfinishedWorkoutTableStructure = {
        tableID:"unfinishedWorkouts",
        rowComponent:"AthleteUnfinishedWorkout",
        paginationEnabled:false,
        sortEnabled:true,
        searchEnabled:false,
        searchTips:"",
        tableHeaders:[
            ["name","Athlete"],
            ["completedSets","Percentage Completed"],
            ["year","Year"]
        ],
        defaultSort: defaultSort,
        defaultDirection:"up",
        dateRangeEnabled:false
    }
    const missedWorkoutTableStructure = {
        tableID:"missedWorkouts",
        rowComponent:"AthleteMissingWorkout",
        paginationEnabled:false,
        sortEnabled:true,
        searchEnabled:false,
        searchTips:"",
        tableHeaders:[
            ["name","Athlete"],
            ["year","Year"]
        ],
        defaultSort: defaultSort,
        defaultDirection:"up",
        dateRangeEnabled:false
    }
    const RPEBarData = {
        labels: barLabels,
        datasets: [{
            label: 'RAW RPE Scores',
            data: barDataset,
            backgroundColor: barColorSet
        }],
        borderWidth: 1
    }
    //ATTENDANCE PIE/DONUT CHART
    const missed = parseFloat(100-completionPercentages.completed-completionPercentages.unfinished);
    const attendanceData = {
        labels: ['Completed','Incomplete','Missed'],
        legend: {position: 'right'},
        datasets:[{
            label: 'Workout Completion',
            data: [completionPercentages.completed, completionPercentages.unfinished, missed],
            backgroundColor:[
                getStatusColor('success'),
                getStatusColor('warning'),
                getStatusColor('error')
            ],
            hoverOffset: 4,
            offset: 3,
        }]
    }
    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
    }
    const pieOptions ={
        /*animation: {duration: 0},*/
        legend: {position: 'right'},
    }
    const chartHeight = '400px';

    //console.log('RPE DATA', RPEData, barDataset);
    const nd = navigationData;

    let workoutList;
    let incompleteList;
    let notPresentList;
    const searching = searchText.length >= SEARCH_MINIMUM_LENGTH;
    if(searching){
        //console.log('___SEARCHING :: ',searchText);
        const searchingFor = searchText.toLowerCase();
        workoutList = completedList.filter(w=> `${w.athleteName.toLowerCase()} ${w.userID} ${w.dateTime.toLowerCase()}`.includes(searchingFor));
        incompleteList = unfinishedList.filter(w => `${w.athleteName.toLowerCase()} ${w.userID}`.includes(searchingFor));
        notPresentList = missingAthleteList.filter(w=> `${w.athleteName.toLowerCase()} ${w.userID}`.includes(searchingFor));
    } else{
        workoutList = completedList;
        incompleteList = unfinishedList;
        notPresentList = missingAthleteList;
    }

    const completedFilterNotice = getFilterNotice(searching, workoutList);
    const unfinishedFilterNotice = getFilterNotice(searching, incompleteList);
    const missingFilterNotice = getFilterNotice(searching, notPresentList);

    const interfaceTitle = `Completed Workout: ${workoutTitle}`;
    setPageTitle(interfaceTitle);

    let missingAthleteAccordion = null;
    if(!isEmpty(missingAthleteList)){
        missingAthleteAccordion =
            <StatusAccordion
                status={'error'}
                defaultExpanded={false}
                summaryText={`Missing Athletes (${notPresentList.length}${missingFilterNotice})`}
                expanded={getExpandedSetting(searching, notPresentList)}
            >
                <DynamicDataTable
                    formatURL={null}
                    format={missedWorkoutTableStructure}
                    serverData={notPresentList}
                    loadData={false}
                    presetVariables={false}
                    rowFunction={null}
                    style={null}
                />
            </StatusAccordion>
    }
    return (
        <div>
            <Typography variant={'h2'}>{interfaceTitle}</Typography>
            <NavigationBar
                previousID={nd.previousWorkoutID}
                nextID={nd.nextWorkoutID}
                currentWorkoutID={nd.workoutID}
                workoutDate={nd.workoutDate}
                workoutDayIndex={nd.workoutDayIndex}
                blockID={nd.blockID}
                blockList={nd.blockList}
                workoutList={nd.workoutList}
                teamID={nd.teamID}
                teamName={nd.teamName}
                subGroupName={nd.subGroupName}
                blockTitle={nd.blockTitle}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <Paper style={PAPER_STYLE_SMALL}>
                        <Typography variant={'h3'}>Rate Of Perceived Exertion</Typography>
                        {/*
                            <TabBox>
                                <Tabs onChange={()=>handleRPETabs()} aria-label="lab API tabs example">
                                    <Tab label="Raw RPE" value="1" />
                                    <Tab label="Normalized Distribution" value="2" />
                                    <Tab label="Estimated Heart Rates (Borg Scale)" value="3" />
                                </Tabs>
                            </TabBox>
                        */}

                        <div style={{height: '400px', width: '100%'}}>
                            <Bar data={RPEBarData} options={barOptions}/>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Paper style={PAPER_STYLE_SMALL}>
                        <Typography variant={'h3'}>Attendance/Completion</Typography>
                        <div style={{height: chartHeight, width: '100%'}}>
                            <Doughnut data={attendanceData} options={pieOptions}/>
                        </div>
                    </Paper>
                </Grid>
                {/*
                <Grid item xs={12} lg={4}>
                    <Paper style={PAPER_STYLE_SMALL}>
                        <Typography variant={'h2'}>Workout Summary</Typography>
                        <Table>
                            <TableHead>
                                <TableRow></TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>{completionPercentages.completed}%</strong></TableCell>
                                    <TableCell>Completion Rate/Attendance %</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>{statMean}%</strong> (S.D.: {statDev.toFixed(1)})</TableCell>
                                    <TableCell>Avg RPE / StdDev</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong></strong></TableCell>
                                    <TableCell>Average Estimated Heart Rate (BPS)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong></strong></TableCell>
                                    <TableCell>Weight Increases/New Maxes</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                */}
                <Grid item xs={12}>
                    <Paper style={PAPER_STYLE_SMALL}>
                        <Typography variant={'h2'}>Individual Results</Typography>
                        <div style={{marginBottom: getSpacing(), marginTop: getSpacing()}}>
                            <AthleteRowSearch
                                value={searchText}
                                searchToolTip={'Athlete Name'}
                                onChangeFn={(value)=>setSearchText(value)}
                                clearSearchFn={()=>setSearchText('')}
                                placeHolderText={'Search'}
                                label={'Athletes'}
                            />
                        </div>

                        <StatusAccordion
                            status={'success'}
                            summaryText={`Completed Workouts (${workoutList.length}${completedFilterNotice})`}
                            defaultExpanded={true}
                            expanded={getExpandedSetting(searching, workoutList)}
                        >
                            <DynamicDataTable
                                formatURL={null}
                                format={completedWorkoutTableStructure}
                                serverData={workoutList}
                                loadData={false}
                                presetVariables={null}
                                rowFunctions={{
                                    displayAthleteResults: (userID)=>displayAthleteResults(userID)
                                }}
                                style={null}
                            />
                        </StatusAccordion>

                        <StatusAccordion
                            status={'warning'}
                            defaultExpanded={false}
                            summaryText={`Incomplete Workouts (${incompleteList.length}${unfinishedFilterNotice})`}
                        >
                            <DynamicDataTable
                                formatURL={null}
                                format={unfinishedWorkoutTableStructure}
                                serverData={incompleteList}
                                loadData={false}
                                presetVariables={false}
                                rowFunction={null}
                                style={null}
                            />
                        </StatusAccordion>
                        {missingAthleteAccordion}
                    </Paper>
                </Grid>
            </Grid>
            <AthleteWorkoutResults
                workoutID={workoutID}
                display={athleteResultsModal.display}
                userID={athleteResultsModal.userID}
                hideFn={()=>hideAthleteResults()}
            />
        </div>
    )
}


export default CompletedWorkoutReport;