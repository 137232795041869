import React from 'react';
import {
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Typography,
} from "@mui/material";
import {getSpacing} from "fit/system/UtilityFunctions";
import SecurityPin from "../SecurityPin";

import Alert from "fit/components/Alert";
import {SHORT_DEBOUNCE_DELAY, TEXTFIELD_DEBOUNCE_DELAY} from "fit/system/FITConstants";
const Security=({displayPin, error, pinError, headerVariant, onboard = false, onChange, pin, setDisplayPin, twoFactor, text2fa})=>{
    const value2fa = parseInt(twoFactor) === 1 || twoFactor === true;
    const valueText2fa = parseInt(text2fa) === 1 || text2fa === true;
    const textingEnabled = false;
    if(false) {
        console.log('SECURITY :: VALUE 2FA', value2fa);
        console.log('SECURITY :: VALUE TEXT2fa', valueText2fa, '(', text2fa, ')');
        console.log('SECURITY :: PIN', pin);
    }
    const twoFactorStatus = value2fa ? 'success' : 'warning';
    const twoFactorHeadline = value2fa ? '2 Factor Authentication Enabled!' : 'Account At Risk!';
    const twoFactorMessage = value2fa ? 'Account further secured by 2 Factor Authentication.' : '2 Factor Authentication disabled!';
    const variant = headerVariant ? headerVariant : 'h3';
    const helperText = pinError || ' ';

    const pinDelay = onboard === true ? SHORT_DEBOUNCE_DELAY : TEXTFIELD_DEBOUNCE_DELAY;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={variant}>Two Factor ("2FA") Authentication</Typography>
                <Alert
                    style={{marginBottom: getSpacing()}}
                    visible={true}
                    status={twoFactorStatus}
                    headline={twoFactorHeadline}
                    message={twoFactorMessage}
                />
                <FormControl>
                    <FormLabel>Enable 2FA for Increased Security</FormLabel>
                    <RadioGroup>
                        <FormControlLabel control={<Radio color={'primary'} checked={value2fa} onChange={()=>onChange('2fa', 1)}/>} label={'On: Increase my account security'} />
                        <FormControlLabel control={<Radio color={'secondary'} checked={!value2fa} onChange={()=>onChange('2fa', 0)}/>} label={'Off: Lessen security'}/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            {

            textingEnabled ?

            <Grid item xs={12}>
                <FormControl>
                    <FormLabel>Receive 2FA Texts</FormLabel>
                    <RadioGroup>
                        <FormControlLabel disabled={!value2fa} control={<Radio color={'primary'} checked={valueText2fa} onChange={(()=>onChange('text2fa', 1))}/>} label={'On: I want to receive SMS authentication messages on my phone'} />
                        <FormControlLabel disabled={!value2fa} control={<Radio color={'secondary'} checked={!valueText2fa} onChange={(()=>onChange('text2fa', 0))}/>} label={'Off: I will only receive 2FA messages via email'}/>
                    </RadioGroup>
                </FormControl>
                <br/><em>*Data fees and rates may apply from your carrier. Message frequency will be dependent on how often you sign in and how you choose to receive 2FA messages.</em>
            </Grid>
            :null
            }
            <Grid item xs={12}>
                <Typography variant={variant}>Personal Security Pin</Typography>
                <Typography variant={'body1'}>Buteo utilizes a pin code (separate from your password) when logging into device accounts.</Typography>
                <div style={{marginTop: getSpacing()}}>
                    <SecurityPin
                        delay={pinDelay}
                        value={pin}
                        displayPin={displayPin}
                        onChange={(value)=>onChange('pin', value)}
                        error={error}
                        helperText={helperText}
                        placeholder={''}
                        setDisplayPin={()=>setDisplayPin(!displayPin)}
                    />
                </div>

            </Grid>
        </Grid>
    )

}

export default Security;