import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from '@mui/material';
import {Cancel, DeleteForever} from '@mui/icons-material';
import {isEmpty} from "fit/system/UtilityFunctions";
import {PERM_TEAM, PERM_DEPT, ATHLETE_LEVEL, ASSISTANT_LEVEL, OPERATIONS_LEVEL, DEPT_MINIMUM_REQUIRED_LEVELS, DEVICE_MASTER_LEVEL} from "fit/system/FITConstants";
import User from 'fit/system/User';
import Alert from "fit/components/Alert";
import PermissionEntry from "./PermissionEntry";
import SecureConnect from "fit/system/SecureConnect";
import Modal from "../../Dialogs/Modal";

const initLastDeptData=()=>{
    return {
        display: false,
        displayConfirmationAlert: false,
        orgShortName: '',
        organizationName: '',
        permissions: {
            organizationID: null,
            userID: null,
            sectionID: null,
            managerLevel: null,
            active: null
        }
    }
}

const UserPermissions=({newUser, userID, type, name, permissions, updateFn, athlete, deviceAccount, deptPermissions})=>{
    const [lastDeptData, setLastDeptData] = useState(initLastDeptData);

    if(type === null || [PERM_DEPT, PERM_TEAM].includes(type) === false){
        //console.error('_PERMISSION TYPE_ must be specified for UserPermissions component');
        //console.error(`_ACCEPTED TYPES: [${PERM_DEPT}, ${PERM_DEPT}]`);
    }
    const checkActiveDept=(organizationID)=>{
        if(type === PERM_DEPT){
            return true;
        }
        //Determine if there's an active dept from deptPermissions based on the teams organizationID
        if(isEmpty(deptPermissions)){
            return false;
        }
        const keys = Object.keys(deptPermissions);
        for(let k = 0; k<keys.length; k++){
            const key = keys[k];
            const permission = deptPermissions[key];
            const active = permission.active != null && parseInt(permission.active) === 1;
            if(parseInt(permission.organizationID) === organizationID && active){
                return true;
            }
        }
        return false;
    }
    const getActiveDeptCount=(organizationID, sectionID)=>{
        //NEED A CHECK ON OVERRIDDING DEPTS
        let orgs = {count: 0, orgShortName: '', organizationName: ''};
        if(type !== PERM_DEPT){
            return orgs;
        } else{
            sectionID = parseInt(sectionID);
            const keys = Object.keys(permissions);
            keys.forEach(key=>{
                const setting = permissions[key];
                const active = setting.active != null && parseInt(setting.active) === 1;
                const overrideCheck = setting.override != null && setting.overrideSectionID != null && parseInt(setting.overrideSectionID) === sectionID;
                if(active && parseInt(setting.organizationID) === parseInt(organizationID) && overrideCheck === false){
                    orgs.count+=1;
                    orgs.orgShortName = setting.orgShortName;
                    orgs.organizationName = setting.organizationName;
                }
            })
        }
        return orgs;
    }
    const handleCheckbox=(checked, sectionID)=>{
        const {hasRecord, record} = getRecord(sectionID);
        //Determining minimum level if the record doesn't exist for the user
        const user = new User;
        const userPermissions = user.getPermissions(type)[sectionID];
        //Default Level is Athlete_LEVEL
        let minimumLevel = ATHLETE_LEVEL;
        if(type === PERM_DEPT && user.checkAthleteDept(userPermissions.deptID) === false) {
            //Dept isn't athlete dept, set mininum level to assistant
            const dept = DEPT_MINIMUM_REQUIRED_LEVELS.find(dept =>parseInt(dept.deptID) === parseInt(userPermissions.deptID));
            if(deviceAccount){
                minimumLevel = DEVICE_MASTER_LEVEL;
            } else if(dept != null && dept.length){
                minimumLevel = dept[0].minimum;
            } else {
                minimumLevel = ASSISTANT_LEVEL;
                //console.log('____SET ASSISTANT LEVEL___ SECTION ID:', sectionID, '||| MINLEVEL', minimumLevel);
            }
        }
        let managerLevel = hasRecord ? record.managerLevel : minimumLevel;
        //If the item is already checked, deactivate.
        const active = checked ? 0 : 1;
        const disablingDept = type === PERM_DEPT && active === 0;
        let displayConfirmation = false;
        let modalData = initLastDeptData();
        if(newUser === false && disablingDept){
            const orgData = getActiveDeptCount(userPermissions.organizationID, sectionID);
            if(orgData.count === 1) {
                displayConfirmation = true;
                //Last Active Dept for the user (Need an organization check??)
                modalData = {
                    display: true,
                    displayConfirmationAlert: false,
                    orgShortName: orgData.orgShortName,
                    organizationName: orgData.organizationName,
                    permissions: {
                        organizationID: userPermissions.organizationID,
                        sectionID: sectionID,
                        managerLevel: managerLevel,
                        active: active,
                    }
                };
            }
        }
        if(displayConfirmation){
            setLastDeptData(modalData);
        } else{
            updateFn(userPermissions.organizationID, sectionID, managerLevel, active);
        }
    }
    const displayDeletionConfirmation=()=>{
        let modalData = {...lastDeptData};
        modalData.displayConfirmationAlert = true;
        setLastDeptData(modalData);
    }
    const confirmDeletion=()=>{
        //Confirm Deletion of the last dept
        //Close the modal
        //Send the request to the server
        let p = lastDeptData.permissions;
        setLastDeptData(initLastDeptData);
        updateFn(p.organizationID, p.sectionID, p.managerLevel, p.active);
    }
    const handleSelector=(e, sectionID)=>{
        const {hasRecord, record} = getRecord(sectionID);
        if(hasRecord === false){
            return;
        }
        const {value} = e.target;
        const active = record.active != null ? record.active : 1;
        updateFn(record.organizationID, sectionID, value, active);
    }
    const getRecord=(sectionID)=>{
        const hasRecord = permissions[sectionID] != null;
        const record =  hasRecord ? permissions[sectionID] : {};
        return {hasRecord, record};
    }

    /*
        -List out viewer's permissions
        -object keys should be of the viewer's permissions
        -active/disabled is dependent on whether the record exists/active for the user
    */

    const checkOptionAvailable=(viewerPermissions)=>{
        if(type === PERM_TEAM){
            return true;
        } else if(type === PERM_DEPT && newUser === false){
            return true;
        } else if (type === PERM_DEPT && newUser === true && viewerPermissions.managerLevel >= OPERATIONS_LEVEL){
            return true;
        } else{
            return false;
        }
    }

    let deviceOrganizationID = false;
    if(deviceAccount && !isEmpty(deptPermissions)){
        const deptKeys = Object.keys(deptPermissions);
        deviceOrganizationID = parseInt(deptPermissions[deptKeys[0]].organizationID);
    }

    const user = new User();
    const viewerPermissions = user.getPermissions(type);
    const keys = Object.keys(viewerPermissions);
    const athleteSetting = athlete != null && athlete === true;
    const closeModalFn = ()=>setLastDeptData(initLastDeptData());
    const permissionsExist = permissions != null;
    return (
        <div>
            <Grid container spacing={2}>
                {keys.map((id, key)=>{
                    const active = permissionsExist && permissions[id] != null && parseInt(permissions[id].active) === 1;
                    const userPermissions = permissionsExist && permissions[id] != null ? permissions[id] : {};
                    //Determine if there's an active dept for each organizationID
                    //Only required for team assignments - team assignments are disabled when users aren't assigned to depts of same org
                    const organizationID = viewerPermissions[id].organizationID != null ? parseInt(viewerPermissions[id].organizationID) : -1;
                    const hasActiveDept = checkActiveDept(organizationID);
                    const enabledDeptCheck = checkOptionAvailable(viewerPermissions[id]);
                    const deptEnabled = hasActiveDept && enabledDeptCheck;
                    const locked = deviceAccount && type === PERM_DEPT;

                    if(deviceAccount && type === PERM_TEAM && deviceOrganizationID !== false && organizationID !== deviceOrganizationID){
                        //Prevent teams from being displayed outside the device's established organization
                        return null;
                    }

                    if(deviceAccount && type === PERM_DEPT && !active){
                        //Device Accounts are limited to organizational departments on creation
                        //They cannot be updated after the fact
                        return null;
                    }
                    return (
                        <Grid item xs={12} key={key}>
                            <PermissionEntry
                                key={key}
                                userID={userID}
                                sectionID={id}
                                active={active}
                                type={type}
                                assignedPermissions={userPermissions}
                                athlete={athleteSetting}
                                locked={locked}
                                deviceAccount={deviceAccount}
                                hasActiveDept={deptEnabled}
                                toggleFn={(checked, sectionID) => handleCheckbox(checked, sectionID)}
                                rankFn={(e, sectionID) => handleSelector(e, sectionID)}
                            />
                        </Grid>
                    )
                })}
            </Grid>
            <Modal
                open={lastDeptData.display}
                title={'Deleting Last Remaining Department: Are You Sure?'}
                closeFunction={closeModalFn}
                modalActions={
                    <React.Fragment>
                        <Button
                            color={'secondary'}
                            variant={'contained'}
                            onClick={closeModalFn}
                        ><Cancel/> Cancel</Button>
                    </React.Fragment>
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert
                            status={'warning'}
                            visible={true}
                            headline={'Deactivating Last Remaining Department'}
                            message={`You are about to deactivate the last remaining department for ${name}.`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body1'}>
                            This will <strong>COMPLETELY ELIMINATE</strong> all association between {name} and {lastDeptData.organizationName}.
                            <br/>You likely will no longer be able to manage or modify this user. <strong>Are you sure you want to do this?</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color={'primary'}
                            variant={'contained'}
                            onClick={()=>displayDeletionConfirmation()}
                            disabled={lastDeptData.displayConfirmationAlert}
                        ><DeleteForever/> Yes! I'm Sure</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color={'secondary'}
                            variant={'contained'}
                            onClick={closeModalFn}
                        ><Cancel/> Nevermind</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert
                            status={'error'}
                            visible={lastDeptData.displayConfirmationAlert}
                            headline={'Please Confirm You Want To Remove This User'}
                            message={'Please confirm one last time you really, REALLY want to do this.'}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        color={'primary'}
                                        variant={'contained'}
                                        disabled={!lastDeptData.displayConfirmationAlert}
                                        onClick={()=>confirmDeletion()}
                                    ><DeleteForever/> Yes! I'm ABSOLUTELY Sure</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        color={'secondary'}
                                        variant={'contained'}
                                        onClick={closeModalFn}
                                    ><Cancel/> Cancel</Button>
                                </Grid>
                            </Grid>
                        </Alert>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    )
}

export default UserPermissions;