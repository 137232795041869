import {LAYOUT_CONTAINER_STYLES} from "@jumbo/utils/constants/layout";
import LAYOUT_NAMES from "../layouts/layouts";
import {createJumboTheme} from "@jumbo/utils";
import Theme from 'fit/system/Theme';

const t = new Theme();
const themeID = 1;
const newMaintheme = t.buildTheme(themeID, 'main');
const newHeadertheme = t.buildTheme(themeID, 'header');
const newFooterTheme = t.buildTheme(themeID, 'footer');
const newSidebarTheme = t.buildTheme(themeID, 'sidebar');

const config = {
    defaultLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
    authorizedLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
    containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,
    theme: createJumboTheme(newMaintheme, newHeadertheme, newSidebarTheme, newFooterTheme),
}

/*
const config = {
    defaultLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
    authorizedLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
    containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,
    theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),
};

 */

export {config};
