import React from 'react';
import {LinearProgress, Tooltip, Box} from "@mui/material";

const ColorCodedStatusBar=({score, color, message})=>{
    const valueScore = parseInt(score);
    const statusStyle = {color: color};
    return (
        <Tooltip title={message} placement={'bottom'}>
            <Box sx={{...statusStyle, paddingTop: '3px'}}>
                <span style={{...statusStyle, fontWeight: 'normal'}}>{valueScore}%</span>
                <LinearProgress variant={'determinate'} value={valueScore} color={'inherit'}/>
            </Box>

        </Tooltip>
    )
}

export default ColorCodedStatusBar;
