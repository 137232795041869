import React from 'react';
import {List, ListItem, ListItemIcon, ListItemText, Paper, Typography} from "@mui/material";
import {getStatusColor} from "../../../system/UtilityFunctions";
import {Block, CheckCircle} from "@mui/icons-material";

const PasswordChecks=({capital, lower, match, minChars, number, special})=>{
    const renderIcon=(status)=>{
        const colorString = status ? 'success' : 'error';
        const color = getStatusColor(colorString);
        const style = {color: color};
        return status ? <CheckCircle style={style}/> : <Block style={style}/>;
    }

    const capitalIcon = renderIcon(capital);
    const lowerIcon = renderIcon(lower);
    const matchIcon = renderIcon(match);
    const minCharsIcon = renderIcon(minChars);
    const numberIcon = renderIcon(number);
    const specialIcon = renderIcon(special);

    return (
        <div>
            <Typography variant={'h4'}>Password Requirements</Typography>
            <List>
                <ListItem>
                    <ListItemIcon>
                        {minCharsIcon}
                    </ListItemIcon>
                    <ListItemText>
                        12 characters minimum
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        {numberIcon}
                    </ListItemIcon>
                    <ListItemText>
                        1 Number
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        {capitalIcon}
                    </ListItemIcon>
                    <ListItemText>
                        1 Capital Letter
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        {lowerIcon}
                    </ListItemIcon>
                    <ListItemText>
                        1 Lowercase Letter
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        {specialIcon}
                    </ListItemIcon>
                    <ListItemText>
                        1 Special Character
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        {matchIcon}
                    </ListItemIcon>
                    <ListItemText>
                        Retyped Password Matches Original
                    </ListItemText>
                </ListItem>
            </List>
        </div>
    )
}

export default PasswordChecks;