import React from 'react';
import {Box} from '@mui/material';
import {useTheme, useMediaQuery} from "@mui/material";
import ExternalSystemFormWrap from "./ExternalSystemFormWrap";

const SoloFormWrapper=(props)=>{


    return (
        <ExternalSystemFormWrap>
            {props.children}
        </ExternalSystemFormWrap>
    )

    const screen ={
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const size = smScreen ? '90%' : '40%';

    const form = {
        width: size,
    }
    return (
        <Box sx={screen}>
            <Box sx={form}>
                {props.children}
            </Box>
        </Box>
    );
}


export default SoloFormWrapper;