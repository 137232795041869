import React from 'react';
import {getSpacing} from "fit/system/UtilityFunctions";
import {DESKTOP_MIN_HEIGHT} from "fit/system/FITConstants";

const TabSection=({children, paddingTop = true})=>{
    const paddingSize = paddingTop ? getSpacing() : '0px';
    return(
        <div style={{paddingTop: paddingSize, minHeight: DESKTOP_MIN_HEIGHT}}>
            {children}
        </div>
    )
}

export default TabSection;