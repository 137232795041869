import React, {useState, useEffect} from 'react';
import useDebounce from "../Functional/useDebounce";
import {InputAdornment, IconButton, Tooltip, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputMask from "react-input-mask";
import {TEXTFIELD_DEBOUNCE_DELAY} from "fit/system/FITConstants";

const SecurityPin=({disabled, fullWidth, displayPin, name, label, helperText, value, onChange, placeholder, setDisplayPin, error, delay, autoComplete})=>{
    const updateDelay = delay != null && parseInt(delay) > 0 ? parseInt(delay) : TEXTFIELD_DEBOUNCE_DELAY;
    const [localValue, setLocalValue] = useState(value);

    //Update state to match props value
    useEffect(()=>{
        setLocalValue(value)
    },[value]);

    //Send value to parent component
    useDebounce(()=>{
        onChange(localValue);
    }, updateDelay,[localValue]);

    label = label !== undefined && label != null && label !== '' ? label : 'Personal Security Pin';
    const toolTipMessage = displayPin ? 'Hide Pin' : 'Show Pin';
    const type = displayPin ? 'text' : 'password';
    const mask = '99999999'; //8 chars
    const pinError = localValue.length < 4;
    let text;
    if(helperText !== '' && helperText !== ' '){
        text = helperText;
    } else if(helperText === ' ' && pinError){
        text = 'Pin must be at least 4 characters long';
    } else{
        text = ' ';
    }
    return (
        <InputMask
            mask={mask}
            maskChar={''}
            name={name}
            label={label}
            error={error || pinError}
            helperText={text}
            disabled={disabled}
            value={localValue}
            onChange={(e)=>setLocalValue(e.target.value)}
            placeholder={placeholder}
            inputProps={{inputMode: 'decimal'}}
            type={type}
        >
        {(props)=> {
            return (
                <TextField
                    fullWidth={fullWidth}
                    autoComplete={autoComplete}
                    disabled={disabled}

                    {...props}
                    sx={{minWidth: '240px'}}
                    inputProps={{inputMode: 'decimal'}}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip title={toolTipMessage} placement={'bottom'}>
                                    <IconButton
                                        edge={'end'}
                                        color={'primary'}
                                        onClick={()=>setDisplayPin(!displayPin)}
                                    >
                                        {!displayPin ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                    }}
                />
            )
        }}
        </InputMask>
    )

}

export default SecurityPin;