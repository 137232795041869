import React from 'react';
import {Tooltip} from "@mui/material";
import {getStatusIcon, getStatusColor, getSpacing} from "fit/system/UtilityFunctions";

const DeviceStatus=({assignedStatus = false})=>{
    const assigned = assignedStatus === true || parseInt(assignedStatus) === 1;
    const status = assigned ? 'success' : 'warning';
    const statusColor = getStatusColor(status);
    const icon = getStatusIcon(status, {position: 'relative', top: getSpacing('iconAlignment'),color: statusColor});
    const statusText = assigned ? 'Active' : 'Inactive';
    const tip = assigned ? 'Account/Device active and ready' : 'Account Inactive: not logged into device';
    return (
        <Tooltip title={tip} placement={'right'}>
            {icon}
            <span style={{color: statusColor}}>
                {statusText}
            </span>
        </Tooltip>
    )

}
export default DeviceStatus;