import React from 'react';
import {Typography, Button, Grid, Paper, TextField, Box, LinearProgress, InputAdornment} from "@mui/material";
import {enqueueSnackbar } from 'notistack'
import {Navigate} from "react-router-dom";
import SecureConnect from "fit/system/SecureConnect";
import {Link as RouterLink} from 'react-router-dom';
import Link from 'fit/components/System/Link'
import {Table, TableHead, TableRow, TableSortLabel,  TableBody, TableCell, IconButton} from "@mui/material/";
import {AccordionDetails, AccordionSummary, Accordion} from "@mui/material";
import {Edit, ExpandMore} from "@mui/icons-material";
import {getSpacing, getGraphLineColor, getRPEColor, getTimestamp} from "fit/system/UtilityFunctions";
import User from "fit/system/User";
import {connect} from 'react-redux';
import {ASSISTANT_LEVEL, PERM_DEPT, PERM_TEAM, URL_LOGIN} from "fit/system/FITConstants";
import {PRODUCTION_STATUS, URL_MY_PROFILE, PAPER_STYLE} from "fit/system/FITConstants";
import CreateUserForm from 'fit/components/User/CreateUserForm';
import {Slider} from '@mui/material';
import MeasurementUnits from "fit/system/MeasurementUnits";
import MeasurementField from "fit/components/Form/MeasurementField";
import RPEModal from "fit/components/Dialogs/RPEModal";
import RefreshStatusBar from "fit/components/RefreshStatusBar";
import moment from 'moment';
import Alert from "fit/components/Alert";
import QRCodeScanner from "fit/components/System/QRCodeScanner";

const GridSquare=({count, color, size= 48})=>{
    const sizeText = `${size}px`;
    const gridStyle={
        display: 'block',
        background: color,
        width: sizeText,
        height: sizeText,
        float: "left"
    }
    return <div key={count} style={gridStyle}>{count}</div>
}


class Home extends React.Component{
    constructor() {
        super();
        this.someRef = React.createRef();
    }
    state = {
        pin: {display: false, value: ''},
        rossNumber: '',
        displayWeightHelper: false,
        sliderValue: 1,
        displayNewUserForm: false,
        testData: {},
        weightData: {
            value: 0,
            unitID: 2,
        },
        dataLoaded: false,
        rowFunctions: null,
        startDate: '',
        repCount: 0,
        maskedTest: 1,
        handleList: 'sort', //sort

        length: {
            unitID: 4,
            value: 71.875,
            feetInchesData: {},
        },
        mass: {
            unitID: 2,
            value: 0
        },
        measurementValues: {
            reps: 5,
            weight: '',
            timeHHMMSS: '',
            timeMMSS: '',
            timeSS: '',
            precision: 1,
            timeTest: '',
        },
        RPE:{
            open: false,
            score: 1,
            lowRange: 1,
            highRange: 20,
            name: 'Emily Reiner'
        },
    }
    setDisplayUserForm(status){
        this.setState({displayNewUserForm: status})
    }
    getAuthTest(){
        console.log('AUTH DATA TEST');
        let sc = new SecureConnect('system.php?action=authUser','get');
        sc.connect().then(json =>{
           if(sc.getCompleted(json)){
               const data = sc.getData(json);
               console.log('TEST DATA', data);
           }
        });
    }
    displayWeightHelper=(bool)=>{
        this.setState({displayWeightHelper: bool});
    }
    resetLength=()=>{
        this.setState({
            length: {
                unitID: 4,
                value: 71.875,
                feetInchesData: {}
            }
        })
    }
    changeMeasurementUnit=(section, unitID)=>{
        let mf = {...this.state[section]};
        const currentUnitID = mf.unitID;
        mf.unitID = parseInt(unitID);
        if(mf.value !== '' || parseFloat(mf.value) !== 0) {
            const mUnits = new MeasurementUnits();
            mf.value = mUnits.convertUnits(mf.value, currentUnitID, unitID, 3);
        }
        this.setState({[section]: mf});
    }
    changeDynamicMeasurement=(section, measurementValue)=>{
        //console.log('DYNAMIC MEASUREMENT', section, measurementValue);
        let mf = {...this.state[section]};
        mf.value = measurementValue;
        this.setState({[section]: mf});
    }
    handleTime(e, precision = 0){
        let {name, value} = e.target;
        let mUnits = new MeasurementUnits();
        value = mUnits.handleTimeValue(value, 2);
        let m = structuredClone(this.state.measurementValues);
        m[name] = value;
        this.setState({measurementValues: m});
    }
    openRPE(open = true){
        let rpe = this.state.RPE;
        rpe.open = open;
        rpe.score = 10;
        this.setState({RPE: rpe});
    }
    handleRPEScore(e){
        //Update the score of the RPE Form
        let value = e.target.value;
        let {RPE} = this.state;
        RPE.score = value;
        this.setState({RPE});
    }
    handleDate(e){
        console.log(e);
    }
    handleChange(e){
        console.log(e);
        const {name, value} = e.target;
        this.setState({[name]: value});
    }
    listRoutes(){
        const jsonString = localStorage.getItem('tempRoutes');
        const routes = jsonString !== '' ? JSON.parse(jsonString) : [];
        if(routes.length <= 0){
            return;
        }
        let listItems = [];
        routes.forEach(route =>{
            listItems.push(
                <li><RouterLink to={route.uri}>{route.label} ({route.uri})</RouterLink></li>
            )
        });
        return listItems;
    }
    testHeader(method){
        let sc = new SecureConnect('testapi.php',method);
        sc.setAction('testHeader');
        sc.setFormData({name: 'blah blah', email:'something@somewhat.net'});
        sc.connect().then(json=>{

        });
    }
    handlePin(value){
        let p = {...this.state.pin};
        p.value = value;
        this.setState({pin: p});
    }
    displayPin(display){
        let p = {...this.state.pin};
        p.display = display;
        this.setState({pin: p});
    }

    testWorkoutAlerts(){
        let alertList = [];
        const units = [1,2,3,5,4,12]; //1 (time),2 (pounds),3 (kg), 5 (ft/in), 4 (in), 12 (m)
        const weightUnits = [2,3];

        const testData = {
            '1':{ //Time
                targetReps: '4:50',
                targetValue: '4:50'
            },
            '2': { //lbs
                percentageValue: 50,
                targetReps: 5,
                targetValue: 100,
            },
            '3': { //kg
                percentageValue: 65,
                targetReps: 5,
                targetValue: 100,
            },
            '4': { //in
                targetReps: 24,
                targetValue: 26
            },
            '5': { //ft/in
                targetReps: "4'3\"",
                targetValue: "2'3\"",
            },
            '12': { //meters
                targetReps: '2.5',
                targetValue: '1.8'
            }
        }

        const mUnits = new MeasurementUnits();
        const autoCompleteEnabled = false;
        const percentageEnabled = true;
        const targetValueAssigned = true;
        const measurementLabel = 'inputLabel';



        units.forEach(repID =>{
            units.forEach(weightID =>{
                const repData = mUnits.getMeasurementUnitByID(repID);
                const weightData = mUnits.getMeasurementUnitByID(weightID);

                const resistanceUnit = weightData.name.toLowerCase();

                let repLabel = repData[measurementLabel];
                const weightLabel = weightData[measurementLabel];

                const resistanceData = testData[weightID];
                const {targetReps, percentageValue, targetValue} = resistanceData;
                const weightPlaceholder = targetValueAssigned ? targetValue : '';

                let liftMessage = '';
                if(autoCompleteEnabled){
                    if(targetValueAssigned){
                        liftMessage = `${repLabel} and ${weightLabel.toLowerCase()} are predetermined. Update the fields if you increased or reduced your values.`;
                    } else{
                        liftMessage = `${repLabel} are predetermined. Input the ${weightLabel} value.`;
                    }
                } else{
                    //autoCompleteDisabled
                    repLabel = repLabel.toLowerCase()
                    if(targetValueAssigned){
                        liftMessage = `This set requires ${targetReps} ${repLabel} and a calculated ${weightLabel} of ${weightPlaceholder} ${resistanceUnit}.`
                    }else if(targetValueAssigned === false && percentageEnabled){
                        liftMessage = `This set requires ${targetReps} ${repLabel} at ${percentageValue}% of your max.`;
                    } else{
                        liftMessage = `This set requires ${targetReps} ${repLabel}.`;
                    }
                }

                alertList.push(
                    <Alert
                        visible={true}
                        status={'description'}
                        style={{marginTop: '12px'}}
                        headline={`REP UNIT: ${repID}, Weight Unit: ${weightID}`}
                        message={liftMessage}
                    />
                )
            })
        })

        return null; //alertList
    }


    render(){
        if(PRODUCTION_STATUS){
            return (<Navigate to={URL_MY_PROFILE}/>);
        }
        let user = new User();
        const userData = user.getUserData();
        const isAthlete = user.isAthlete();
        //console.log('LEVEL CHECK', user.getManagerLabel(user.getSystemManagerLevel(1),false));
        let name = 'noName';
        if(user.getLoggedInStatus()){
            let info = userData.account.userInfo;
            name = `${info.firstName} ${info.lastName}`;
        }
        const s = this.state;




        let RPEGrid = [];
        for(let j = 1; j<=9; j++){
            const color = getGraphLineColor(j);
            RPEGrid.push(
                <GridSquare count={j} color={color} />
            )
        }

        const size = 40

        const renderColorGrid=(limit, size)=>{
            let grid = [];
            for(let k=0; k<limit; k++){
                let color = getGraphLineColor(k);
                grid.push(<GridSquare color={color} count={k} size={size}/>)
            }
            return grid;
        }

        const debounceWrapped=()=>{
            console.log('START', moment().format('HH:MM:SS'));
        }




        return (
      <div>
            <Typography variant = 'h1'>Hello {name},</Typography>
            <Paper style={PAPER_STYLE}>

                <QRCodeScanner/>


                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>Color Scales</AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'h3'}>NEW WITH SATURATION DEGRADATION</Typography>
                                {renderColorGrid(300, size)}


                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore/>}>Changing Units</AccordionSummary>
                  <AccordionDetails>
                      <Grid container spacing={2}>
                          <Grid item xs={12}>
                              <Typography variant={'h3'}>Changing Units</Typography>
                          </Grid>
                          <Grid item xs={12}>
                              <MeasurementField
                                  allowAlternativeMeasurements={true}
                                  measurementID={s.length.unitID}
                                  label={'Height'}
                                  name={'height'}
                                  value={s.length.value}
                                  precision={3}
                                  changeFn={(value)=>this.changeDynamicMeasurement('length', value)}
                                  changeUnitFn={(unitID)=>this.changeMeasurementUnit('length', unitID)}
                              />
                          </Grid>
                          <Grid item xs={12}>
                              <Button
                                  color={'secondary'}
                                  variant={'contained'}
                                  onClick={()=>this.resetLength()}
                              >Reset Length</Button>
                          </Grid>
                          <Grid item xs={12}>
                              {/*
                              <MeasurementField
                                  measurementID={s.length.unitID}
                                  label={'Height'}
                                  name={'height'}
                                  value={s.length.value}
                                  precision={0}
                                  changeFn={(value)=>this.changeDynamicMeasurement('length', value)}
                                  changeUnitFn={(unitID)=>this.changeMeasurementUnit('length', unitID)}
                              />*/}
                          </Grid>
                          <Grid item xs={12}>
                              <hr/>
                              <MeasurementField
                                  measurementID={s.mass.unitID}
                                  label={'Weight'}
                                  name={'mass'}
                                  value={s.mass.value}
                                  precision={0}
                                  changeFn={(value)=>this.changeDynamicMeasurement('mass', value)}
                                  changeUnitFn={(unitID)=>this.changeMeasurementUnit('mass',unitID)}
                              />
                          </Grid>
                          <Grid item xs={6}>
                              <MeasurementField
                                  measurementID={14}
                                  name={'longLength'}
                                  precision={3}
                              />
                          </Grid>
                          <Grid item xs={12}>
                              <hr/>
                          </Grid>
                      </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMore/>}>Login Checks/Buttons</AccordionSummary>
                  <AccordionDetails>
                      <Grid container spacing={1}>
                          <Grid item xs={3}>
                              <Button
                                  fullWidth
                                  onClick={this.getAuthTest.bind(this)}
                                  color={'primary'}
                                  variant={'contained'}
                              >
                                  Load Authorizations
                              </Button>
                          </Grid>
                          <Grid item xs={3}>
                              <Button
                                  fullWidth
                                  onClick={()=>{user.logout()}}
                                  color={'secondary'}
                                  variant={'contained'}
                              >Logout</Button>
                          </Grid>
                          <Grid item xs={3}>
                              <Button
                                  fullWidth
                                  onClick={()=>{
                                      console.log('-----------OUTPUTTING USER STORE()-----------');
                                      console.log('Logged In', user.getLoggedInStatus());
                                      console.log('Device Account', user.getDeviceAccount());
                                      console.log('permissions', user.getPermissions());
                                      console.log('----------/OUTPUTTING USER STORE()-----------');
                                  }}
                                  color={'secondary'}
                                  variant={'outlined'}
                              >
                                  Output Store Permissions
                              </Button>
                          </Grid>
                          <Grid item xs={3}>
                              <RouterLink to={URL_LOGIN}>
                                  <Button
                                      fullWidth
                                      color={'primary'}
                                      variant={'outlined'}
                                  >Go To Login</Button>
                              </RouterLink>
                          </Grid>
                          <Grid item xs={12}>
                              <Button
                                  color={'primary'}
                                  variant={'contained'}
                                  fullWidth
                                  onClick={()=>{enqueueSnackbar('Have you thought about jumping off a bridge?',{autoHideDuration: 3000, variant: 'info'})}}
                              >Click for snackbar</Button>
                          </Grid>
                          <Grid item xs={12}>
                              <Button
                                  onClick={()=>this.setState({displayNewUserForm: true})}
                                  color={'primary'}
                                  variant={'contained'}
                              >New User</Button>
                              <span> </span>
                              <Button color={'secondary'} onClick={()=>this.openRPE(true)} variant={'contained'}>OPEN RPE</Button>
                              <span> </span>
                              <Button color={'primary'} onClick={()=>this.displayWeightHelper(true)} variant={'contained'}>Weight Helper</Button>
                              <span> </span>
                              <Button color={'secondary'} variant={'outlined'} onClick={()=>this.testHeader('post')}>Test Header (POST)</Button>
                              <span> </span>
                              <Button color={'primary'} variant={'outlined'} onClick={()=>this.testHeader('get')}>Test Header (GET)</Button>
                          </Grid>
                      </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore/>}>RPE Stuff</AccordionSummary>
                  <AccordionDetails>
                      <Grid container spacing={2}>
                          <Grid item xs={12}>
                              {RPEGrid}
                          </Grid>
                          <Grid item xs={12}>
                              <div style={{
                                  height: '12px',
                                  display: 'block',
                                  border: '1px solid #000',
                                  background: getRPEColor(this.state.sliderValue*5)
                              }}
                              ></div>
                          </Grid>
                          <Grid item xs={12}>
                              <Slider
                                  onChange={(e)=>{
                                      this.setState({sliderValue: parseInt(e.target.value)})
                                  }}
                                  valueLabelDisplay="auto"
                                  step={1}
                                  marks
                                  min={1}
                                  max={20}
                                  size={'large'}
                                  value={this.state.sliderValue}
                              />
                          </Grid>
                      </Grid>
                  </AccordionDetails>
                </Accordion>
            </Paper>
          <CreateUserForm
              display={s.displayNewUserForm}
              handleClose={()=>this.setDisplayUserForm(false)}
              completedFunction={()=> {
                  console.log('****************');
                  console.log('NEW USER CREATED');
                  console.log('NEW USER CREATED');
                  console.log('NEW USER CREATED');
                  console.log('NEW USER CREATED');
                  console.log('NEW USER CREATED');
                  console.log('****************');
              }
              }
          />
          <RPEModal
              open={this.state.RPE.open}
              lowRange={this.state.RPE.lowRange}
              highRange={this.state.RPE.highRange}
              score={this.state.RPE.score}
              name={this.state.RPE.name}
              closeFunction={()=>{this.openRPE(false)}}
              handleScoreFunction={(e)=>this.handleRPEScore(e)}
              handleSubmitFunction={()=>this.openRPE(false)}
          />
          {/*
          <WeightHelperModal
              targetValue={150}
              targetUnitID={2}
              precision={0}
              display={this.state.displayWeightHelper}
              closeModalFn={()=>this.displayWeightHelper(false)}
          />
          */}
      </div>
    );
    }
}
/*
const Home = () => {
    return (
        <div>
            <Typography variant={'h1'}>Balls</Typography>
            <h2>Sample blank page</h2>
            <p>Hahahaha go fuck yourself!</p>
            <p>This page is just to showcase the way you can add your own pages.</p>
            <p>Happy Coding!</p>
        </div>
    );
};
 */

const mapStateToProps = (state) => {
    return {FITUser: state.FITUser};
};

export default connect(mapStateToProps)(Home);