import React from 'react';
import {Button, Checkbox, Grid, MenuItem, TextField, Typography, FormControlLabel, Tooltip} from "@mui/material";
import InputMask from "react-input-mask";
import Alert from "../Alert";
import moment from "moment";
import {ORGANIZATION_TYPES, MOMENT_DATE_FORMAT} from "fit/system/FITConstants";
import {splitISODateString} from "fit/system/UtilityFunctions";
import {getSpacing} from "fit/system/UtilityFunctions";
import User from 'fit/system/User';


const AthleteEligibilityForm=({affiliationData, affiliationKey, athleteLabels, disabled, newUser, updateFunction, submitFunction})=>{
    /*
    affiliationData:
    {
        expirationDate: ISODateString
        organizationTypeLabelID: integer (label ID), use ORGANIZATION_TYPES
        redShirt: 1/0
        yearLabelID: integer
        active: 1/0
        expired: 1/0
    }
    */
    const getAthleteLabelSetting=(yearLabelID)=>{
       return athleteLabels.find(set => parseInt(set.labelID) === parseInt(yearLabelID));
    }
    const checkUniversity=()=>{
        return parseInt(affiliationData.organizationTypeID) === parseInt(ORGANIZATION_TYPES.university)
    }
    const checkRedshirtOption=()=>{
        if(!checkUniversity()){
            //Not University. No option available
            return false;
        }
        if(affiliationData.yearLabelID === 0){
            //Not Specified. Enable
            return true;
        }
        const index = athleteLabels.findIndex(item => parseInt(item.labelID) === parseInt(affiliationData.yearLabelID) && item.redShirtAlt != null);
        return index > -1;
    }
    const filterLabels=()=>{
        if(checkUniversity()){
            //University
            const collegeID = parseInt(ORGANIZATION_TYPES.university);
            return athleteLabels.filter(item => parseInt(item.redShirt) === parseInt(affiliationData.redShirt) && parseInt(item.organizationTypeID) === collegeID);
            if(parseInt(yearLabelID) === 0) {
                //Supply all university
                return athleteLabels.filter (item => parseInt(item.organizationTypeID) === collegeID)
            } else{
                //Filter the list based on redshirt Setting
                return athleteLabels.filter(item => parseInt(item.redShirt) === parseInt(affiliationData.redShirt) && parseInt(item.organizationTypeID) === collegeID);
            }
        }
        else if(!checkUniversity()){
            //High school
            return athleteLabels.filter(item => parseInt(item.organizationTypeID) === parseInt(ORGANIZATION_TYPES.highSchool));
        }
        else {
            return athleteLabels;
        }
    }
    const handleAffiliationData=(setting, value)=>{
        let section = structuredClone(affiliationData);
        const noExpiration = section.expirationDate == null || section.expirationDate === '';
        //console.log('AFFILIATION', affiliationKey, setting, value);
        switch(setting){
            case 'month':
                //Recreate expiration Date set month of expiration date (MONTH-MAX DAY OF MONTH-year)
                const month = value < 10 ? `0${value}` : `${value}`;
                if(noExpiration){
                    const year = parseInt(moment().format('YYYY'));
                    section.expirationDate = `${year}-${month}-01`;
                } else{
                    const d = splitISODateString(section.expirationDate);
                    section.expirationDate = `${d.year}-${month}-01`;
                }
                break;
            case 'year':
                //Recreate expiration Date set YEAR of expiration date (month-MAX day of Month-YEAR)
                if(noExpiration){
                    //Get the current month,
                    section.expirationDate = `${value}-${'06'}-01`
                } else{
                    const d = splitISODateString(section.expirationDate);
                    section.expirationDate = `${value}-${d.month}-01`;
                }
                break;
            case 'redShirt':
                //console.log('HANDLING RedShirt');
                //Determine checked status: set redShirt status
                //Flip yearLabelID based on redShirtAlt value
                section.redShirt = value ? 1 : 0;
                const currentSetting = getAthleteLabelSetting(section.yearLabelID);
                if(parseInt(section.yearLabelID) !== 0 && currentSetting?.redShirtAlt != null) {
                    section.yearLabelID = currentSetting.redShirtAlt;
                }
                break;
            case 'studentYear':
                //console.log('HANDLING STUDENT YEAR');
                section.yearLabelID = value;
                //find the year label
                const setting = getAthleteLabelSetting(value);
                if(setting != null && setting.redShirt != null){
                    section.redShirt = setting.redShirt;
                }
                break;
        }
        updateFunction(affiliationKey, section);
    }
    const user = new User();
    const sportSpecificCoach = user.isSportSpecificCoach();
    const universityAffiliation = checkUniversity();
    const {expirationDate, redShirt, yearLabelID} = affiliationData;
    const active = parseInt(affiliationData.active) === 1;
    const dateSet = splitISODateString(expirationDate);  //localExpiration
    const month = parseInt(dateSet.month)-1; //Array Index Offset
    const year = dateSet.year;
    const currentYear = moment().format('YYYY');
    const currentDate = moment().format(MOMENT_DATE_FORMAT);
    const invalidDate = (expirationDate < currentDate && newUser);
    const yearError = (year.length > 0 && year.length < 4) || invalidDate;
    const monthError = expirationDate == null || invalidDate || (expirationDate !== '' && (month > 11 || month < 0));
    const studentYearError = parseInt(yearLabelID) === 0;
    let yearHelperText;
    if(invalidDate){
        yearHelperText = 'Invalid Date';
    } else if(yearError) {
        yearHelperText = 'Year Invalid';
    } else if(expirationDate == null || expirationDate === ''){
        yearHelperText = 'Select Month First';
    } else{
        yearHelperText = ' ';
    }
    let toolTipMessage;
    const redShirtAvailable = checkRedshirtOption();
    if(disabled){
        toolTipMessage = 'Disabled';
    } else if(!redShirtAvailable){
        toolTipMessage = 'Disabled: No Redshirt Alternative';
    } else{
        toolTipMessage = 'Toggle Redshirt Status';
    }
    //Set the columnWidth of month/year based on university affiliation - there's no redshirt for high schools
    const expirationColumnWidth = universityAffiliation ? 4 : 6;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={'body1'}>
                    These settings aid in managing rosters and statistical reporting for teams.
                </Typography>
                <Alert
                    status={'error'}
                    visible={expirationDate === null && active === true}
                    headline={'Athlete Eligibility Incomplete'}
                    message={'Graduation Date and the student\'s year in school still need to be entered.'}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={expirationColumnWidth}>
                <TextField
                    fullWidth
                    label={'Graduation Month'}
                    value={month}
                    onChange={(e)=>handleAffiliationData('month',parseInt(e.target.value)+1 /*Array Index Offset*/)}
                    error={monthError}
                    helperText={invalidDate ? 'Invalid Date' : monthError ? 'Invalid Month' : ' '}
                    disabled={disabled || !sportSpecificCoach}
                    select
                >
                    {
                        moment.months().map((month, k)=>{
                            return (
                                <MenuItem key={k} value={k}>{month}</MenuItem>
                            )
                        })
                    }
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={expirationColumnWidth}>
                <InputMask
                    fullWidth={true}
                    mask={'9999'}
                    maskChar={''}
                    label={'Graduation Year'}
                    placeholder={currentYear}
                    name={'expirationDate'}
                    disabled={disabled || expirationDate == null || !sportSpecificCoach}
                    value={year}
                    onChange={(e)=>handleAffiliationData('year',e.target.value)}
                    error={yearError}
                    helperText={yearHelperText}
                >
                    {(props)=> {return <TextField {...props} error={yearError} disabled={disabled || expirationDate == null || !sportSpecificCoach} InputLabelProps={{shrink: true}} />}}
                </InputMask>
            </Grid>
            {
                universityAffiliation ?
                    <Grid item xs={12} sm={12} md={4}>
                        <div style={{paddingTop: getSpacing('tiny')}}>
                            <Tooltip title={toolTipMessage} placement={'bottom'}>
                                <FormControlLabel
                                    control={<Checkbox/>}
                                    label={'Redshirt'}
                                    disabled={disabled || !redShirtAvailable || !sportSpecificCoach}
                                    onChange={(e)=>handleAffiliationData('redShirt',e.target.checked)}
                                    checked={parseInt(redShirt) === 1}
                                />
                            </Tooltip>
                        </div>
                    </Grid>
                : null
            }
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    select
                    label={'Student Year'}
                    value={yearLabelID}
                    disabled={disabled || !sportSpecificCoach}
                    error={studentYearError}
                    helperText={studentYearError ? 'Enter Student Year' : ' '}
                    onChange={(e)=>handleAffiliationData('studentYear', parseInt(e.target.value))}
                >
                    {
                        parseInt(yearLabelID) === 0 ?
                            <MenuItem value={0}>Unspecified</MenuItem> : null
                    }
                    {
                        filterLabels().map((setting, key)=>{
                            return (
                                <MenuItem key={key} value={setting.labelID}>{setting.label}</MenuItem>
                            )
                        })
                    }
                </TextField>
            </Grid>
            {!newUser && sportSpecificCoach ?
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>submitFunction()}
                        disabled={disabled || monthError || yearError || studentYearError || !sportSpecificCoach}
                    >Update Athlete Eligibility Information</Button>
                </Grid>
                : null
            }
        </Grid>
    )
}

export default AthleteEligibilityForm;