import React from 'react';
import {Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from "@mui/material";
import User from 'fit/system/User';
import UserPreferences from 'fit/system/UserPreferences';

const DeviceSettings=({unitsLocked, altAccounts, headerVariant, onChange})=>{

    const lockUnits = parseInt(unitsLocked) === 1 || unitsLocked === true;
    const alts = parseInt(altAccounts);
    const variant = headerVariant ? headerVariant : 'h3';
    //console.log('UNITS LOCKED', unitsLocked, 'LOCK UNITS', lockUnits);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={variant}>Device Settings</Typography>
                <Typography variant={'body1'}>
                    Locking Device Units prevents users from modifying the measurement units when recording their workouts. If your exercise equipment is specifically set up to use a single unit type (e.g. pounds or inches), you will want to enable this setting. Disabling this setting will allow a user to change how a measurement will be recorded.
                </Typography>
                <FormControl>
                    <FormLabel>Lock Device Units</FormLabel>
                        <RadioGroup>
                            <FormControlLabel control={<Radio color={'primary'} checked={lockUnits} onChange={()=>onChange('unitsLocked', 1)}/>} label={'On: Lock measurement units on this device'} />
                            <FormControlLabel control={<Radio color={'secondary'} checked={!lockUnits} onChange={()=>onChange('unitsLocked', 0)}/>} label={'Off: allow entry of alternative measurement units'}/>
                        </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'body1'}>
                    This setting enables Buteo users to access their workouts through this device. Disabling this setting will only allow authorized coaches to push their workouts to the device.
                </Typography>
                <FormControl>
                    <FormLabel>Allow Users To Sign Into Their Accounts Through This Device</FormLabel>
                        <RadioGroup>
                            <FormControlLabel control={<Radio color={'secondary'} checked={alts === 0} onChange={()=>onChange('allowAltAccounts', 0)}/>} label={'Disabled: Only authorized coaches can assign workouts to the device'}/>
                            <FormControlLabel control={<Radio color={'primary'} checked={alts === 1} onChange={()=>onChange('allowAltAccounts', 1)}/>} label={'Athletes with matching team permissions may access their workouts'} />
                            <FormControlLabel control={<Radio color={'primary'} checked={alts === 2} onChange={()=>onChange('allowAltAccounts', 2)}/>} label={'Athletes associated with the device\'s assigned organization may load their workouts'} />
                            <FormControlLabel control={<Radio color={'primary'} checked={alts === 3} onChange={()=>onChange('allowAltAccounts', 3)}/>} label={'All Buteo users may load their workouts through the device'} />
                        </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default DeviceSettings;