import React, {useState} from 'react';
import Alert from "../Alert";
import {isEmpty, getSpacing} from "fit/system/UtilityFunctions";
import MeasurementUnits from "fit/system/MeasurementUnits";
import {InputAdornment, TextField, Grid} from "@mui/material";
import InputMask from "react-input-mask";
import MeasurementOptionsMenu from "./MeasurementField/MeasurementOptionsMenu";
import ActiveUnitLabel from "./ActiveUnitLabel";


const MeasurementField=({measurementID, name, label, value, changeFn, allowAlternativeMeasurements = true, size, changeUnitFn, openWeightHelperFn = null, precision = 0, autoFocus = false, error, helperText, placeholder, disabled, readOnly, allowedMeasurementUnitsList, shrink = undefined, ref})=>{
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuElement, setMenuElement] = useState(null);
    const checkForError=(error, name)=>{
        return error != null && (error[name] != null && error[name].length > 0) || error === true;
    }
    const getErrorMessage=(error, name, helperText = '')=>{
        return checkForError(error, name) ? error[name] : helperText;
    }
    const openMenu=(e)=>{
        setMenuOpen(true);
        setMenuElement(e.currentTarget);
    }
    const closeMenu=()=>{
        setMenuOpen(false);
        setMenuElement(null);
    }
    const selectItem=(selectionValue)=>{
        closeMenu();
        changeUnitFn(selectionValue);
    }
    const openHelper=()=>{
        closeMenu();
        openWeightHelperFn();
    }
    const updateValue=(value)=>{
        //Validate & filter the value based on measurementID
        //send the updated value to the change function
        const mUnits = new MeasurementUnits();
        const newValue = mUnits.handleValue(value, measurementID, precision);
        changeFn(newValue);

    }

    const handleFeetInchesField=(field, inputValue)=>{

        const mUnits = new MeasurementUnits();
        const {feetFieldValue, inchFieldValue} = mUnits.explodeFeetInchesString(value, precision);
        /*
        console.log('--');
        console.log('MEASUREMENT FIELD VALUE',value,'FIELD',field, 'INPUT VALUE', inputValue);
        console.log('FT.FIELD', feetFieldValue, 'IN.FIELD', inchFieldValue);
         */
        let newValue = parseFloat(mUnits.handleNumericValue(inputValue, precision));
        let string;
        if(field === 'feet'){
            string = `${newValue}'${inchFieldValue}"`;
        } else{
            //inches
            newValue = newValue >= 12 ? 11 : newValue;
            string = `${feetFieldValue}'${newValue}"`;
        }
        changeFn(string);
    }

    measurementID = parseInt(measurementID);
    readOnly = readOnly != null ? readOnly : false;
    const mUnits = new MeasurementUnits();
    const menuEnabled = allowAlternativeMeasurements && changeUnitFn != null;
    const inputSize= size != null ? size : 'large';
    const fullWidth=true;
    const inputMode = 'decimal';
    const inputProps = {inputMode: inputMode, readOnly};
    const buttonSize = getSpacing('small');
    const buttonStyle = {
        position: 'absolute',
        right: 0,
        padding: buttonSize,
        borderRadius: buttonSize
    };
    //NO MeasurementID
    if(isNaN(measurementID)){
        return (
            <Alert status={'error'} visible={true} headline={`NO MEASUREMENT SET FOR '${name}'`}/>
        )
    }
    //Menu for changing units of an input
    let menu = null;
    if(menuEnabled){
        const hasLimits = !isEmpty(allowedMeasurementUnitsList) && allowedMeasurementUnitsList.length > 1;
        //Determine options based on whether there are limited measurement units or get alternatives
        const options = hasLimits? mUnits.getLimitedAlternatives(measurementID, allowedMeasurementUnitsList) : mUnits.getMeasurementAlternatives(measurementID);
        const mixerAvailable = mUnits.isWeight(measurementID) && openWeightHelperFn != null;
        menu =
            <MeasurementOptionsMenu
                open={menuOpen}
                anchorEl={menuElement}
                allowWeightMixer={mixerAvailable}
                openMixer={()=>openHelper()}
                onClick={(value)=>selectItem(value, name)}
                onClose={()=>closeMenu()}
                measurementOptions={options}
                measurementID={measurementID}
            />
    }


    //FEET INCHES
    //NEW FEET INCHES
    if(mUnits.isFeetInches(measurementID)){
        //receive a ft'in" string (e.g. 5'11.875", '9", 13'")
        //console.log('FEET/INCHES: RECEIVED VALUE', value, 'MUID', measurementID);
        const data = mUnits.explodeFeetInchesString(value, 3, false);
        //console.log('DATA', data);
        const {feet, inches, feetFieldValue, inchFieldValue} = data;
        const feetUnitData = mUnits.getMeasurementUnitByID(mUnits.FEET_INCHES_ID);
        const inchUnitData = mUnits.getMeasurementUnitByID(mUnits.INCHES_ID);
        const ftElement = menuEnabled ? <ActiveUnitLabel style={buttonStyle} onClick={(e)=>openMenu(e)}>Ft</ActiveUnitLabel> : 'Ft';
        const inElement = menuEnabled ? <ActiveUnitLabel style={buttonStyle} onClick={(e)=>openMenu(e)}>In</ActiveUnitLabel> : 'In';
        const feetProps = {endAdornment: <InputAdornment position={'end'}>{ftElement}</InputAdornment>}
        const inchProps = {endAdornment: <InputAdornment position={'end'}>{inElement}</InputAdornment>}
        const feetValid = !isNaN(feet) && parseInt(feet) >= 0;
        const inchesValid = mUnits.validateInchesValue(inches);
        const feetInputName = mUnits.createFeetInchesSubInputName(name, 'feet');
        const inchInputName = mUnits.createFeetInchesSubInputName(name, 'inches');

        const feetText = getErrorMessage(error, feetInputName);
        const inchText = getErrorMessage(error, inchInputName);
        const hasLabel = label != null && label.length;
        const feetLabel = hasLabel ? `${label} (Ft)` : feetUnitData.inputLabel;
        const inchLabel = hasLabel ? `${label} (In)` : inchUnitData.inputLabel;

        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth={fullWidth}
                            autoFocus={autoFocus}
                            ref={ref}
                            size={inputSize}
                            label={feetLabel}
                            placeholder={feetUnitData.placeholderText}
                            name={feetInputName}
                            value={feetFieldValue}
                            onChange={(e)=>handleFeetInchesField('feet', e.target.value)}
                            error={feetValid === false}
                            helperText={feetText || ' '}
                            inputProps={inputProps}
                            InputProps={feetProps}
                            InputLabelProps={{shrink: shrink}}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth={fullWidth}
                            size={inputSize}
                            label={inchLabel}
                            placeholder={inchUnitData.placeholderText}
                            name={inchInputName}
                            value={inchFieldValue}
                            onChange={(e)=>handleFeetInchesField('inches', e.target.value)}
                            error={inchesValid === false}
                            helperText={inchText || ' '}
                            inputProps={inputProps}
                            InputProps={inchProps}
                            InputLabelProps={{shrink: shrink}}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
                {menu}
            </div>
        )
    }

    const hasError = checkForError(error, name);
    const {inputLabel, placeholderText, abbreviation} = mUnits.getMeasurementUnitByID(measurementID);
    const isTime = mUnits.isTime(measurementID);
    const isRep = mUnits.isRep(measurementID);
    const unitChangeAvailable = !isTime && !isRep;
    const unitOption = unitChangeAvailable && menuEnabled ? <ActiveUnitLabel style={buttonStyle} onClick={(e)=>openMenu(e)}>{abbreviation}</ActiveUnitLabel> : abbreviation;
    const fieldAdornment = {endAdornment: <InputAdornment position={'end'}>{unitOption}</InputAdornment>};
    const inputPlaceholder = placeholder != null ? `${placeholder}` : placeholderText;

    //const helperText = getErrorMessage(error, name, '');

    if(isTime){ //TIME FIELD
        const timeLabel = mUnits.getTimeTypeString(value);
        const timeProps = {endAdornment: <InputAdornment position={'end'}>{timeLabel}</InputAdornment>};
        return (
            <TextField
                fullWidth={fullWidth}
                autoFocus={autoFocus}
                size={inputSize}
                ref={ref}
                label={label ? label : inputLabel}
                placeholder={inputPlaceholder}
                name={name}
                value={value}
                onChange={(e)=>updateValue(e.target.value)}
                error={hasError}
                helperText={helperText || ' '}
                inputProps={inputProps}
                InputProps={timeProps}
                InputLabelProps={{shrink: shrink}}
                disabled={disabled}
            />
        )
    } else if(isRep){ //LIMIT TO INTEGERS
        return (
            <InputMask
                fullWidth={true}
                mask={'999'}
                maskChar={''}
                size={inputSize}
                label={label ? label : inputLabel}
                placeholder={inputPlaceholder}
                name={name}
                value={value}
                onChange={(e)=>updateValue(e.target.value)}
                error={error}
                helperText={helperText || ' '}
                disabled={disabled}
                >
                {(props)=> {return <TextField {...props} ref={ref} inputProps={{inputMode, readOnly: readOnly}} autoFocus={autoFocus} InputLabelProps={{shrink: shrink}} />}}
            </InputMask>
        )
    } else{
        return (
            <div>
                <TextField
                    fullWidth={fullWidth}
                    autoFocus={autoFocus}
                    size={inputSize}
                    ref={ref}
                    label={label ? label : inputLabel}
                    placeholder={inputPlaceholder}
                    name={name}
                    value={value}
                    onChange={(e)=>updateValue(e.target.value)}
                    error={hasError}
                    helperText={helperText || ' '}
                    disabled={disabled}
                    inputProps={inputProps}
                    InputProps={fieldAdornment}
                    InputLabelProps={{shrink: shrink}}
                />
                {/* Alternative measurement group options*/
                    menu
                }
            </div>
        )
    }
}

export default MeasurementField;