import React, {useState} from 'react';
import DynamicDataTable from "../DynamicDataTable";
import {Button, Grid, Paper} from '@mui/material'
import {CheckBox, Checklist, List} from "@mui/icons-material";
import {ATHLETE_ASSIGNMENT_TYPES, DISPLAY_ADJUSTMENT_STRINGS} from "fit/system/FITConstants";
import User from 'fit/system/User';

const AthleteAssignments=({type, assignments, updateFn})=>{
    const user = new User();
    const sportSpecificCoach = user.isSportSpecificCoach();
    const [activeFilter, setActiveFilter] = useState(!sportSpecificCoach);

    let data = assignments != null ? assignments : [];
    if(activeFilter){
        //Show only active assignments
        data = data.filter(item => parseInt(item.active) === 1);
    }

    const label = type === ATHLETE_ASSIGNMENT_TYPES.squads ? 'Squad' : 'Position';
    const structure = {
        rowComponent:"SquadPositionAthleteList",
        paginationEnabled: false,
        sortEnabled: false,
        searchEnabled: false,
        searchTips:"",
        tableHeaders:[
            {key: 'id', label: 'Active', sx: {}, style: ''},
            {key: 'teamName', label: 'Team', sx: {}, style: ''},
            {key: 'label', label: label, sx: {display:{xs: 'none', md: 'table-cell'}}, style: ''}
        ],
        defaultSort: 'name',
        defaultDirection:"up",
        dateRangeEnabled: false
    };

    const allVariant = activeFilter ? 'outlined' : 'contained';
    const activeVariant = activeFilter ? 'contained' : 'outlined';

    return (
        <Grid container spacing={2}>
            {
            sportSpecificCoach ?
                <React.Fragment>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color={'primary'}
                            variant={allVariant}
                            onClick={()=>setActiveFilter(false)}
                        ><List/>All</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            color={'primary'}
                            variant={activeVariant}
                            onClick={()=>setActiveFilter(true)}
                        ><Checklist/>Assigned</Button>
                    </Grid>
                </React.Fragment>

                : null
            }
            <Grid item xs={12}>
                <Paper>
                    <DynamicDataTable
                        formatURL={null}
                        format={structure}
                        serverData={data}
                        loadData={false}
                        rowFunctions={{
                            updateFn: (teamID, assignmentID, groupTypeID, active)=>updateFn(teamID, assignmentID, groupTypeID, active)
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default AthleteAssignments;