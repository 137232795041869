import React from 'react';
import ResetPassword from "fit/components/System/ResetPassword/";
import SoloFormWrapper from "fit/components/System/SoloFormWrapper";
import {useParams} from "react-router-dom";

const ResetPasswordRoute =()=>{
    const params = useParams();
    return (
        <SoloFormWrapper>
            <ResetPassword resetKey={params.resetKey}/>
        </SoloFormWrapper>
    )
}

export default ResetPasswordRoute;