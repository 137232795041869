import React from "react";
import {Grid, TextField, Button, Collapse} from "@mui/material";
import MaskedInput from 'fit/components/Form/MaskedInput';
import SecureConnect from "fit/system/SecureConnect";
import User from "fit/system/User";
import {getSpacing} from "fit/system/UtilityFunctions";
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import Alert from "../Alert";
import {OPERATIONS_LEVEL, DOMAIN_NAME} from "fit/system/FITConstants";
//import StateSelector from "fit/components/Form/StateSelector";
//import {USA} from "fit/system/FITConstants";



class UserDetails extends React.Component{
    state={
        firstName: this.props.firstName || '',
        lastName: this.props.lastName || '',
        email: this.props.email || '',
        phone: this.props.phone || '',
        detailsUpdated: false,
        loaded: false, //Set on Render
        modified: [], //Set when edited
    };
    editUserDetails(data){
        let sc = new SecureConnect('user.php','post');
        sc.setAction('editUserDetails');
        const user = new User();
        const userID = this.checkIsSelf() ? user.getUserID() : this.props.userID;
        let formData = {...data, userID};
        sc.setFormData(formData);
        sc.connect().then(json=>{
            const completed = sc.getCompleted(json);
            this.setState({detailsUpdated: completed});
            if(completed && this.checkIsSelf()){
                //User updated their information
                //Update the state with new user information
                //Don't get this from the server - will cause interface to reload
                let {account} = user.getUserData();
                let ui = account.userInfo;
                ui.firstName = data.firstName;
                ui.lastName = data.lastName;
                ui.email = data.email;
                ui.phone = data.phone;
                ui.fullLegalName = data.fullLegalName;
                if(account.actorChecks != null && data.streetAddress !== ''){
                    //Actor - address changed
                    let acad = account.actorChecks.address;
                    acad.streetAddress = data.streetAddress;
                    acad.city = data.city;
                    acad.state = data.state;
                    acad.zip = data.zip;
                }
                //Send to redux
                //	const reduxUser = new User;
                //	reduxUser.setClientUserDetails(account);
            }
            if(completed && this.props.completionFunction != null){
                //Execute any completion function (e.g. update the profile or state of another app)
                this.props.completionFunction();
            }
        });
    }
    checkIsSelf(){
        let user = new User();
        return parseInt(user.getUserID()) === parseInt(this.props.userID);
    }
    handleChange(e){
        const {name, value} = e.target;
        let {modified} = this.state;
        //Super Hacky
        if(!modified.includes(name)){
            modified.push(name);
        }
        this.setState({[name]: value, modified});
    }
    setValue(key){
        //Really Hacky - there's gotta be a better way
        const s = this.state;
        const p = this.props;
        if(s.modified.includes(key)){
            return s[key]; //return the modified value
        } else if(s.loaded === false){
            return ''; //Return blank string when not loaded
        } else{
            //Loaded and not modified - pick props or state based on which is blank
            return s[key] !== '' ? s[key] : p[key];
        }
    }
    render(){
        /*
        console.log('-----------------------------------------');
        console.log('< USERDETAILS /> PROPS');
        console.log(this.props);
        console.log('< USERDETAILS /> STATE');
        console.log(this.state);
        console.log('-----------------------------------------');
        */

        //console.log('PROPS', this.props);
        const s = this.state;
        const validationSchema = {
            firstName: yup.string()
                .trim()
                .min(2, 'First name should at least 2 characters long')
                .max(50, 'The first name is too long')
                .required('First name is required'),
            lastName: yup.string()
                .trim()
                .min(2, 'Last name should at least 2 characters long')
                .max(50, 'The last name is too long')
                .required('Last name is required'),
            email: yup.string()
                .email('Invalid email')
                .required('Required'),
            phone: yup.string()
                .trim()
                .min(14, 'Phone number is incomplete')
        };
        const actorAddressValidation = {
            streetAddress: yup.string()
                .trim()
                .min(4, 'Street Address Required')
                .required('Required'),
            city: yup.string()
                .trim()
                .min(2, 'City Required')
                .required('Required'),
            state: yup.string()
                .trim()
                .min(2, 'State Required')
                .required('Required'),
            zip: yup.string()
                .min(5, 'Zip code should be at least 5 characters long')
                .max(7, 'Zip could should be less than 7 characters (for Canada)')
                .required('Zip code required'),
        };
        //Hacky shit. Doesn't automatically render from props.
        const init = {
            firstName: this.setValue('firstName'),
            lastName: this.setValue('lastName'),
            fullLegalName: this.setValue('fullLegalName'),
            email: this.setValue('email'),
            phone: this.setValue('phone'),
            streetAddress: this.setValue('streetAddress'),
            city: this.setValue('city'),
            state: this.setValue('state'),
            zip: this.setValue('zip')
        };

        const self = this.checkIsSelf();
        const onboarding = this.props.onboarding != null && this.props.onboarding === true;
        const user = new User();
        const athlete = this.props.athlete;
        const onboarded = this.props.onboarded;
        const sportSpecificCoach = user.isSportSpecificCoach(OPERATIONS_LEVEL);
        const readOnly = onboarded && !self;

        const buttonStyle={marginTop: getSpacing()};
        const handleChange = this.handleChange.bind(this);
        const displaySuccessMessage = this.props.displaySuccessMessage != null && this.props.displaySuccessMessage === true;
        if(s.loaded === false){
            this.setState({loaded: true});
        }
        return (
            <Formik
                initialValues={init}
                validationSchema={yup.object().shape(validationSchema)}
                onSubmit={this.editUserDetails.bind(this)}
                enableReinitialize={true}
            >
                {({errors, touched}) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstName"
                                    label="First Name"
                                    type="text"
                                    placeholder="Robert"
                                    onChange={handleChange}
                                    value={init.firstName}
                                    error={errors.firstName && touched.firstName}
                                    helperText={errors.firstName || ''}
                                    InputProps={{readOnly}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastName"
                                    label="Last Name"
                                    type="text"
                                    placeholder="Paulsen"
                                    onChange={handleChange}
                                    value={init.lastName}
                                    error={errors.lastName && touched.lastName}
                                    helperText={errors.lastName || ''}
                                    InputProps={{readOnly}}
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    type="email"
                                    placeholder={`my_email@${DOMAIN_NAME}`}
                                    onChange={handleChange}
                                    value={init.email}
                                    error={errors.email && touched.email}
                                    helperText={errors.email || ''}
                                    InputProps={{readOnly}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MaskedInput
                                    name="phone"
                                    pattern={"phone"}
                                    label="Phone"
                                    type="phone"
                                    value={init.phone}
                                    placeholder="(888) 643-2583"
                                    error={errors.phone}
                                    touched={touched.phone}
                                    onChange={handleChange}
                                    InputProps={{readOnly}}
                                    maxLength="14"
                                    fullWidth
                                />
                            </Grid>
                            {/*Require the actor's address*
                            <Grid item xs={12}>
                                <Collapse in={isActor}>
                                    <TextField
                                        name={"streetAddress"}
                                        label={"Street Address"}
                                        onChange={handleChange}
                                        value={init.streetAddress}
                                        error={errors.streetAddress && touched.streetAddress}
                                        helperText={errors.streetAddress || ''}
                                        fullWidth
                                    />
                                </Collapse>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Collapse in={isActor}>
                                    <TextField
                                        name={"city"}
                                        label={"City"}
                                        onChange={handleChange}
                                        value={init.city}
                                        error={errors.city && touched.city}
                                        helperText={errors.city || ''}
                                        fullWidth
                                    />
                                </Collapse>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>

                                <Collapse in={isActor}>
                                    <StateSelector
                                        name={'state'}
                                        country={USA}
                                        displayHelperText={true}
                                        value={init.state}
                                        error={errors.state}
                                        onChange={handleChange}
                                        disabled={false}
                                        helperText={errors.stateSelector || ''}
                                    />
                                </Collapse>

                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                                <Collapse in={isActor}>
                                    <MaskedInput
                                        name="zip"
                                        pattern="zip"
                                        label="Zip"
                                        type="text"
                                        placeholder="49525"
                                        onChange={handleChange}
                                        value={init.zip}
                                        error={errors.zip}
                                        touched={touched.zip}
                                        fullWidth
                                    />
                                </Collapse>
                            </Grid>*/}
                            <Grid item xs={12} md={12}>
                                {
                                    onboarding === false && (self || (sportSpecificCoach && athlete && !onboarded)) ?
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            style={buttonStyle}
                                            type="submit"
                                        >Update</Button>
                                        : null
                                }
                                <Alert
                                    visible={displaySuccessMessage && s.detailsUpdated}
                                    status={'success'}
                                    headline={'User Information Updated'}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    }

}

export default UserDetails;