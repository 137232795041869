import React from 'react';
import Link from 'fit/components/System/Link';
import {createWorkoutTitle} from "fit/system/UtilityFunctions";
import {URL_TEAM_WORKOUTS} from "fit/system/FITConstants";

const WorkoutLink=({name, dayIndex, urlKey})=>{
    const title = createWorkoutTitle(name, dayIndex)
    return <Link to={`${URL_TEAM_WORKOUTS}${urlKey}/`}>{title}</Link>
}

export default WorkoutLink;