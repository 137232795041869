import React, {useState, useEffect} from 'react';
import useDebounce from "../Functional/useDebounce";
import {TextField} from "@mui/material";
import {TEXTFIELD_DEBOUNCE_DELAY} from "fit/system/FITConstants";


const DebounceTextField=(props)=>{
    const delay = props.delay != null && parseInt(props.delay) > 0 ? props.delay : TEXTFIELD_DEBOUNCE_DELAY;
    const [localValue, setLocalValue] = useState(props.value);

    //Update state to match props value
    useEffect(()=>{
        setLocalValue(props.value)
    },[props.value]);

    //Send value to parent component
    useDebounce(()=>{
        if(`${props.value}` !== `${localValue}`) {
            props.onChange(localValue);
        }
    }, delay,[localValue]);


    return (
        <TextField
            {...props}
            value={localValue}
            onChange={(e)=>setLocalValue(e.target.value)}
        />
    )
}

export default DebounceTextField;