import {isEmpty} from "./UtilityFunctions";

export default class Statistics{
    constructor(dataSet = []) {
        this.dataSet = dataSet;
        this.population = false;
        this.sample = false;
        this.mean = null;
        this.mode = null;
        this.median = null;
        this.standardDeviation = null;
    }
    setDataSet(dataSet){
        this.dataSet = dataSet;
        //reset all calculations when setting a dataset
        this.#initStatCalculations();
    }
    setPopulation(bool){
        this.population = bool(bool);
        this.sample = !bool(bool);
    }
    setSample(bool){
        this.sample = bool(bool);
        this.population = !bool(bool);
    }
    getMean(label = null){
        let sum = 0;
        if(this.#filterDataSubSet(label)){
            this.dataSet.forEach(item =>{
                sum+= parseFloat(item[label]);
            })
        } else{
            //Standard array
            sum = this.dataSet.length === 0 ? 0 : this.dataSet.reduce((a,b)=>a+b);
        }
        this.mean = sum/(this.dataSet.length);
        return this.mean;
    }
    getMode(label = null){


    }
    getMedian(label = null){
        let array = this.dataSet;
        if(this.#filterDataSubSet(label)){
            array = [];
            this.dataSet.forEach(item =>{
                array.push(item[label]);
            });
        }
        array = [...array].sort((a,b)=> a-b);
        const half = Math.floor(array.length / 2);

        this.median = (array.length % 2 === 0 ? array[half] : (array[half - 1] + array[half]) / 2);
        return this.median;
    }
    getStandardDeviation(label = null){
        let array = this.dataSet;
        if(this.mean == null){
            this.getMean(label);
        }
        if(this.#filterDataSubSet(label)){
            array = [];
            this.dataSet.forEach(item=>{
                array.push(parseFloat(item[label]));
            })
        }
        let distanceSum = 0;
        array.forEach(number =>{
            distanceSum+=Math.abs(parseFloat(number - this.mean))**2;
        })
        this.standardDeviation = Math.sqrt(distanceSum/array.length);
        return this.standardDeviation;
    }
    getZScore(value, label = null){
        if(this.mean == null){
            this.getMean(label);
        }
    }
    plotNormalCurve(x, label = null){
        if(this.mean == null){
            this.getMean(label);
        }
        if(this.standardDeviation == null){
            this.getStandardDeviation(label);
        }
        return 1 / (this.standardDeviation * Math.sqrt(2*Math.PI))  *  Math.E**( -1*( ((x-this.mean)**2)/(2*this.standardDeviation**2) ));
        // f(x) = 1 / (stddev * Sqrt(2*p) * e **(-(x-avg)**2/(2*stddev)**2)
    }

    #filterDataSubSet(label){
        if(isEmpty(this.dataSet) || label == null){
            return false;
        }
        const set = this.dataSet[0];
        return typeof set === "object" && label != null;
    }
    #initStatCalculations(){
        this.mean = null;
        this.median = null;
        this.mode = null;
        this.standardDeviation = null;
        this.population = null;
        this.sample = null;

    }

}