import React from 'react';
import {useState, useEffect} from "react";
import {Paper, Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import DynamicDataTable from "fit/components/DynamicDataTable";
import ExerciseEditModal from "fit/components/Exercise/ExerciseEditModal";
import {setPageTitle} from "fit/system/UtilityFunctions";
import {PAPER_STYLE} from "fit/system/FITConstants";
import SecureConnect from "fit/system/SecureConnect";
import Header from 'fit/components/Header/';


const initExerciseModal=()=>{
     return {
        display: false,
        exerciseID: null,
        name: '',
        shortName: '',
        exerciseStyleID: null,
        measurementType: null,
        allowPercentage: 1,
        repUnitID: null,
        repTypeID: null,
        fixedRepValue: null,
        measurementUnitID: null,
        recordWeightDefault: 1,
        eachOnly: 0,
        unitLabelID: null,
        recordSingleWeight: 0,
        active: 1,
        exercisePreviouslyExecuted: 0,
     }
}


const ExerciseList=()=>{
    const [loadData, setLoadData] = useState(true);
    const [exerciseModalData, setExerciseModalData] = useState(initExerciseModal());
    const [exerciseStyles, setExerciseStyles] = useState([]);

    useEffect(()=>{
        let sc = new SecureConnect('testapi.php?action=getExerciseVariables');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
           if(sc.getCompleted(json)){
               console.log('DATA', sc.getData(json));
               const {exerciseStyles, exerciseUnits} = sc.getData(json);
               setExerciseStyles(exerciseStyles);
           }
        });
    },[])

    const initExerciseCreation=()=>{
        let exerciseData = initExerciseModal();
        exerciseData.display = true;
        setExerciseModalData(exerciseData);
    }

    const loadExercise=(exerciseData)=>{
       //Receives the full row data from the exerciseList
       let modal = structuredClone(exerciseData);
       modal.display = true;
       modal.exerciseID = modal.id;
       modal.wtf = 'WWWWWWWWWWWWWWWWWWWWTTTTTTTTTTTTTTTTTTTFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
       delete(modal.id);
       console.log('EXERCISE DATA', modal);
       setExerciseModalData(modal);
    }
    const closeExercise=()=>{
        setExerciseModalData(initExerciseModal());
    }
    const ed = exerciseModalData;
    setPageTitle('Exercises');
    return(
        <div>
            <Header header={'Exercises'}>
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={()=>initExerciseCreation()}
                ><Add/> Create Exercise</Button>
            </Header>
            <Paper style={PAPER_STYLE}>
                <DynamicDataTable
                    title={'Workouts Table'}
                    formatURL={'testapi.php?action=listExercises'}
                    loadData={loadData}
                    dataLoaded={()=>setLoadData(false)}
                    rowFunctions={{
                        loadExercise
                    }}
                />
            </Paper>
            <ExerciseEditModal
                display={ed.display}
                exerciseID={ed.exerciseID}
                name={ed.name}
                shortName={ed.shortName}
                exerciseStyleID={ed.exerciseStyleID}
                repTypeID={ed.repTypeID}
                repUnitID={ed.repUnitID}
                fixedRepValue={ed.fixedRepValue}
                measurementTypeID={ed.measurementTypeID}
                measurementUnitID={ed.measurementUnitID}
                recordWeightDefault={ed.recordWeightDefault}
                recordSingleWeight={ed.recordSingleWeight}
                eachEnabled={ed.eachEnabled}
                active={ed.active}
                exercisePreviouslyExecuted={ed.exercisePreviouslyExecuted}

                exerciseStyles={exerciseStyles}
                closeFunction={closeExercise}
                loadDataFunction={()=>setLoadData(true)}
            />
        </div>
    )
}

export default ExerciseList;
