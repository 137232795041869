import React from 'react';
import {TableRow, TableCell, InputAdornment, Typography} from "@mui/material";
import DebounceTextField from "../Form/DebounceTextField";
import {useTheme} from "@mui/material";
import {Checkbox, FormControlLabel} from "@mui/material";
import Link from 'fit/components/System/Link';
import {Tooltip, IconButton, Button} from '@mui/material';
import {
	Pending,
	PanoramaFishEye,
	CheckCircle,
	Edit,
	Warning,
	ContentCopy,
	Smartphone,
	PhonelinkErase,
	MobileFriendly,
	BarChart,
	Cancel,
	Star,
	Logout, Login,
	TableView, DeleteForever, VisibilityOff, Visibility, RemoveCircle, FormatListNumberedRtl
} from "@mui/icons-material";
import RPEStatusBar from "fit/components/Workout/RPEStatusBar";
import ColorCodedStatusBar from "fit/components/ColorCodedStatusBar";
import {
	getStatusScaleColor,
	getStatusColor,
	getSpacing,
	getHighlightStyle,
	isEmpty,
	getStatusIcon,
	createWorkoutTitle,
	calculatePercentage,
	getCompletionColor,
	trimURL
} from 'fit/system/UtilityFunctions';
import User from 'fit/system/User';
import UserPreferences from "fit/system/UserPreferences";
import {
	BUTEO_SYSTEM_USER_ID,
	ATHLETE_ASSIGNMENT_TYPES,
	URL_TEAMS,
	COLOR_SCALES,
	URL_PROFILES,
	URL_DEVICES,
	ASSISTANT_LEVEL, OPERATIONS_LEVEL, DEVICE_CONSTANTS, URL_ANALYTICS, URL_TEAM_WORKOUTS, DISPLAY_ADJUSTMENT_STRINGS
} from "fit/system/FITConstants";
import DeviceStatus from "../Devices/DeviceStatus";
import DeviceWorkoutStatus from "../Devices/DeviceWorkoutStatus";
import {UserLink, WorkoutLink, WorkoutBlockLink, WorkoutBlockReportLink, TeamLink} from "fit/components/Links";
import WorkoutReportLink from "../Links/WorkoutReportLink";


const fadedOpacity = .55;
const fadedIconOpacity = '.70';

const getCellStyle=(headers, type, key, label = '')=>{
	const element = headers[key][type];
	const exist = element && (typeof element === 'string' && element !== '') || (typeof element === 'object' && !isEmpty(element));
	if(!exist || exist && type === 'style'){
		return {};
	}
	console.log(label, '::EL::', element);
	return element;
}

export const getRowComponent =(component)=>{
	const rowComponents = {
		'AthleteCompletedWorkout' : AthleteCompletedWorkout,
		'AthleteMissingWorkout': AthleteMissingWorkout,
		'AthleteUnfinishedWorkout': AthleteUnfinishedWorkout,
		'NoData' : NoData,
		'ActiveWorkoutAthleteSelectList': ActiveWorkoutAthleteSelectList,
		'DeviceManagementList': DeviceManagementList,
		'ExerciseList' : ExerciseList,
		'SquadManagementList': SquadManagementList,
		'SquadPositionAthleteList': SquadPositionAthleteList,
		'SquadPositionAssignmentList': SquadPositionAssignmentList,
		'TeamList': TeamList,
		'TeamSharingAthleteException': TeamSharingAthleteException,
		'TestBatteryList' : TestBatteryList,
		'WorkoutBlockList' : WorkoutBlockList,
		'WorkoutBlockCompletedList' : WorkoutBlockCompletedList,
		'WorkoutCompletedList': WorkoutCompletedList,
		'WorkoutCompletedSummaryList': WorkoutCompletedSummaryList,
		'WorkoutList' : WorkoutList,
	};
	return rowComponents[component]
};

export class AthleteCompletedWorkout extends React.Component{
	render(){
		const d = this.props.rowData;
		const hasRPE = isNaN(d.RPE) === false
		const RPEValue = hasRPE ? (parseInt(d.RPE)*5) : d.RPE;
		const RPEElement = hasRPE ? <RPEStatusBar score={RPEValue}/> : '-';
		const userPrefs = new UserPreferences();
		return (
			<TableRow hover>
				<TableCell>
					<Tooltip title={'View Individual Workout'} placement={'bottom'}>
						<IconButton
							color={'primary'}
							onClick={()=>this.props.rowFunctions.displayAthleteResults(d.userID)}
						>
							<TableView/>
						</IconButton>
					</Tooltip>
				</TableCell>
				<TableCell>
					<UserLink urlKey={d.userKey} name={d.athleteName}/>
				</TableCell>
				<TableCell>
					{RPEElement}
				</TableCell>
				{/*
				<TableCell>Yes/No/Exceeded</TableCell>
				*/}
				<TableCell>{userPrefs.convertServerDate(d.dateScheduled)}</TableCell>
				<TableCell>{userPrefs.convertServerDate(d.dateTime)}</TableCell>
				<TableCell>{d.submitterName}</TableCell>
			</TableRow>
		)
	}
}


export class AthleteMissingWorkout extends React.Component{
	render(){
		const d = this.props.rowData;
		return (
			<TableRow hover>
				<TableCell>
					<UserLink urlKey={d.userKey} name={d.athleteName}/>
				</TableCell>
				<TableCell>{d.yearLabel}</TableCell>
			</TableRow>
		)
	}
}
export class AthleteUnfinishedWorkout extends React.Component{
	render(){
		const d = this.props.rowData;
		const {completedSets, setCount} = d;
		const score = calculatePercentage(completedSets, setCount, 1);
		const color = getCompletionColor(score);
		return (
			<TableRow hover>
				<TableCell>
					<UserLink urlKey={d.userKey} name={d.athleteName}/>
				</TableCell>
				<TableCell>
					<ColorCodedStatusBar score={score} message={`${score}% Completed`} color={color}/>
				</TableCell>
				<TableCell>{d.yearLabel}</TableCell>
			</TableRow>
		)
	}
}


export class NoData extends React.Component{
	render(){
		const {colSpan, cellText} = this.props;
		return (
			<TableRow>
				<TableCell
					colSpan={colSpan}
					align={'center'}
				>
					<em>{cellText}</em>
				</TableCell>
			</TableRow>
		);
	}
}

export class ActiveWorkoutAthleteSelectList extends React.Component{
	render(){
		const d = this.props.rowData;
		const rf = this.props.rowFunctions;
		return (
			<TableRow hover>
				<TableCell>
					<FormControlLabel
						control={
							<Checkbox
								onChange={rf.toggleAthlete.bind(this, d.userID)}
								color={'primary'}
							/>
						}
						label={`${d.firstName} ${d.lastName}`}
					/>
				</TableCell>
				<TableCell>{d.positionStatus}</TableCell>
				<TableCell>{d.yearStatus}</TableCell>
				<TableCell>{d.sexStatus}</TableCell>
			</TableRow>
		)
	}
}




export const DeviceLoadList=({data, loginFn, logoutFn})=>{
	//used by DeviceMasterProfile account (Master account for loading slave/sub accounts)

	const d = data;
	const userID = parseInt(d.userID);
	const assigned = parseInt(d.assigned) === 1;
	const color = assigned ? 'secondary' : 'primary';
	const actionText = assigned ? 'Logout' : 'Login';
	const fn = assigned ? ()=>logoutFn(userID) : ()=>loginFn(userID);
	const personalWorkout = parseInt(d.personalWorkoutUserID) > 0;
	const workoutAssigned = parseInt(d.workoutID) > 0;
	return (
		<TableRow hover>
			<TableCell sx={{width: '120px'}}>
				<Tooltip title={`${actionText} ${d.firstName} ${d.lastName}`} placement={'left'}>
					<Button
						fullWidth
						variant={'contained'}
						color={color}
						onClick={fn}
					>
						{
							assigned ? <Logout/> : <Login/>
						}
						{actionText}
					</Button>
				</Tooltip>
			</TableCell>
			<TableCell>
				{d.firstName} {d.lastName}
			</TableCell>
			<TableCell>
				<DeviceStatus assignedStatus={assigned}/>
			</TableCell>
			<TableCell>
				<DeviceWorkoutStatus
					active={assigned}
					assignedWorkout={workoutAssigned}
					personalWorkout={personalWorkout}
					screenValue={d.screen}
					updated={d.updated}
				/>
			</TableCell>
		</TableRow>

	)

}
export const DeviceManagementList= ({copyDeviceName, renumberGroup, deviceData, subKey, handleRemoval, logoutDevice, manageDevice, updateDeviceName})=>{
	//In childDeviceTable for masterDeviceAccount
	const checkName=(setting, value)=>{
		let error;
		let helperText;
		if(value.length < 2){
			error = true;
			helperText = 'Error: Name less than 2 characters';
		} else if(setting === 'firstName' && value.length > 50){
			error = true;
			helperText = 'Error: Name exceeds 50 characters';
		} else if(setting === 'lastName' && value.length > 8){
			error = true;
			helperText = 'Error: Name exceeds 8 characters';
		} else{
			error = false;
			helperText = `Edit ${setting === 'firstName'? 'Device Name' :'ID#'}`;
		}
		return {error, helperText}
	}

	const d = deviceData;
	const parentID = parseInt(d.parentID);
	const userID = parseInt(d.userID);
	const parentDevice = parentID === userID;
	const highlight = d.urlKey === subKey;
	const theme = useTheme();
	const highlightStyle = highlight ? getHighlightStyle(theme) : {};
	const noParent = true; //d.parentID == null;
	const assigned = parseInt(d.assigned)===1;
	const workoutAssigned = parseInt(d.workoutID) > 0;
	const personalWorkout = parseInt(d.personalWorkoutUserID) > 0;
	const removeIcon = parentDevice ? <DeleteForever/> : <RemoveCircle/>;
	const removeText = parentDevice ? 'Delete' : 'Remove';
	const removeTip = parentDevice ? 'Delete Entire Group' : 'Remove From Group';
	const removeID = parentDevice ? parentID : userID;
	const buttonColor = parentDevice ? 'secondary' : 'primary';
	const buttonVariant = parentDevice ? 'contained' : 'outlined';

	//Name validation
	const fData = checkName('firstName', d.firstName);
	const lData = checkName('lastName', d.lastName);
	if(fData.error || lData.error) {
		console.log('DEVICE ', d.userID, d.firstName, fData);
	}


	const removeFunction = parentDevice ? ()=>handleRemoval(removeID, parentDevice) : ()=>manageDevice(userID, DEVICE_CONSTANTS.MANAGE_OPTIONS.remove, parseInt(d.parentID), '');
	const iProps = { endAdornment: <InputAdornment position={'end'}>
			<Tooltip title={'Copy To Group'} placement={'right'}>
				<IconButton color={'primary'} onClick={()=>copyDeviceName(parentID, d.firstName)} disabled={fData.error}>
					<ContentCopy/>
				</IconButton>
			</Tooltip>
		</InputAdornment>
	}
	let renumberProps = null;
	if(parentDevice && true){
		renumberProps = {
			endAdornment: <InputAdornment position={'end'}>
				<Tooltip title={'Renumber Devices'} placement={'right'}>
					<IconButton color={'primary'} onClick={()=>renumberGroup(parentID)} disabled={lData.error}>
						<FormatListNumberedRtl/>
					</IconButton>
				</Tooltip>
			</InputAdornment>
		};
	}

	const buttonWidth = {maxWidth: '100px'};
	return (
		<TableRow hover style={highlightStyle}>
			<TableCell sx={buttonWidth}>
				<Tooltip title={assigned ? 'Logout device account': 'Disabled: Already logged out'} placement={'right'}>
					<span>
					<Button fullWidth variant={'contained'} color={'secondary'} disabled={!assigned} onClick={()=>logoutDevice(userID)}>
						<Logout/> Logout
					</Button>
					</span>
				</Tooltip>
			</TableCell>
			<TableCell sx={buttonWidth}>
				<Tooltip title={removeTip} placement={'right'}>
					<Button
						fullWidth
						color={buttonColor}
						variant={buttonVariant}
						onClick={()=>removeFunction()}
					>
						{removeIcon} {removeText}
					</Button>
				</Tooltip>
			</TableCell>
			<TableCell>
				<Tooltip title={fData.helperText} placement={'top'}>
					<DebounceTextField
						size={'small'}
						label={'Device Name'}
						value={d.firstName}
						onChange={(value)=>updateDeviceName(userID, 'firstName', value)}
						error={fData.error}
						InputProps={iProps}
					/>
				</Tooltip>
				<span> </span>
				<Tooltip title={lData.helperText} placement={'top'}>
					<DebounceTextField
						size={'small'}
						label={'ID#'}
						value={d.lastName}
						onChange={(value)=>updateDeviceName(userID, 'lastName', value)}
						error={lData.error}
						disabled={!noParent}
						sx={{width: '140px'}}
						InputProps={renumberProps}
					/>
				</Tooltip>
			</TableCell>
			<TableCell>
				<DeviceStatus assignedStatus={d.assigned}/>
			</TableCell>
			<TableCell>
				<DeviceWorkoutStatus
					active={d.assigned}
					assignedWorkout={workoutAssigned}
					personalWorkout={personalWorkout}
					screenValue={d.screen}
					updated={d.updated}
				/>
			</TableCell>

		</TableRow>
	)
}
export class ExerciseList extends React.Component{
	render(){
		const d = this.props.rowData;
		const {loadExercise} = this.props.rowFunctions;
		const iconStyle = {marginTop: getSpacing('iconAlignment'), position: 'relative'};
		const successStyle ={...iconStyle, color: getStatusColor('success')};
		const noActionStyle ={...iconStyle, color: getStatusColor('noAction')};
		const statusMessage = parseInt(d.active) === 1 ? 'Active' :'Inactive';
		const statusIcon = parseInt(d.active) === 1 ? <CheckCircle style={successStyle}/> : <PanoramaFishEye style={noActionStyle}/>
		const recordData = parseInt(d.recordWeightDefault) === 1;
		const recordDataMessage = recordData ? 'Recording Athlete Data' : 'Not Capturing Data';
		const recordIcon = recordData ? <CheckCircle style={successStyle}/> : <PanoramaFishEye style={noActionStyle}/>
		const {name, shortName, exerciseStyle, measurementType, measurementUnit} = d;
		return (
			<TableRow hover>
				<TableCell>
					<Tooltip title={`Edit ${name}`} placement={'bottom'}>
						<IconButton
							color={'primary'}
							onClick={()=>loadExercise(d)}
						><Edit/></IconButton>
					</Tooltip>
					{name}
				</TableCell>
				<TableCell>{shortName}</TableCell>
				<TableCell>
					<Tooltip title={statusMessage} placement={'bottom'}>{statusIcon}</Tooltip>
				</TableCell>
				<TableCell>
					<Tooltip title={recordDataMessage} placement={'bottom'}>{recordIcon}</Tooltip>
				</TableCell>
				<TableCell>{exerciseStyle}</TableCell>
				<TableCell>{measurementType}</TableCell>
				<TableCell>{measurementUnit}</TableCell>
			</TableRow>
		);
	}
}



const SquadManagementList=({rowData, rowFunctions, presetVariables})=>{
	//Table for managing, editing, individual squads
	const d = rowData;
	const systemRecord = parseInt(d.userID) === BUTEO_SYSTEM_USER_ID;

	const user = new User();
	const noAccess = !user.isTeamSportSpecificCoach(parseInt(d.teamID), OPERATIONS_LEVEL);

	//console.log('TEAM', d.teamID, 'NO ACCESS', noAccess);

	const iconStyle = {position: 'relative', top: getSpacing('iconAlignment')};

	const active = parseInt(d.active) === 1;
	const activeMessage = active ? 'Active' : 'Inactive';
	const activeStatus = active ? 'success' : 'warning';
	const activeStyle = {color: getStatusColor(activeStatus), ...iconStyle};
	const activeIcon = active ? <CheckCircle style={activeStyle}/> : <Warning style={activeStyle}/>;
	const athleteHidden = d.athleteHidden != null && parseInt(d.athleteHidden) === 1;
	const visibilityStatus = athleteHidden ? 'warning' : 'success';
	const visibilityStyle = {color: getStatusColor(visibilityStatus), ...iconStyle};
	const visibilityIcon =  athleteHidden ? <VisibilityOff style={visibilityStyle}/> : <Visibility style={visibilityStyle}/>
	const visibilityMessage = athleteHidden ? 'Hidden From Athletes' : 'Visible To Athletes';
	const pendingWorkouts = parseInt(d.workoutPendingCompletion) === 1;
	const workoutString = parseInt(d.workoutCount) > 1 ? 'Workouts' : 'Workout';
	let deleteMessage;
	if(systemRecord){
		deleteMessage = "Can't delete system group";
	} else if(pendingWorkouts){
		deleteMessage = `Can't Delete: Has ${d.workoutCount} Pending ${workoutString}`;
	} else if(noAccess){
		deleteMessage = `Can't Delete ${d.title}: Permission Required`;
	} else {
		deleteMessage = `Delete ${d.title}`;
	}
	const editIcon = systemRecord || noAccess ? <Visibility/> : <Edit/>
	const editMessage = systemRecord || noAccess ? `View ${d.title}` : `Edit ${d.title}`;


	const filterFunction=()=>{
	//	console.log('TEAMID =>',d.teamID, 'AssignmentGroupID=>',d.id, 'groupTypeID=>',d.groupTypeID);
	}
	const userLink =  !systemRecord ? <UserLink name={d.ownerName} urlKey={d.userKey}/> : d.ownerName;
	return (
		<TableRow hover>
			<TableCell>
				<Tooltip placement={'left'} title={editMessage}>
					<span>
						<IconButton
							color={'primary'}
							onClick={()=>rowFunctions.loadSquad(d)}
						>
							{editIcon}
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip placement={'right'} title={deleteMessage}>
					<span>
						<IconButton
							color={'secondary'}
							onClick={()=>rowFunctions.deleteSquad(d)}
							disabled={pendingWorkouts || systemRecord || noAccess}
						>
							<DeleteForever/>
						</IconButton>
					</span>
				</Tooltip>
			</TableCell>
			<TableCell>
				<span
					onClick={()=>filterFunction()}
				>
					{d.title}
				</span>

			</TableCell>
			<TableCell>
				<Tooltip title={activeMessage} placement={'bottom'}>{activeIcon}</Tooltip>
				{activeMessage}
			</TableCell>
			<TableCell>
				<Tooltip title={visibilityMessage} placement={'bottom'}>{visibilityIcon}</Tooltip>
				{visibilityMessage}
			</TableCell>
			<TableCell>
				{d.athleteCount}
			</TableCell>
			<TableCell>
				<TeamLink urlKey={d.teamKey} name={d.teamName} org={d.shortLabel}/>
			</TableCell>
			<TableCell>
				{userLink}
			</TableCell>
		</TableRow>
	)
}
const SquadPositionAthleteList=({rowData, rowFunctions, presetVariables})=>{
	/*
		-For handling a single user/athlete (within user profile)
		-Will handle either squads or positions
		-Data/Options/Assignments will be deteremined based on the viewer's permissions/Team assignments
		-Only Sport Specific Coaches get to toggle assignments.
	*/

	const d = rowData;
	//console.log('D', d);
	const {teamID, groupTypeID, assignmentGroupID} = d;

	const checked = parseInt(d.active) === 1;
	const user = new User();
	const isCoach = user.isTeamStrengthCoach(teamID) || user.isTeamSportSpecificCoach(teamID);
	const disabled = !user.isTeamSportSpecificCoach(teamID, OPERATIONS_LEVEL);
	const statusText = checked ? 'active' : 'inactive';
	const message = disabled ? `${d.title}: ${statusText}` : `Toggle ${d.title}`;
	const activeValue = checked ? 0 : 1;
	const teamString = `${d.shortLabel} ${d.teamName}`;

	const inlineText = DISPLAY_ADJUSTMENT_STRINGS.text;
	const columnHidden = {display: {xs: 'none', sm: 'none', md: DISPLAY_ADJUSTMENT_STRINGS.td}}
	const condensedText = {display: {xs: inlineText, sm: inlineText, md: 'none'}};
	const team = isCoach ? <TeamLink urlKey={d.teamKey} org={d.shortLabel} name={d.teamName}/> : teamString;
	return (
		<TableRow hover>
			<TableCell sx={{width: '60px'}}>
				<Tooltip title={message} placement={'bottom'}>
					<span>
						<Checkbox
							checked={checked}
							disabled={disabled}
							onChange={()=>rowFunctions.updateFn(teamID, assignmentGroupID, groupTypeID, activeValue)}
						/>
					</span>
				</Tooltip>
			</TableCell>
			<TableCell>
				{team}
				<Typography sx={condensedText}>
					<br/>{d.title}
				</Typography>
			</TableCell>
			<TableCell sx={columnHidden}>{d.title}</TableCell>
		</TableRow>
	);

}
const SquadPositionAssignmentList=({presetVariables, rowData, rowFunctions})=>{
	//For handling an entire roster, managing both squads & positions
	const theme = useTheme();
	const assignmentColor = theme.palette.secondary.dark;
	const handleAssignmentList=(assignmentList)=>{
		if(isEmpty(assignmentList)){
			return 'Unassigned';
		} else{
			let list = [];
			const multipleAssignmentsMaxLength = 15;
			const singleAssignmentMaxLength = 20;
			for(let k=0; k<assignmentList.length; k++){
				const item = assignmentList[k];
				const wrap = k+1 < assignmentList.length;
				const groupStyle = {color: assignmentColor, cursor: 'pointer'};
				const fn = ()=>rowFunctions.handleGroupClick(item.assignmentGroupID, item.groupTypeID, item.label);
				let label = item.label;
				if(label > multipleAssignmentsMaxLength && assignmentList.length > 1){
					label = `${item.label.substr(0, multipleAssignmentsMaxLength-3)}...`;
				} else if(label > singleAssignmentMaxLength){
					label = `${item.label.substr(0, singleAssignmentMaxLength-3)}...`;
				} else {
					label = item.label;
				}
				const element = <Tooltip key={`tt_${k}`} title={`Select/Toggle ${item.label}`} placement={'bottom'}>
					<span style={groupStyle} onClick={fn}>{label}</span>
				</Tooltip>
				if(wrap){
					list.push(<span key={`s_${k}`}>{element}, </span>)
				} else{
					list.push(element);
				}
			}
			return list;
		}
	}
	const checkActive=()=>{
		const {assignmentTypeSelected, assignmentID} = presetVariables;
		let list = [];
		const squadTypes = [ATHLETE_ASSIGNMENT_TYPES.squads, ATHLETE_ASSIGNMENT_TYPES.inactive];
		if(squadTypes.includes(parseInt(assignmentTypeSelected))){ //SQUADS
			list = rowData.squads;
		} else{ //POSITIONS
			list = rowData.positions;
		}
		const index = list.findIndex(item => parseInt(item.assignmentGroupID) === parseInt(assignmentID));
		return index > -1;
	}
	const d = rowData;
	const squadText = handleAssignmentList(d.squads);
	const positionText = handleAssignmentList(d.positions);
	const {assignmentTypeSelected, assignmentID} = presetVariables;
	const disabledCheckbox =  !(assignmentTypeSelected != null && parseInt(assignmentTypeSelected) >= 0 && assignmentID != null && parseInt(assignmentID) >= 0);
	const active = checkActive();
	const checked = active && !disabledCheckbox;
	const submitActiveValue = active ? 0 : 1;
	const tipMessage = disabledCheckbox ? 'Disabled: Select A Squad/Position First' : `Toggle '${presetVariables.assignmentText}'`;
	return (
		<TableRow hover>
			<TableCell>
				<Tooltip title={tipMessage} placement={'bottom'}>
					<span>
						<Checkbox disabled={disabledCheckbox} onChange={()=>rowFunctions.handleAssignment(d.userID, submitActiveValue)} checked={checked}/>
					</span>
				</Tooltip>
			</TableCell>
			<TableCell>
				<UserLink urlKey={d.urlKey} name={`${d.firstName} ${d.lastName}`}/>
			</TableCell>
			<TableCell>{squadText}</TableCell>
			<TableCell>{positionText}</TableCell>
			<TableCell>{d.yearStatus}</TableCell>
		</TableRow>
	)
}
export const TeamList =({rowData})=>{
	const d = rowData;
	const maxLength = 40;
	const substringLength = maxLength - 3;
	const unAssignedString = '-';
	const alumni = parseInt(d.expired) === 1;
	const alumniYear = d && d.expirationDate ? d.expirationDate.substring(0,4) : '';
	const yearStatus = alumni ? `Alum: ${alumniYear}` : d.yearStatus;
	if(alumni){
	//	console.log('ALUMNI', d.expirationDate, d.expirationDate.substring(2,4));
	}

	let squadText = !isEmpty(d.squads) ? d.squads.map(item => item.label).join(', ') : unAssignedString;
	squadText = squadText.length > maxLength ? `${squadText.substring(0, substringLength)}...` : squadText;
	let positionText = !isEmpty(d.positions) ? d.positions.map(item => item.label).join(', ') : unAssignedString;
	positionText = positionText.length > maxLength ? `${positionText.substring(0, substringLength)}...` : positionText;

	return (
		<TableRow hover>
			<TableCell>
				<UserLink urlKey={d.urlKey} name={`${d.firstName} ${d.lastName}`}/>
			</TableCell>
			<TableCell>
				<TeamLink org={d.organizationShortLabel} name={d.teamName} urlKey={d.teamURLKey}/>
			</TableCell>
			<TableCell>{alumniYear}</TableCell>
			<TableCell>{yearStatus}</TableCell>
			<TableCell>{squadText}</TableCell>
			<TableCell>{positionText}</TableCell>
		</TableRow>
	)

}
export const TeamSharingAthleteException=(props)=>{
	const {userID, userKey, sectionID, athleteName, label, shareTeam, shareLeaderboard, sharePosition, shareSquad} = props.rowData;
	const {teamDisabled} = props.presetVariables;
	const {removeException, toggleSetting} = props.rowFunctions;
	const teamON = parseInt(shareTeam) === 1;
	const leaderboardOn = parseInt(shareLeaderboard) === 1;
	const positionOn = parseInt(sharePosition) === 1;
	const squadOn = parseInt(shareSquad) === 1
	const tipText = teamDisabled ? 'Disabled: Access Required' : `Remove Exception For ${athleteName}`;
	return (
		<TableRow hover>
			<TableCell>
				<Tooltip title={tipText}>
					<span>
						<IconButton disabled={teamDisabled} color={'secondary'} onClick={()=>removeException(sectionID, userID)}><DeleteForever/></IconButton>
					</span>
				</Tooltip>
				<UserLink urlKey={userKey} name={athleteName}/>
			</TableCell>
			<TableCell>{label}</TableCell>
			<TableCell>
				<Tooltip title={'Toggle Team Sharing'}>
					<Checkbox checked={teamON} disabled={teamDisabled} onClick={()=>toggleSetting('shareTeam',sectionID, userID, teamON ? 0:1)}/>
				</Tooltip>
			</TableCell>
			<TableCell>
				<Tooltip title={'Toggle Leaderboard'}>
					<Checkbox checked={leaderboardOn} disabled={teamDisabled} onClick={()=>toggleSetting('shareLeaderboard',sectionID, userID, leaderboardOn ? 0:1)}/>
				</Tooltip>
			</TableCell>
			<TableCell>
				<Tooltip title={'Toggle Position Sharing'}>
					<Checkbox checked={positionOn} disabled={teamDisabled} onClick={()=>toggleSetting('sharePosition',sectionID, userID, positionOn ? 0:1)}/>
				</Tooltip>
			</TableCell>
			<TableCell>
				<Tooltip title={'Toggle Squad Sharing'}>
					<Checkbox checked={squadOn} disabled={teamDisabled} onClick={()=>toggleSetting('shareSquad',sectionID, userID, squadOn ? 0:1)}/>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
}
export class TestBatteryList extends React.Component{
	render(){
		const d = this.props.rowData;
		const active = parseInt(d.active) === 1;
		const status = active ? 'success' : 'warning';
		const iconStyle = {position: 'relative', top: getSpacing('iconAlignment'), color: getStatusColor(status)}
		const icon = getStatusIcon(status, iconStyle);
		const message = active ? 'Test Battery Active' : 'Test Battery Disabled';
		const statusText = active ? 'Active' :'Disabled';
		const lastUsed = d.teamName != null ? `${d.shortLabel} ${d.teamName}` : '-';
		const userPrefs = new UserPreferences();
		return (
			<TableRow hover>
				<TableCell>
					<Tooltip title={`Edit ${d.title}`} placement={'bottom'}>
						<IconButton
							color={'primary'}
							onClick={()=>this.props.rowFunctions.editBattery(d.batteryID)}
						>
							<Edit/>
						</IconButton>
					</Tooltip>
					<Tooltip title={`Remove ${d.title}`} placement={'bottom'}>
						<IconButton
							color={'secondary'}
							onClick={()=>this.props.rowFunctions.promptDeleteBattery(d.batteryID, d.title)}
						>
							<DeleteForever/>
						</IconButton>
					</Tooltip>
				</TableCell>
				<TableCell>
					<Tooltip title={message} placement={'bottom'}>
						{icon}
					</Tooltip>
					{statusText}
				</TableCell>
				<TableCell>
					{d.title}
				</TableCell>
				<TableCell>{lastUsed}</TableCell>
				<TableCell>{userPrefs.convertServerDate(d.modifiedTimeStamp)}</TableCell>
				<TableCell>{userPrefs.convertServerDate(d.createdTimeStamp)}</TableCell>

			</TableRow>
		)
	}
}
export class WorkoutBlockList extends React.Component{
	renderToolTipIcon(icon, message){
		return (
			<Tooltip title={message} position={'bottom'}>
				{icon}
			</Tooltip>
		)
	}
	render(){
		const d = this.props.rowData;
		const {error, warning, status} = d;
		const inactiveStyle ={color: getStatusColor('error')};
		const defaultStyle = {position: 'relative', top: getSpacing('iconAlignment')};
		const successStyle = {color: getStatusColor('success'), ...defaultStyle};
		const errorStyle = {color: getStatusColor('error'), ...defaultStyle};
		const warningStyle = {color: getStatusColor('warning'), ...defaultStyle}
		const errorIcon = error === 1 ? this.renderToolTipIcon(getStatusIcon('error', errorStyle), 'Has Errors') : null;
		const warningIcon = warning === 1 ? this.renderToolTipIcon(getStatusIcon('warning', warningStyle), 'Has Warnings') : null;
		const successIcon = error !== 1 && warning !== 1 ? this.renderToolTipIcon(getStatusIcon('success', successStyle), 'Validated') : null;
		const statuses = {
			0: 'Inactive',
			1: 'Active',
			2: 'Completed'
		}
		const statusText = status === 0? <span style={inactiveStyle}>{statuses[status]}</span> : statuses[status];
		const userPrefs = new UserPreferences();
		return(
			<TableRow hover>
				<TableCell>
					{errorIcon}{warningIcon}{successIcon} {statusText}
				</TableCell>
				<TableCell>
					<WorkoutBlockLink urlKey={d.blockKey} name={d.title}/>
				</TableCell>
				<TableCell>
					<UserLink name={`${d.firstName} ${d.lastName}`} urlKey={d.userKey}/>
				</TableCell>
				<TableCell>
					<TeamLink urlKey={d.teamKey} org={d.shortLabel} name={d.label}/>
				</TableCell>
				<TableCell>{d.daysPerWeek}</TableCell>
				<TableCell>{d.dayValuesStrings}</TableCell>
				<TableCell>{userPrefs.convertServerDate(d.startDate)}</TableCell>
				<TableCell>{userPrefs.convertServerDate(d.endDate)}</TableCell>
			</TableRow>
		)
	}

}
export class WorkoutBlockCompletedList extends React.Component{
	render(){
		const d = this.props.rowData;
		const userPrefs = new UserPreferences();
		const RPEValue = parseFloat(d.teamRPEAverage)*5;
		return (
			<TableRow hover>
				<TableCell>
					<WorkoutBlockReportLink
						urlKey={d.blockKey}
						name={d.title}
					/>
				</TableCell>
				<TableCell><RPEStatusBar score={RPEValue}/></TableCell>
				<TableCell>
					<TeamLink
						urlKey={d.teamKey}
						org={d.shortLabel}
						name={d.label}
					/>
				</TableCell>
				<TableCell>{userPrefs.convertServerDate(d.startDate)}</TableCell>
				<TableCell>{userPrefs.convertServerDate(d.endDate)}</TableCell>
				<TableCell>{d.dayValuesStrings}</TableCell>
				<TableCell>
					<UserLink
						name={`${d.firstName} ${d.lastName}`}
						urlKey={d.userKey}
					/>
				</TableCell>
			</TableRow>
		)
	}
}
export class WorkoutCompletedList extends React.Component{
	render(){
		let user = new User();
		const isCoach = user.isStrengthCoach() || user.isSportSpecificCoach();
		const d = this.props.rowData;
		//console.log('ROW', d);
		const {userID, workoutID} = d;
		const workoutTitle = createWorkoutTitle(d.title, d.dayIndex);
		let workoutElement;
		if(isCoach){
			workoutElement= <WorkoutReportLink urlKey={d.workoutKey} name={d.title} dayIndex={d.dayIndex}/>
		} else {
			//Athlete
			workoutElement = workoutTitle;
		}
		const userPrefs = new UserPreferences();

		return (
			<TableRow hover>
				<TableCell>
					<Tooltip title={'View Workout'} placement={'bottom'}>
						<IconButton
							color={'primary'}
							onClick={()=>this.props.rowFunctions.viewWorkout(userID, workoutID)}
						>
							<TableView/>
						</IconButton>
					</Tooltip>
				</TableCell>
				<TableCell>
					<RPEStatusBar score={d.RPEScore}/>
				</TableCell>
				{/*<TableCell>{userPrefs.convertServerDate(d.dateScheduled)}</TableCell>*/}
				<TableCell>{userPrefs.convertServerDate(d.dateTime)}</TableCell>
				<TableCell>{workoutElement}</TableCell>
				<TableCell>{d.submitterName}</TableCell>
			</TableRow>
		)
	}
}
export class WorkoutCompletedSummaryList extends React.Component{
	renderNewMaxIcon(newMax, style){
		const count = parseInt(newMax);
		const hasNewMax = count > 0;
		const message = hasNewMax ? `(${newMax}) New Max Achieved` : 'No New Maxes';
		let iconStyle
		if(hasNewMax){
			iconStyle = {...style, color: getStatusColor('success')}
		} else{
			iconStyle = {...style, opacity: fadedOpacity}
		}
		return (
			<Tooltip title={message} placement={'bottom'} >
				<Star style={iconStyle}/>
			</Tooltip>
		)
	}
	render(){
		const d = this.props.rowData;
		//console.log('COMPLETED WORKOUT', d);
		/*
		const {exceededReps, exceededWeight, failedReps, failedWeight, newMax} = d;
		let exceedRepsIcon, exceedWeightIcon, failedRepIcon, failedWeightIcon;
		const newMaxIcon = this.renderNewMaxIcon(newMax, iconStyle);
		*/
		const completed = parseInt(d.completed) === 1;
		const url = `${URL_ANALYTICS}${trimURL(URL_TEAM_WORKOUTS)}${d.workoutKey}/`;
		let actionButton = null;
		let RPEBar = 'Undetermined';
		let workoutTitle = createWorkoutTitle(d.title, d.dayIndex);
		let attendanceBar = 'Pending';
		let buttonTip = '';
		if(completed){
			buttonTip = 'View Results';
			actionButton =
					<IconButton component={Link} to={url} color={'primary'}>
						<BarChart/>
					</IconButton>
			workoutTitle = <WorkoutReportLink urlKey={d.workoutKey} dayIndex={d.dayIndex} name={d.title}/>

			const attendanceColor = getStatusScaleColor(d.completionRate, COLOR_SCALES.red, COLOR_SCALES.yellow, COLOR_SCALES.green);
			attendanceBar = <ColorCodedStatusBar score={d.completionRate} color={attendanceColor} message={`Completion Rate: ${d.completionRate}%`}/>;
		} else{
			buttonTip = 'Results Pending';
			actionButton =
						<IconButton disabled={true}>
							<Pending/>
						</IconButton>
		}
		if(d.RPEScore > 0){
			RPEBar = <RPEStatusBar score={d.RPEScore}/>;
		}
		const userPrefs = new UserPreferences();
		return (
			<TableRow hover>
				<TableCell>
					<Tooltip title={buttonTip} placement={'bottom'}>
						<span>
							{actionButton}
						</span>
					</Tooltip>
				</TableCell>
				<TableCell>{workoutTitle}</TableCell>
				<TableCell>{RPEBar}</TableCell>
				<TableCell>{attendanceBar}</TableCell>
				<TableCell>{userPrefs.convertServerDate(d.dateScheduled)}</TableCell>
				<TableCell>
					<TeamLink org={d.shortLabel} name={d.label} urlKey={d.teamKey}/>
				</TableCell>
				<TableCell>
					<UserLink urlKey={d.coachKey} name={d.coachName}/>
				</TableCell>

			</TableRow>
		)
	}

}
export const WorkoutList=({rowData, rowFunctions, headers})=>{
	const d = rowData;
	console.log(headers);
	const completedURL = `${URL_ANALYTICS}${trimURL(URL_TEAM_WORKOUTS)}${d.workoutKey}/`;
	const workoutURL = `${URL_TEAM_WORKOUTS}${d.workoutKey}/`;
	const iconStyle = {position: 'relative', top: getSpacing('iconAlignment')};
	const buttonStyle = {position: 'relative', bottom: '3px'}
	const {prepPush, prepManage} = rowFunctions;
	const workoutError = parseInt(d.error) === 1;
	const workoutWarning = parseInt(d.warning) === 1;
	const workoutAssigned = parseInt(d.assignedDevice) === 1;
	const completed = d.completed === 1;
	const assignedWorkoutButton = <IconButton style={{...buttonStyle, color: getStatusColor('success')}} onClick={()=>prepManage(d.workoutID, d.teamID)}><MobileFriendly/></IconButton>;
	const unAssignedWorkoutButton = <IconButton style={{...buttonStyle, opacity: fadedIconOpacity}} onClick={()=>prepPush(d.workoutID, d.teamID)}><Smartphone/></IconButton>;
	const errorWorkoutButton = <span><Link to={workoutURL}><IconButton style={{...buttonStyle, color: getStatusColor('error')}}><PhonelinkErase/></IconButton></Link></span>;
	let mainIcon, iconText, message, deviceIcon, deviceTooltipMessage, deviceText;
	//Workout Status Column
	if(completed){
		mainIcon = <BarChart color={'primary'} style={iconStyle} />
		iconText = 'View Results';
		message = 'Completed: View Results';

	} else if(workoutError){
		mainIcon = <Cancel style={{color: getStatusColor('error'),...iconStyle}}/>;
		iconText = 'Incomplete';
		message= 'Workout Has Errors/Warnings';

	} else if(workoutWarning){
		mainIcon = <Warning style={{color: getStatusColor('warning'), ...iconStyle}}/>;
		iconText = 'Pending';
		message = 'Workout Has Warnings';

	} else{
		mainIcon = 	<CheckCircle style={{color: getStatusColor('success'),...iconStyle}}/>;
		iconText = 'Ready';
		message = 'Ready For Athletes'
	}

	//Workout Status URL
	const statusURL = completed ? completedURL : workoutURL;



	//SET the Device Status Column Button
	if(workoutError){
		deviceIcon = errorWorkoutButton;
		deviceTooltipMessage = 'Fix Errors'
		deviceText = 'Error';
	} else if(workoutAssigned){
		deviceIcon = assignedWorkoutButton
		deviceTooltipMessage = 'Manage Workout'
		deviceText = 'Active';
	} else{
		deviceIcon = unAssignedWorkoutButton;
		deviceTooltipMessage = 'Activate Workout'
		deviceText = 'Push Workout';
	}

	console.log('WL', d);
	const userPrefs = new UserPreferences();
	return (
		<TableRow hover>
			<TableCell
				style={getCellStyle(headers, 'style', 0)}
				sx={getCellStyle(headers, 'sx', 0)}
			>
				<Tooltip title={message} placement={'bottom'}>
					<Link to={statusURL}>{mainIcon} {iconText}</Link>
				</Tooltip>
			</TableCell>
			<TableCell
				style={getCellStyle(headers, 'style', 1)}
				sx={getCellStyle(headers, 'sx', 1)}
			>
				<Tooltip title={deviceTooltipMessage} placement={'bottom'}>
					{deviceIcon}
				</Tooltip>
				 {deviceText}
			</TableCell>
			<TableCell
				style={getCellStyle(headers, 'style', 2)}
				sx={getCellStyle(headers, 'sx', 2)}
			>
				{userPrefs.convertServerDate(d.dateScheduled)}
			</TableCell>
			<TableCell
				style={getCellStyle(headers, 'style', 3)}
				sx={getCellStyle(headers, 'sx', 3)}
			>
				<Link to={workoutURL}>{d.workoutBlockTitle}: Day {d.dayIndex+1}</Link>
				<Typography sx={{display: {xs: 'none', sm: 'inline', md: 'none'}}}>
					<br/><TeamLink urlKey={d.teamKey} org={d.shortLabel} name={d.teamName}/>
				</Typography>
			</TableCell>
			<TableCell
				style={getCellStyle(headers, 'style', 4)}
				sx={getCellStyle(headers, 'sx', 4, '')}
			>
				<TeamLink urlKey={d.teamKey} org={d.shortLabel} name={d.teamName}/>
			</TableCell>
			<TableCell
				style={getCellStyle(headers, 'style', 5)}
				sx={getCellStyle(headers, 'sx', 5)}
			>
				<UserLink urlKey={d.userKey} name={`${d.firstName} ${d.lastName}`}/>
			</TableCell>
		</TableRow>
	);
}



