import React, {useState, useEffect} from "react";
import {Grid, Typography, Button, Paper, TextField, Dialog, Collapse, Divider} from '@mui/material';
import {Form, Formik} from "formik";
import * as yup from "yup";
import User from 'fit/system/User';
import Alert from "fit/components/Alert";
import UserPermissions from "./Permissions/UserPermissions";
import AthleteEligibilityForm from "./AthleteEligibilityForm";
import SecureConnect from "fit/system/SecureConnect";
import {objFlatten, isEmpty, getSpacing} from "fit/system/UtilityFunctions";
import MaskedInput from "fit/components/Form/MaskedInput";
import {PERM_DEPT, PERM_TEAM, PAPER_STYLE, ORGANIZATION_TYPES, OPERATIONS_LEVEL, MOMENT_DATE_FORMAT} from "fit/system/FITConstants";
import moment from 'moment';

const validationSchema = yup.object().shape({
    firstName: yup.string()
        .trim()
        .min(2,'First name should at least 2 characters long')
        .max(50,'The first name is too long')
        .required('First name required'),
    lastName: yup.string()
        .trim()
        .min(2,'Last name should at least 2 characters long')
        .max(50,'The last name is too long')
        .required('Last name required'),
    email: yup.string()
        .trim()
        .email('Invalid email')
        .required('Email required'),
    phone: yup.string()
        .trim()
        .min(14, 'Phone number is incomplete')
        .max(16, 'Phone number is too long'),
});

const initUserData=()=>{
    return {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    }
}
const initAthleteData=()=>{
    return {
        graduationYear: '',
        active: 1,
        yearLabelID: 0,
        redShirt: 0,
    }
}

const CreateUserForm =({display, handleClose, completedFunction})=>{
    const [userData, setUserData] = useState(initUserData);
    const [isAthlete, setIsAthlete] = useState(false);
    const [depts, setDepts] = useState({});
    const [teams, setTeams] = useState({});
    const [submitPending, setSubmitPending] = useState(false);
    const [athleteLabels, setAthleteLabels] = useState([]);
    const [athleteAffiliations, setAthleteAffiliations] = useState([]);

    useEffect(()=>{
        const user = new User();
        if(isEmpty(athleteLabels) && user.isSportSpecificCoach(OPERATIONS_LEVEL)) {
            getAthleteLabels();
        }
    })
    const initState=()=>{
        setUserData(initUserData);
        setIsAthlete(false);
        setDepts({});
        setTeams({});
        setSubmitPending(false);
        setAthleteAffiliations([]);
    }
    const getAthleteLabels=()=>{
        //Load athlete labels (affiliation settings) for athletes
        let sc = new SecureConnect('team.php?action=getAthleteAffiliationLabels');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json =>{
            const data = sc.getCompleted(json) ? sc.getData(json) : [];
            setAthleteLabels(data);
        })
    }

    const submitForm=(data)=>{
        console.log('SUBMITTING');
        let formData = {...data}; //JSON.parse(JSON.stringify(data));


        //Verify at least one department has been set
        //Assign only active depts/teams
        formData.athlete = isAthlete ? 1 :0;
        formData.depts = JSON.stringify(depts);
        formData.teams = JSON.stringify(teams);
        formData.affiliations = JSON.stringify(athleteAffiliations);
        setSubmitPending(true);
        console.log('************* SUBMITTING FORM DATA *************');
        console.log(formData);
        console.log('*************************************************');
        let sc = new SecureConnect('user.php','post');
        sc.setDisplaySuccessMessages(true);
        sc.setAction('createUser');
        sc.setFormData(objFlatten(formData));
        console.log('Here now');
        sc.connect().then(json=>{
            console.log('connecting');
            if(sc.getCompleted(json)){
                const test = true;
                //reset form
                if(!test){
                    initState();
                } else{
                    setSubmitPending(false);
                }
                //
                //Close the modal
                //Update the main table
                completedFunction(json);
            } else{
                setSubmitPending(false);
            }

        });
    }
    const handleChange=(e)=>{
        const {name, value} = e.target;
        let ud = {...userData};
        ud[name] = value;
        setUserData(ud);
    }
    const checkOverrides=(list, sectionID, managerLevel, activated)=>{
        const user = new User();
        const depts = user.getDepts();
        const keys = Object.keys(depts);
        sectionID = parseInt(sectionID);
        managerLevel = parseInt(managerLevel);
        keys.forEach(key =>{
            let dept = depts[key];
            const override = dept.override != null && dept.overrideSectionID != null && parseInt(dept.overrideSectionID) === sectionID;
            const sID = parseInt(dept.sectionID);
            if(override){
                //Add/Remove from permissions list based on whether the original dept was de/activated
                if(activated){
                    //Inherit managerLevel from original dept setting
                    dept.managerLevel = managerLevel;
                    dept.active = 1;
                    list[sID] = dept;
                } else{
                    delete(list[sID]);
                }
            }
        })
        return list;
    }
    const checkAthlete=(list)=>{
        if(!isEmpty(list)){
            let user = new User();
            const keys = Object.keys(list);
            for(let k=0; k<keys.length; k++){
                const key = keys[k];
                const dept = list[key];
                const athleteSet = user.checkAthleteDept(dept.deptID) && dept.override == null;
                //console.log('DEPT ID', dept.deptID, 'ATHLETE DEPT?', user.checkAthleteDept(dept.deptID), 'NO OVERRIDE',dept.override == null);
                if(athleteSet){
                    return true;
                }
            }
        }
        return false;
    }
    const initAffiliations=(organizationID, organizationName, active)=>{
        let affiliations = structuredClone(athleteAffiliations);
        //find whether the organization exists within the list of affiliations
        //if active it doesn't exist, add to the end
        //if exists and inactive, remove it
        const index = affiliations.findIndex(aff => parseInt(aff.organizationID) === parseInt(organizationID));
        //console.log('INIT AFFs', organizationName, organizationID, active);
        if(index > -1 && !active){
            //console.log('REMOVE', organizationID, organizationName, active);
            //Deactivated - remove the affilation
            affiliations.splice(index, 1);
        }
        if(index === -1 && active){
            //Doesn't exist, add it
            //Get the organizationTypeID from the organization
            let organizationTypeID = ORGANIZATION_TYPES.university;
            const user = new User();
            const depts = user.getDepts();
            const keys = Object.keys(depts);
            for(let k = 0; k<keys.length; k++){
                const key = keys[k];
                const dept = depts[key];
                if(parseInt(dept.organizationID) === parseInt(organizationID)){
                    //console.log('FOUND ORG', organizationName, dept.organizationTypeID); console.log('ORG DETAILS', dept);
                    organizationTypeID = dept.organizationTypeID;
                    break;
                }
            }

            //Create the Affiliation Record
            affiliations.push({
                name: organizationName,
                expirationDate: null,
                yearLabelID: 0,
                redShirt: 0,
                active: 1,
                organizationID,
                organizationTypeID: organizationTypeID
            })
        }
        setAthleteAffiliations(affiliations);
    }
    const handleAthleteAffiliation=(arrayKey, affiliationData)=>{
        let affiliations = structuredClone(athleteAffiliations);
        affiliations.splice(arrayKey, 1, affiliationData);
        setAthleteAffiliations(affiliations);
        //console.log('AFFILIATIONS', affiliations);
        //console.log('HANDLE AFFILIATION', arrayKey, affiliationData);
    }
    const getMax=(currentRank, newRank)=>{
        return parseInt(currentRank) < parseInt(newRank) ? parseInt(newRank) : parseInt(currentRank);
    }
    const getMaxTeamRank=(list, organizationID)=>{
        //Get the max rank of a user for the organization
        //Use this for assigning team ranks
        let managerLevel = 0;
        Object.keys(list).forEach(key =>{
            const dept = list[key];
            if(parseInt(dept.organizationID) === parseInt(organizationID)) {
                //Update manager Level only for those within the active dept
                managerLevel = getMax(managerLevel, dept.managerLevel);
            }
        });
        return managerLevel;
    }
    const updateTeamRanks=(organizationID, newRank)=>{
        //Update the team ranks for all teams when a dept rank changes
        let newTeams = {...teams};
        Object.keys(newTeams).forEach(k =>{
            if(newTeams[k].organizationID === organizationID) {
                newTeams[k].managerLevel = newRank;
            }
        })
        setTeams(newTeams);
    }
    const handlePermissionChange=(type, organizationID, sectionID, managerLevel, active)=>{
        //console.log('HANDLING PERMISSION',type, organizationID, sectionID, managerLevel, active);
        let user = new User();
        const teamSettings = user.getPermissions('teams');
        const deptSettings = user.getPermissions('depts');
        //console.log('TEAMS', teams); console.log('DEPTS', depts);
        const deptSet = type === PERM_DEPT;
        const activated = parseInt(active) === 1;
        let setting = deptSet ? structuredClone(deptSettings[sectionID]) : structuredClone(teamSettings[sectionID]);
        //Apply settings additional settings from the form
        setting.managerLevel = deptSet ? managerLevel : getMaxTeamRank(depts, organizationID);
        setting.active = active;
        //console.log('SETTING', setting);
        //Strip Unnecessary settings from the settings
        delete(setting.userID);
        delete(setting.override);
        delete(setting.overrideDeptName);
        delete(setting.overrideDeptID);
        delete(setting.overrideSectionID);
        //Add or Remove from the list
        let list = deptSet ? {...depts} : {...teams};
        if(activated){
            list[sectionID] = setting;
        } else{
            //Remove from list
            delete(list[sectionID]);
        }
        if(deptSet){
            //Handle Dept Overrides
            list = checkOverrides(list, sectionID, managerLevel, activated);
            //Update Depts
            setDepts(list);
            //Update Athlete Setting
            const athlete = checkAthlete(list);
            setIsAthlete(athlete);
            if(athlete){
                //set the affiliations for the athlete
                initAffiliations(organizationID, setting.orgShortName, activated);
            } else{
                setAthleteAffiliations([]);
            }
            //console.log('FINAL LIST', list);
            //Get the new max rank for the teams based on dept settings
            const maxRank = getMaxTeamRank(list, organizationID);
            //Update teams with rank changes
            updateTeamRanks(organizationID, maxRank);
            if(activated === false) {
                //Potentially remove all teams if no org dept assigned
                handleTeamRemoval(list);
            }
        } else{
            //Update Teams
            setTeams(list);
        }
    }
    const handleTeamRemoval=(deptList)=>{
        let activeOrganizationIDs = [];
        Object.keys(deptList).forEach(key =>{
            const dept = deptList[key];
            const active = parseInt(dept.active) === 1;
            const orgID = parseInt(dept.organizationID);
            if(!activeOrganizationIDs.includes(orgID) && active){
                activeOrganizationIDs.push(orgID);
            }
        })
        if(isEmpty(teams)){
            return; //die
        }
        let t = {...teams};
        Object.keys(teams).forEach(key =>{
            const team = teams[key];
            if(!activeOrganizationIDs.includes(parseInt(team.organizationID))){
                //Associated team no longer in active orgs. Delete
                delete(t[key]);
            }
        })
        //Update with new list
        setTeams(t);
    }
    const handleDialogClose =()=>{
        initState()
        if(handleClose != null){
            handleClose();
        }
    }
    const validateAffiliations=()=>{
        if(isEmpty(athleteAffiliations)){
            return true;
        }
        let valid = true;
        const today = moment().format(MOMENT_DATE_FORMAT);
        for(let k=0; k<athleteAffiliations.length; k++){
            const aff = athleteAffiliations[k];
            const invalidDate = aff.expirationDate == null || aff.expirationDate < today;
            if(invalidDate || parseInt(aff.yearLabelID) === 0){
                valid = false;
                break;
            }
        }
        return valid;
    }

    const dialogPadding = {padding: getSpacing()};
    const ud = userData;
    const fullName = `${ud.firstName} ${ud.lastName}`;
    const paperSpacing = {marginTop: getSpacing()};
    const paperStyle = {...PAPER_STYLE, ...paperSpacing};
    const noDepts = isEmpty(depts);
    const noTeams = isEmpty(teams);
    const affiliationsComplete = validateAffiliations();
    const alertStyle = {marginTop: getSpacing('small'), marginBottom: getSpacing('small')}
    //console.log('TEAMS LIST', teams);
    return (
        <Dialog
            open={display}
            onClose={()=>handleDialogClose()}
            aria-labelledby="add-user-dialog-title"
            aria-describedby="add-user-dialog-description"
            maxWidth={'md'}
        >
            <div style={dialogPadding}>

                <Formik
                    initialValues={userData}
                    validationSchema={validationSchema}
                    onSubmit={(data)=>submitForm(data)}
                    enableReinitialize={true}
                >
                    {({errors, touched}) => (
                        <Form>
                            <Typography variant={'h2'}>
                                Create A New User
                            </Typography>
                            <Paper style={paperStyle}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant={'h3'}>Basic Information</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            name="firstName"
                                            label="First Name"
                                            error={errors.firstName && touched.firstName}
                                            helperText={errors.firstName || ' '}
                                            type="text"
                                            placeholder="Robert"
                                            onBlur={(e)=>handleChange(e)}
                                            defaultValue={ud.firstName}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            name="lastName"
                                            label="Last Name"
                                            error={errors.lastName && touched.lastName}
                                            helperText={errors.lastName || ' '}
                                            type="text"
                                            placeholder="Paulsen"
                                            onBlur={(e)=>handleChange(e)}
                                            defaultValue={ud.lastName}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            name="email"
                                            label="Email"
                                            error={errors.email && touched.email}
                                            helperText={errors.email || ' '}
                                            type="email"
                                            placeholder="RobertPaulsen@bodjour.com"
                                            onBlur={(e)=>handleChange(e)}
                                            defaultValue={ud.email}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MaskedInput
                                            fullWidth
                                            required
                                            pattern={"phone"}
                                            name={"phone"}
                                            label={"Phone"}
                                            error={errors.phone}
                                            helperText={errors.phone || ' '}
                                            placeholder="(888) 867-5309"
                                            onBlur={(e)=>handleChange(e)}
                                            defaultValue={ud.phone}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper style={paperStyle}>
                                <Typography variant={'h3'}>Departments</Typography>
                                <Alert
                                    visible={isEmpty(depts)}
                                    status={'error'}
                                    style={alertStyle}
                                    headline={'Active Department Required'}
                                    message={'The user must be assigned to at least one active department.'}
                                />
                                <UserPermissions
                                    newUser={true}
                                    userID={null}
                                    name={fullName}
                                    type={PERM_DEPT}
                                    permissions={depts}
                                    athlete={isAthlete}
                                    updateFn={(organizationID, sectionID, managerLevel, active)=>handlePermissionChange(PERM_DEPT, organizationID, sectionID, managerLevel, active)}
                                />
                            </Paper>
                            <Paper style={paperStyle}>
                                <Typography variant={'h3'}>Teams</Typography>
                                <Alert
                                    visible={isEmpty(teams) && !isAthlete}
                                    status={'warning'}
                                    style={alertStyle}
                                    headline={'No Teams Assigned'}
                                    message={'The user will not be able to perform any tasks unless assigned to a team.'}
                                />
                                <Alert
                                    visible={isEmpty(teams) && isAthlete}
                                    status={'error'}
                                    style={alertStyle}
                                    headline={'Team Required'}
                                    message={'When creating an athlete, you must assign them to a team.'}
                                />
                                <UserPermissions
                                    newUser={true}
                                    userID={null}
                                    name={fullName}
                                    type={PERM_TEAM}
                                    permissions={teams}
                                    athlete={isAthlete}
                                    deptPermissions={depts}
                                    updateFn={(organizationID, sectionID, managerLevel, active)=>handlePermissionChange(PERM_TEAM, organizationID, sectionID, managerLevel, active)}
                                />
                            </Paper>
                            <Collapse in={isAthlete}>
                                {
                                    athleteAffiliations.map((affData, k) =>{
                                        //const disabled = parseInt(affData.active)!==1;
                                        return (
                                            <Paper style={paperStyle} key={k}>
                                                <Typography variant={'h4'}>
                                                    {affData.name} Affiliation & Eligibility
                                                </Typography>
                                                <Divider/>
                                                <AthleteEligibilityForm
                                                    newUser={true}
                                                    disabled={isAthlete === false}
                                                    affiliationKey={k}
                                                    affiliationData={affData}
                                                    organizationTypeID={1}
                                                    athleteLabels={athleteLabels}
                                                    updateFunction={(arrayKey, affiliationData)=>handleAthleteAffiliation(arrayKey, affiliationData)}
                                                />
                                            </Paper>
                                        )
                                    })
                                }
                            </Collapse>
                            <Grid container spacing={2} sx={{paddingTop: getSpacing()}}>
                                <Grid item xs={12}>
                                    <Alert
                                        visible={submitPending}
                                        status={'info'}
                                        headline={'Processing New User'}
                                        message={"Your new user submission is pending. The system is setting up the account now."}
                                    />
                                    <Alert
                                        visible={!isEmpty(errors) && touched}
                                        status={'error'}
                                        headline={'Form Has Errors'}
                                        message={'Please correct the errors before submitting.'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={submitPending || noDepts || noTeams || !affiliationsComplete}
                                    >Create User</Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        type="reset"
                                        onClick={()=>initState()}
                                        disabled={submitPending}
                                    >Reset</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </Dialog>
    )
}

export default CreateUserForm;
