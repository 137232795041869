import React from 'react';
import {Grid, Typography} from "@mui/material";
import {getSpacing} from "fit/system/UtilityFunctions";
import {useTheme, useMediaQuery} from "@mui/material";

import Box from "@mui/material/Box"
import Button from "@mui/material/Button";

const Header=({header, children, variant})=>{
    const theme = useTheme();
    const justify = {xs: 'left', md: 'expand-end'};
    const smallWindow = useMediaQuery(theme.breakpoints.down('sm'));
    const alignment = smallWindow ? 'left' :'right';

    const headerVariant = variant == null ? 'h1' : variant;
    return (
        <Grid container spacing={1} sx={{paddingBottom: getSpacing('small')}}>
            <Grid item xs={12} sm={6}>
                <Typography variant={headerVariant}>{header}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} justifyContent={justify} sx={{textAlign: alignment}}>
                    {children}
            </Grid>
        </Grid>
    )
}

export default Header