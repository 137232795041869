import React,{useState, useEffect} from 'react';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import {useTheme} from "@mui/material";
import {GOLD_COLOR} from "fit/system/FITConstants";
import {isEmpty} from "../../../system/UtilityFunctions";


const LeaderConfetti=({active, place, location = {}})=>{

    const [init, setInit] = useState(false);
    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        //console.log('PARTCILES LOADED', container);
    };

    const theme = useTheme();
    const p = theme.palette.primary;
    const pMain = p.main;
    const pDark = p.dark;
    const pLight = p.light;
    const s = theme.palette.secondary;
    const sMain = s.main;
    const sDark = s.dark;
    const sLight = s.light;

    const confettiColors = [
        GOLD_COLOR, //gold
        "#DDD", //silver?
        pMain, pLight, pDark,
        sMain, sLight, sDark
    ]

    //console.log('LOCATION', location);

    const origin = !isEmpty(location) ? location : null;

    const defaults = {
        count: place === 2 ? 300 : 150,
        spread: 360,
        ticks: 100,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        origin: {x: 120, y:120}
    }

    //Tilt, Wobble, Dark create the 3d effect
    const rainOptions ={
        fullScreen: {
            zIndex: 0,
        },
        flat: false,
        particles: {
            color: {
                value: confettiColors,
            },
            move: {
                direction: "bottom",
                enable: true,
                outModes: {
                    default: "out"
                },
                size: true,
                speed: {
                    min: 1,
                    max: 3
                }
            },
            number: {
                value: 500,
                density: {
                    enable: true,
                    area: 800
                }
            },
            size: {
                value: {
                    min: 2,
                    max: 4
                }
            },
            shape: {
                type: [
                    "circle",
                    "square"
                ],
                options: {}
            },
            opacity: {
                value: 1,
                animation: {
                    enable: false,
                    startValue: "max",
                    destroy: "min",
                    speed: 0.3,
                    sync: true
                }
            },
            rotate: {
                value: {
                    min: 0,
                    max: 360
                },
                direction: "random",
                move: true,
                animation: {
                    enable: true,
                    speed: 30
                }
            },
            tilt: {
                direction: "random",
                enable: true,
                move: true,
                value: {
                    min: 0,
                    max: 360
                },
                animation: {
                    enable: true,
                    speed: 60
                }
            },
            wobble: {
                distance: 30,
                enable: true,
                move: true,
                speed: {
                    min: -15,
                    max: 15
                }
            },
            roll: {
                darken: {
                    enable: true,
                    value: 30
                },
                enlighten: {
                    enable: true,
                    value: 3000
                },
                enable: true,
                speed: {
                    min: 15,
                    max: 25
                }
            }
        }
    }
    const singleConfetti = {
            fullScreen: {
                zIndex: 1
            },
            particles: {
                ...defaults,
                number: {
                    value: 0
                },
                color: {
                    value: confettiColors
                },
                shape: {
                    type: ['square','triangle','polygon'],
                    options: {
                        polygon: [
                            {
                                sides: 5
                            },
                            {
                                sides: 6
                            }
                        ]
                    }
                },
                opacity: {
                    value: {
                        min: 0,
                        max: 1
                    },
                    animation: {
                        enable: true,
                        speed: 2,
                        startValue: "max",
                        destroy: "min"
                    }
                },
                size: {
                    value: {
                        min: 2,
                        max: 4
                    }
                },
                links: {
                    enable: false
                },
                life: {
                    duration: {
                        sync: true,
                        value: 5
                    },
                    count: 1
                },
                move: {
                    enable: true,
                    gravity: {
                        enable: true,
                        acceleration: 10
                    },
                    speed: {
                        min: 10,
                        max: 20
                    },
                    decay: 0.1,
                    direction: "none",
                    straight: false,
                    outModes: {
                        default: "destroy",
                        top: "none"
                    }
                },
                rotate: {
                    value: {
                        min: 0,
                        max: 360
                    },
                    direction: "random",
                    move: true,
                    animation: {
                        enable: true,
                        speed: 60
                    }
                },
                tilt: {
                    direction: "random",
                    enable: true,
                    move: true,
                    value: {
                        min: 0,
                        max: 360
                    },
                    animation: {
                        enable: true,
                        speed: 60
                    }
                },
                roll: {
                    darken: {
                        enable: true,
                        value: 25
                    },
                    enable: true,
                    speed: {
                        min: 15,
                        max: 25
                    }
                },
                wobble: {
                    distance: 30,
                    enable: true,
                    move: true,
                    speed: {
                        min: -15,
                        max: 15
                    }
                }
            },
            emitters: {
                life: {
                    count: 1,
                    duration: 0.1,
                    delay: 0.4
                },
                rate: {
                    delay: 0.1,
                    quantity: 150
                },
                size: {
                    width: 0,
                    height: 0
                }
            }
        }

    let options;
    if(place === 1){
        options = rainOptions;
    } else if(place === 2 || place === 3){
        options = singleConfetti;
    } else{
        options = {};
    }
    if (init && active) {
        return (
            <Particles
                particlesLoaded={particlesLoaded}
                options={defaults}
            />
        );
    }
    return null;
}

export default LeaderConfetti;