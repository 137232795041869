import React, {useState, useEffect, memo, useCallback} from 'react';
import moment from "moment";
import {MOMENT_DATE_FORMAT, MOMENT_MIN_DATE, TEXTFIELD_DEBOUNCE_DELAY} from "fit/system/FITConstants";
import {convertDateStrToDateObj, getSpacing, isEmpty, paperStyles, getTimestamp, lazyLoad} from "fit/system/UtilityFunctions";
import {SecureConnect} from "fit/system/SecureConnect";
import {
    Box,
    Collapse,
    Fade, FormControl, FormHelperText,
    Grid, IconButton, InputAdornment,
    LinearProgress,
    Table,
    TableBody, TableCell, TableHead, TablePagination,
    TableRow,
    TableSortLabel, TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {Backspace, Search} from "@mui/icons-material";
import UserPreferences from "../../system/UserPreferences";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {getRowComponent, NoData} from "./DynamicRows";
import DynamicSelector from "./DynamicSelector";
import DebounceTextField from "../Form/DebounceTextField";

const getRowsPerPage=()=>{
    const rowsPerPage = localStorage.getItem(ROW_STORAGE_NAME);
    return !rowsPerPage || rowsPerPage < 1 ? 25 : rowsPerPage;
}

const init=(set, otherData)=>{
    switch (set){
        case 'tableFormat':
            if(otherData != null){
                return otherData;
            }
            return {
                tableHeaders: []/*{show_ID:"Show ID",lastName:"Customer Name",email:"Email",phone:"Phone"}*/,
                thClass: '',
                rowComponent: '',
                paginationEnabled: false,
                searchEnabled: false,
                sortEnabled: false,
                searchTips: [],
                selectors: [],
                dateRangeEnabled: false,
                startDateMinimum: null,
                endDateMaximum: null,
                searchValue: '',
                method: 'get',
                action: '',
                url: '',
                defaultSort: '',
                defaultDirection: '',
            }
        case 'tableInputs':
            const rows = getRowsPerPage();
            return {
                pageInput: 1, //The is the pagination input
                rowsPerPage: rows,
                sort: '',
                direction: '',
                searchCriteria: '',
                startDateRange: '',
                endDateRange: '',
                modified: false, //Gets set to true when any inputs have been modified
            }
        case 'tableRequests':
            return {
                dataPending: true, //Is interface waiting on server
                tableFormatLoaded: false
            }
        case 'pageData':
            return {
                currentPage: 1,
                totalPages: 10,
                rowsPerPage: 25,
                totalRows: 250
            }
    }
}

const ROW_STORAGE_NAME = 'systemTableRows';
const delayMS = 100;
const displayDelayMS =800;
const debugScreenSizes = false;


class LocalStorageTable{
    constructor(interfaceName = null, log = false) {
        this.data = {};
        this.tableKey = 'DynamicTable';
        this.stateKey = 'state';
        this.timeStampKey = 'timestamp';
        this.tableExpirationLimit = 3600; //Limit of 10 minutes before getting a new table // 1 day = 86400
        this.freshnessLimit = 600; //Time Limit before getting new data
        this.interface = interfaceName != null ? interfaceName : '_DEFAULT_';
        let tableData = this.#getFullTable();
        if(tableData[this.interface] == null){
            tableData[this.interface] = {};
            localStorage.setItem(this.tableKey, JSON.stringify(tableData));
        }
        this.log = log;
    }
    setInterface(string){
        this.interface = string.length ? string : '';
    }
    unsetInterface(){
        this.interface = '';
    }
    getTable(key) {
        const fullData = this.#getFullTable();
        const tableData = key && key.length && fullData[this.interface][key] ? fullData[this.interface][key] : {};
        if(!isEmpty(tableData)) {
            let {timestamp, state} = tableData;
            const now = getTimestamp();
            const fresh = now - this.freshnessLimit < timestamp; //Fresh as opposed to expired
            const tableOK = now - this.tableExpirationLimit < timestamp;
            if (tableOK && fresh) {
                return state;
            } else if (tableOK && !fresh) {
                //Need new data
                state.tableData = [];
                return state;
            } else {
                return {};
            }
        }
        return {};
    }
    storeTable(key, tableState){
        if(key && key.length && !isEmpty(tableState)){
            const unixTime = getTimestamp();
            const tableData = {[this.timeStampKey]: unixTime, [this.stateKey]: tableState};
            this.#storeFullTable(key, tableData);
        }
    }
    clearTable(key){
        let tableData = structuredClone(this.#getFullTable());
        delete (tableData[this.interface][key]);
        if(this.log) {
            console.log('CLEARING TABLE', key);
            console.log('NEW DATA', tableData);
        }
        this.#store(tableData);
    }
    #storeFullTable(key, tableData){
        let currentData = this.#getFullTable();
        if(this.interface !== '') {
            currentData[this.interface][key] = tableData;
            this.#store(currentData);
        } else{
            console.error('INTERFACE REQUIRED BEFORE STORING TABLE');
        }
    }
    #store(dataObj){
        localStorage.setItem(this.tableKey, JSON.stringify(dataObj));
    }
    #getFullTable(){
        const tableData = localStorage.getItem(this.tableKey);
        if(tableData != null){
            return JSON.parse(tableData);
        }
        return {};
    }

}

const TableForm=({buttonStyle, getData, handleSearch, handleChange, handleDateChange, searchText, inputs, format, requests, resetTable})=>{
    let searchBar, dateRange;
    const ti = inputs;
    const tf = format;
    const tr = requests;
    const {tableFormatLoaded} = tr;



    if(!tableFormatLoaded){
        const fakeField =
            <TextField
                disabled={true}
            />
        const fakeButton =
            <IconButton
                variant="contained"
                type="button"
                style={buttonStyle}
                disabled={true}
            ><Backspace/>
            </IconButton>;
        return (
            <Box>
                <TextField
                    disabled={true}
                    placeholder='Just A Second'
                    helperText={'Loading...'}
                    style={{minWidth: '245px'}}
                /> {fakeField} {fakeField} {fakeButton}
            </Box>
        );
    }
    const enableForm = tableFormatLoaded && (tf.searchEnabled || tf.dateRangeEnabled || !isEmpty(tf.selectors));
    if(!enableForm){
        return null;
    }
    const disabled = tr.dataPending === true;
    const resetButton =
        <Tooltip
            title='Reset Filters'
            placement={'top'}
        >
				<span>
				<IconButton
                    variant="contained"
                    color="secondary"
                    type="button"
                    style={buttonStyle}
                    disabled={disabled}
                    onClick={resetTable}>
					<Backspace/>
				</IconButton>
				</span>
        </Tooltip>;

    //Enable Search field
    if (tf.searchEnabled && tableFormatLoaded) {
        searchBar = <Tooltip
                        title={tf.searchTips}
                        placement={'top-start'}
                        enterDelay={300}
                    >
						<span>
						<DebounceTextField
                            type="search"
                            delay={TEXTFIELD_DEBOUNCE_DELAY}
                            value={searchText}
                            onChange={(value)=>handleSearch(value)}
                            placeholder="Search"
                            helperText="Search The System"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            color={'primary'}
                                            onClick={()=>getData()}
                                            disabled={disabled}
                                        >
                                            <Search/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
						</span>
					</Tooltip>
    }
    //Date Range
    if (tf.dateRangeEnabled) {
        const userPrefs = new UserPreferences();
        const maxDateString = tf.dateRange.max != null ? tf.dateRange.max : moment().format(MOMENT_DATE_FORMAT);
        const minDateString = tf.dateRange.min != null ? tf.dateRange.min : moment(MOMENT_MIN_DATE).format(MOMENT_DATE_FORMAT);
        const startDateValue = ti.startDateRange === '' ? null : ti.startDateRange;
        const endDateValue = ti.endDateRange === '' ? null : ti.endDateRange;
        const endDateMax = maxDateString;
        const startDateMin = minDateString;
        const startDateMax = endDateValue !== null ? ti.endDateRange : maxDateString;
        const endDateMin = startDateValue !== null ? ti.startDateRange : minDateString;
        const respectLeadingZeros = true;
        let startDatePicker =
            <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        value={moment(startDateValue)}
                        format={userPrefs.dateFormat}
                        onChange={(e) => handleDateChange(e, 'startDateRange')}
                        disabled={disabled}
                        minDate={moment(startDateMin, MOMENT_DATE_FORMAT)}
                        maxDate={moment(startDateMax, MOMENT_DATE_FORMAT)}
                        shouldRespectLeadingZeros={respectLeadingZeros}
                        slotProps={{textField: {fullWidth: true, error: false}}}
                        error={false}
                    />
                </LocalizationProvider>
                <FormHelperText>From Start Date</FormHelperText>
            </FormControl>
        ;
        let endDatePicker =
            <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        value={moment(endDateValue)}
                        format={userPrefs.dateFormat}
                        onChange={(e) => handleDateChange(e, 'endDateRange')}
                        disabled={disabled}
                        minDate={moment(endDateMin, MOMENT_DATE_FORMAT)}
                        maxDate={moment(endDateMax, MOMENT_DATE_FORMAT)}
                        slotProps={{textField: {fullWidth: true, error: false}}}
                        shouldRespectLeadingZeros={respectLeadingZeros}
                    /></LocalizationProvider>
                <FormHelperText>To End Date</FormHelperText>
            </FormControl>;
        //const startDatePicker = null;
        //const endDatePicker = null;
        dateRange = <span>{startDatePicker} {endDatePicker}</span>;
    }
    //Form Selectors
    let formSelectors = null;
    if (!isEmpty(tf.selectors)) {
        formSelectors = tf.selectors.map((set, key) => {
            //console.log(set.label, `(${set.variableName})`,'=>', ti[set.variableName]);
            return (
                <span key={key}>
					<DynamicSelector
                        key={key}
                        label={set.label}
                        variableName={set.variableName}
                        options={set.options}
                        onChange={(e) => handleChange(e)}
                        dataPending={disabled}
                        value={ti[set.variableName]}
                    /> </span>
            )
        })};
    //Build Form
    return (
        <Box
            autoComplete={false}
        >
            {searchBar} {dateRange} {formSelectors} {resetButton}
        </Box>
    )
}

const TableData=({tableData, headers, colSpan, RowComponent, rowFunctions, presetVariables})=>{
    if(RowComponent == null){
        return (
            <NoData key={0} colSpan={colSpan} cellText={'...Loading...'}/>
        )
    }
    if(tableData.length === 0){
        return (
            <NoData
                key={0}
                colSpan={colSpan}
                cellText={'Nothing Here'}
            />
        )
    }
    return (
        <React.Fragment>
            {
                Object.keys(tableData).map(key => {
                    return (
                        <RowComponent
                            key={key}
                            headers={headers}
                            rowData={tableData[key]}
                            rowFunctions={rowFunctions}
                            presetVariables={presetVariables}
                        />
                    );
                })
            }
        </React.Fragment>
    )
}


const DynamicDataTable=({
    formatURL, //URL TO RECEIVE JSON FOR FORMATTING TABLE
    presetVariables = {}, //PRESET VARIABLES ADDED TO SERVER REQUESTS
    rowFunctions, //Functions/methods passed to the dynamic rows
    loadData,
    dataLoaded, //function to execute after data is loaded
    serverData, //Table Data provided at the component level
    format, //Format provided through props

    cacheExpiration, //HOW LONG BEFORE CLEARING OUT DATA CACHE

    interfaceName, //CACHING INTERFACE NAME FOR TABLE
    storageKey, //CACHING LOCATION OF DATA

    sx, //mui sx for table component
    style, //any css supplied
    className, //any class supplied to table


})=>{


    const [styling, setStyling] = useState({paper: true});
    const [tableData, setTableData] = useState([]);
    const [tableFormat ,setTableFormat] = useState(init('tableFormat', format));
    const [tableInputs, setTableInputs] = useState(init('tableInputs', 25))
    const [tableRequests, setTableRequests] = useState(init('tableRequests'));
    const [pageData, setPageData] = useState(init('pageData'));
    const [presets, setPresets] = useState(JSON.stringify(presetVariables));


    const [manageDataClientSide, setManageDataClientSide] = useState(false);

    //REMOVE THIS: NO LONGER NECESSARY
    const [simplePagination, setSimplePagination] = useState({moreRowsAvailable: true, buttonText: 'Retrieve More Rows'});
    const [useSimplePagination, setUseSimplePagination] = useState(false);

    const validateDate=(date)=>{
        return date === '' || (date !== 'Invalid date' && date.substr(0,1) !== '0');
    }

    const debugWTF=(vars, caller)=>{
        if(false) {
            console.log('*******************************');
            console.log('CALLING FUNCTION', caller);
            console.log(vars);
            console.log('*******************************');
        }
    }

    const debugTableInput=(tableInput, caller)=>{
        if(false) {
            console.log('*******************************');
            console.log('CALLING FUNCTION', caller);
            console.log(tableInput);
            console.log('*******************************');
        }
        setTableInputs(tableInput);
    }


    //ComponentDidMount
    useEffect(()=>{
    //0. Check if the data already exists for this interface/table
        const lt = new LocalStorageTable(interfaceName, true);
        const tableState = lt.getTable(storageKey);
        if(!isEmpty(tableState)){
            //LOAD STRAIGHT FROM TABLE STORAGE - AVOID API CALL
            const {styling, tableData, tableFormat, tableInputs, tableRequests, pageData, manageDataClientSide} = tableState;
            setStyling(styling);
            setTableData(tableData);
            setTableFormat(tableFormat);
            debugTableInput(tableInputs, 'MOUNTING COMPONENT');
            setTableRequests(tableRequests);
            setPageData(pageData);
            setManageDataClientSide(manageDataClientSide);
            return;
        }
        //2. Check props passed: is urlLoader or a copy of tableFormat
        const getServerData = checkMakeServerRequests();
        const noHeaders = tableFormat.tableHeaders.length === 0;
        if(getServerData && noHeaders){
            initTable();
        } else{ //JSON
            //  B. if TableFormat, Load the tableFormat into the system and carry on as usual
            manageClientDataBehaviors();
        }
    },[])




    //getData on new change
    useEffect(()=>{
        //getData
        //const validDate = dateValue !== 'Invalid date' && dateValue.substr(0,1) !== '0'
        //Check Valid Date
        //Validate Dates
        if(checkMakeServerRequests()) {
            if (!tableInputs.modified) {
                //console.log('NOT MODIFIED');
                return;
            }
            debugWTF(tableInputs, 'USEEFFECT :: Table Inputs Modified');
            getData();
        } else{
            setTableData(serverData);
        }
    },[tableInputs, serverData]);


    //LoadData When called by parent Component
    useEffect(()=>{
        //console.log('UPDATING TABLE FROM PARENT');
        const contactServer = checkMakeServerRequests();
        let contacted = false;
        const currentPresets = JSON.stringify(presetVariables);
        const presetsChanged = presets !== currentPresets;
        const hasPresets = !isEmpty(presetVariables);
        if(contactServer){
            if(presetsChanged && hasPresets){
                debugWTF(currentPresets, 'USEEFFECT :: HAS PRESETS');
                getData(true);
                setPresets(currentPresets);
            } else if(presetsChanged && !hasPresets){
                debugWTF(currentPresets, 'USEEFFECT ::: NO PRESETS');
                //Presets removed. Re-initialize table
                initTable(true);
                setPresets(currentPresets);
            } else if(loadData === true){
                debugWTF(loadData, 'USEEFFECT :: LOAD DATA');
                getData(true);
            } else {
                //Do nothing
                return;
            }
        }
    },[loadData, presetVariables])


    //For searching
    const handleSearch=(value)=>{
        let ti = {...tableInputs};
        ti.searchCriteria = value;
        ti.modified = true;
        setTableInputs(ti);
    }
    const checkMakeServerRequests=()=>{
        return formatURL != null && formatURL !== '';
    }
    const setPage=(page)=>{
        if(tableRequests.dataPending){
            return; //Do nothing if waiting for response from server
        }
        //Update the state with the new page/pageInput
        let ti = {...tableInputs};
        ti.pageInput = parseInt(page); //Set the pageInput too
        debugTableInput(ti, 'SETPAGE');
    }
    const setDataLoaded=()=>{
        if(dataLoaded != null){
            //Data loaded function set
            dataLoaded();
        }
    }
    const handleChange=(e)=>{
        let {name, value} = e.target;
        let ti = {...tableInputs};
        ti[name] = value;
        ti.pageInput = 1;
        ti.modified = true;
        debugTableInput(ti, 'HANDLE CHANGE');
    }
    const handleDateChange=(e, variableName)=>{
        const dateValue = e === null ? '' : moment(e._d).format(MOMENT_DATE_FORMAT);
        let ti = {...tableInputs};
        ti[variableName] = dateValue;
        ti.modified = true;
        debugTableInput(ti, 'HANDLE DATE');
    }
    const setRowsPerPage=(e)=>{
        const rows = parseInt(e.target.value);
        localStorage.setItem(ROW_STORAGE_NAME, rows);
        //Newer
        let ti = {...tableInputs};
        ti.rowsPerPage = rows;
        ti.modified = true;
        debugTableInput(ti, 'SET ROWS PER PAGE');
    }

    const validateDates=()=>{
        let validStartDate = true;
        let validEndDate = true;
        //console.log('RUNNING');
        const {startDateRange, endDateRange} = tableInputs;
        if (tableFormat.dateRangeEnabled) {
            validStartDate = validateDate(startDateRange);
            validEndDate = validateDate(endDateRange);
            if (!validStartDate || !validEndDate) {
                //console.log('DATE DIED');
                return false;
            }
        }
        return true;
    }

    const manageClientDataBehaviors=()=>{
        if(checkMakeServerRequests()){
            //end. everything is server side
            return false;
        }

        //console.log('MANAGE CLIENT DATA BEHAVIORS');


        const test = true;

        //INIT Settings
        const tf = tableFormat;
        let ti = {...tableInputs};
        ti.sort = tf.defaultSort;
        ti.direction = tf.defaultDirection;
        ti.rowsPerPage = getRowsPerPage();
        let tr = {...tableRequests};
        tr.tableFormatLoaded = true;
        tr.dataPending = false;
        //Initialize the tableData - Perhaps this was loaded through props?
        let tableData = [];
        if(!tf.paginationEnabled && serverData != null && Object.keys(serverData).length >= 0){
            //Pagination is disabled (Receiving a complete dataset), table data received
            //Table Data sent through props
            tableData = serverData;
            //Automatically set the data loaded as its passed via props
            setDataLoaded();
        }
        //Set the state of the table
        setTableData(tableData);
        setTableRequests(tr);
        //console.log('TABLE DATA', tableData);
    }

    const resetTable=()=>{
        //0. Reset Search Criteria
        const {selectors} = tableFormat;
        //1. Initialize all table inputs
        let ti = {...tableInputs};
        Object.keys(ti).forEach(k => {
            //Set default reset value
            let resetValue = '';
            if(!isEmpty(selectors)){
                //determine if value being reset is a selector
                const filtered = selectors.filter(item => item.variableName === k);
                if(filtered.length > 0){
                    //selector found. apply first setting as reset value
                    //options[0][0] => optionsList[first Setting][key]
                    resetValue = filtered[0].options[0][0];
                }
            }
            ti[k] = resetValue;
        });
        ti.modified = true;
        //2. Reset Defaults
        const defaults = {
            ...ti,
            pageInput: 1,
            rowsPerPage: getRowsPerPage(),
            sort: tableFormat.defaultSort,
            searchCriteria: '',
            direction: tableFormat.defaultDirection
        };
        //3. Reset State

        debugTableInput(defaults, 'RESET TABLE');
        const lst = new LocalStorageTable(interfaceName);
        lst.clearTable(storageKey);
    }
    const buildQueryString=(keyValuePairs)=>{
        return Object.keys(keyValuePairs).map(e =>
            `&${e}=${keyValuePairs[e]}`
        ).join('');
    }
    const setSort=(e)=>{
        //1. Set the sort based on the tableHeader clicked
        if(tableRequests.dataPending || !tableFormat.sortEnabled){return;}
        const sort = e.currentTarget.dataset.sortType;
        let direction = 'up';
        let sortClassDirection = 'sortedUp';
        if(tableInputs.sort === sort){
            //2. change direction of current tableheader (same th clicked)
            //direction = tableInputs.direction === 'up' ? 'down' : 'up';
            if(tableInputs.direction === 'up'){
                direction = 'down';
                sortClassDirection = 'sortedDown';
            }
        }
        //3. update state
        let ti = {...tableInputs};
        ti.sort = sort;
        ti.direction = direction;
        ti.pageInput = 1; //Reset the page
        debugTableInput(ti, 'SET SORT()');
        //4. Modify the data/view
        if(tableFormat.paginationEnabled && manageDataClientSide === false){
            //4. Run getData()
            getData();
        } else{ //Pagination Disabled - Sort data using javascript
            //4. Sort Table Data based on column ????
            browserSort(sort, direction);
        }
        //5. Set The Class
        /*
        console.log('SETTING HEADERS');
        const headers = document.querySelectorAll(`#${tableFormat.tableID} th`);
        //A. Initialize All headers
        headers.forEach(header => {
                header.classList.remove('sortedUp','sortedDown');
            }
        );
        */
        //B. Set Header Class for the current sort
        e.currentTarget.classList.add(sortClassDirection);
    };
    const initTable=(resetStructure = false)=>{
        //Load rowsPerPage from localStorage
        const rowsPerPage = getRowsPerPage();
        //If urlLoader - go to the endPoint, get the tableFormat
        let url = formatURL;
        const requestStructureString = '&requestingTableStructure=1';
        url = tableRequests.tableFormatLoaded === false || resetStructure === true ? `${url}${requestStructureString}` : url;
        if(!isEmpty(presetVariables)){
            const keys = Object.keys(presetVariables);
            keys.forEach(k =>{
                url+=`&${k}=${presetVariables[k]}`
            });
        }
        const sc = new SecureConnect(url, tableFormat.method);
        sc.setDisplayNotifications(true);
        sc.setLogConsole(false);
        sc.setDisplaySuccessMessages(false);
        //console.log('----REQUESTING INIT -------', 'FORMAT LOADED?', tableRequests.tableFormatLoaded);
        sc.connect().then(json => {
            const data = sc.getTableStructure(json);
            if(data != null && Object.keys(data).length) {
                let ti = {...tableInputs};
                //console.log('---TABLE STRUCTURE', data);
                ti.sort = data.defaultSort;
                ti.direction = data.defaultDirection;
                ti.rowsPerPage = rowsPerPage; //setRowsPerPage
                //DateRangeEnabled
                if(data.dateRangeEnabled != null && data.dateRangeEnabled === true){
                    ti.startDateRange = '';
                    ti.endDateRange = '';
                }
                //Assign selectors to tableInputs
                if(!isEmpty(data.selectors)){
                    data.selectors.forEach(set => {
                        ti[set.variableName] = set.options[0][0];
                    })
                }
                let tr = {...tableRequests};
                tr.dataPending = false;
                tr.tableFormatLoaded = true;
                setTableFormat(data);
                debugTableInput(ti, 'MOUNTING 2');
                setTableRequests(tr);
                if(!isEmpty(sc.getData(json))){
                    processServerData(json, tr, data);
                }
                //data already retrieved. nothing else to do.
                //Minimize further api calls
            }
        });
    }
    const getData=(ignoreChecks = false, pending = tableRequests.dataPending)=>{
        const tf = tableFormat;
        const log = false;
        if(log) {
            console.log('GETTING DATA :: IGNORE CHECKS', ignoreChecks);
        }
        //URL must be set and response not be pending
        if(tf.url.length === 0 || pending){
            if(log){
                console.log('DYING: URL FAILED?',  tf.url.length === 0, 'PENDING?',pending);
            }

            return;
        }

        if(!ignoreChecks && !tableInputs.modified){
            //TABLE STRUCTURE PREVIOUSLY REQUESTED. PREVENT ANOTHER API CALL UNTIL PARAMS ARE CHANGED
            if(log) {
                console.log('NOT MODIFIED');
                console.log(tableInputs);
            }
            return;
        }

        const stateVarString = buildQueryString(tableInputs);
        const presets = presetVariables;
        let presetString = '';

        //PRESETS SET
        if(presets !== undefined && presets !== null){
            presetString =  buildQueryString(presets);
        }
        const formDataValues = `?action=${tf.action}${stateVarString}${presetString}`;
        const getURL = tf.url + formDataValues;

        let tr = {...tableRequests};
        tr.dataPending = true;
        //Set Request as Pending, turn off managing data client side
        setTableRequests(tr);
        setManageDataClientSide(false);
        const sc = new SecureConnect(getURL, tf.method);
        sc.setDisplayNotifications(true);
        sc.setDisplaySuccessMessages(false);

        //Set expiration if props set
        if(cacheExpiration && parseInt(cacheExpiration) > 0){
            sc.setCacheExpiration(cacheExpiration);
        }
        sc.connect().then(json => {
            processServerData(json);
        });

    }
    const processServerData=(json, tableRequest = null, receivedTableFormat = null)=>{
        let mdcs = manageDataClientSide;
        let simplePagination = {...simplePaginationStyle};
        let sc = new SecureConnect();
        const completed = sc.getCompleted(json);
        let tableData = completed ? sc.getData(json) : [];
        const hasData = !isEmpty(tableData);
        const noPageData = json.data == null || json.data.pageData == null;
        const pageData = useSimplePagination || hasData === false || noPageData  ? {} : json.data.pageData;
        const simplifiedPagination = hasData && json.data.hasOwnProperty('pageData') === false;
        if(simplifiedPagination){
            //Set pagination settings
            const hasMoreRows = !(tableData.length < tableInputs.rowsPerPage);
            simplePagination.moreRowsAvailable = hasMoreRows;
            simplePagination.buttonText = 'BUTTON TEXT';
            mdcs = hasMoreRows === false;
            //Apply Rows to table
            tableData = tableInputs.pageInput === 1 ? tableData : tableData.concat(tableData);
        }

        let tr = tableRequest != null ? tableRequest : {...tableRequests};
        const tf = receivedTableFormat != null ? receivedTableFormat : {...tableFormat}
        tr.dataPending = false;
        setTableRequests(tr);
        setTableData(tableData);
        setPageData(pageData);
        setManageDataClientSide(mdcs);
        setSimplePagination(simplePagination);
        setUseSimplePagination(simplifiedPagination);
        setDataLoaded();
        setTimeout(()=>{
            const lt = new LocalStorageTable(interfaceName);
            const state = {
                styling,
                tableData,
                tableFormat: tf,
                tableInputs,
                tableRequests: tr,
                pageData,
                manageDataClientSide,
                simplePagination,
                useSimplePagination
            }
            lt.storeTable(storageKey, state);
        }, delayMS);
    }
    const checkDisplayProgress=()=>{
        const {dataPending} = tableRequests;
        //Data is pending wait 800 milliseconds
        return dataPending === true && new Promise((wait) => {
            setTimeout(() => {
                wait(true);
            }, displayDelayMS);
        });
    }
    const browserSort=(sortColumn, direction)=>{
        const td = tableData;
        if(Object.keys(td).length === 0){
            //Nothing to sort - prevent further sorting
            return false;
        }
        const isDate = sortColumn.toLowerCase().includes('date');
        const sorted = td.sort(function(r1, r2){
            //Set default sort value based on sorting order
            let returned = direction === 'up' ? 1 : -1;
            //If not a date, sort via standard value. Otherwise convert to js date obj
            let v1 = !isDate ? r1[sortColumn] : convertDateStrToDateObj(r1[sortColumn]);
            let v2 = !isDate ? r2[sortColumn] : convertDateStrToDateObj(r2[sortColumn]);
            if(v1 > v2){
                return returned;
            }
            return returned*-1;
        });
        setTableData(sorted);
    }
    const setPageInput=(e)=>{
        //Gets called onblur of pageInput: set the page to the input value
        if(e != null){
            setPage(e.currentTarget.value);
        }
    }
    const setPreviousPage=()=>{
        //decrement page by 1 - cannot go beyond page 1
        let pageInput = parseInt(tableInputs.pageInput);
        const page  = pageInput > 1 ? pageInput-1 : 1;
        setPage(page);
    }
    const setNextPage=()=>{
        //Increment Page by 1 - cannot exceed max pages
        let pageInput = parseInt(tableInputs.pageInput);
        let totalPages = parseInt(pageData.totalPages);
        let page;
        if((useSimplePagination && simplePagination.moreRowsAvailable) || (useSimplePagination === false && pageInput < totalPages)){
            //Get the next page if more rows available (using simple pagination)
            //OR
            //Current page is less than total pages
            page = pageInput+1;
        } else if(useSimplePagination === false && pageInput >= totalPages){
            //Get the last page of total pages when not using simple pagination
            page = totalPages;
        } else{
            //default to first page
            page = 1;
        }
        setPage(page);
    }

    const renderPagination=()=>{
        const {tableFormatLoaded} = tableRequests;
        const {paginationEnabled} = tableFormat;
        let paginationSection;
        const pd = pageData;


        if(pageData == null){
            return null;
        }
        //console.log('PAGE DATA', pageData);

        const rowsPerPage = pd.rowsPerPage != null ? pd.rowsPerPage : 25;
        const totalRows = pd.totalRows != null ? pd.totalRows : isEmpty(tableData) ? 0 : tableData.length;
        const page = pd.currentPage != null ? pd.currentPage - 1 : 1;
        if(paginationEnabled && tableFormatLoaded) {
            paginationSection =
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100, 200]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Results"
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                        'onClick' : ()=>setPreviousPage()
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                        'onClick' : ()=>setNextPage()
                    }}
                    onPageChange={()=>setPageInput()}
                    onRowsPerPageChange={(e)=>setRowsPerPage(e)}
                />;
        }
        return paginationSection;
    }


    const {dataPending} = tableRequests;
    const pagination = !isEmpty(tableData) ? renderPagination() : null;
    //Styles

    const pendingStyle = dataPending ? {opacity: .25} : null;
    let propsStyle = style != null ? style : null;
    const tableStyles = paperStyles();
    const finalStyle = {...propsStyle, ...tableStyles.table};
    const simplePaginationStyle = {marginTop: getSpacing('small')};
    const displayProgress = checkDisplayProgress();


    //Table Column Labels <th>
    const {tableHeaders, sortEnabled} = tableFormat;
    const sortDirection = tableInputs.direction === 'up' ? 'asc' : 'desc';
    const sortColumn = tableInputs.sort;

    const RowComponent = tableFormat.rowComponent !== '' ? getRowComponent(tableFormat.rowComponent) : null;


    const overflowStyles = {
        display: 'block',
        overflow: 'scroll',
        overflowY: 'hidden',
        overflowX: 'auto',
        width: '100%',
        height: 'auto'
    }

    return (
        <div style={overflowStyles}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TableForm
                        buttonStyle={{position: 'relative', top: getSpacing('iconAlignment')}}
                        inputs={tableInputs}
                        format={tableFormat}
                        requests={tableRequests}
                        getData={()=>getData()}
                        handleChange={(e)=>handleChange(e)}
                        handleSearch={(value)=>(handleSearch(value))}
                        handleDateChange={(e, variableName)=>handleDateChange(e, variableName)}
                        searchText={tableInputs.searchCriteria}
                        resetTable={()=>resetTable()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Fade in={displayProgress}>
                        <LinearProgress color={'primary'}/>
                    </Fade>
                </Grid>
                {debugScreenSizes &&
                <Grid item xs={12}>
                    <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                        <div style={{background: '#000', color: '#FFF'}}>
                            XS
                        </div>
                    </Box>
                    <Box sx={{display: {xs: 'none', sm: 'block', md: 'none'}}}>
                        <div style={{background: '#f00', color: '#FFF'}}>
                            SM
                        </div>
                    </Box>
                    <Box sx={{display: {xs: 'none', md: 'block', lg: 'none'}}}>
                        <div style={{background: '#00f', color: '#FFF'}}>
                            MD
                        </div>
                    </Box>
                    <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                        <div style={{background: '#0A0', color: '#FFF'}}>
                            LG
                        </div>
                    </Box>
                </Grid>
                }
                <Grid item xs={12}>
                    <Table
                        style={finalStyle}
                        className={className}
                        sx={sx}
                    >
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, k) => {
                                    //console.log(k, 'HEADER', header);
                                    const {label, key, style, sx} = header;
                                    const cellSX = !isEmpty(sx) ? sx : {};
                                    const cellStyle = style !== '' ? {} : {};
                                    const debugStyle = debugScreenSizes ? {borderRight: '1px solid #00f'} : {};
                                    const finalStyle = {...cellStyle, ...debugStyle};
                                    if(debugScreenSizes) {
                                        console.log(label, 'FINAL STYLE', finalStyle);
                                        console.log(label, cellSX);
                                    }
                                    if(sortEnabled) {
                                        const headerString = typeof label === 'string';
                                        let headerElement = label;
                                        if(headerString){
                                            headerElement = <Tooltip
                                                title={`Sort By ${label}`}
                                                placement={'bottom-start'}
                                                enterDelay={300}
                                            >
                                                <span>
                                                <TableSortLabel
                                                    active={key === sortColumn}
                                                    direction={sortDirection}
                                                    onClick={(e)=>setSort(e)}
                                                    data-sort-type={key}
                                                > {label}
                                                </TableSortLabel>
                                                </span>
                                            </Tooltip>
                                        }
                                        //RETURN Sort Enabled Component
                                        return (
                                            <TableCell
                                                key={k}
                                                align="left"
                                                padding="default"
                                                sortDirection="asc"
                                                style={finalStyle}
                                                sx={cellSX}
                                            >
                                                {headerElement}
                                            </TableCell>
                                        )
                                    } else{
                                        //Sort disabled - still show the desc
                                        return (
                                            <TableCell
                                                key={k}
                                                align="left"
                                                padding="default"
                                                style={finalStyle}
                                                sx={cellSX}
                                            > {label}
                                            </TableCell>
                                        )
                                    }
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody style={pendingStyle}>
                            {
                            <TableData
                                headers={tableFormat.tableHeaders}
                                tableData={tableData}
                                colSpan={tableFormat.tableHeaders.length}
                                RowComponent={RowComponent}
                                rowFunctions={rowFunctions}
                                presetVariables={presetVariables}
                            />
                            }
                    </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Fade in={displayProgress && tableData.length > 20}>
                        <LinearProgress color={'primary'}/>
                    </Fade>
                    {pagination}
                    <div style={{paddingTop: getSpacing('small'), pointerEvents: 'none'}}>
                        <Collapse in={useSimplePagination === true}>
                            <Grid container spacing={2} direction={'row-reverse'}>
                                <Grid item xs={4}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={10} align={'right'}>
                                            <div style={simplePaginationStyle}>
                                                <Typography variant={'body1'}>
                                                    Displaying <strong>{tableData.length}</strong> Results
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    {/*Spacer*/}
                                </Grid>
                            </Grid>
                        </Collapse>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default DynamicDataTable;
