import React from 'react';
import {Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, useTheme} from "@mui/material";
import UserPreferences from "fit/system/UserPreferences";
import moment from 'moment';

const UserFormats=({unitType, dateFormat, timeFormat, numberFormat, onChange, headerVariant})=>{
    /*
    console.log('***************************** UserFormat.PROPS *****************************');
    console.log('DATE', dateFormat);
    console.log('NUMBER', numberFormat);
    console.log('TIME', timeFormat);
    console.log('UNIT', unitType);
    */

    const variant = headerVariant ? headerVariant : 'h3';
    const theme = useTheme();
    const secondaryColor = theme.palette.secondary.main;
    const userPrefs = new UserPreferences(secondaryColor);
    const {formats} = userPrefs;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={variant}>Measurement Units: Metric/Imperial</Typography>
                <FormControl>
                    <FormLabel>Measurement Unit Preferences</FormLabel>
                    <RadioGroup>
                        {formats.unitTypes.map((item,k) =>{
                            const checked = parseInt(unitType) === parseInt(item.id);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('unitType', item.id)}/>} label={item.label}/>
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <Typography variant={variant}>Date Formatting</Typography>
                    <FormLabel>Date Format</FormLabel>
                    <RadioGroup>
                        {formats.dateFormats.map((item,k) =>{
                            const checked = parseInt(dateFormat) === parseInt(item.id);
                            const date = moment().format(item.conversion);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('dateFormat', item.id)}/>} label={`${item.label}: ${date}`}/>
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={variant}>Time Formatting</Typography>
                <FormControl>
                    <FormLabel>Time Format</FormLabel>
                    <RadioGroup>
                        {formats.timeFormats.map((item,k) =>{
                            const checked = parseInt(timeFormat) === parseInt(item.id);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('timeFormat', item.id)}/>} label={item.label}/>
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <Typography variant={variant}>Number Formatting</Typography>
                    <FormLabel>Number Formatting</FormLabel>
                    <RadioGroup>
                        {formats.numberFormats.map((item,k) =>{
                            const checked = parseInt(numberFormat) === parseInt(item.id);
                            return <FormControlLabel key={k} control={<Radio checked={checked} onChange={()=>onChange('numberFormat', item.id)}/>} label={item.label}/>
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default UserFormats;