import React from 'react';
import {Grid, TextField, Checkbox, FormHelperText, FormControl, Typography, MenuItem} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {TYPOGRAPHY_BODY_STYLE, MOMENT_DATE_FORMAT} from "fit/system/FITConstants";
import UserPreferences from "fit/system/UserPreferences";

const PersonalAthleteInformation=({DOB, ignoreDOB, genderAtBirth, gender, onChange, errors, touched, birthGenderLabels = [], genderLabels = [],})=>{
    const maxDate = moment().subtract(13, 'year').format(MOMENT_DATE_FORMAT); //13 Years old
    const minDate = moment().subtract(100, 'year').format(MOMENT_DATE_FORMAT); //100 year old

    const DOBError = errors && errors.DOB ? errors.DOB : null;
    const DOBTouch = touched && touched.DOB ? touched.DOB : null;

    const handleDate=(e)=>{
        console.log(e);
        const date = moment(e._d).format(MOMENT_DATE_FORMAT);
        onChange('DOB', date);
    }
    const sx = {minWidth: '245px'};
    const userPrefs = new UserPreferences();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={'body1'} sx={TYPOGRAPHY_BODY_STYLE}>
                    Buteo utilizes date of birth and gender data to correlate you to similar athletes and their abilities.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            fullWidth
                            format={'MM-DD-YYYY'}
                            disableFuture={true}
                            value={moment(DOB)}
                            label={'Date of Birth'}
                            onChange={(e)=>handleDate(e)}
                            disabled={ignoreDOB === true}
                            minDate={moment(minDate, MOMENT_DATE_FORMAT)}
                            maxDate={moment(maxDate, MOMENT_DATE_FORMAT)}
                            slotProps={{ textField: { fullWidth: true, error:DOBError && DOBTouch, helperText: DOBError || ' '}}}
                            shouldRespectLeadingZeros={true}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    select
                    sx={sx}
                    label={'Gender Assigned at Birth'}
                    value={genderAtBirth}
                    onChange={(e)=>onChange('genderAtBirth', e.target.value)}
                    helperText={' '}
                >
                    <MenuItem value={0}>Unspecified</MenuItem>
                    {
                        birthGenderLabels.map((item, key)=>{
                            return (
                                <MenuItem key={key} value={item.lv_ID}>{item.label}</MenuItem>
                            )
                        })
                    }

                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    select
                    sx={sx}
                    label={'Gender'}
                    value={gender}
                    onChange={(e)=>onChange('genderLabelID', e.target.value)}
                    helperText={' '}
                >
                    <MenuItem value={0}>Unspecified</MenuItem>
                    {
                        genderLabels.map((item, key)=>{
                            return (
                                <MenuItem key={key} value={item.lv_ID}>{item.label}</MenuItem>
                            )
                        })
                    }
                </TextField>
            </Grid>
        </Grid>
    )
}

export default PersonalAthleteInformation;