import React from 'react';
import Link from 'fit/components/System/Link';
import {Button, Paper} from '@mui/material'
import DynamicDataTable from "fit/components/DynamicDataTable";
import {PAPER_STYLE, URL_TEAM_BLOCKS, URL_CREATE} from "fit/system/FITConstants";
import {Add} from '@mui/icons-material';
import {setPageTitle, trimURL} from "fit/system/UtilityFunctions";
import Header from 'fit/components/Header/';


class WorkoutBlockList extends React.Component{
    state={
        loadData: true
    }
    setDataLoaded(){
        this.setState({loadData: false});
    }
    render(){
        setPageTitle('Workout Blocks');
        return(
            <div>
                <Header header={'Workout Blocks'}>
                    <Link to={`${URL_TEAM_BLOCKS}${trimURL(URL_CREATE)}`}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                        ><Add/> Create Block</Button>
                    </Link>
                </Header>
                <Paper style={PAPER_STYLE}>
                    <DynamicDataTable
                        title={'Workout Blocks Table'}
                        formatURL={'workout.php?action=listWorkoutBlocks'}
                        loadData={this.state.loadData}
                        dataLoaded={this.setDataLoaded.bind(this)}
                    />
                </Paper>
            </div>
        );
    }
}

export default WorkoutBlockList;