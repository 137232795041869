import React from 'react';
import {useState} from "react";
import {Paper,IconButton} from '@mui/material'
import DynamicDataTable from "fit/components/DynamicDataTable";
import FullscreenDialog from "fit/components/Dialogs/FullscreenDialog";
import {Tooltip} from "@mui/material";
import {setPageTitle} from "fit/system/UtilityFunctions";
import {PAPER_STYLE, DEVICE_CONSTANTS} from "fit/system/FITConstants";
import Header from 'fit/components/Header/';
import {Refresh} from "@mui/icons-material";
import DeviceSelectionInterface from "../../Devices/DeviceSelectionInterface";

const initDeviceModal=()=>{
    return {
        display: false,
        action: DEVICE_CONSTANTS.NO_ACTION,
        workoutID: DEVICE_CONSTANTS.noWorkoutID,
        teamID: DEVICE_CONSTANTS.noWorkoutID,
        selectionTab: DEVICE_CONSTANTS.availableDevices
    }
}

const WorkoutsList=({getToday})=>{
    const {availableDevices, assignedDevices, PUSH_ACTION, PULL_ACTION, MANAGE_ACTION} = DEVICE_CONSTANTS;
    const [refreshWorkouts, setRefreshWorkouts] = useState(true);
    const [reloadDeviceList, setReloadDeviceList] = useState(false);
    const [deviceModal, setDeviceModal] = useState(initDeviceModal);

    const updateDeviceModal=(workoutID, teamID, action)=>{
        const tab = action === PUSH_ACTION ? availableDevices : assignedDevices;
        console.log('UPDATE DEVICE MODAL', workoutID, teamID, action);
        setDeviceModal({
            display: true,
            workoutID: parseInt(workoutID),
            teamID: parseInt(teamID),
            action,
            selectionTab: tab
        })
    }

    const completeOperation=()=>{
        //Workouts pushed/pulled/managed etc
        //Reload workouts
        console.log('COMPLETING OPERATION');
        setRefreshWorkouts(true);
        //Close Modal
        setDeviceModal(initDeviceModal);
    }

    const pushing = deviceModal.action === PUSH_ACTION;

    getToday = getToday === true;
    const listPresets = getToday ? {getToday: 1} : {};
    const title = getToday ? "Today's Workouts" : 'Workouts';
    setPageTitle(title);
    return(
        <div>
            <Header header={'Workouts'}/>
            <Paper style={PAPER_STYLE}>
                <DynamicDataTable
                    title={'Workouts Table'}
                    formatURL={'workout.php?action=listWorkouts'}
                    loadData={refreshWorkouts}
                    dataLoaded={()=>setRefreshWorkouts(false)}
                    rowFunctions={{
                        prepPush: (workoutID, teamID)=>updateDeviceModal(workoutID, teamID, PUSH_ACTION),
                        prepManage: (workoutID, teamID)=>updateDeviceModal(workoutID, teamID, MANAGE_ACTION)
                    }}
                    presetVariables={listPresets}
                />
            </Paper>
            <FullscreenDialog
                open={deviceModal.display}
                title={pushing ? 'Activate Workout' : 'Manage Workout'}
                closeFunction={()=>setDeviceModal(initDeviceModal)}
                size={'lg'}
            >
                <div>
                    <Header header={
                        <span>
                            Assign Your Workout
                            <Tooltip title={'Refresh Device List'}>
                               <IconButton color={'primary'} onClick={()=>setReloadDeviceList(true)}><Refresh/></IconButton>
                            </Tooltip>
                        </span>
                    }/>
                    {/*
                    <br/>WORKOUT_ID: {deviceModal.workoutID}
                    <br/>TEAMID: {deviceModal.teamID}
                    <br/>SELECTION TAB: {deviceModal.selectionTab}
                    <br/>RELOAD :{reloadDeviceList ? 1 : 0}*/}
                    <Paper style={PAPER_STYLE}>
                        <DeviceSelectionInterface
                            action={deviceModal.action}
                            workoutID={deviceModal.workoutID}
                            teamID={deviceModal.teamID}
                            closeOnCompletion={true}
                            displayInactive={false}
                            selectionTab={deviceModal.selectionTab}
                            reloadDevices={reloadDeviceList}
                            reloadCompletedFunction={()=>setReloadDeviceList(false)}
                            completeFunction={()=>completeOperation()}
                        />
                    </Paper>
                </div>
            </FullscreenDialog>
        </div>
    );
}

export default WorkoutsList;