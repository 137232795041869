import React from 'react';

import {AccordionSummary, AccordionDetails, Accordion, Typography, useTheme} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {getSpacing, getHighlightStyle} from "fit/system/UtilityFunctions";


const PreferencesAccordion=({highlight = false, defaultExpanded, icon, summaryText, secondaryText, children, disabled = false, style, sx})=>{

    //Setting the highlight for the accordion
    const theme = useTheme();
    const fullStyle = highlight ? {...style, ...getHighlightStyle(theme)} : style;
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            slotProps={{transition: {unmountOnExit: true}}}
            disabled={disabled}
            style={fullStyle}
            sx={sx}
        >
            <AccordionSummary expandIcon={<ExpandMore/>}>
                {icon}
                <Typography>{summaryText}</Typography>
                <Typography sx={{marginLeft: getSpacing()}}>{secondaryText}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default PreferencesAccordion;