import React from 'react';
import JumboThemeSidebarContext from "./JumboThemeSidebarContext";
import {createTheme} from "@mui/material/styles";

const JumboThemeSidebar = ({children, init, themeColorID}) => {
    const [sidebarTheme, setSidebarTheme] = React.useState(init);
    const themeSidebarContextValue = React.useMemo(() => {
        if(false) {
            console.log('**** < JUMBO THEME SIDEBAR > **********')
            console.log('**********UPDATING THEME***************');
            console.log('**********UPDATING THEME***************');
            console.log('**********UPDATING THEME***************');
            console.log('**********UPDATING THEME***************');
            console.log(' ');
            //console.log(init.palette.name);
            console.log(' ');
            console.log('**********UPDATING THEME***************');
            console.log('**********UPDATING THEME***************');
            console.log('**********UPDATING THEME***************');
            console.log('**********UPDATING THEME***************');
        }
        return {
            sidebarTheme: createTheme(sidebarTheme),
            setSidebarTheme: setSidebarTheme,
        }
    }, [sidebarTheme, setSidebarTheme, themeColorID]);

    return (
        <JumboThemeSidebarContext.Provider value={themeSidebarContextValue}>
            {children}
        </JumboThemeSidebarContext.Provider>
    );
};

export default JumboThemeSidebar;