import React, {useState, useEffect} from 'react'
import {Grid, Typography, Paper, Button} from "@mui/material";
import {FormLabel, FormControl, FormControlLabel, RadioGroup, Radio, TextField, MenuItem, FormHelperText, Collapse} from "@mui/material";
import {Cancel, Add, DeleteForever} from "@mui/icons-material";
import DynamicDataTable from "../DynamicDataTable";
import {ATHLETE_ASSIGNMENT_TYPES, OPERATIONS_LEVEL, PAPER_STYLE} from "fit/system/FITConstants";
import Modal from 'fit/components/Dialogs/Modal';
import Alert from "../Alert";
import User from 'fit/system/User';
import SecureConnect from "fit/system/SecureConnect";
import * as yup from "yup";
import {Form, Formik} from "formik";
import {getSpacing} from "fit/system/UtilityFunctions";
import Header from 'fit/components/Header/';

const initSquadData =()=>{
    const user = new User;
    const teams =  user.getAthleticTeams()
    const teamID = teams.length === 1 ? teams[0].sectionID : null;

    return {
        display: false,
        active: 1,
        athleteHidden: 0,
        groupTypeID: null,
        id: null,
        ownerName: '',
        shared: 1,
        shortLabel: '',
        teamID: teamID,
        teamName: '',
        title: '',
        userID: null,
        workoutCount: 0,
        workoutPendingCompletion: 0

    }
}

const squadValidationSchema = {
    title: yup.string()
        .trim()
        .min(3, 'Minimum of 3 characters required')
        .max(30, 'Cannot exceed 30 characters')
        .required('Title required'),
    teamID: yup.string()
        .typeError('Team required')
        .required('Team required'),
    active: yup.number()
        .test(
            'activeBoolCheck',
            'Status Required',
            (value) => [0,1].includes(parseInt(value))
        )
        .typeError('Status required')
        .required('Status required'),
    athleteHidden: yup.number()
        .test(
            'visibleBoolCheck',
            'Visibility Required',
            (value) => [0,1].includes(parseInt(value))
        )
        .typeError('Visibility required')
        .required('Visibility required')
}

const SquadManagerTable=()=>{

    const [loadData, setLoadData] = useState(true);
    const [squadData, setSquadData] = useState(initSquadData);
    const [displayDeletionConfirmation, setDisplayDeletionConfirmation] = useState(false);
    useEffect(()=>{



    },[])


    const loadSquad=(rowData)=>{
        console.log('LOADING SQUAD', rowData);
        let squadData = {...rowData};
        squadData.display = true;
        setSquadData(squadData);
    }

    const getAthleticTeams=()=>{
        const user = new User();
        return user.getAthleticTeams();
    }
    const getTeamCount=()=>{
        return getAthleticTeams().length;
    }
    const openModal=()=>{
        let squad = {...squadData};
        squad.display = true;
        setSquadData(squad);
    }
    const openDeletionModal=(squadData)=>{
        setSquadData(squadData);
        setDisplayDeletionConfirmation(true);
    }
    const deleteSquad=()=>{
        let sc = new SecureConnect('team.php', 'post');
        sc.setAction('deleteSquad');
        sc.setFormData(squadData);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                clearSquadModal();
                setLoadData(true);
            }
        })
    }

    const modifySquad=(e)=>{
        const {name, value} = e.target;
        let squad = {...squadData};
        squad[name] = value;
        setSquadData(squad);
    }
    const handleRadio=(name, value)=>{
        let squad = {...squadData};
        squad[name] = value;
        setSquadData(squad);
    }
    const renderDeleteButton=()=>{
        return null;
    }
    const renderTeamSelector=(error, touched, noAccess)=>{
        const teams = getAthleticTeams();


        if(squadData.id !== null ||  teams.length === 1){
            //Team already selected or the coach is only assigned to one team.
            //Provide a textfield that's predetermined
            //Pull TeamName off Squad Data or pull from the single team permission
            const disabled = noAccess || parseInt(squadData.groupTypeID) === ATHLETE_ASSIGNMENT_TYPES.inactive;
            const value = squadData.id != null ? squadData.teamName : `${teams[0].orgShortName} ${teams[0].label}`;
            return (
                <TextField
                    fullWidth
                    label={'Team'}
                    value={value}
                    inputProps={{readOnly: true}}
                    disabled={disabled}
                />
            )
        } else{
            return (
                <TextField
                    fullWidth
                    select
                    label={'Team'}
                    name={'teamID'}
                    value={squadData.teamID}
                    onChange={(e)=>modifySquad(e)}
                    error={error && touched}
                    helperText={error || ' '}

                >
                    {teams.map((team, k)=>{
                        return (
                            <MenuItem key={k} value={team.sectionID}>{team.orgShortName} {team.label}</MenuItem>
                        )
                    })}
                </TextField>
            )
        }
    }
    const clearSquadModal=()=>{
        setSquadData(initSquadData);
        setDisplayDeletionConfirmation(false);
    }
    const submitSquad=()=>{
        let sc = new SecureConnect('team.php','post');
        const action = squadData.id !== null ? 'editSquad' : 'createSquad';
        sc.setAction('submitSquad');
        sc.setFormData(squadData);
        sc.connect().then(json=>{
           if(sc.getCompleted(json)){
               //COMPLETED - CLOSE MODAl, RELOAD TABLE
               clearSquadModal();
               setLoadData(true);
           }
        });
    }

    const teamCount= getTeamCount();
    const closeFn = ()=>clearSquadModal();
    const inactiveListSelected = parseInt(squadData.groupTypeID) === ATHLETE_ASSIGNMENT_TYPES.inactive;
    const workoutsPending = parseInt(squadData.workoutPendingCompletion) > 0;
    const multipleWorkouts = parseInt(squadData.workoutCount) > 1;
    const headerAlertStyle = {marginBottom: getSpacing()};

    const user = new User();
    const noAccess = !user.isTeamSportSpecificCoach(7, OPERATIONS_LEVEL);

    let modalTitle;
    if(squadData.id === null){
        modalTitle = 'Create Squad';
    } else if(noAccess){
        modalTitle = `View ${squadData.title}`
    } else {
        modalTitle =  inactiveListSelected ? 'Inactive List Selected' : `Update ${squadData.title}`;
    }




    return (
        <div>
            <Header header={'Create/Manage Squads'}>
                <Button
                    color={'primary'}
                    disabled={teamCount <= 0}
                    variant={'contained'}
                    onClick={()=>openModal()}>
                    <Add/> Create Squad
                </Button>
            </Header>
            <Paper style={PAPER_STYLE}>
                <DynamicDataTable
                    title={'Squad Management'}
                    formatURL={'team.php?action=listSquad'}
                    loadData={loadData}
                    dataLoaded={()=>setLoadData(false)}
                    rowFunctions={{
                        loadSquad: (squadData)=>loadSquad(squadData),
                        deleteSquad: (squadData)=>openDeletionModal(squadData)
                    }}
                />
            </Paper>

            <Modal
                open={displayDeletionConfirmation}
                title={'Confirm Deletion'}
                closeFunction={()=>clearSquadModal()}
                modalActions={
                        <Button
                            color={'secondary'}
                            variant={'contained'}
                            onClick={()=>clearSquadModal()}
                        ><Cancel/> Cancel</Button>
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert
                            visible={true}
                            status={'error'}
                            headline={`Deleting "${squadData.title}"`}
                            message={`This action is irreversible. Are you sure?`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        You are about to delete <strong>"{squadData.title}"</strong> and all player assignments. <strong>Are you sure?</strong>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={()=>deleteSquad()}
                                ><DeleteForever/> Delete IT</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    color={'secondary'}
                                    variant={'contained'}
                                    onClick={()=>clearSquadModal()}
                                >Nevermind</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                open={squadData.display}
                title={modalTitle}
                closeFunction={closeFn}
                modalActions={
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={closeFn}
                    ><Cancel/> Cancel</Button>
                }
            >
                <Formik
                    initialValues={squadData}
                    validationSchema={yup.object().shape(squadValidationSchema)}
                    onSubmit={()=>submitSquad()}
                    enableReinitialize={true}
                >
                {({errors, touched}) => (
                <Form>
                <Alert
                    status={'info'}
                    headline={'Inactive List Explanation'}
                    visible={inactiveListSelected}
                    style={headerAlertStyle}
                >
                    <Typography variant={'body1'}>
                        The Inactive List tells the system to not include (calculate) a player's statistics in position, squad, or team averages. Should a player get injured or go on hiatus, the inactive list enables coaches to get an accurate view of team performance with the player's absence.
                    </Typography>
                </Alert>
                <Alert
                    status={'warning'}
                    visible={noAccess && !inactiveListSelected}
                    headline={'Access Limited'}
                    message={'You currently do not have permission to modify squads. Only head coaches or operations may manage these settings.'}
                    style={headerAlertStyle}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            size={'large'}
                            label={'Title'}
                            name={'title'}
                            value={squadData.title}
                            disabled={inactiveListSelected || noAccess}
                            placeholder={"What's the name of your squad/group?"}
                            error={errors.title && touched.title }
                            helperText={errors.title || ' '}
                            onChange={(e)=>modifySquad(e)}
                        />
                    </Grid>
                    {renderDeleteButton()}
                    <Grid item xs={12}>
                        {renderTeamSelector(errors.teamID, touched.teamID, noAccess)}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl error={errors.active}>
                            <FormLabel>Status</FormLabel>
                            <RadioGroup
                                row
                                value={squadData.active}
                            >
                                <FormControlLabel value={1} label={'Active'} control={<Radio onChange={(e)=>handleRadio('active',1)} disabled={workoutsPending || inactiveListSelected || noAccess}/>}/>
                                <FormControlLabel value={0} label={'Inactive/Disabled'} control={<Radio onChange={(e)=>handleRadio('active', 0)} disabled={workoutsPending || inactiveListSelected || noAccess}/>}/>
                            </RadioGroup>
                            <FormHelperText>{errors.active || ' '}</FormHelperText>
                        </FormControl>
                        <Alert
                            status={'error'}
                            visible={workoutsPending}
                            headline={'Squad Must Remain Active: Active Workouts Exist'}
                            message={`The ${multipleWorkouts ? 'are' : 'is'} ${squadData.workoutCount} ${multipleWorkouts ? 'workouts' : 'workout'}  pending completion. ${multipleWorkouts ? 'They' : 'It'} must be completed or deleted before the squad can be rendered inactive.`}
                        />
                        <Alert
                            status={'warning'}
                            visible={parseInt(squadData.active) === 0}
                            headline={'Deactivated!'}
                            message={`${squadData.title.length > 0 ? `"${squadData.title}"` : 'This Squad'} will be disabled. No athletes or workouts can be assigned to the squad.`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl error={errors.athleteHidden}>
                            <FormLabel>Athlete Visibility</FormLabel>
                            <RadioGroup
                                row
                                value={squadData.athleteHidden}
                            >
                                <FormControlLabel value={0} label={'Visible'} control={<Radio onChange={(e)=>handleRadio('athleteHidden',0)} disabled={inactiveListSelected || noAccess}/>}/>
                                <FormControlLabel value={1} label={'Hidden From Athletes'} control={<Radio onChange={(e)=>handleRadio('athleteHidden',1)} disabled={inactiveListSelected || noAccess}/>}/>
                            </RadioGroup>
                            <FormHelperText>{errors.athleteHidden || ' '}</FormHelperText>
                        </FormControl>
                        <Alert
                            status={'warning'}
                            visible={parseInt(squadData.athleteHidden) === 1}
                            headline={'Hidden From Athletes'}
                            message={`${squadData.title.length > 0 ? `"${squadData.title}"` : 'This Squad'} will be hidden from athletes. They will be unaware they\'re assigned to this squad.`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Alert
                            status={'warning'}
                            visible={inactiveListSelected}
                            headline={'This Squad Cannot Be Updated Or Modified'}
                            message={'This is a system standardized Inactive List. It cannot be modified'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            type={'submit'}
                            color={'primary'}
                            variant={'contained'}
                            disabled={inactiveListSelected || noAccess}
                        >{modalTitle}</Button>
                    </Grid>
                </Grid>
                </Form>
                )}
                </Formik>
            </Modal>



        </div>
    )

}

export default SquadManagerTable;