import React from 'react';
import {LinearProgress, Paper} from "@mui/material";
import Div from "@jumbo/shared/Div";
import TabSection from "../TabSection";

const ButeoSuspenseFallback=()=>{
    return (
        <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                height: '100%',
            }}
        >
            <Paper sx={{width: '100%'}}>
                <TabSection>
                    <p>_BUTEO_SUSPENSE_HERE</p>
                </TabSection>
            </Paper>

            <LinearProgress sx={{m: '-40px auto 0'}}/>
        </Div>
    )
}

export default ButeoSuspenseFallback;