import React,{memo} from 'react';
import {
    ASSISTANT_LEVEL,
    DEVICE_CONSTANTS, OPERATIONS_LEVEL,
    URL_PROFILES,
    URL_TEAMS, URL_DEVICES
} from "fit/system/FITConstants";
import User from "fit/system/User";
import Link from "fit/components/System/Link";
import UserLink from "../Links/UserLink";
import {createWorkoutTitle} from "fit/system/UtilityFunctions";
import DeviceStatus from "fit/components/Devices/DeviceStatus";
import DeviceWorkoutStatus from "fit/components/Devices/DeviceWorkoutStatus";
import {TableRow, TableCell, Tooltip, Checkbox} from "@mui/material";
import {TeamLink} from "../Links";
const fadedOpacity = .55;

export const DeviceSelectionRow=({action, activeWorkoutID, teamID, pushList, preList, pullList, deviceData, toggleSelectedDevice})=> {
    const {NO_ACTION, PULL_ACTION, PUSH_ACTION, MANAGE_ACTION, noWorkoutID} = DEVICE_CONSTANTS;
    const d = deviceData;
    teamID = parseInt(teamID);
    const user = new User();
    const getChecked=()=>{
        const deviceID = parseInt(d.userID);
        let checked;
        let color = 'primary';
        let assignPull = false;
        if(action === PULL_ACTION){
            checked = pullList.includes(deviceID);
        } else if(action === PUSH_ACTION){
            checked = pushList.includes(deviceID);
        } else if(action === MANAGE_ACTION){
            const inPush = pushList.includes(deviceID);
            const inPull = pullList.includes(deviceID);
            const inPre = preList.includes(deviceID);
            color = inPre ? 'secondary' : 'primary';
            assignPull = inPre;
            //The item is checked if in the push list OR in one of the other lists
            //
            checked = inPush || (inPull && !inPre) || (inPre && !inPull);
        } else {
            //NO ACTION
            checked = false;
        }
        return {checked, color, assignPull};
    }
    const getCurrentlyAssigned=()=>{
        return parseInt(d.workoutAssigned) === 1;
    }
    const getPersonalWorkout=()=>{
        const {personalWorkoutUserID, workoutAssigned} = d;
        return parseInt(workoutAssigned) === 1 && personalWorkoutUserID !== '' && parseInt(personalWorkoutUserID) > 0;
    }
    const getTeamText=()=>{
        const access = user.managerTeamLevel(d.teamID, ASSISTANT_LEVEL);
        //console.log(d.teamID, access);
        const {teamName, orgShortName, personalWorkoutUserName} = d;
        const currentlyAssigned = getCurrentlyAssigned();
        const personalWorkout = getPersonalWorkout();
        let athlete;
        if(personalWorkout && access){
            //Can access the athlete's profile
            athlete = <UserLink name={personalWorkoutUserName} urlKey={d.personalWorkoutUserKey}/>
        } else if(personalWorkout && !access){
            athlete = personalWorkoutUserName;
        } else{
            athlete = null;
        }
        let team;
        if(currentlyAssigned && access){
            team = <TeamLink org={orgShortName} name={teamName} urlKey={d.teamKey}/>
        } else if(currentlyAssigned && !access){
            team = teamName;
        } else{
            team = '-';
        }
        if(currentlyAssigned && personalWorkout){
            return <span>{team} ({athlete})</span>
        } else if(currentlyAssigned && !personalWorkout){
            return team;
        } else{
            return '-';
        }
    }
    //console.log('DEVICE DATA', d);
    const {deviceName, blockTitle, ownerName, dayIndex, ownerKey, teams} = d;
    const workoutID = parseInt(d.workoutID);
    const currentlyAssigned = getCurrentlyAssigned();
    const workoutName = currentlyAssigned ? createWorkoutTitle(blockTitle, dayIndex) : '-';
    const teamText = getTeamText();
    const coachName = currentlyAssigned ? <UserLink urlKey={ownerKey} name={ownerName}/> : '-';
    const settingWorkout = (action === MANAGE_ACTION || action === PUSH_ACTION);

    const adminTeams = user.getTeamList(OPERATIONS_LEVEL);


    const deptAccess = user.getSpecificDepts([parseInt(d.deptID)], OPERATIONS_LEVEL).length > 0;
    const teamAccess = adminTeams.filter(t=> teams.includes(t.sectionID)).length > 0

    const hasMasterAccess = deptAccess && teamAccess;

    //console.log(deviceName, 'MASTER',hasMasterAccess, d.deptID, 'DEPT ACCESS', deptAccess, 'TEAM ACESS', teamAccess, 'TEAMID', teamID);

    const noWorkoutSelected = activeWorkoutID === noWorkoutID;
    const accountAssigned = parseInt(d.deviceAssigned) === 1;
    const {checked, color, assignPull} = getChecked();
    const noWorkoutToPull = !currentlyAssigned && action === PULL_ACTION;
    const workoutRequired = settingWorkout && noWorkoutSelected
    const pushingAlreadyAssigned = settingWorkout && currentlyAssigned && activeWorkoutID !== workoutID;
    const teamActive = noWorkoutSelected || (!noWorkoutSelected && settingWorkout && teams.includes(teamID));
    const disabled = !accountAssigned || action === NO_ACTION || noWorkoutToPull || workoutRequired || !teamActive || pushingAlreadyAssigned;
    const faded = noWorkoutToPull || !accountAssigned || workoutRequired || !teamActive || pushingAlreadyAssigned;
    //Tooltip message
    let message;
    if(disabled){
        if(noWorkoutToPull){
            message = 'Disabled: No workout to pull';
        } else if(!accountAssigned){
            message = 'Disabled: Account inactive';
        } else if(!teamActive){
            message = 'Disabled: Device not permitted to access the team'
        } else if(pushingAlreadyAssigned){
            message = 'Disabled: Device already assigned'
        } else if(workoutRequired){
            message = 'Disabled: Workout selection required';
        } else {
            message = 'Disabled: Select action/workout first';
        }
    } else if(checked && action === MANAGE_ACTION && activeWorkoutID === workoutID){
        message = `Remove workout from ${deviceName}`;
    } else{
        message = `Select ${deviceName}`
    }

    const opacity = !faded ? 1: fadedOpacity;
    const fadeStyle = {opacity};

    //console.log('------------------------------------------------');
    //console.log('< NEW DEVICE SELECTION LIST >', d);
    //console.log(action, ':: CHECKED/COLOR', checked, color);
    //console.log('ACTIVE_W/WorkoutID', activeWorkoutID, workoutID);

    const deviceText = hasMasterAccess ? <Link to={`${URL_DEVICES}admin/manage/${d.masterURLKey}/${d.deviceKey}/`}>{deviceName}</Link> : deviceName;


    return (
        <TableRow hover>
            <TableCell>
                <Tooltip title={message} placement={'right'}>
					<span>
					<Checkbox
                        onChange={()=>toggleSelectedDevice(assignPull)}
                        checked={checked}
                        disabled={disabled}
                        color={color}
                    />
					</span>
                </Tooltip>
            </TableCell>
            <TableCell style={fadeStyle}>{deviceText}</TableCell>
            <TableCell>
                <DeviceStatus assignedStatus={accountAssigned}/>
            </TableCell>
            <TableCell style={fadeStyle}>
                <DeviceWorkoutStatus
                    active={accountAssigned}
                    assignedWorkout={currentlyAssigned}
                    personalWorkout={getPersonalWorkout()}
                    screenValue={d.screen}
                    updated={d.updated}
                />
            </TableCell>
            <TableCell style={fadeStyle}>{teamText}</TableCell>
            <TableCell style={fadeStyle}>{workoutName}</TableCell>
            <TableCell style={fadeStyle}>{coachName}</TableCell>
        </TableRow>
    )
}


export default memo(DeviceSelectionRow);