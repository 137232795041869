import React from 'react';
import {
    Button,
    Collapse,
    Grid, IconButton, InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {PAPER_STYLE_SMALL} from "fit/system/FITConstants";
import {getSpacing, getStatusColor} from "fit/system/UtilityFunctions";
import Alert from "fit/components/Alert/";
import Link from "../Link";
import {Block, CheckCircle, Visibility, VisibilityOff} from "@mui/icons-material";
import PasswordChecks from "./PasswordChecks";

const ResetForm=({disabled, form, handleChange, passwordChecks, passwordVisible, profileReset, requested, resetCompleted, resetPassword, serverResponse, toggleVisibility})=>{
    const {originalPassword, password, passwordMatch} = form;
    const {status, message, headline} = serverResponse;
    const type = passwordVisible ? 'text' : 'password';
    const title = passwordVisible ? 'Hide Password' : 'Show Password';
    const icon = passwordVisible ? <VisibilityOff/> : <Visibility/>;
    const pc = passwordChecks;

    const viewButton =
        <InputAdornment position={'end'}>
            <Tooltip title={title} enterDelay={300} placement={'bottom-start'}>
                <IconButton color={'primary'} onClick={()=>toggleVisibility()}>
                    {icon}
                </IconButton>
            </Tooltip>
        </InputAdornment>;

    let currentPassword = null;
    const originalPasswordReq = profileReset === false || originalPassword.length > 0;
    const checksFailed = pc.minChars === false || pc.number === false || pc.capital === false || pc.lower === false || pc.special === false || pc.match === false || originalPasswordReq === false;

    if(profileReset){
        currentPassword = <Grid item xs={12}>
            <TextField
                fullWidth
                type={type}
                label={'Your Current Password'}
                value={originalPassword}
                name={'originalPassword'}
                onChange={(e)=>handleChange(e)}
                InputProps={{
                    endAdornment: viewButton,
                }}
            />
        </Grid>
    }
    
    return (
        <Grid container spacing={2}>
            {currentPassword}
            <Grid item xs={12}>
                <TextField
                    type={type}
                    label="Your New Password"
                    fullWidth
                    name="password"
                    value={password}
                    onChange={(e)=>handleChange(e)}
                    margin="normal"
                    InputProps={{
                        endAdornment: viewButton,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type={type}
                    label="Confirm Password"
                    fullWidth
                    name="passwordMatch"
                    value={passwordMatch}
                    onChange={(e)=>handleChange(e)}
                    margin="normal"
                    InputProps={{
                        endAdornment: viewButton,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                {/*
                Password Reqs:
                -Min 12 Chars
                -Requires number
                -Requires Capital Letter
                -Requires Lowercase Letter
                -Requires Special Char
				*/}
                <Paper style={PAPER_STYLE_SMALL}>
                    <PasswordChecks
                        capital={pc.capital}
                        lower={pc.lower}
                        match={pc.match}
                        minChars={pc.minChars}
                        number={pc.number}
                        special={pc.special}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    onClick={()=>resetPassword()}
                    variant="contained"
                    color="primary"
                    disabled={disabled || resetCompleted || checksFailed}
                >
                    Reset Password
                </Button>
                <div style={{marginTop: getSpacing('small')}}>
                    <Alert
                        visible={requested}
                        status={status}
                        headline={headline}
                        message={message}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={resetCompleted && !profileReset}>
                    <Link to="/login/">
                        Log In
                    </Link>
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default ResetForm;