import React from 'react';
import Box from "@mui/material/Box";

//{ borderBottom: 1, borderColor: 'divider' }

const TabBox=({children})=>{
    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            {children}
        </Box>
    )
}

export default TabBox;