import React from "react";
import {IconButton, InputAdornment, Tooltip} from "@mui/material";
import DebounceTextField from "../Form/DebounceTextField";
import {Close, Search} from "@mui/icons-material";
import {SEARCH_DEBOUNCE_DELAY} from "fit/system/FITConstants";

const AthleteRowSearch=({value, onChangeFn, clearSearchFn, placeHolderText, label, searchToolTip})=>{
    const fieldLabel = label != null ? label : 'Athlete';
    const fieldPlaceholder = placeHolderText != null ? placeHolderText : 'Search';
    const field =
        <DebounceTextField
            fullWidth
            label={fieldLabel}
            delay={SEARCH_DEBOUNCE_DELAY}
            placeholder={fieldPlaceholder}
            value={value}
            onChange={(e)=>onChangeFn(e)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            color={'secondary'}
                            onClick={clearSearchFn}
                        >
                            <Tooltip title={'Clear Search'} placement={'top'}>
                                <Close />
                            </Tooltip>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />;
    if(searchToolTip == null || searchToolTip.length === 0){
        return field;
    }

    return (
        <Tooltip title={`Search For: ${searchToolTip}`} placement={'bottom'}>
            {field}
        </Tooltip>
    )




}

export default AthleteRowSearch;