import React from 'react';
import {useTheme} from "@mui/material";

interface LabelProps { }

const ActiveUnitLabel=(props: React.PropsWithChildren<LabelProps>)=>{
    const theme = useTheme();
    const color= theme.palette.primary.main;
    const s = {...props.style, color: color, cursor: 'pointer'};
    return <span {...props} style={s}>{props.children}</span>
}

export default ActiveUnitLabel;