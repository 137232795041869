import React, {useState, useRef} from 'react';
import {connect} from 'react-redux';
import {TextField, Button, Grid, Typography, InputAdornment, Tooltip, IconButton, Collapse} from '@mui/material';
import {FormControl, FormControlLabel, Radio, RadioGroup, FormLabel} from "@mui/material";
import {Visibility, VisibilityOff, ArrowBack} from "@mui/icons-material";
import Link from 'fit/components/System/Link';
import {Navigate} from 'react-router-dom';
import Alert from "fit/components/Alert";
import {setPageTitle, isEmpty, getSpacing} from 'fit/system/UtilityFunctions'
import SecureConnect from "fit/system/SecureConnect";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {PRODUCTION_STATUS , URL_ACTIVE_WORKOUT, URL_MY_PROFILE, URL_LOGIN, URL_DEVICES} from "fit/system/FITConstants";
import User from 'fit/system/User';

const validationSchema = yup.object().shape({
	email: yup.string()
		.required('Email required')
		.email('Email required')
		.min('5','Email required'),
	password: yup.string()
		.required('Password required')
});

const Login=(props)=>{
	const [waiting, setWaiting] = useState(false);
	const [step, setStep] = useState(0);
	const [formData, setFormData] = useState({
		email: PRODUCTION_STATUS ? '' : '',
		password: PRODUCTION_STATUS ? '' : '',
		displayPassword: false,
		displayConnectLog: false,
 	});
	const [errorData, setErrorData] = useState({
		hasError: false,
		errorMessage: '',
	});
	const [confirmData, setConfirmData] = useState({
		phone: '###-###-####',
		key: '',
		phoneEnabled: false,
		method: 'email',
		email: 'email',
		code: ['','','','','',''],
	})
	const field0 = useRef();
	const field1 = useRef();
	const field2 = useRef();
	const field3 = useRef();
	const field4 = useRef();
	const field5 = useRef();


	const handleMeasurementData=(data)=>{
		const hasUnits = data.measurementUnits != null && !isEmpty(data.measurementUnits);
		const user = new User();
		if(hasUnits){
			const mUnits = data.measurementUnits;
			user.setMeasurementData(mUnits)
			user.storeLocalMeasurementUnits(mUnits);
		}
	}
	const handleCode=(field, value)=>{
		let cd = {...confirmData};
		let code = [...confirmData.code];

		value = `${value}`.substr(0,1).toUpperCase();
		code[field] = value;
		cd.code = code;
		setConfirmData(cd);

		//AutoFocus the field based on input's and field entered
		const refList = [field0,field1,field2,field3,field4,field5];
		const stepValue = value.length ? 1 : -1;
		if(!((field === 0 && value === '') || (field === 5 && value !== '')) || (field > 0 && value === '')){
			const ref = refList[field+stepValue];
			ref.current.focus();
		}
	}
	const setError=(hasError, errorMessage = '')=>{
		let ed = {...errorData};
		ed.hasError = hasError;
		ed.errorMessage = errorMessage;
		setErrorData(ed);
	}
	const handleChange=(e)=>{
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		const fd = {...formData};
		fd[name] = value;
		setFormData(fd);
	}
	const toggleDisplayPassword=()=>{
		let fd = {...formData};
		fd.displayPassword = !formData.displayPassword;
		setFormData(fd);
	}
	const handleSendCodeMethod=(method)=>{
		let cd ={...confirmData};
		cd.method = method;
		setConfirmData(cd);
	}
	const login=()=>{
		const {email, password} = formData;
		let sc = new SecureConnect('system.php','post');
		setError(false);
		setWaiting(true);
		sc.setAction('login');
		sc.setDisplaySuccessMessages(true);
		sc.setDisplayNotifications(true);
		sc.setFormData({login: email, password: password});
		sc.connect().then(json =>{
			const authorized = sc.getCompleted(json);
			const data = sc.getData(json);
			const variable = '2FARequired';
			if(authorized){
				if(data[variable] != null && data[variable] === true){
					setStep(1); //Submit 2 Factor Authentication
					let cd = {...confirmData};
					const {email, phoneEnabled, phone, key} = data;
					cd.key = key;
					cd.email = email;
					cd.phoneEnabled = phoneEnabled;
					cd.phone = phone.length === 0 ? 'Unavailable' : phone;
					setConfirmData(cd);
				}
			} else {
				const responseMessage = sc.getResponseMessage(json);
				setError(true, responseMessage);
			}
			setWaiting(false);
		});
	}
	const requestCode=()=>{
		let sc = new SecureConnect('system.php', 'post');
		sc.setAction('request2FACode');
		sc.setFormData({email: confirmData.email, method: confirmData.method, key: confirmData.key});
		setWaiting(true);
		sc.connect().then(json =>{
			if(sc.getCompleted(json)){
				//Code Sent. Set Next Step
				setStep(2);
			}
			setWaiting(false);
		});
	}
	const submitCode=()=>{
		let sc = new SecureConnect('system.php', 'post');
		setWaiting(true);
		sc.setAction('submit2FACode');
		setError(false, '');
		sc.setFormData({key: confirmData.key, code: confirmData.code.join('')});
		const user = new User();
		sc.connect().then(json=>{
			if(!sc.getCompleted(json)){
				user.setMeasurementData(sc.getData(json));
			} else{
				setError(true, sc.getResponseMessage(json));
			}
			setWaiting(false);
		});
	}
	const user = new User();
	const loggedIn = user.getLoggedInStatus();
	const deviceAccount = user.getDeviceAccount();
	const deviceMaster = user.getDeviceMaster();

	if(loggedIn && deviceAccount === false){
		//Active users go to their profile on logging in
		const requestedPath = props.router.location.pathname;
		const bannedList = ['', '/', URL_LOGIN];
		const redirect = bannedList.includes(requestedPath) === false ? requestedPath : URL_MY_PROFILE;
		return (
			<Navigate to={redirect}/>
		)
	}
	if(loggedIn && deviceMaster){
		return <Navigate to={URL_DEVICES}/>
	}

	if(loggedIn && deviceAccount){
		//Logged in device accounts go to the active workout page.
		return <Navigate to={URL_ACTIVE_WORKOUT}/>
	}
	const alertStyle = {paddingTop: getSpacing('small'), paddingBottom: getSpacing('small')};
	const {email, password,displayPassword} = formData;
	const {hasError, errorMessage} = errorData;
	setPageTitle('Sign In');
	return (
	<div>
		<Collapse in={step === 2}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant={'h1'}>Submit Code</Typography>
					<Typography variant={'body1'}>
						To complete verification, a code has been {confirmData.method === 'email'? 'sent to your email' : 'texted to your phone'}.
						<br/>Please enter the code below.
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<TextField
						fullWidth
						inputRef={field0}
						size={'large'}
						value={confirmData.code[0]}
						onChange={(e)=>handleCode(0, e.target.value)}
						inputProps={{style: {textAlign: 'center'}}}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						fullWidth
						inputRef={field1}
						size={'large'}
						value={confirmData.code[1]}
						onChange={(e)=>handleCode(1, e.target.value)}
						inputProps={{style: {textAlign: 'center'}}}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						fullWidth
						inputRef={field2}
						size={'large'}
						value={confirmData.code[2]}
						onChange={(e)=>handleCode(2, e.target.value)}
						inputProps={{style: {textAlign: 'center'}}}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						fullWidth
						inputRef={field3}
						size={'large'}
						value={confirmData.code[3]}
						onChange={(e)=>handleCode(3, e.target.value)}
						inputProps={{style: {textAlign: 'center'}}}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						fullWidth
						inputRef={field4}
						size={'large'}
						value={confirmData.code[4]}
						onChange={(e)=>handleCode(4, e.target.value)}
						inputProps={{style: {textAlign: 'center'}}}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						fullWidth
						inputRef={field5}
						size={'large'}
						value={confirmData.code[5]}
						onChange={(e)=>handleCode(5, e.target.value)}
						inputProps={{style: {textAlign: 'center'}}}
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Tooltip title={'Go Back To Request A New Code'} placement={'bottom'}>
						<Button
							fullWidth
							color={'primary'}
							variant={'outlined'}
							onClick={()=>setStep(1)}
						><ArrowBack/> Back</Button>
					</Tooltip>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Button
						fullWidth
						color={'primary'}
						variant={'contained'}
						disabled={waiting || confirmData.code.join('').length !== 6}
						onClick={()=>submitCode()}
					>Submit Code</Button>
				</Grid>
				<Grid item xs={12}>
					<Alert
						visible={errorData.hasError}
						status={'error'}
						headline={'Error!'}
						message={errorData.errorMessage}
					/>
				</Grid>
			</Grid>
		</Collapse>

		<Collapse in={step === 1}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant={'h1'}>MultiFactor Authentication Required</Typography>
					<Typography variant={'body1'}>We need to send you a code to verify it's you. How would you like to receive it?</Typography>
				</Grid>
				<Grid item xs={12}>
					<FormControl>
						<FormLabel>Send my code via:</FormLabel>
						<RadioGroup
							defaultValue={'email'}
							name="method"
						>
							<FormControlLabel
								value={'email'}
								control={<Radio />}
								label={`Email: ${confirmData.email}`}
								onClick={()=>handleSendCodeMethod('email')}
							/>
							<FormControlLabel
								value={'phone'}
								control={<Radio disabled={confirmData.phoneEnabled === false}/>}
								label={`Phone: ${confirmData.phone}`}
								onClick={()=>handleSendCodeMethod('phone')}
							/>
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						color={'primary'}
						variant={'contained'}
						disabled={waiting}
						onClick={()=>requestCode()}
					>Send Code</Button>
				</Grid>
			</Grid>
		</Collapse>

		<Collapse in={step === 0}>
			<Formik
				initialValues={formData}
				validationSchema={validationSchema}
				onSubmit={()=>login()}
				enableReinitialize={true}
			>
				{({ errors, touched}) => (
					<Form>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant={'h1'}>
									Sign In
								</Typography>
							</Grid>
							<Grid item xs={12}>

							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Email"
									fullWidth
									name="email"
									onChange={(e)=>handleChange(e)}
									defaultValue={email}
									margin="normal"
									className="mt-1 my-sm-3"
									error={(errors.email && touched.email) || hasError}
									helperText={errors.email || ' '}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									type={displayPassword ? 'text' : 'password'}
									name="password"
									label="Password"
									fullWidth
									onChange={(e)=>handleChange(e)}
									defaultValue={password}
									margin="normal"
									className="mt-1 my-sm-3"
									error={(errors.password && touched.password) || hasError}
									helperText={errors.password || ' '}
									InputProps={{
										endAdornment: <InputAdornment position="end">
											<Tooltip title={'Toggle Password Visibility'} placement={'bottom'}>
											<IconButton
												edge={'end'}
												color={'primary'}
												onClick={()=>toggleDisplayPassword()}
											>
												{displayPassword ? <VisibilityOff/>: <Visibility/>}
											</IconButton>
											</Tooltip>
										</InputAdornment>
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Alert
									visible={hasError}
									status={'error'}
									headline={'Login Error'}
									message={errorMessage}
									style={alertStyle}
								/>
								<Button
									fullWidth
									disabled={waiting}
									variant={'contained'}
									color={'primary'}
									type={'submit'}
								>Sign In
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Link to="/reset-password/">Forgot Your Password?</Link>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Collapse>


	</div>
	)

}


const mapStateToProps = (state) => {
	return {
		router: state.router,
		FITUser: state.FITUser
	};
};

export default connect(mapStateToProps)(Login);
