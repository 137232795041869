import React,{useState, useRef} from 'react';
import MeasurementUnits from "fit/system/MeasurementUnits";
import {Paper, Typography, Divider} from "@mui/material";
import {getSpacing, isEmpty, getAthleteLeaderboardPlacement} from "fit/system/UtilityFunctions";
import {Table, TableCell, TableBody, TableHead, TableRow, useTheme} from "@mui/material";
import {GOLD_COLOR, DISPLAY_ADJUSTMENT_STRINGS, ASSISTANT_LEVEL} from "fit/system/FITConstants";
import {EmojiEvents, Person} from "@mui/icons-material";
import UserPreferences from "fit/system/UserPreferences";
import ActiveUnitLabel from "fit/components/Form/ActiveUnitLabel";
import MeasurementOptionsMenu from "fit/components/Form/MeasurementField/MeasurementOptionsMenu";
import User from 'fit/system/User';
import LeaderConfetti from "./LeaderConfetti";
import {UserLink} from 'fit/components/Links/'

const ExerciseLeaderPost=({exerciseName, leaders, moreBetter, masterConfettiToggle = false, teamID})=>{
    //A leader posting for a single exercise/stat
    //Leaders is an array ordered by placement (Top Leader is array index 0)
    //leader = {userID, user's name, data value, unitID, logDate, recordedBy}
    //statData = {dataValue, measurementUnitID, logDate, recordedBy}s
    //Underneath leaderboard, provided current user's stat and what percentage they are off.
    //console.log('EXERCISE', exerciseName, 'MORE BETTER?', moreBetter);
    const [currentUnitID, setCurrentUnitID] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuElement, setMenuElement] = useState(null);

    const user = new User();
    const userID = user.getUserID();
    const rosterAccess = user.isTeamSportSpecificCoach(teamID) || user.isTeamStrengthCoach(teamID);
    const defaultMeasurementUnitID = 2; //LBS
    const theme = useTheme();
    const separatorColor = theme.palette.secondary.main;

    const secondPlaceRef = useRef();
    const thirdPlaceRef = useRef();


    const mUnits = new MeasurementUnits();
    const userPrefs = new UserPreferences();
    const nameStyle = {
        textAlign: 'center',
        padding: getSpacing('small')
    }
    const updateMeasurement=(value)=>{
        setCurrentUnitID(value);
        handleMenu(false);
    }

    const getTopPerformer=()=>{
        let value = 0;
        let measurementUnitID = defaultMeasurementUnitID;
        let rawValue = 0;

        if(leaders && leaders.length){
            value = leaders[0].value
            measurementUnitID = leaders[0].measurementUnitID;
            rawValue = leaders[0].rawValue;
        }
        return {leadingValue: value, leadingUnitID: measurementUnitID, leadingRawValue: rawValue}
    }

    const handleMenu=(open, element = null)=>{
        setMenuOpen(open);
        if(element === null) {
            setMenuElement(element);
        } else{
            setMenuElement(element.currentTarget);
        }
    }

    const calcPercentage=(numerator, denominator, precision)=>{
        return parseFloat(parseFloat(numerator)/parseFloat(denominator)*100).toFixed(precision);
    }
    const calcDifference=(major, minor, precision)=>{
        return parseFloat(parseFloat(major)-parseFloat(minor)).toFixed(precision);
    }

    const getFireworks=(place)=>{
        const secondPlace = place === 2;
        const ref = secondPlace ? secondPlaceRef : thirdPlaceRef;
        const count = secondPlace ? 200 : 100;
        const origin = {x: ref.current.offsetLeft, y: ref.current.offsetTop};


        const defaults = {
            spread: 360,
            ticks: 50,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            shapes: ["star"],
            colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
        }

        /*
        confetti({
            ...defaults,
            particleCount: 40,
            scalar: 1.2,
            shapes: ["star"],
        })

         */
    }

    const iconCSS = {position: 'relative', top: getSpacing('iconAlignment')};
    const trophy = <EmojiEvents sx={{...iconCSS, color: GOLD_COLOR}}/>;
    if(leaders == null || isEmpty(leaders)){
        return null;
    }
    const convertUnits = currentUnitID !== null;
    //Set Default exercise Units to the leaderboards measurementUnitID or 2
    const exerciseUnit = leaders && leaders.length ? leaders[0].measurementUnitID : defaultMeasurementUnitID;
    const preferredUnitData = userPrefs.getPreferredUnit(exerciseUnit);
    const measurementUnitSetting = convertUnits ? currentUnitID : preferredUnitData.id;
    const unitPrecision = 1;
    const {type} = mUnits.getMeasurementUnitByID(exerciseUnit);
    const placement = 2; //getAthleteLeaderboardPlacement(leaders, userID);
    let location = {};
    if(placement === 2 || placement ===3){
        const ref = placement === 2 ? secondPlaceRef : thirdPlaceRef;
        //console.log('LOC', ref.current.offsetLeft, ref.current.offsetTop);
        location = ref.current ? {x: ref.current.offsetLeft, y: ref.current.offsetTop} : {};
    }
    const displayString = DISPLAY_ADJUSTMENT_STRINGS.td;
    const columnHidden = {display: {xs: 'none', sm: 'none', md: displayString, lg: displayString}};
    return (
        <Paper>
            <LeaderConfetti active={masterConfettiToggle} place={placement} location={location}/>
            <div style={nameStyle}>
                <Typography variant={'h1'}>{exerciseName}</Typography>
                {/*
                <button onClick={()=>getFireworks(2)}>fw(2)</button><button onClick={()=>getFireworks(3)}>fw(3)</button>
            */}
            </div>
            <Divider/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Place</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align={'right'}>{type}</TableCell>
                        <TableCell align={'right'} sx={columnHidden}>Difference</TableCell>
                        <TableCell align={'right'} sx={columnHidden}>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        leaders.map((athlete, key)=>{
                            //console.log('athlete', athlete);
                            const {place, date, value, measurementUnitID} = athlete;

                            let differenceCell = '';
                            const unitLabelID = convertUnits ? currentUnitID : measurementUnitID;
                            const time = mUnits.isTime(measurementUnitID);
                            let {abbreviation} = mUnits.getMeasurementUnitByID(unitLabelID);
                            let displayValue;
                            if(convertUnits){
                                displayValue = mUnits.convertUnits(value, measurementUnitID, currentUnitID, unitPrecision);
                            } else{
                                //Provide the preferred units based on user settings
                                displayValue = userPrefs.convertToPreferredMeasurement(value, measurementUnitID, unitPrecision);
                                const conversionData = userPrefs.getPreferredUnit(measurementUnitID);
                                abbreviation = conversionData.abbreviation;
                            }
                            const firstPlace = key === 0;
                            let icon;
                            if(firstPlace){
                                icon = trophy;
                            } else if(!firstPlace && athlete.userID && parseInt(athlete.userID) === userID ){
                                icon = <Person sx={iconCSS}/>;
                            } else{
                                icon = null;
                            }

                            let name;
                            if(firstPlace && rosterAccess){
                                //Link to user with bold text
                                name = <strong><UserLink urlKey={athlete.userKey} name={athlete.name}/></strong>;

                            } else if(firstPlace){
                                //Make the user bold
                                name = <strong>{athlete.name}</strong>

                            } else if(rosterAccess){
                                //Link to the user
                                name = <UserLink urlKey={athlete.userKey} name={athlete.name}/>
                            } else{
                                //just name
                                name = athlete.name;
                            }
                            //const name = firstPlace ? <strong>{athlete.name}</strong> : athlete.name;
                            const leadingAthlete = parseInt(place) <= 3;
                            const leaderStyle = !leadingAthlete ? {borderTop: `2px solid ${separatorColor}`} : null;
                            const primaryStyle = leaderStyle;
                            const secondaryStyle = {...columnHidden, ...leaderStyle};

                            const displayDate = userPrefs.convertServerDate(date);
                            //Difference Column
                            let targetRef = null;
                            if(!firstPlace){
                                if(key === 1){
                                    targetRef = secondPlaceRef;
                                } else if (key === 2){
                                    targetRef = thirdPlaceRef;
                                } else{
                                    targetRef = null;
                                }
                                let {leadingUnitID, leadingValue, leadingRawValue} = getTopPerformer();
                                if(convertUnits){
                                    leadingValue = mUnits.convertUnits(leadingValue, leadingUnitID, currentUnitID, unitPrecision);
                                } else{
                                    leadingValue = userPrefs.convertToPreferredMeasurement(leadingValue, leadingUnitID, unitPrecision);
                                }
                                let percentage, difference, timeSymbol;

                                if(moreBetter === true){
                                    timeSymbol = '-';
                                    if(time === false){
                                        percentage = calcPercentage(displayValue, leadingValue, unitPrecision);
                                        difference = calcDifference(displayValue, leadingValue, unitPrecision);
                                    } else{
                                        //TIME
                                        difference = calcDifference(athlete.rawValue, leadingRawValue, unitPrecision);
                                        percentage = calcPercentage(athlete.rawValue, leadingRawValue, unitPrecision);
                                    }
                                } else{ //LESS BETTER
                                    timeSymbol = '+';
                                    if(time === false){
                                        percentage = calcPercentage(leadingValue, displayValue, unitPrecision);
                                        difference = calcDifference(leadingValue, displayValue, unitPrecision);
                                    } else{
                                        //TIME
                                        difference = calcDifference(leadingRawValue, athlete.rawValue, unitPrecision);
                                        percentage = calcPercentage(leadingRawValue, athlete.rawValue, unitPrecision);
                                    }
                                }

                                //Format Difference
                                difference = time ? `${timeSymbol}${mUnits.formatTimeString(difference, unitPrecision)}` : userPrefs.convertNumber(difference);

                                //Build Cell Data
                                differenceCell = time ? `${difference} (${percentage}%)` : `${difference} ${abbreviation} (${userPrefs.convertNumber(percentage)}%)`;

                            }
                            //convert display value
                            displayValue = time ? displayValue : userPrefs.convertNumber(displayValue);
                            return (
                                <TableRow key={key}>
                                    <TableCell sx={primaryStyle} ref={targetRef}>{place}{icon}</TableCell>
                                    <TableCell sx={primaryStyle}>{name}</TableCell>
                                    <TableCell sx={primaryStyle} align={'right'}>
                                        {displayValue}{' '}
                                        {!time ?
                                            <ActiveUnitLabel onClick={(e)=>handleMenu(true, e)}>
                                                {abbreviation}
                                            </ActiveUnitLabel>
                                            :
                                            null
                                        }

                                    </TableCell>
                                    <TableCell sx={secondaryStyle} align={'right'}>{differenceCell}</TableCell>
                                    <TableCell sx={secondaryStyle} align={'right'}>{displayDate}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <MeasurementOptionsMenu
                measurementID={measurementUnitSetting}
                anchorEl={menuElement}
                open={menuOpen}
                allowWeightMixer={false}
                measurementOptions={mUnits.getMeasurementAlternatives(measurementUnitSetting)}
                onClick={(value)=>updateMeasurement(value)}
                onClose={()=>handleMenu(false)}
            />
        </Paper>
    )

};


export default ExerciseLeaderPost;