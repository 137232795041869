import React from 'react';
import {Select, MenuItem, FormControl, InputLabel, FormHelperText} from '@mui/material';

import {TextField} from "@mui/material";

const DynamicSelector=(props)=>{
	const {label, key, onChange, dataPending, value, helperText, options, variableName} = props;
	return(
		<FormControl>
			<Select
				key={key}
				name={variableName}
				onChange={onChange}
				disabled={dataPending}
				value={value}
				autoWidth={true}
				displayEmpty={true}
			>
			{
				Object.keys(options).map(key => (
					<MenuItem value={options[key][0]} key={key}>
						{options[key][1]}
					</MenuItem>
					)
				)
			}
			</Select>
			<FormHelperText>{label}</FormHelperText>
		</FormControl>
	);



	return (
		<TextField
			select
			key={key}
			name={variableName}
			value={value}
			label={label}
			disabled={dataPending}
			onChange={onChange}
		>
			{
				Object.keys(options).map(key => (
						<MenuItem value={options[key][0]} key={key}>
							{options[key][1]}
						</MenuItem>
					)
				)
			}
		</TextField>
	)


}
export default DynamicSelector;