import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from 'fit/components/System/Link';
import {ASSET_IMAGES} from "../../utils/constants/paths";
import {URL_MY_PROFILE, COMPANY_NAME} from 'fit/system/FITConstants';


const Logo = ({mini, mode, sx}) => {

    let logo
    if(!mini){
        logo = <img src={ mode === "light" ? `${ASSET_IMAGES}/logo.png` : `${ASSET_IMAGES}/logo-white.png`} alt={COMPANY_NAME} height={'35px'} width={'110px'}/>;
    } else{
        logo = <img src={mode === "light" ? `${ASSET_IMAGES}/logo-short.png` : `${ASSET_IMAGES}/logo-short-white.png`} alt={COMPANY_NAME}  height={'35px'} width={'110px'}/>
    }

    return (
        <Div sx={{display: "inline-flex", ...sx}}>
            <Link to={URL_MY_PROFILE}>
                {logo}
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
