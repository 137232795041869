import React from 'react';
import WorkoutReport from "fit/components/Analytics/Workout/TeamWorkoutReport";
import {useParams} from "react-router-dom";
import {isEmpty} from "fit/system/UtilityFunctions";

const CompletedWorkoutRoute =()=>{
    let props = useParams();
    const workoutID = !isEmpty(props) && props.workoutID != null ? props.workoutID : null;
    return <WorkoutReport workoutID={workoutID}/>
}

export default CompletedWorkoutRoute;
