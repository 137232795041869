//Multiple Utility functions for getting/processing data
import {lazy} from 'react';
import SecureConnect from './SecureConnect';
import User from "./User";
import InputAdornment from "@mui/material/InputAdornment"; //getLockInputProps()
import Lock from "@mui/icons-material/Lock"; //getLockInputProps()
import {CheckCircle, Info, Warning, Cancel, PanoramaFishEye} from "@mui/icons-material";
import React from "react";
import {Tooltip, Tab, Badge} from "@mui/material";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import chroma from 'chroma-js';
import {COLOR_SCALES} from "./FITConstants";
//import WBGStyles from "fit/components/WorkoutBlockGenerator/WBGStyles";
//import {config} from 'app/config/main';


export const lazyLoad=(absolutePath, component)=>{
	return lazy(()=>{
		const promise = import(`../${absolutePath}`);
		return component == null ? promise : promise.then(js => ({default: js[component]}));
	})
}


/*
export const debounce=(func, wait = 200, immediate = false)=>{
	let timeout;
	return (...args)=> {
		let context = this; //args = arguments;
		let later =()=> {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
*/

export const hashString =(str)=>{
	//return str;
	let hash = 0, i, chr;
	//return str;
	if (str.length === 0) return hash;
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}

export const getTimestamp=()=>{
	return Math.floor(Date.now()/1000);
}

export const convertDateStrToDateObj =(mmddyyyy) => {
	//Receive mm-dd-yyyy
	//convert to date obj for sorting of client side data
	//used by browserSort method in dynamic table (and elsewhere)
	const dateParts = mmddyyyy.split('-');
	const year = dateParts[2];
	const month = parseInt(dateParts[0])-1;
	const day = dateParts[1];
	return new Date(year, month, day);
};

export const getAthleteLeaderboardPlacement=(athleteList, userID)=>{
	//Recieve an athlete leaderboard list
	//determine if the athlete (userID) is within the list
	let index = athleteList.findIndex(a=>parseInt(a.userID) === parseInt(userID));
	if(index > -1){
		return index+1;
	}
	return 0;
}

export const createFormikSelectOptions =(obj, labelName, valueName, assignInt = false) =>{
	//Receive an object, labelName and ValueName
	//Return an array of key/value pairs for Formik Selectors
	//Format: [{label: 'SOMELABEL', value: 'SOMEVALUE'},...]
	const keys = Object.keys(obj);
	if(keys.length < 1){
		//Nothing available. Return empty array
		return [];
	}
	let options = [];
	keys.forEach(k => {
		const item = obj[k];
		const val = assignInt ? parseInt(item[valueName]) : item[valueName];
		options.push({label : item[labelName], value: val});
	});
	return options;
};

export const formatMoney =(value)=>{
	let number = parseFloat(value);
	return (number - Math.floor(number) !== 0) ? number.toFixed(2) : number;
};

export const getGraphLineColor=(dataIndex, offset = 0)=>{
	dataIndex = parseInt(dataIndex);
	const palette = [
		'#F00',
		'#F90',
		'#FF0',
		'#9F0',
		'#0F0',
		'#0F9',
		'#0FF',
		'#09F',
		'#00F',
		'#90F',
		'#F0F',
		'#F09'
	]; //12 starting colors
	const limit = palette.length*4; //How large to stretch/scale the original palette (48 colors)
	const scales = chroma.scale(palette).mode('lch').colors(limit);
	const stepOver = 8; //# of steps between colors
	const maxDesaturation = 3; //Maximum levels of desaturation before resetting
	const colorCount = scales.length; //Total # of colors

	//Math/Calculations below
	let startingIndex = parseInt((parseInt(dataIndex)*stepOver)+offset);
	const maxColorScaleOffset = colorCount*stepOver;
	//How much does the startingIndex extend past the colorScales : Loop through original color range
	const modStep = Math.floor(startingIndex/ colorCount) % colorCount;
	const desaturationLevel = modStep % maxDesaturation; //Determine the degree of saturation
	const colorIndex = startingIndex <= colorCount-1 ? startingIndex : ((startingIndex%colorCount)+modStep)%colorCount;
	return desaturationLevel === 0 ? scales[colorIndex] : chroma(scales[colorIndex]).desaturate(desaturationLevel).hex();
}


export const getRPEScaleString=(score, oneHundred = false)=>{
	const RPE = oneHundred ? parseInt(score)/5 : parseInt(score);
	const scale = {
		1: "Nothing At All",
		2: "Just Noticeable",
		3: "Just Noticeable+",
		4: "Light",
		5: "Light+",
		6: "Moderate",
		7: "Moderate+",
		8: "Somewhat Heavy",
		9: "Somewhat Heavy+",
		10: "Heavy",
		11: "Heavy+",
		12: "Heavy++",
		13: "Heavy+++",
		14: "Very Heavy",
		15: "Very Heavy+",
		16: "Very Heavy+",
		17: "Extremely Heavy",
		18: "Extremely Heavy+",
		19: "Extremely Heavy++",
		20: "I Am Completely Exhausted"
	}
	return scale[RPE];
}

export const getPercentageStatusColor=(intPercentage)=>{
	if(intPercentage < 65){
		return getStatusColor('success');
	} else if(65 <= intPercentage && intPercentage < 85){
		return getStatusColor('warning');
	} else {
		return getStatusColor('error');
	}
}

export const getCardMaskPattern =(cardNumberValue)=>{
	const AMEX = {
		pattern: [4,6,5],
		minLength: 15,
		maxLength: 15,
		sets: [34, 37],
		cscLength: 4,
	};
	const dinersClub = {
		pattern: [4,6,4],
		minLength: 14,
		maxLength: 14,
		sets: [300, 301, 302, 303, 304, 305]
	};
	const dinersClubIntl ={
		pattern: [4,6,4],
		minLength: 14,
		maxLength: 14,
		sets: [36]
	};
	const dinersClubUSA = {
		pattern: [4,4,4,4],
		minLength: 16,
		maxLength: 16,
		sets: [54, 55]
	};
	const discover = {
		pattern: [4,4,4,4],
		minLength: 16,
		maxLength: 16,
		sets: [6011, '622126-622925', 644, 645, 646, 647, 648, 649, 65]
	};
	/*
	const jcb = ['3528-3589'];
	const maestro = [5018, 5020, 5038, 5893, 6304, 6759, 6761, 6762, 6763];
	const instaPayment = [637, 638, 639];
	*/
	const masterCard = {
		pattern: [4,4,4,4],
		minLength: 16,
		maxLength: 16,
		sets: [51, 52, 53, 54, 55, '222100-272099']
	};
	const visa = {
		pattern: [4,4,4,4],
		minLength: 13,
		maxLength: 16,
		sets: [4],
	};
	const cards = [AMEX, dinersClub, dinersClubIntl, dinersClubUSA, discover, masterCard, visa];
	let patternFound = false;
	let regex = /(\d{20})/;
	let maskPattern = '$1'; //Default 20 numbers - no spacing
	let minLength = 13;
	let maxLength = 20;
	for(let c=0; c<cards.length; c++){
		const card = cards[c];
		for(let k=0; k<card.sets.length; k++){
			const num = `${card.sets[k]}`;
			const hasRange = num.includes('-');
			if(hasRange){
				//Run loop through range
				const range = num.split('-');
				const start = parseInt(range[0]);
				const end = parseInt(range[1]);
				for(let rangeValue = start; rangeValue<=end; rangeValue++){
					patternFound = getCardPrefix(cardNumberValue, rangeValue);
					if(patternFound) {
						break;
					}
				}
			} else{
				patternFound = getCardPrefix(cardNumberValue, num);
				if(patternFound){
					//Break loop through card patterns
					break;
				}
			}
		}
		if(patternFound){
			//Pattern found
			//Break cards loop
			//Build pattern based on the length and the chunks of the pattern
			const spacer = '   ';
			maskPattern = '$1';
			const numericValue = cardNumberValue.replace(/\D/g,'');
			const numericValueLength = numericValue.length;
			regex = createCardChunk(card.pattern[0]);
			let chunkTotal = 0;
			for(let k = 0; k < card.pattern.length; k++){
				const chunk = card.pattern[k];
				//Add the chunk to the total
				chunkTotal+=chunk;
				const totalDifference = chunkTotal - numericValueLength;
				const chunkRemaining = chunk - Math.abs(totalDifference);
				const groupLimit = totalDifference > 0 ? chunkRemaining : chunk;
				if(chunkTotal === chunk && numericValueLength <= chunk){
					//First Set - do nothing
					break;
				}
				if(k > 0){
					//Add pattern
					regex+=createCardChunk(groupLimit);
				}
				if(chunkTotal < numericValueLength){
					//More characters: Add Next Pattern
					maskPattern+=`${spacer}$${k+2}`;
				} else{
					//Group > length: end
					break;
				}
			}
			//maskPattern = card.pattern;
			regex = new RegExp(regex);
			maxLength = card.maxLength + (card.pattern.length-1)*spacer.length;
			minLength = card.minLength;
			break;
		}
	} //End cards Loop
	return {
		maskPattern,
		regex,
		minLength,
		maxLength
	};
};
const createCardChunk =(length)=>{
	return `(\\d{${length}})`;
};

const getCardPrefix =(cardValue, prefixValue)=>{
	//receive the present card value, check that against the prefix value
	//return whether it's an exact match
	prefixValue = `${prefixValue}`;
	cardValue = cardValue.split(' ').join('');
	const prefixLength = prefixValue.length;
	const cardPrefix = cardValue.substring(0, prefixLength);
	return cardValue.length >= prefixLength && parseInt(cardPrefix) === parseInt(prefixValue);
};

export const getCastPendingIcon =(style)=>{
	const iconStyle = {...style, color: getStatusColor('info')};
	return (
		<Tooltip
			title={'Doublebooked: Pending Approval'}
			enterDelay={300}
			placement={'bottom-start'}
		><Info style={iconStyle}/></Tooltip>
	);
};

export const getDeniedRoute =()=>{
	//Return the route for missing/unavialable/denied access pages;
	return '/'
};

export const getLockInputProps =()=>{
	//Provide a list of input properties that signifies that the user cannot edit the input
	//but doesn't make the input disabled / faded...
	return ({
		endAdornment: <InputAdornment position={"end"}><Lock/></InputAdornment>,
		readOnly: true
	})

};

export const getSpacing =(size = 'normal') => {
	const spacing ={
		small: '12px',
		medium: '18px',
		normal: '24px',
		larger: '36px',
		large: '48px',
		iconAlignment: '6px',
		iconButtonAlignment: '9px',
		tiny: '3px'
	};
	return spacing[size];
};

export const calculatePercentage=(part, whole, precision = 1)=>{
	return parseFloat((parseFloat(part/whole)*100).toFixed(precision));
}

export const trimURL=(url)=>{
	//remove the leading slash of a global URL (from FITConstants)
	//Enables stacking of global URLS for consistency
	return url.substring(1);

}

export const createWorkoutTitle=(blockTitle, dayIndex)=>{
	const day = parseInt(dayIndex)+1;
	return `${blockTitle} Day: ${day}`;

}

export const getDaysInMonth =(month, year)=> { // Use 1 for January, 2 for February, etc.
	return new Date(year, month, 0).getDate();
}
export const splitISODateString=(ISODate)=>{
	const sets =`${ISODate}`.split('-');
	return {
		year: sets[0],
		month: sets[1],
		days: sets[2]
	}
}

export const getStatusAccentColor =(status)=>{
	//For foregrounds for the standard status colors
	//there probably needs to be a better way. But whatevs
	const defaultStatus = 'info';
	const statuses = {
		success: '#FFF',//'#BADA55',
		info: '#FFF',//'#0AC',
		warning: '#FFF',//'#FA0',
		error: '#FFF',//'#F50',
		noAction: '#FFF',//'#999',
	};
	return statuses[status] !== null ? statuses[status] : statuses[defaultStatus];
};

export const getStatusByCode =(code)=>{
	const statuses = ['noAction','success','info','warning','error'];
	return statuses[code];
};

export const getRPEColor=(score100)=>{
	const newMethod = true;
	if (newMethod){
		const startColor = COLOR_SCALES.green; //getStatusColor('success');
		const endColor = COLOR_SCALES.red;// getStatusColor('error');
		const midColor = COLOR_SCALES.yellow; //getStatusColor('warning');
		return getStatusScaleColor(score100, startColor, midColor, endColor);
	} else {
		//Use Standard Status Colors
		let RPEColor;
		if (score100 >= 85) {
			RPEColor = 'error';
		} else if (65 <= score100 && score100 < 85) {
			RPEColor = 'warning';
		} else {
			RPEColor = 'success';
		}
		return getStatusColor(RPEColor);
	}
}

export const getHighlightStyle=(muiTheme)=>{
	//Return color/shadow highlight css based on the mui theme
	const colorOption = muiTheme.palette.mode === 'light' ? 'light' : 'dark';
	const color = muiTheme.palette.primary[colorOption];
	const length = 0;
	const radius = 5;
	const spread = 2;
	const inset = ''; // '' or 'inset'
	const shadowString = `${inset} ${length}px ${length}px ${radius}px ${spread}px ${color}`;
	return {
		'-webkit-box-shadow': shadowString,
		boxShadow: shadowString,
		zIndex: 10
	}
}

export const getCompletionColor=(score100)=>{
	const startColor = COLOR_SCALES.red;
	const midColor = COLOR_SCALES.yellow;
	const endColor= COLOR_SCALES.green;
	return getStatusScaleColor(score100, startColor, midColor, endColor);
}

export const getStatusScaleColor=(score, lowColor, midColor, highColor)=>{
	const scoreValue = parseInt(score);
	const startPosition = 0;
	const midPosition = 15;
	const endPosition = 21-midPosition;
	const firstSection = chroma.scale([lowColor, midColor]).mode('hsl').colors(midPosition-startPosition);
	const secondSection = chroma.scale([midColor, highColor]).mode('hsl').colors(endPosition);
	const list = firstSection.concat(secondSection);
	const position = parseInt(scoreValue/5);
	return list[position];
}

export const getStatusColor =(status, fade = false)=>{
	//Get the color based on the status
	//colors are in RGB Format so Alpha may be applied
	let user = new User();
	const colors = user.getStatusColors();
	if(!isEmpty(colors) && colors[status] !== ''){
		return colors[status];
	}
	const fadeAlpha = '.1';
	const defaultStatus = 'info';
	const statuses = {
		success: '59,204,0', //3bcc00, OLD :: '186,218,85',//'#BADA55',
		info: '0,170,204',//'#0AC',
		warning: '229,153,0',//'#e59900',
		error: '229,58,54',//'#e53a36',
		noAction: '154,154,154',//'#999',
	};
	const colorSet = statuses[status] !== null ? statuses[status] : statuses[defaultStatus];
	const alpha = !fade ? '1' : fadeAlpha;
	return `rgb(${colorSet},${alpha})`;
};

export const getStatusIcon =(status, style)=>{
	const statusIcons = {
		success: <CheckCircle style={style}/>,
		info: <Info style={style}/>,
		warning: <Warning style={style} />,
		error: <Cancel style={style}/>,
		noAction: <PanoramaFishEye style={style}/>,
	};
	return statusIcons[status];
};

export const getDayLabel=(day)=>{
	const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
	return days[day];
}

export const getTabPermissions =(permissionsSet)=>{
	/*
		-Receive array of objects
		-Object: {
			tabKey: (key used for assigning tabValues)
			details: {
				label: (text for the tab)
				icon: (icon for the tab)
				visible: (test to determine if the tab should be visible)
				disabled: (test to determine if the tab should be disabled)(visibilty outranks disabled)
				order: (integer 0,1,2,3,4,5)
				tabElement: (element that gets rendered based on above variables)
				tabValue: (gets calculated by this function
				}
			}
		-if disabled, create disabled
	*/
	//Tabs that get returned to the user
	let activeGroup = permissionsSet.filter(tab => tab.visible === true);
	let disabledGroup = permissionsSet.filter(tab => tab.visible === false);
	let activeTabs = []; //list of active tabs
	let tabValues = {};
	//Build active tabs
	activeGroup.forEach((t,k) =>{
		const badgeTest = t.badge != null && t.badge === true;
		let label = badgeTest ? <Badge badgeContent={t.badgeContent} color={'secondary'} max={100}>{t.label}</Badge>: t.label;

		activeTabs.push(
			<Tab
				key={k}
				label={label}
				icon={t.icon}
				disabled={t.disabled}
			/>
		);
		tabValues[t.key] = k;
	});

	//Provide tabValues with integers higher than originals
	//This will maintain proper tab order for rendering
	disabledGroup.forEach((t,k) =>{
		tabValues[t.key] = 1000+k;
	});
	return {activeTabs, tabValues};
};

export const getUnique =(objectSet, key)=>{
	return [...new Set(objectSet.map(item=> item[key]))];
};

export const getZip =(zipCode, displayNotifications = false)=>{
	const sc = new SecureConnect(`system.php?action=getZip&zip=${zipCode}`,'get');
	sc.setDisplayNotifications(displayNotifications);
	return new Promise(response => {
		sc.connect().then(jsonResponse=> {
			if(sc.getCompleted(jsonResponse)){
				const data = sc.getData(jsonResponse);
				response(data);
			}
			response(null);
		});
	});
};

export const isEmpty =(obj)=> {
	// Speed up calls to hasOwnProperty
	const hasOwnProperty = Object.prototype.hasOwnProperty;

	// null and undefined are "empty"
	if (obj == null) return true;

	// Assume if it has a length property with a non-zero value
	// that that property is correct.
	if (obj.length > 0)    return false;
	if (obj.length === 0)  return true;

	// If it isn't an object at this point
	// it is empty, but it can't be anything *but* empty
	// Is it empty?  Depends on your application.
	if (typeof obj !== "object") return true;

	// Otherwise, does it have any properties of its own?
	// Note that this doesn't handle
	// toString and valueOf enumeration bugs in IE < 9
	for (var key in obj) {
		if (hasOwnProperty.call(obj, key)) return false;
	}

	return true;
};

export const IconDecrease =(amount)=>{
	return (
		<span style={{color: getStatusColor('error')}}>
			<ArrowDropDown style={{position: "relative", top: getSpacing('iconAlignment')}}/> {amount}
		</span>
	)
};
export const IconIncrease =(amount)=>{
	return (
		<span style={{color: getStatusColor('success')}}>
			<ArrowDropUp style={{position: "relative", top: getSpacing('iconAlignment')}}/> +{amount}
		</span>
	)
};

export const objUnflatten = (data)=> {
	if (Object(data) !== data || Array.isArray(data))
		return data;
	let result = {}, cur, prop, idx, last, temp;
	for(var p in data) {
		cur = result;
		prop = "";
		last = 0;
		do {
			idx = p.indexOf(".", last);
			temp = p.substring(last, idx !== -1 ? idx : undefined);
			cur = cur[prop] || (cur[prop] = (!isNaN(parseInt(temp)) ? [] : {}));
			prop = temp;
			last = idx + 1;
		} while(idx >= 0);
		cur[prop] = data[p];
	}
	return result[""];
};

export const objFlatten =(data)=> {
	var result = {};
	function recurse (cur, prop) {
		if (Object(cur) !== cur) {
			result[prop] = cur;
		} else if (Array.isArray(cur)) {
			for(var i=0, l=cur.length; i<l; i++)
				recurse(cur[i], prop ? prop+"."+i : ""+i);
			if (l === 0)
				result[prop] = [];
		} else {
			var isEmpty = true;
			for (var p in cur) {
				isEmpty = false;
				recurse(cur[p], prop ? prop+"."+p : p);
			}
			if (isEmpty)
				result[prop] = {};
		}
	}
	recurse(data, "");
	return result;
};

export const paperStyles =()=>{
	const styles ={
		divWrapper: {
			width: '100%',
			overflowX: 'scroll',
			overflowY: 'hidden',
		},
		paper: {
			width: '100%',
			marginTop: getSpacing(),
			overflowX: 'scroll',
			overflowY: 'hidden',
		},
		table: {
			minWidth: 650,
		},
	};
	return styles;
};

export const maskPhone =(value) =>{
	//1. Get Value
	//2. Strip to just numbers
	let number = value.replace(/\D/g, '');
	//3. Determine Length of phone number (just numbers)
	number = number.substr(0,10);
	const length = number.length;
	const prefix = number.substr(0,3);
	const group1 = number.substr(3,3);
	const group2 = number.substr(6,9);
	let phoneNumber = number;
	if(length >= 3 && length <= 6){
		//4. If 3-6 characters long wrap in parentheses, add [space]
		phoneNumber = `(${prefix}) ${group1}`
	}
	if(length > 6){
		//5. if 6+ characters long add [space]-[space] numbers
		phoneNumber = `(${prefix}) ${group1}-${group2}`
	}
	return phoneNumber;
};
export const calculateWeightMax=(weight, percentage)=>{
	//receive a percentage served (ideally) as an integer
	//receive weight in (in lbs?)
	//calculate percentage of the weight
	//round up to nearest 5 lb increment

	//initialize percentage (assuming a float might be passed accidentally)
	if(parseFloat(percentage).toFixed(2) < 1){
		percentage = parseInt(parseFloat(percentage).toFixed(2)*100);
	}
	let multiple = parseInt(weight*percentage/100);
	const modulus = multiple%5;
	if(modulus === 0){
		//NO MORE WORK THAT NEEDS TO BE DONE
		return multiple;
	} else{
		//Add difference
		const modDifference = parseInt(5-modulus);
		multiple = parseInt(multiple+=modDifference);
		return multiple;
	}
};

export const resetForm =(formObj)=>{
	Object.keys(formObj).forEach(name =>{
		formObj[name] = '';
	});
	return formObj;
};

export const rand =(min, max)=> {
	/**
	 * Returns a random integer between min (inclusive) and max (inclusive).
	 * The value is no lower than min (or the next integer greater than min
	 * if min isn't an integer) and no greater than max (or the next integer
	 * lower than max if max isn't an integer).
	 * Using Math.round() will give you a non-uniform distribution!
	 */
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const arrayChunkSplit =(list, groups)=>{
	let set = [];
	const chunkSize = Math.ceil(list.length/groups);
	for(let i=0; i<list.length; i+=chunkSize){
		set.push(list.slice(i,i+chunkSize));
	}
	return set;
};

export const scrollTo =(ref)=>{
	//Scroll to a specific section on the page
	//Useful for button console on the edit show form
	window.scrollTo(0, ref.current.offsetTop);
};

export const setPageTitle =(title)=>{
	document.title = `${title} | Buteo`;
};

export const setPermissionsActive=(permissionSet)=> {
	let permissions = {};
	const keys = Object.keys(permissionSet);
	keys.forEach(k => {
		const record = permissionSet[k];
		const statusValue = record.active != null ? record.active : 1;
		permissions[k] = {...record, active: statusValue};
	})
	return permissions;
}

export const strReplace =(needle, replaced, haystack)=>{
	while(haystack.includes(needle)){
		haystack = haystack.replace(needle, replaced);
	}
	return haystack;
};