import React from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography} from "@mui/material";
import {getStatusIcon, getStatusColor, getSpacing} from "fit/system/UtilityFunctions";
import {ExpandMore} from "@mui/icons-material";


const StatusAccordion=({defaultExpanded, expanded, status, disabled, summaryText, children})=>{

    const statusColor = getStatusColor(status);
    const iconStyle = {color: statusColor, position: 'relative', top: getSpacing('iconAlignment')};
    const icon = getStatusIcon(status, iconStyle);
    const headerStyle = {color: statusColor, fontWeight: 'normal'}
    const expandedStatus = expanded == null ? undefined : expanded;
    //console.log('status', expandedStatus, summaryText);
    return (
        <Accordion defaultExpanded={defaultExpanded} disabled={disabled} expanded={expandedStatus}>
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography>{icon} <span style={headerStyle}>{summaryText}</span></Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default StatusAccordion;