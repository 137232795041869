import React from 'react';
import {useParams} from 'react-router-dom';
import User from "fit/system/User";
import UserProfile from "fit/components/User/UserProfile";
import MyProfileRoute from "./MyProfileRoute";

const ProfileRoute =()=>{
    let {userID, section} = useParams();
    if(userID == null){
        return (<MyProfileRoute/>);
    }
    const user = new User();
    const self = userID === user.getUserUrlKey();
    return <UserProfile urlKey={userID} self={self} section={section} />
}

export default ProfileRoute;