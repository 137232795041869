import React from 'react';
import {TextField, MenuItem} from "@mui/material";
import User from 'fit/system/User';

/*
    PROPS:
    -label (label for textField)
    -teamLimits (Limits to the team specified)
    -managerLevel (required level for the team list)
    -disabledList (options that are disabled and cannot be selected)





 */
const TeamSelectorField=(props)=>{
    const labelText = props.label != null ? props.label : 'Select Team';
    const limits = props.teamlimits != null ? props.teamlimits : [];
    const user = new User();
    const level = props.managerLevel != null ? parseInt(props.managerLevel) : 0;
    const disabledSet = props.disabledList != null ? props.disabledList : [];
    let teamList = user.getTeamList(level);
    if(limits.length){
        teamList = teamList.filter(team => limits.includes(parseInt(team.sectionID)));
    }
    //console.log('GET TEAM LIST', level, teamList, 'LIMITS', limits);
    return (
        <TextField
            select
            {...props}
            label={labelText}
        >
            {
                teamList.map((team, k)=>{
                    const disabled = disabledSet.includes(parseInt(team.sectionID));
                    return (
                        <MenuItem key={k} value={team.sectionID} disabled={disabled}>{team.orgShortName} {team.label}</MenuItem>
                    )
                })
            }
        </TextField>
    )
}

export default TeamSelectorField;