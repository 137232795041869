import React, {Suspense, useEffect} from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import {history} from './redux/store';
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import {SnackbarProvider} from "notistack";
import AppProvider from "./AppProvider";
import {config} from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import ClientStore from "fit/system/ClientStore";
import User from "fit/system/User";
import FitAppRoutes from "./FitAppRoutes";
import ButeoSuspenseFallback from 'fit/components/System/ButeoSuspenseFallback';
import {ConnectedRouter} from 'connected-react-router';

//FIT User preferences
import {createJumboTheme} from "@jumbo/utils";
import Theme from 'fit/system/Theme';



const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});


const App =()=>{
    useEffect(()=>{
        //Onload: determine if the user is already logged in. Forward them to the requested route
        //Or display the login/public facing forms
        let user = new User(false);
        user.persistClientState();
    })

    let user = new User;
    const layout = user.getLoggedInStatus() ? config.authorizedLayout : config.defaultLayout;
    //const theme = initUserTheme();
    //console.log('STANDARD THEME', config.theme);    console.log('CUSTOM THEME', theme);
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={ClientStore}>
                <BrowserRouter history={history}>
                    <AppProvider>
                        <JumboApp activeLayout={layout}>
                            <JumboTheme>
                                <JumboRTL>
                                    <JumboDialogProvider>
                                        <JumboDialog/>
                                        <SnackbarProvider>
                                            <AppLayout>
                                                <Suspense
                                                    fallback={
                                                        <ButeoSuspenseFallback/>
                                                    }
                                                >
                                                <FitAppRoutes history={history}/>
                                                </Suspense>
                                            </AppLayout>
                                        </SnackbarProvider>
                                    </JumboDialogProvider>
                                </JumboRTL>
                            </JumboTheme>
                        </JumboApp>
                    </AppProvider>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
