import React,{lazy} from 'react';
import {useParams} from "react-router-dom";
import {isEmpty} from "fit/system/UtilityFunctions";
const NewWorkoutBlockGenerator = lazy(()=>import("fit/components/Workout/WorkoutBlockGenerator/WBG"));

const WorkoutBlocksEditor =()=>{
    let props = useParams();
    const workoutBlockID = !isEmpty(props) && props.workoutBlockID != null ? props.workoutBlockID : null;
    const workoutID = !isEmpty(props) && props.workoutID != null ? props.workoutID : null;
    return <NewWorkoutBlockGenerator workoutBlockID={workoutBlockID} workoutID={workoutID}/>
}

export default WorkoutBlocksEditor;