import React, {useState} from 'react';
import {Typography, Collapse} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {CheckCircle, Info, PanoramaFishEye, Cancel, Close, Warning, Chat} from "@mui/icons-material";
import {getStatusAccentColor, getStatusColor, getSpacing} from 'fit/system/UtilityFunctions';

const Alert=({status, visible, headline, message, children, style, allowClose})=>{
	const [closed, setClosed] = useState(false);
	const theme = useTheme();
	const getIcon=(status, style)=>{
		switch(status){
			case 'success':
				return <CheckCircle style={style}/>;
			case 'info':
				return <Info style={style}/>;
			case 'warning':
				return <Warning style={style}/>;
			case 'error':
				return <Cancel style={style}/>;
			case 'description':
				return <Chat style={style}/>;
			case 'noAction':
				return <PanoramaFishEye style={style}/>;
			default:
				return <Info style={style}/>;
		}
	};
	const getColors=()=>{
		const setStatus = status == null || status === '' ? 'noAction' : status;
		const p = theme.palette;
		//console.log('MUI THEME', theme);console.log('THEME MODE', p.mode);
		if(status === 'description'){
			return {bgColor: '#ccc', color: '#111'}
		} else{
			return{bgColor: p[setStatus].main, color: p[setStatus].contrastText}
		}
	}

	const old = false;
	let {color, bgColor} = getColors();
	if(old) {
		color = getStatusAccentColor(status);
		bgColor = getStatusColor(status); //background color
	}

	const iconSize = getSpacing();
	const iconAlignment = {position: 'relative', top: getSpacing('tiny'), color: color};
	const closeAlignment = {position: 'absolute', right: 0, top: 0, cursor: 'pointer', marginTop: '5px', marginRight: '5px', width: iconSize, height: iconSize};
	const radius = `${theme.shape.borderRadius}px`;
	const headerStyle={color: color, fontWeight: 'normal'};
	const messageStyle={color: color};
	const alertStyle = {
		position: 'relative',
		background: bgColor,
		color: color,
		padding: getSpacing('small'), //12px
		borderRadius: radius,
		WebkitBorderRadius: radius,
		width : '100%',
		display: 'block'
		 /* 'border': `1px solid ${color}` */
	};
	const icon = getIcon(status,iconAlignment);
	const alertHeadlines = {
		description: 'Explanation',
		success: 'Success',
		info: 'Notice',
		warning: 'Warning',
		error: 'Error',
		noAction: 'Pause',
	};
	headline = headline !== null ? headline : alertHeadlines[status];
	const closeButton = allowClose === true ? <Close onClick={()=>setClosed(true)} style={closeAlignment} /> : null ;

	if(message != null){
		message = <Typography variant={'body1'} style={messageStyle}>
			{message}
		</Typography>
	}
	return (
		<Collapse in={visible && !closed}>
			<div style={{...style}}>
				<div style={alertStyle}>
					{closeButton}
					<Typography variant="h3" style={headerStyle}>
						{icon} {headline}
					</Typography>
					{message}
					{children}
				</div>
			</div>
		</Collapse>
	)
}

export default Alert;