import React, {useEffect, useState, useCallback, useMemo} from 'react';
import config from "@jumbo/config";
import JumboThemeContext from "@jumbo/components/JumboTheme/JumboThemeContext";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import JumboThemeHeader from "./JumboThemeHeader";
import JumboThemeSidebar from "./JumboThemeSidebar";
import JumboThemeFooter from "./JumboThemeFooter";
import {connect} from "react-redux";
import Theme from "fit/system/Theme";
import {createJumboTheme} from "@jumbo/utils";
import UserPreferences from "fit/system/UserPreferences";



const JumboTheme = ({children}) => {

    const t = new Theme();
    const [buteoTheme, setButeoTheme] = useState(t.getUserTheme());
    const [muiLocale, setMuiLocale] = useState(config.locale);
    const userPrefs = new UserPreferences();
    const colorID = userPrefs.themeColorID;




    useEffect(()=>{
        const theme = t.getUserTheme();
        setButeoTheme(t.getUserTheme());
    },[userPrefs.themeColorID]);


    const updateTheme = useCallback((options) => {
        console.log('UPDATING THEME');
        const newTheme = createTheme({...buteoTheme.main, ...options}, muiLocale);
        setButeoTheme(newTheme);
    }, [buteoTheme, setButeoTheme]);

    const themeContextValue = useMemo(() => ({
        theme: createTheme(buteoTheme.main, muiLocale),
        setTheme: updateTheme,
        setMuiLocale: setMuiLocale,
    }), [buteoTheme, muiLocale]);

    if(false) {
        console.log('-------------------------------------------------------');
        //console.log('BUTEO THEME', buteoTheme);
        console.log('BUTEO SIDEBAR', buteoTheme.sidebar);
        console.log('-------------------------------------------------------');
    }
    return (
        <JumboThemeContext.Provider value={themeContextValue}>
            <ThemeProvider theme={themeContextValue.theme}>
                <JumboThemeHeader init={buteoTheme.header}>
                    <JumboThemeSidebar init={buteoTheme.sidebar} themeColorID={colorID}>
                        <JumboThemeFooter init={buteoTheme.footer}>
                            {children}
                        </JumboThemeFooter>
                    </JumboThemeSidebar>
                </JumboThemeHeader>
            </ThemeProvider>
        </JumboThemeContext.Provider>
    );
};


const mapStateToProps = (state) => {
    return {FITUser: state.FITUser};
};
export default connect(mapStateToProps)(JumboTheme);


//export default JumboTheme;