import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {PAPER_STYLE} from "fit/system/FITConstants";
import Header from "fit/components/Header";
import Leaderboard from "./index";
import SecureConnect from "fit/system/SecureConnect";

const AthleteLeaderboard=()=>{
    const [teamSettings, setTeamSettings] = useState([]);
    useEffect(()=>{
        getTeamSettings();
    },[])
    const getTeamSettings=()=>{
        let sc = new SecureConnect('stat.php?action=getTeamSettings');
        sc.setDisplayNotifications(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                const allSettings = sc.getData(json);
                console.log('TEAM SETTINGS', allSettings);
                //Pull only the team settings where the leaderboard gets shared
                const sharedLeaderboard = allSettings.filter(team =>parseInt(team.shareTeam) === 1);
                setTeamSettings(sharedLeaderboard);
            }
        })
    }
    return (
        <div>
            <Header header={'Team Leaderboard'}/>
            <Paper style={PAPER_STYLE}>
                <Leaderboard teamSettings={teamSettings} athleteLeaderboard={true}/>
            </Paper>
        </div>
    )
}


export default AthleteLeaderboard;