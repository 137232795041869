import React from 'react';
import {useState} from 'react';
import {Box, AppBar, Toolbar, IconButton, Breadcrumbs, Button, Tooltip, ButtonGroup} from "@mui/material";
import {Menu, MenuItem} from "@mui/material";
import moment from 'moment';
import {ArrowForward, ArrowBack, ViewModule, NavigateNext} from "@mui/icons-material";
import {isEmpty, trimURL} from "fit/system/UtilityFunctions";
import {Link} from 'react-router-dom';
import {MOMENT_DATE_FORMAT, URL_ANALYTICS, URL_TEAM_WORKOUTS, URL_TEAM_BLOCKS} from "fit/system/FITConstants";
import UserPreferences from "fit/system/UserPreferences";

const WorkoutNavigationBar=({previousID, nextID, currentWorkoutID, blockList, workoutList, teamID, teamName, subGroupName, blockTitle, blockID, workoutDayIndex, workoutDate})=>{
    const [blockMenuOpen, setBlockMenuOpen] = useState(false);
    const [workoutMenuOpen, setWorkoutMenuOpen] = useState(false);
    const [blockMenuElement, setBlockMenuElement] = useState(null);
    const [workoutMenuElement, setWorkoutMenuElement] = useState(null);
    const formatDate=(date, format)=>{
        return moment(date, MOMENT_DATE_FORMAT).format(format);
    }

    const handleMenuClick=(menu, event: React.MouseEvent<HTMLBaseElement>)=>{
        switch(menu){
            case 'block':
                setBlockMenuOpen(true);
                setBlockMenuElement(event.currentTarget);
                break;
            case 'workout':
                setWorkoutMenuOpen(true);
                setWorkoutMenuElement(event.currentTarget);
                break;
            default:
                setBlockMenuOpen(false);
                setBlockMenuElement(null);
                break;
        }
    }
    const handleMenuClose=(menu)=>{
        switch(menu){
            case 'block':
                setBlockMenuOpen(false);
                setBlockMenuElement(null);
                break;
            case 'workout':
                setWorkoutMenuOpen(false);
                setWorkoutMenuElement(null);
                break;
            default:
                setBlockMenuOpen(false);
                setBlockMenuElement(null);
                break;
        }
    }
    const userPrefs = new UserPreferences();
    const viewingWorkouts = currentWorkoutID != null;
    const nextURL = viewingWorkouts ? `${URL_ANALYTICS}${trimURL(URL_TEAM_WORKOUTS)}${nextID}/` : `${URL_ANALYTICS}${trimURL(URL_TEAM_BLOCKS)}${nextID}/`;
    const previousURL = viewingWorkouts ? `${URL_ANALYTICS}${trimURL(URL_TEAM_WORKOUTS)}${previousID}/` : `${URL_ANALYTICS}${trimURL(URL_TEAM_BLOCKS)}${previousID}/`;

    const nullValues = [null, '', false];

    const hasPrevious = !nullValues.includes(previousID);
    const hasNext = !nullValues.includes(nextID);

    const previousWorkoutMessage = hasPrevious ? 'View Previous Workout' : 'No Previous Workout';
    const nextWorkoutMessage = hasNext ? 'View Next Workout' : 'No Other Workout';

    const buttonColor = 'primary';
    const breadCrumbSeparatorColor = 'secondary'
    const defaultDateFormat = userPrefs.dateFormat;
    const workoutDateFormat = `ddd, ${userPrefs.dateFormat}`;
    const workoutMenuList = !isEmpty(workoutList) && workoutList != null ? workoutList : [];

    if(false) {
        console.log('----------------------- PROPS -----------------------');
        console.log('----------------------- PROPS -----------------------');
        console.log('PREV', previousID, 'NEXT', nextID, 'CURRENT', currentWorkoutID)
        console.log('TEAM', teamName, 'SUB', subGroupName, 'Title', blockTitle, 'BLOCK ID', blockID);
        console.log('W_DAYIndex', workoutDayIndex, 'DATE', workoutDate);
        console.log('NAV WORKOUT LIST', workoutList);
        console.log('NAV BLOCK LIST', blockList);
        console.log('----------------------- PROPS -----------------------');
        console.log('----------------------- PROPS -----------------------');
    }
    //Init Workout BreadCrumb
    let workoutCrumb = null;
    if(viewingWorkouts){
        workoutCrumb =[
                <Tooltip title={'Select Different Workout'} placement={'bottom'}>
                    <Button
                        onClick={(e)=>handleMenuClick('workout', e)}
                        disabled={isEmpty(workoutList)}
                    >
                        Day {parseInt(workoutDayIndex)+1}: {formatDate(workoutDate, workoutDateFormat)}
                    </Button>
                </Tooltip>
            ];
    }

    const teamText = subGroupName == null ? teamName : `${teamName}: ${subGroupName}`;
    //console.log('TEAMID', teamID);

    const backLinkText = viewingWorkouts ? 'Workout Reports' : 'Block Reports';
    const backURL = viewingWorkouts ? `${URL_ANALYTICS}${trimURL(URL_TEAM_WORKOUTS)}` : `${URL_ANALYTICS}${trimURL(URL_TEAM_BLOCKS)}`;

    return (
        <Box sx={{flexGrow: 1}}>

            {/*<AppBar position={'static'} color={'inherit'}>*/}

                <Toolbar sx={{marginLeft: '0 !important!'}}>


                    <Tooltip title={previousWorkoutMessage}>
                        <span><IconButton
                            LinkComponent={Link}
                            to={previousURL}
                            disabled={!hasPrevious}
                            color={buttonColor}
                        ><ArrowBack/></IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={nextWorkoutMessage}>
                        <span><IconButton
                            LinkComponent={Link}
                            to={nextURL}
                            disabled={!hasNext}
                            color={buttonColor}>
                        <ArrowForward/>
                    </IconButton></span>
                    </Tooltip>



                    {/*
                    <ButtonGroup variant={'text'}>
                        <Tooltip title={previousWorkoutMessage} placement={'bottom'}>
                            <Button
                                LinkComponent={Link}
                                to={`/analytics/workouts/${previousID}/`}
                                disabled={!hasPrevious}
                                color={buttonColor}
                            >
                                <ArrowBack/>
                            </Button>
                        </Tooltip>
                        <Tooltip title={nextWorkoutMessage} placement={'bottom'}>
                            <Button
                                LinkComponent={Link}
                                to={`/analytics/workouts/${nextID}/`}
                                disabled={!hasNext}
                                color={buttonColor}
                            >
                                <ArrowForward/>
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                    */}
                    <Breadcrumbs separator={<NavigateNext color={breadCrumbSeparatorColor}/>} aria-label={'breadcrumb'}>
                        <Tooltip title={`Go Back To ${backLinkText}`}>
                            <span><Button
                                component={Link}
                                to={backURL}
                            >
                                {backLinkText}
                            </Button></span>
                        </Tooltip>
                        <ButtonGroup variant={'text'}>
                            <Tooltip title={'Select Different Block'} placement={'bottom'}>
                                <span><Button
                                    onClick={(e)=>handleMenuClick('block', e)}
                                    disabled={isEmpty(blockList)}
                                >
                                    <ViewModule/>
                                </Button></span>
                            </Tooltip>
                            <Tooltip title={'View Block Report'} placement={'bottom'}>
                                <span><Button
                                    disabled={currentWorkoutID == null || parseInt(currentWorkoutID) < 1}
                                    component={Link}
                                    to={`${URL_ANALYTICS}${trimURL(URL_TEAM_BLOCKS)}${blockID}/`}
                                >
                                    {blockTitle}
                                </Button></span>
                            </Tooltip>
                        </ButtonGroup>
                        {workoutCrumb}
                    </Breadcrumbs>

                    {/*WORKOUT BLOCK MENU*/}
                    <Menu
                        anchorEl={blockMenuElement}
                        open={blockMenuOpen}
                        onClose={()=>handleMenuClose('block')}
                    >
                        {
                            blockList.map((item, key)=>{
                                const startDate = formatDate(item.startDate, defaultDateFormat);
                                const endDate = formatDate(item.endDate, defaultDateFormat);
                                const url = `${URL_ANALYTICS}${trimURL(URL_TEAM_BLOCKS)}${item.blockKey}/`;
                                const disabled = blockID === item.blockKey;
                                const blockTitle = item.squadID != null && item.squadLabel.length > 0 ? `${item.squadLabel} | ${item.title}` : item.title;
                                return (
                                    <MenuItem key={key} component={Link} to={url} disabled={disabled} onClick={()=>setBlockMenuOpen(false)}>{blockTitle}: {startDate} - {endDate}</MenuItem>
                                )
                            })
                        }
                    </Menu>
                    {/*WORKOUT LIST MENU*/}
                    <Menu
                        anchorEl={workoutMenuElement}
                        open={workoutMenuOpen}
                        onClose={()=>handleMenuClose('workout')}
                    >
                        {
                            workoutMenuList.map((item, key)=>{
                                const disabled = item.workoutKey === currentWorkoutID || parseInt(item.completed) !== 1;
                                const workoutDate = formatDate(item.dateScheduled, workoutDateFormat);
                                const url = `${URL_ANALYTICS}${trimURL(URL_TEAM_WORKOUTS)}${item.workoutKey}/`;
                                return (
                                    <MenuItem key={key} component={Link} disabled={disabled} to={url} onClick={()=>setWorkoutMenuOpen(false)}>Day: {parseInt(item.dayIndex)+1}: {workoutDate}</MenuItem>
                                )
                            })
                        }
                    </Menu>
                </Toolbar>
            {/*</AppBar>*/}
        </Box>
    );
}

export default WorkoutNavigationBar;

