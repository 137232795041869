import React, {useEffect, useState} from 'react';
import User from 'fit/system/User';
import {PERM_DEPT, PERM_TEAM, ATHLETE_MANAGER_LABELS, DEPT_MINIMUM_REQUIRED_LEVELS, OPERATIONS_LEVEL, DEVICE_MASTER_LEVEL, DEVICE_ACCOUNT_LABEL} from "fit/system/FITConstants";
import {Checkbox, FormControlLabel, FormGroup, Grid, Tooltip, TextField, MenuItem, InputAdornment} from "@mui/material";
import {getSpacing, isEmpty, getStatusIcon, getStatusColor} from "fit/system/UtilityFunctions";
import {Block} from "@mui/icons-material";

const PermissionEntry=({userID, sectionID, active, type, assignedPermissions, toggleFn, rankFn, athlete, deviceAccount, hasActiveDept, locked})=>{
    //console.log('ASSIGNED PERMISSIONS', assignedPermissions);
    const getAssignedTeamRankString=(managerLevel, active)=>{
        const none = 'Unspecified';
        if(active === 0){
            return none;
        }
        managerLevel = parseInt(managerLevel);
        const list = ATHLETE_MANAGER_LABELS.find(item => parseInt(item.value) === managerLevel);
        return list != null && list.label != null ? list.label : none;
    }
    const getManagerRanges=()=>{
        const {deptID} = assignedPermissions;
        const dept = DEPT_MINIMUM_REQUIRED_LEVELS.filter(dept => parseInt(dept.deptID) === parseInt(deptID));
        if(dept.length > 0){
            return {min: dept[0].minimum, max: dept[0].maximum}
        }
        return {min: null, max: null};
    }
    const limitMenuOptions=()=>{
        //LIMIT THE options on what the user/viewer can select based on compared permissions
        //Get the viewer's/user's permissions for the specific group
        const {min, max} = getManagerRanges();
        const user = new User();
        const viewerLevel = parseInt(user.getPermissions(type)[sectionID].managerLevel);
        //Only allow permissions that are equal to or less than the viewer's managerLevel
        let allowedPermissions = [];
        ATHLETE_MANAGER_LABELS.forEach(option => {
            const optionValue = parseInt(option.value);
            const minimumMet = min == null || optionValue >= parseInt(min);
            const withinMax = max == null || optionValue <= parseInt(max);
            if(minimumMet && withinMax && optionValue <= viewerLevel){
                //Disable options that are at the viewer's level - Cannot equalize or go above viewer's permissions
                const disabled = optionValue === viewerLevel;
                allowedPermissions.push({...option, disabled});
            }
        });
        return allowedPermissions;
    }
    const user = new User();
    const sportSpecificCoach = user.isSportSpecificCoach(OPERATIONS_LEVEL);
    const operationsLevel = user.getManagerDepts(OPERATIONS_LEVEL).length > 0;

    const settingUnlocked = locked == null || !locked;

    //console.log('PERM ENTRY:::', 'OP LEVEL', operationsLevel, 'SPORT SPEC COACH', sportSpecificCoach, type, assignedPermissions);
    //console.log('TEAM ENTRY', type===PERM_TEAM, 'ATHLETE?', athlete, 'SPORT SPECIFIC COACH?', sportSpecificCoach);
    const viewingUserID = user.getUserID();
    const isSelf = parseInt(viewingUserID) === parseInt(userID);
    const viewerPermissions = user.getPermissions(type)[sectionID];
    //console.log('VIEWER PERMISSION SET:', sectionID, viewerPermissions);
    const overridden = assignedPermissions.override != null && parseInt(assignedPermissions.override) === 1;
    const divStyle = {marginTop: 0};
    const groupLabel = `${viewerPermissions.orgShortName} ${viewerPermissions.label}`;
    const menuOptions = type === PERM_DEPT ? limitMenuOptions() : [];
    //Disable items if the viewer isn't a sport specific coach and the entry is a team
    const athleteDisabled = type === PERM_TEAM && athlete && sportSpecificCoach === false;
    const noActiveDeptDisabled = hasActiveDept !== true; //type === PERM_TEAM && hasActiveDept !== true;
    const disabled = athleteDisabled || noActiveDeptDisabled || operationsLevel === false || isSelf; //isSelf;
    const disabledText = disabled ? '(Disabled)':'';
    const teamRankDisabled = type === PERM_TEAM;
    let checkboxTip = `Toggle ${groupLabel} ${disabledText}`;
    let overrideProps = null;
    if(overridden){
        checkboxTip = `Overridden By ${assignedPermissions.orgShortName} ${assignedPermissions.overrideDeptName} Department`;
        const status = 'info';
        const color = getStatusColor(status);
        const icon = getStatusIcon(status, {color});
        overrideProps = {
            endAdornment: <InputAdornment position={'end'}>
                <Tooltip title={checkboxTip} placement={'bottom'}>
                    {icon}
                </Tooltip>
            </InputAdornment>
        }
    }
    if(!settingUnlocked){
        checkboxTip = 'System Locked: Uneditable';
    }


    const inputLabel = `${viewerPermissions.label} Rank`;
    const setManagerLevel = isEmpty(assignedPermissions) ? null : assignedPermissions.managerLevel;
    const activeStatus = isEmpty(assignedPermissions) ? 0 : parseInt(assignedPermissions.active);


    return (
        <div style={divStyle}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <FormGroup style={divStyle}>
                        <FormControlLabel
                            label={groupLabel}
                            labelPlacement={'start'}
                            control={
                                <Tooltip
                                    title={checkboxTip}
                                    enterDelay={300}
                                    placement={'right'}
                                >
                                    <span>
                                    { settingUnlocked  ?
                                        <Checkbox
                                            checked={active}
                                            onChange={() => toggleFn(active, sectionID)}
                                            name={'toggle'}
                                            value={1}
                                            color={'primary'}
                                            disabled={disabled || overridden === true}
                                        />
                                        :
                                        <Checkbox
                                            checked={active}
                                            name={'toggle'}
                                            color={'secondary'}
                                            disabled={true}
                                        />
                                    }
                                    </span>
                                </Tooltip>
                            }
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={7}>
                    {active && type === PERM_DEPT && overridden === false ?
                        <TextField
                            fullWidth={true}
                            size={'small'}
                            select={true}
                            label={inputLabel}
                            value={setManagerLevel}
                            onChange={(e) => rankFn(e, sectionID)}
                            disabled={disabled || !settingUnlocked || teamRankDisabled || active === false}
                        >
                            {
                                deviceAccount ?
                                <MenuItem value={DEVICE_MASTER_LEVEL}>{DEVICE_ACCOUNT_LABEL}</MenuItem>
                                :
                                menuOptions.map((item, k) => {
                                const optionDisabled = disabled || item.disabled;
                                return (
                                    <MenuItem key={k} value={item.value}
                                              disabled={optionDisabled}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                        :
                        <TextField
                            fullWidth={true}
                            size={'small'}
                            label={inputLabel}
                            value={getAssignedTeamRankString(setManagerLevel, activeStatus)}
                            disabled={true}
                            InputProps={overrideProps}
                        />
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default PermissionEntry;