import React from 'react';
import {useState, useEffect} from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    MenuItem,
    Collapse,
    FormLabel,
    RadioGroup,
    FormControlLabel, Radio, FormControl
} from "@mui/material";
import SecureConnect from "fit/system/SecureConnect";
import {getSpacing, isEmpty} from 'fit/system/UtilityFunctions';
import Modal from 'fit/components/Dialogs/Modal';
import Alert from 'fit/components/Alert';
import {Form, Formik} from "formik";
import * as yup from "yup";
import MeasurementUnits from 'fit/system/MeasurementUnits'
import MeasurementField from "../Form/MeasurementField";


const validationSchema = yup.object().shape({
    exerciseName: yup.string()
        .required('Exercise name required')
        .min('4','Minimum of 4 characters')
        .max('60','Maximum of 60 characters'),
    exerciseShortName: yup.string()
        .min('2','Minimum of 2 characters')
        .max('20','Maximum of 20 characters'),
    exerciseStyleID: yup.string()
        .nullable()
        .required('Exercise style required'),
    active: yup.string()
        .required('Exercise status required'),
    repTypeID: yup.string()
        .nullable()
        .required('Repetition type required'),
    repUnitID: yup.string()
        .nullable()
        .required('Repetition unit required'),
    measurementTypeID: yup.string()
        .nullable()
        .required('Measurement type required'),
    measurementUnitID: yup.string()
        .nullable()
        .required('Measurement units required'),
    recordWeightDefault: yup.string()
        .nullable()
        .required('Required')
});



const ExerciseEditModal=(props)=>{
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [exerciseName, setExerciseName] = useState('');
    const [exerciseShortName, setExerciseShortName] = useState('');
    const [exerciseStyleID, setExerciseStyleID] = useState(null);
    const [repTypeID, setRepTypeID] = useState(null);
    const [repUnitID, setRepUnitID] = useState(null);
    const [measurementTypeID, setMeasurementTypeID] = useState(null);
    const [measurementUnitID, setMeasurementUnitID] = useState(null);
    const [allowPercentage, setAllowPercentage] = useState(0);
    const [recordWeightDefault, setRecordWeightDefault] = useState(1);
    const [recordSingleWeight, setRecordSingleWeight] = useState(1);
    const [eachEnabled, setEachEnabled] = useState(0);
    const [fixedRepValueEnabled, setFixedRepValueEnabled] = useState(false);
    const [fixedRepValue, setFixedRepValue] = useState(0);
    const [active, setActive] = useState(1);
    const [exercisePreviouslyExecuted, setExercisePreviouslyExecuted] = useState(false);

    useEffect(()=>{
        if(props.display === true){
            //console.log('MODAL PROPS', props);
        }
        if(props.display === true && props.exerciseID != null){
            setExerciseName(props.name);
            setExerciseShortName(props.shortName);
            setExerciseStyleID(props.exerciseStyleID);
            setRepTypeID(props.repTypeID);
            setRepUnitID(props.repUnitID);
            setMeasurementTypeID(props.measurementTypeID);
            setMeasurementUnitID(props.measurementUnitID)
            setRecordWeightDefault(props.recordWeightDefault);
            setRecordSingleWeight(props.recordSingleWeight);
            setEachEnabled(props.eachEnabled);
            setActive(props.active);

            console.log('PROPS', props);

            //Fixed Rep value logic
            const fixedReps = props.fixedRepValue != null;
            const fixedValue = fixedReps ? props.fixedRepValue : 0;
            setFixedRepValueEnabled(fixedReps);
            setFixedRepValue(fixedValue);

            const previouslyExecuted = props.exercisePreviouslyExecuted === 1;
            setExercisePreviouslyExecuted(previouslyExecuted);
        }
        if(props.display === false){
            initModal();
        }
    }, [props.display])
    const initModal=()=>{
        setExerciseName('');
        setExerciseShortName('');
        setExerciseStyleID(null);
        setRepTypeID(null);
        setRepUnitID(null);
        setFixedRepValueEnabled(false);
        setFixedRepValue(0);
        setMeasurementTypeID(null);
        setMeasurementUnitID(null);
        setRecordWeightDefault(1);
        setRecordSingleWeight(0);
        setEachEnabled(0);
        setActive(1);
        setExercisePreviouslyExecuted(false);
    }
    const handleModalValue=(e)=>{
        const {name, value} = e.target;
        switch(name){
            case 'exerciseName':
                setExerciseName(value);
                break;
            case 'exerciseShortName':
                setExerciseShortName(value);
                break;
            case 'exerciseStyleID':
                setExerciseStyleID(value);
                break;
            case 'repTypeID':
                console.log('SETTING REP TYPE', value);
                setRepTypeID(value);
                //Reset Rep Label When type changes
                const singleRepUnit = checkUnitOptions(value);
                if(singleRepUnit != null){
                    console.log('SETTING REP UNIT ID == ', singleRepUnit);
                    setRepUnitID(singleRepUnit);
                } else{
                    setRepUnitID(null);
                }
                break;
            case 'repUnitID':
                setRepUnitID(value);
                break;
            case 'measurementTypeID':
                setMeasurementTypeID(value);
                const mUnits = new MeasurementUnits();
                console.log('CHECKING WEIGHT', mUnits.isWeightByTypeID(value));
                const singleMeasurementUnit = checkUnitOptions(value);
                if(singleMeasurementUnit != null){
                    setMeasurementUnitID(singleMeasurementUnit);
                } else{
                    //Reset MeasurementLabel
                    setMeasurementUnitID(null);
                }
                if(mUnits.isWeightByTypeID(value) === false){
                    //Disable Percentage calculations for exercises other than weight lifting
                    setAllowPercentage(0);
                } else{
                    setAllowPercentage(1);
                    setEachEnabled(0); //Not Single Arm/Single Leg
                    setRecordSingleWeight(0); //Record Weight Total
                }
                break;
            case 'measurementUnitID':
                setMeasurementUnitID(value);
                break;
            case 'allowPercentage':
                setAllowPercentage(value);
                break;
            case 'recordWeightDefault':
                setRecordWeightDefault(value);
                break;
            case 'recordSingleWeight':
                setRecordSingleWeight(value);
                break;
            case 'eachEnabled':
                setEachEnabled(value);
                if(value === 0){
                    //On by default
                    setRecordSingleWeight(0);
                }
                break;
            case 'active':
                setActive(value);
                break;
        }
    }
    const checkUnitOptions=(typeID)=>{
        let mUnits = new MeasurementUnits();
        const units = mUnits.getMeasurementUnitsByTypeID(typeID);
        if(!isEmpty(units) && units.length === 1){
            return units[0].id; //Return the single option ID
        }
        return null;
    }
    const submitFunction=()=>{
        const hasExerciseID = props.exerciseID != null;
        setFormSubmitting(true);
        const data={
            name: exerciseName,
            shortName: exerciseShortName,
            exerciseStyleID,
            repTypeID,
            repUnitID,
            fixedRepValue: fixedRepValue > 0 ? fixedRepValue : null,
            measurementTypeID,
            measurementUnitID,
            allowPercentage,
            recordWeightDefault,
            recordSingleWeight,
            eachEnabled,
            active
        }
        if(hasExerciseID){
            data.exerciseID = props.exerciseID;
        }
        let sc = new SecureConnect('testapi.php','post');
        sc.setAction('submitExerciseData');
        sc.setFormData(data);
        sc.connect().then(json=>{
            const completed = sc.getCompleted(json);
            const noChange = sc.getResponseCode(json) === 2;
            if(completed || noChange){
                //Reset Modal Data
                initModal();
                //Close Modal
                closeFunction();
            }
            if(completed && props.loadDataFunction != null){
                //Reload the table
                props.loadDataFunction();
            }
            setFormSubmitting(false);
        })
    }
    const closeFunction=()=>{
        props.closeFunction();
    }
    //NEW EXERCISE UNITS
    let mUnits = new MeasurementUnits();
    //Rep Settngs
    const repTypes = mUnits.getMeasurementTypes();
    const hasRepTypes = !isEmpty(repTypes);
    const repSet = parseInt(repTypeID) > 0;
    const repUnits = mUnits.getMeasurementUnitsByTypeID(repTypeID);
    //Measurement Settings (Types & Units)
    const measurementTypes = mUnits.getMeasurementTypes();
    const hasUnits = !isEmpty(measurementTypes);
    const measurementSet = parseInt(measurementTypeID) > 0;
    const measurementUnits = mUnits.getMeasurementUnitsByTypeID(measurementTypeID);
    const isWeight = mUnits.isWeightByTypeID(measurementTypeID);
    //Styling and Display
    const alertStyle={marginTop: getSpacing('small')};
    const editing = props.exerciseID != null && parseInt(props.exerciseID) > 0;
    const header = editing ? 'Edit An Exercise' : 'Create A New Exercise';
    const actionTitle = editing ? 'Edit Exercise' : 'Create Exercise';
    const prompt = editing ? 'Update an exercise' : 'Create a new exercise';
    const hVariant = 'h5';
    const selectorShrinkValue = true;



    return (
        <Modal
            open={props.display}
            title={actionTitle}
            closeFunction={closeFunction}
            modalActions={
                <Button
                    color={'secondary'}
                    variant={'contained'}
                    onClick={closeFunction}
                >Cancel</Button>
            }
        >
            <Formik
                initialValues={{exerciseName, exerciseShortName, exerciseStyleID, active, repTypeID, repUnitID, measurementTypeID, measurementUnitID, recordWeightDefault, eachEnabled, recordSingleWeight}}
                validationSchema={validationSchema}
                onSubmit={submitFunction}
                enableReinitialize={true}
            >
                {({ errors, touched}) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'h1'}>{header}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'body1'}>
                                    {prompt} by providing the following information.
                                    <br/><strong>Notes:</strong>
                                </Typography>
                                <ul>
                                    <li>A <strong>Shorthand Exercise Name</strong> isn't required, but will aid in finding your exercise when using the autocomplete.</li>
                                    <li>Measurement types and units are only required if you're recording the data of your athletes.</li>
                                    <li>Recording data is on by default.</li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert
                                    status={'info'}
                                    visible={exercisePreviouslyExecuted}
                                    headline={'Some Form Elements Are Disabled'}
                                    message={"This exercise has been previously executed by athletes. Some form elements for labeling and measuring data have been disabled to protect athlete data."}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={hVariant}>Exercise Basics</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={'Full Exercise Name'}
                                    name={'exerciseName'}
                                    value={exerciseName}
                                    disabled={formSubmitting || exercisePreviouslyExecuted}
                                    placeholder={'Enter the full name of the exercise (example: "Medicine Ball Overhand Throws")'}
                                    onChange={(e)=>handleModalValue(e)}
                                    error={errors.exerciseName && touched.exerciseName}
                                    helperText={errors.exerciseName || ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={'Shorthand Exercise Name'}
                                    name={'exerciseShortName'}
                                    value={exerciseShortName}
                                    disabled={formSubmitting}
                                    placeholder={'Enter a short name for the exercise (example: "MB OH Throws")'}
                                    onChange={(e)=>handleModalValue(e)}
                                    error={errors.exerciseShortName && touched.exerciseShortName}
                                    helperText={errors.exerciseShortName || ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    label={'Exercise Style'}
                                    name={'exerciseStyleID'}
                                    value={exerciseStyleID}
                                    disabled={formSubmitting || exercisePreviouslyExecuted}
                                    onChange={(e)=>handleModalValue(e)}
                                    error={errors.exerciseStyleID && touched.exerciseStyleID}
                                    helperText={errors.exerciseStyleID || ''}
                                    InputLabelProps={{shrink: selectorShrinkValue}}
                                >
                                    {
                                        isEmpty(props.exerciseStyles) ?
                                            <MenuItem value={0}>Unknown</MenuItem> :
                                            props.exerciseStyles.map((item, k)=>{
                                                return <MenuItem value={item.lv_ID} key={k}>{item.label}</MenuItem>
                                            })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    label={'Status: Displayed/Hidden'}
                                    name={'active'}
                                    value={active}
                                    disabled={formSubmitting}
                                    onChange={(e)=>handleModalValue(e)}
                                    error={errors.active && touched.active}
                                    helperText={errors.active || ''}
                                    InputLabelProps={{shrink: selectorShrinkValue}}
                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={0}>Disabled: Unable to be selected for workouts</MenuItem>
                                </TextField>
                                <Alert
                                    style={alertStyle}
                                    status={'warning'}
                                    visible={active===0}
                                    headline={'This Exercise Will Be Unavailable!'}
                                    message={"Disabling this exercise will prevent coaches from accessing it when creating workouts."}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    label={'Capture/Record Lift Data By Default'}
                                    name={'recordWeightDefault'}
                                    value={recordWeightDefault}
                                    disabled={formSubmitting}
                                    onChange={(e)=>handleModalValue(e)}
                                    error={errors.recordWeightDefault && touched.recordWeightDefault}
                                    InputLabelProps={{shrink: selectorShrinkValue}}
                                >
                                    <MenuItem value={1}>Yes. Log Athlete Data.</MenuItem>
                                    <MenuItem value={0}>No. Don't Capture Data. Just mark as completed.</MenuItem>
                                </TextField>
                                <Alert
                                    status={'warning'}
                                    style={alertStyle}
                                    visible={recordWeightDefault === 0}
                                    headline={"Athlete Data Not Recorded By Default"}
                                    message={"Athletes won't be required to log their workout data by default. This can still be enabled when adding the exercise in a workout block. It's usually better to keep this enabled."}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={hVariant}>Rep Settings</Typography>
                                Repetitions may be a basic count. They may also be holding a position for a period of time, or running a specific distance. Determine this setting here.
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label={'Repetition Type'}
                                    name={'repTypeID'}
                                    value={repTypeID}
                                    disabled={formSubmitting || exercisePreviouslyExecuted}
                                    onChange={(e)=>handleModalValue(e)}
                                    placeholder={'Rep Type'}
                                    error={errors.repTypeID && touched.repTypeID}
                                    helperText={errors.repTypeID || ' '}
                                    InputLabelProps={{shrink: selectorShrinkValue}}
                                >
                                    {
                                        isEmpty(repTypes) ?
                                            <MenuItem value={0}>Unavailable</MenuItem>
                                            :
                                            repTypes.map((type,k)=>{
                                                return <MenuItem value={type.typeID} key={k}>{type.name}</MenuItem>
                                            })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label={'Repetition Unit'}
                                    name={'repUnitID'}
                                    value={repUnitID}
                                    disabled={isEmpty(repUnits) || formSubmitting || exercisePreviouslyExecuted}
                                    placeholder={'Unit Label'}
                                    onChange={(e)=>handleModalValue(e)}
                                    error={errors.repUnitID && touched.repUnitID}
                                    helperText={errors.repUnitID || repSet === false ? 'Select Repetition Type First': ' '}
                                    InputLabelProps={{shrink: selectorShrinkValue}}
                                >
                                    {
                                        repSet === false || hasRepTypes === false ?
                                            <MenuItem value={0}>Select Repetition Type</MenuItem>
                                            :
                                            repUnits.map((item,k) =>{
                                                return <MenuItem value={item.id} key={k}>{item.name}</MenuItem>
                                            })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>Lock "Rep Value"</FormLabel>
                                    <Typography variant={'body1'}>Explain Rep Values in greater detail</Typography>
                                    <RadioGroup>
                                        <FormControlLabel control={<Radio color={'primary'} checked={!fixedRepValueEnabled} onChange={()=>setFixedRepValueEnabled(false) }/>} label={'Off: Lessen security'}/>
                                        <FormControlLabel control={<Radio color={'secondary'} checked={fixedRepValueEnabled} onChange={()=>setFixedRepValueEnabled(true)} />} label={'On: Increase my account security'} />
                                    </RadioGroup>
                                </FormControl>

                                <Collapse in={fixedRepValueEnabled}>
                                    <MeasurementField
                                        measurementID={repUnitID}
                                        name={'fixedRepValue'}
                                        value={fixedRepValue}
                                        precision={0}
                                        changeFn={(value)=>setFixedRepValue(value)}
                                    />

                                </Collapse>

                            </Grid>



                            <Grid item xs={12}>
                                <Typography variant={'h5'}>Measurement Settings</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label={'Measurement Type'}
                                    name={'measurementTypeID'}
                                    value={measurementTypeID}
                                    disabled={formSubmitting || exercisePreviouslyExecuted}
                                    onChange={(e)=>handleModalValue(e)}
                                    placeholder={'Measurement Type'}
                                    error={errors.measurementTypeID && touched.measurementTypeID}
                                    helperText={errors.measurementTypeID || ' '}
                                    InputLabelProps={{shrink: selectorShrinkValue}}
                                >
                                    {
                                        isEmpty(measurementTypes) ?
                                            <MenuItem value={0}>Unavailable</MenuItem>
                                        :
                                        measurementTypes.map((type,k)=>{
                                            return <MenuItem value={type.typeID} key={k}>{type.name}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label={'Measurement Unit'}
                                    name={'measurementUnitID'}
                                    value={measurementUnitID}
                                    disabled={measurementSet === false || formSubmitting || exercisePreviouslyExecuted}
                                    placeholder={'Unit Label'}
                                    onChange={(e)=>handleModalValue(e)}
                                    error={errors.measurementUnitID && touched.measurementUnitID}
                                    helperText={errors.measurementUnitID || measurementSet === false ? 'Select Measurement Type First': ' '}
                                    InputLabelProps={{shrink: selectorShrinkValue}}
                                >
                                    {
                                        measurementSet === false || hasUnits === false ?
                                            <MenuItem value={0}>Select Measurement Type</MenuItem>
                                            :
                                            measurementUnits.map((item,k) =>{
                                                return <MenuItem value={item.id} key={k}>{item.name}</MenuItem>
                                            })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={ isWeight === true}>
                                    <TextField
                                        select
                                        fullWidth
                                        label={'Enable Percent Max Calculations'}
                                        name={'allowPercentage'}
                                        value={allowPercentage}
                                        disabled={formSubmitting}
                                        onChange={(e)=>handleModalValue(e)}
                                        error={errors.allowPercentage && touched.allowPercentage}
                                        InputLabelProps={{shrink: selectorShrinkValue}}
                                    >
                                        <MenuItem value={1}>ON: Enable Percentage Calculations</MenuItem>
                                        <MenuItem value={0}>OFF: Disable Percentage Calculations</MenuItem>
                                    </TextField>
                                </Collapse>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={hVariant}>Each Arm / Each Leg</Typography>
                                <Typography variant={'body1'}>
                                    This will create an "each" notification for the athlete (i.e. "3x6each").
                                    Turn this on when the the athlete is to perform a certain number of reps for each arm or leg (example: lunges or single arm rows).
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    label={'"Each" Enabled: Single Arm/Single Leg Exercise'}
                                    name={'eachEnabled'}
                                    value={eachEnabled}
                                    disabled={formSubmitting || exercisePreviouslyExecuted}
                                    onChange={(e)=>handleModalValue(e)}
                                    error={errors.eachEnabled && touched.eachEnabled}
                                    InputLabelProps={{shrink: selectorShrinkValue}}
                                >
                                    <MenuItem value={0}>Off: Reps are NOT performed by a single arm or leg</MenuItem>
                                    <MenuItem value={1}>On: Perform reps for each arm/leg</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={eachEnabled === 1}>
                                    <TextField
                                        select
                                        fullWidth
                                        label={'Record Single/Partial Weight?'}
                                        name={'recordSingleWeight'}
                                        value={recordSingleWeight}
                                        disabled={eachEnabled === 0 || formSubmitting || exercisePreviouslyExecuted}
                                        onChange={(e)=>handleModalValue(e)}
                                        error={errors.recordSingleWeight && touched.recordSingleWeight}
                                        InputLabelProps={{shrink: selectorShrinkValue}}
                                    >
                                        <MenuItem value={0}>OFF: Record Total Weight. (Dumbbell Lunges, Bulgarian Split Squat)</MenuItem>
                                        <MenuItem value={1}>ON: Record Single Weight. (Single Arm Rows, Bicep Curls) </MenuItem>
                                    </TextField>
                                </Collapse>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert
                                    status={'error'}
                                    visible={!isEmpty(errors)}
                                    headline={'Form Has Errors'}
                                    message={'Please correct the errors before resubmitting.'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    color={'primary'}
                                    type={'submit'}
                                    variant={'contained'}
                                    fullWidth={true}
                                    disabled={formSubmitting}
                                >{actionTitle}</Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default ExerciseEditModal;