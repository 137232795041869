import React, {lazy} from 'react';
import {useState, useEffect} from "react";
import {
    Typography,
    Grid,
    Paper,
    Tabs,
    Tab, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import TabBox from "../TabBox";
import TabSection from "../TabSection";
import Link from 'fit/components/System/Link';
import {Link as RouterLink, Navigate} from 'react-router-dom';
import User from 'fit/system/User';
import SecureConnect from 'fit/system/SecureConnect';

import {getSpacing, setPageTitle, isEmpty, setPermissionsActive} from 'fit/system/UtilityFunctions';
import {
    FitnessCenter,
    Bedtime,
    MonitorHeart,
    QueryStats,
    AccountCircle,
    Settings, Person,
    ExpandMore, TabletAndroid
} from '@mui/icons-material';
import Alert from "fit/components/Alert";
import {
    PAPER_STYLE,
    PERM_SET,
    PERM_DEPT,
    PERM_TEAM,
    ATHLETE_ASSIGNMENT_TYPES, URL_ONBOARDING,
    URL_ATHLETE_ASSIGNMENTS, URL_MY_PROFILE, URL_PROFILES, ICON_SX
} from "fit/system/FITConstants";
import {connect} from "react-redux";
import UserDetails from "./UserDetails";
import DeniedRoute from "fit/routes/system/DeniedRoute";
import AthleteWorkoutResults from "fit/components/Analytics/Workout/AthleteWorkoutResults";
import UserPermissions from "./Permissions/UserPermissions";
import AthleteAssignments from "./AthleteAssignments";
import AthleteEligibilityForm from "./AthleteEligibilityForm";
import Header from 'fit/components/Header/';
import ProfilePrefs from "./Preferences/ProfilePrefs";
import PreferencesAccordion from "../PreferencesAccordion";

const DynamicDataTable = lazy(()=>import('fit/components/DynamicDataTable/'));
const GraphTrend  = lazy(()=>import("fit/components/Analytics/StatReporting/GraphTrend"));


const initAthleteSpecificWorkoutModal =()=>{
    return {
        display: false,
        userID: null,
        workoutID: null
    }
}
const initUserInfo=()=>{
    return {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        onboarded: false,
    }
}
const initStatuses=()=> {
    return {
        athlete: false,
        deviceAccount: false,
        hasActiveDept: true,
        hasActiveTeam: true,
    }
}


const UserProfile =({urlKey, self, section})=>{
    const [denied, setDenied] = useState(false);
    const [loadData, setLoadData] = useState(true);
    const [userID, setUserID] = useState(null);
    const [userInfo, setUserInfo] = useState(initUserInfo);
    const [statuses, setStatuses] = useState(initStatuses);

    const [lastLogin, setLastLogin] = useState(null);
    const [userPermissions, setUserPermissions] = useState([]);
    const [athleteAssignments, setAthleteAssignments] = useState([]);
    const [athleteAffiliations, setAthleteAffiliations] = useState([]);
    const [athleteAffiliationLabels, setAthleteAffiliationLabels] = useState([]);
    const [deviceData, setDeviceData] = useState({});

    //console.log('URL KEY :: ',urlKey,' USERID', userID);


    const [tabSection, setTabSection] = useState(1);

    //Workout Data
    const [athleteSpecificWorkoutModal, setAthleteSpecificWorkoutModal] = useState(initAthleteSpecificWorkoutModal)
    const user = new User();

    //Component Did Mount-
    useEffect(()=>{
        //SET THE USER ID
        //User hasn't onboarded
        if(user.getOnboarded()){
            getUser(urlKey);
        }
    }, [urlKey]);
    useEffect(()=>{
        const tab = section ? section : 'overview';
        setTabSection(tab);
    })

    const getUser =(urlKey)=>{
        const queryString = urlKey == null ?  'getMyProfile' : `getProfile&userID=${urlKey}`;
        let sc = new SecureConnect(`user.php?action=${queryString}`,'get');
        sc.setDisplayNotifications(false);
        sc.connect().then(json => {
                const response = sc.getResponse(json);
                const code = sc.getResponseCode(json);
                const deniedCodes = [4, 403];
                //console.log('RESPONSE', response);
                if(deniedCodes.includes(code)){ //ERROR - DENIED OR DOESNT EXIST
                    setDenied(true);
                }
                if(sc.getCompleted(json)) {
                    applyUserData(sc.getData(json));
                }
            }
        )
    }
    const getAthleteLabels=()=>{
        //Load athlete labels (affiliation settings) for athletes
        let sc = new SecureConnect('team.php?action=getAthleteAffiliationLabels');
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json =>{
            const data = sc.getCompleted(json) ? sc.getData(json) : [];
            setAthleteAffiliationLabels(data);
        })
    }
    const applyUserData =(userData)=>{
        //console.log('USER DATA', userData);
        const {firstName, lastName, email, phone, lastLogin, userID} = userData;
        const onboarded = parseInt(userData.onboarded) === 1;
        console.log('DATA', userData);
        setUserID(userID);
        const deptPermissions = setPermissionsActive(userData[PERM_SET][PERM_DEPT]);
        const teamPermissions = setPermissionsActive(userData[PERM_SET][PERM_TEAM]);
        let clientPermissions = {
            [PERM_DEPT]: deptPermissions,
            [PERM_TEAM]: teamPermissions
        }
        //Determine if the user is an athlete - to load assignments/etc
        const user = new User;
        let athlete = false;
        let activeDept = false;
        const deptKeys = Object.keys(deptPermissions);
        deptKeys.forEach(key =>{
            const dept = deptPermissions[key];
            const {deptID} = dept;
            const active = dept.active != null && parseInt(dept.active) === 1;
            const athleteTest = user.checkAthleteDept(deptID) && active != null && active;
            if(active){
                activeDept = true;
            }
            if(athleteTest){
                athlete = true;
            }
        });
        //Determine if the user has access to teams
        const teamKeys = Object.keys(teamPermissions);
        let activeTeam = false;
        for(let k=0; k<teamKeys.length; k++){
            const key = teamKeys[k];
            const team = teamPermissions[key];
            if(team.active != null && parseInt(team.active) === 1){
                activeTeam = true;
                break;
            }
        }


        //Athlete Specific Variables
        if(athlete) {
            setAthleteAssignments(userData.assignments);
            getAthleteLabels();
        }

        //Set User Info
        //setUserID(userID);
        setLastLogin(lastLogin);
        setUserInfo({
            firstName,
            lastName,
            email,
            phone,
            onboarded
        });
        //Set Permissions
        setUserPermissions(clientPermissions);
        //Set Athlete Affiliations
        setAthleteAffiliations(userData.affiliations);
        //Set Statuses for User
        setStatuses({
            athlete: athlete,
            deviceAccount: userData.deviceAccount,
            hasActiveDept: activeDept,
            hasActiveTeam: activeTeam,
        })
    }
    const handleEligibilityInformation=(arrayKey, affiliationData)=>{
        let affiliations = [...athleteAffiliations];
        affiliations.splice(arrayKey, 1, affiliationData);
        console.log('AFFILIATIONS', affiliations);
        setAthleteAffiliations(affiliations);
    }
    const submitEligibilityInformation=(key)=>{
        let sc = new SecureConnect('user.php','post');
        sc.setAction('editAthleteEligibility');
        const formData = athleteAffiliations[key];
        sc.setFormData(formData);
        sc.connect().then(json=>{

        });
    }
    const submitPermissionChange=(type, organizationID, sectionID, managerLevel, active)=>{
        //console.log('SUBMITTING PERMISSION CHANGE', type, organizationID, sectionID, managerLevel, active);
        let sc = new SecureConnect('user.php', 'post');
        sc.setAction('updateUserPermissions');
        const formData = {userID, organizationID, sectionID, managerLevel, active};
        sc.setFormData(formData);
        sc.connect().then(json =>{
            if(sc.getCompleted(json)){
                const userData = sc.getData(json);
                //console.log('USER DATA', userData);
                applyUserData(userData);
            }
        });
    }
    const handleAthleteAssignment=(teamID, assignmentID, groupTypeID, active)=>{
        //console.log('HANDLE ATHLETE ASSIGNMENT', teamID, assignmentID, groupTypeID, active);
        let sc = new SecureConnect('team.php', 'post');
        sc.setAction('setAthleteAssignment');
        sc.setFormData({teamID, userID, assignmentID, groupTypeID, active, fromAthleteProfile: 1});
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                const data = sc.getData(json);
                setAthleteAssignments(data);
            }
        })
    }
    const viewSpecificWorkout=(userID, workoutID)=>{
        setAthleteSpecificWorkoutModal({
            display: true,
            userID,
            workoutID
        });
    }
    const hideSpecificWorkout=()=>{
        setAthleteSpecificWorkoutModal(initAthleteSpecificWorkoutModal);
    }
    const getProfileLink=(route)=>{
        const self = userID === user.getUserID();
        const prefix = self ? URL_MY_PROFILE : `${URL_PROFILES}${urlKey}/`;
        const address = `${prefix}${route}`;
        //console.log('___TAB ADDRESS', 'SELF?', self, prefix, address);
        return address
    }
    const getTabValue=(permissionsSet, activeTab)=>{
        const index = permissionsSet.findIndex(set => set.route.includes(activeTab));
        return index > -1 ? index : 0;
    }



    const appendUserID = true; //user.isCoach(ASSISTANT_LEVEL);
    const appendedUserParameter = appendUserID ? `&userID=${userID}` : '';
    const completedWorkoutsURL = `workout.php?action=listProfileCompletedWorkouts`;

    //console.log('COMPLETED WORKOUT URL', completedWorkoutsURL);

    const viewAthleteSettingsEnabled = true;
    const viewerStrengthCoach = user.isStrengthCoach();
    const viewerSportSpecificCoach = user.isSportSpecificCoach();
    const viewerNutritionist = false;

    const name = `${userInfo.firstName} ${userInfo.lastName}`;
    const title = self ? 'My Profile' : name;
    setPageTitle(title);
    const tabIconStyle = {fontSize: 24};
    const permissionTopOffset = {marginTop: getSpacing('small')};

    const {athlete, deviceAccount} = statuses;


    const permissionsSet = [
        {
            label: 'Overview',
            route: 'overview/',
            icon: <MonitorHeart style={tabIconStyle}/>,
            visible: true,
            disabled: false,
        },
        {
            label: deviceAccount ? 'Device' : 'Account',
            route: 'account/',
            icon: deviceAccount ? <TabletAndroid style={tabIconStyle}/> : <AccountCircle style={tabIconStyle}/>,
            visible: true,
            disabled: false,
        },
        {
            label: 'Metrics',
            route: 'metrics/',
            icon: <QueryStats style={tabIconStyle}/>,
            visible: athlete && viewAthleteSettingsEnabled,
            disabled: false,
        },
        {
            label: 'Past Workouts',
            route: 'past-workouts/',
            icon: <FitnessCenter style={tabIconStyle}/>,
            visible: athlete && viewAthleteSettingsEnabled && (self || viewerSportSpecificCoach || viewerStrengthCoach),
            disabled: false,
        },
        /*
        {
            label: 'Sleep',
            route: 'sleep/',
            icon: <Bedtime style={tabIconStyle}/>,
            visible: false && showPendingTabs && athlete && viewAthleteSettingsEnabled && (self || viewerNutritionist),
            disabled: false,
        },
        {
            label: 'Nutrition',
            route: 'nutrition/',
            icon: <Kitchen style={tabIconStyle}/>,
            visible: false && showPendingTabs &&  athlete && viewAthleteSettingsEnabled,
            disabled: false,
        },

         */
        {
            label: 'Settings',
            route: 'settings/',
            icon: <Settings style={tabIconStyle}/>,
            visible: self,
            disabled: false,
        },
    ];

    //User hasn't onboarded



    if(!user.getOnboarded()){
        return (<Navigate to={URL_ONBOARDING}/>);
    }

    //Unable to access the user - supply denied page
    if(denied === true){
        return <DeniedRoute/>
    }



    let positionLink = 'Player Positions';
    let position2Link = 'player positions';
    let squadLink = 'Squads';
    if(user.isSportSpecificCoach()){
        const link = URL_ATHLETE_ASSIGNMENTS;
        positionLink = <Link to={link}>{positionLink}</Link>;
        position2Link = <Link to={link}>{position2Link}</Link>;
        squadLink = <Link to={link}>{squadLink}</Link>;
    }
    user.getTeamAffiliationLimits(userPermissions, athleteAffiliations);
    const teamIntersection = user.getPermissionsIntersection(PERM_TEAM, userPermissions);
    const teamDateLimits = user.getTeamAffiliationLimits(userPermissions, athleteAffiliations);
    const affiliationsExist = !isEmpty(athleteAffiliations);


    /*
    const ui = user.getUserInfo();
    const preferences = ui.preferences ? ui.preferences : 'NO PREFS';
    const prefString = JSON.stringify(preferences);
    //const appPrefs = appState.account.userInfo.preferences;
    const appString = JSON.stringify(user.getAppState().userInfo.preferences);
    const match = appString === prefString;
    */

    return (
        <div>
            <Header header={title}/>
            <Paper style={PAPER_STYLE}>


                {/*
                <Alert
                    visible={true}
                    status={match ? 'success' :'error'}
                    headline={match ? 'PREFS CORRECT' : 'PREFS OUT OF SYNC'}
                    message={match ? 'ALL GOOD' : 'FAIL'}
                />
                    {prefString}
                <hr/>
                {appString}
*/}




                <TabBox>
                    <Tabs value={getTabValue(permissionsSet, section)} variant={"scrollable"} scrollButtons={'auto'}>
                        {
                            permissionsSet.map((item, key) =>{
                                if(item.visible){
                                    return (
                                        <Tab key={key} icon={item.icon} label={item.label} to={getProfileLink(item.route)} component={RouterLink}/>
                                    )
                                }
                            })
                        }
                    </Tabs>
                </TabBox>
                {
                    tabSection === 'overview' &&
                    <TabSection>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'h2'}>Overview (Work In Progress)</Typography>
                                <ul>
                                    <li>This will be the first thing a user sees when they sign in</li>
                                    <li>GOAL: Provide an overview of all their recent data: <ul>
                                        <li>Last Workout RPE</li>
                                        <li>New Maxes</li>
                                        <li>Sleep (pending)</li>
                                        <li>Calories (pending)</li>
                                        <li>Maybe a correlation of sleep/calories/macros to RPE</li>
                                    </ul>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </TabSection>
                }
                {
                    tabSection === 'account' &&
                    <TabSection>
                        <Grid container spacing={4}>
                            <Grid item xs={12} xl={6}>
                                <Typography variant={'h2'}>
                                    {deviceAccount ? 'Device' : 'Personal'} Information & Settings
                                </Typography>
                                <PreferencesAccordion
                                    defaultExpanded={true}
                                    icon={<Person sx={ICON_SX}/>}
                                    summaryText={'Personal Information'}
                                >
                                    <UserDetails
                                        userID={userID}
                                        athlete={statuses.athlete}
                                        firstName={userInfo.firstName}
                                        lastName={userInfo.lastName}
                                        email={userInfo.email}
                                        phone={userInfo.phone}
                                        onboarded={userInfo.onboarded}
                                    />
                                </PreferencesAccordion>

                                { affiliationsExist && !deviceAccount ?
                                    <div>
                                    <Typography variant={'h2'} style={{marginTop: getSpacing('small')}}>Athlete Affiliations & Eligibility</Typography>
                                        {
                                        athleteAffiliations.map((affiliation, key) =>{
                                        const disabled = parseInt(affiliation.active)!==1;
                                        return (
                                            <Accordion defaultExpanded={!disabled} key={key} disabled={disabled}>
                                                <AccordionSummary expandIcon={<ExpandMore/>}>{affiliation.organizationName} Eligibility</AccordionSummary>
                                                <AccordionDetails>
                                                    <AthleteEligibilityForm
                                                        affiliationKey={key}
                                                        affiliationData={affiliation}
                                                        organizationTypeID={1}
                                                        newUser={false}
                                                        disabled={disabled}
                                                        athleteLabels={athleteAffiliationLabels}
                                                        updateFunction={(arrayKey, affiliationData)=>handleEligibilityInformation(arrayKey, affiliationData)}
                                                        submitFunction={()=>submitEligibilityInformation(key)}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                    }
                                    </div>
                                    :
                                    null
                                }




                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <Typography variant={'h2'}>Permissions/Assignments</Typography>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMore/>}>Department Permissions</AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant={'body1'}>
                                                    Department Permissions determine what roll the user plays within your organization.
                                                    Different departments have different responsibilities and interfaces.
                                                    A user may operate across several departments. In some cases a department may override access to another. You can manage these settings here.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={permissionTopOffset}>
                                                    <UserPermissions
                                                        newUser={false}
                                                        userID={userID}
                                                        name={name}
                                                        permissions={userPermissions[PERM_DEPT]}
                                                        type={PERM_DEPT}
                                                        athlete={athlete}
                                                        deviceAccount={deviceAccount}
                                                        updateFn={(organizationID, sectionID, managerLevel, active)=>submitPermissionChange(PERM_DEPT, organizationID, sectionID, managerLevel, active)}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMore/>}>Team Assignments</AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant={'body1'}>
                                                    Team Assignments silo users to specific teams.
                                                    Users can only see data for teams they're assigned to.
                                                    In some instances, a coach or player may be assigned to one or more teams. Team assignments inherit their ranks from department permissions.
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Alert
                                                    status={'error'}
                                                    visible={statuses.hasActiveDept === false}
                                                    headline={'Team Assignments Disabled'}
                                                    message={'The user needs an active department before being assigned to a team. Assign a department first.'}
                                                />
                                                <Alert
                                                    status={'warning'}
                                                    visible={statuses.hasActiveTeam === false}
                                                    style={{paddingTop: getSpacing('small')}}
                                                    headline={'No Active Team Assignments'}
                                                    message={'The user will not be able to access athlete team/data until the user is assigned to a team.'}
                                                />
                                                <div style={permissionTopOffset}>
                                                    <UserPermissions
                                                        newUser={false}
                                                        userID={userID}
                                                        name={name}
                                                        deviceAccount={deviceAccount}
                                                        permissions={userPermissions[PERM_TEAM]}
                                                        type={PERM_TEAM}
                                                        athlete={athlete}
                                                        deptPermissions={userPermissions[PERM_DEPT]}
                                                        updateFn={(organizationID, sectionID, managerLevel, active)=>submitPermissionChange(PERM_TEAM, organizationID, sectionID, managerLevel, active)}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                {
                                    !deviceAccount && affiliationsExist?
                                    <div>
                                    <Accordion disabled={athlete === false}>
                                        <AccordionSummary expandIcon={<ExpandMore/>}>Squad Assignments</AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant={'body1'}>
                                                        {squadLink} allow coaches to organize their players into unique groups depending on their needs. {squadLink} enable
                                                        coaches to compare an athlete's capability to that of their squad, as well as squads to the team as a whole.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AthleteAssignments
                                                        type={ATHLETE_ASSIGNMENT_TYPES.squads}
                                                        assignments={athleteAssignments.squads}
                                                        updateFn={(teamID, assignmentID, groupTypeID, active)=>handleAthleteAssignment(teamID, assignmentID, groupTypeID, active)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion disabled={athlete === false}>
                                        <AccordionSummary expandIcon={<ExpandMore/>}>Player Positions</AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant={'body1'}>
                                                        {positionLink} are formalized positions associated with each sport.
                                                        Similar to squads, {position2Link} allow coaches to compare a player or position's performance to the team performance.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AthleteAssignments
                                                        type={ATHLETE_ASSIGNMENT_TYPES.positions}
                                                        assignments={athleteAssignments.positions}
                                                        updateFn={(teamID, assignmentID, groupTypeID, active)=>handleAthleteAssignment(teamID, assignmentID, groupTypeID, active)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    </div>
                                : null
                                }


                            </Grid>
                        </Grid>
                    </TabSection>
                }
                {
                    tabSection === 'metrics' &&
                    <TabSection>
                        <Typography variant={'h2'}>Athlete Metrics</Typography>
                        <GraphTrend
                            userProfile={true}
                            userID={userID}
                            teamIntersections={teamIntersection}
                            teamDateLimits={teamDateLimits}
                        />
                    </TabSection>
                }
                {
                    tabSection === 'past-workouts' &&
                    <TabSection>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'h2'}>Completed Workouts</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DynamicDataTable
                                    title={'Workout Blocks Table'}
                                    formatURL={completedWorkoutsURL}
                                    loadData={loadData}
                                    dataLoaded={()=>setLoadData(false)}
                                    rowFunctions={{
                                        viewWorkout: viewSpecificWorkout
                                    }}
                                    presetVariables={{
                                        userID: userID
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </TabSection>
                }
                {
                    tabSection === 'sleep' &&
                    <TabSection>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                Sleep: Coming eventually?
                            </Grid>
                        </Grid>
                    </TabSection>
                }
                {
                    tabSection === 'nutrition' &&
                    <TabSection>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                Nutrition: Coming Eventually?
                            </Grid>
                        </Grid>
                    </TabSection>
                }
                {
                    tabSection === 'settings' &&
                    <TabSection>
                        <Typography variant={'h2'}>User Settings & Preferences</Typography>
                        <ProfilePrefs/>
                    </TabSection>
                }
            </Paper>
            <AthleteWorkoutResults
                workoutID={athleteSpecificWorkoutModal.workoutID}
                display={athleteSpecificWorkoutModal.display}
                userID={athleteSpecificWorkoutModal.userID}
                hideFn={()=>hideSpecificWorkout()}
            />
        </div>

    )
}

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser};
}

export default connect(mapStateToProps)(UserProfile);