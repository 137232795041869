import React from 'react';
import {Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from "@mui/material";
import UserPreferences from "fit/system/UserPreferences";


const PrivacySettings=({headerVariant})=>{
    const variant = headerVariant ? headerVariant : 'h3';
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ul>
                    <li>Note: Your Coaches and Superiors will be still be able to find you through directories</li>
                    <li>These settings hide your information from "outsiders" or people outside your hierarchical structure</li>
                    <li>User Directory Setting</li>
                    <li>Global User Directory Setting (HIDE)</li>
                    <li>Allow Phone # (DISABLE BY DEFAULT)</li>
                    <li>Allow Email</li>
                    <li>Personal Profile Page: Future stuff (who can find them)</li>
                    <li>Profile Picture: Display/Hide (Eventually work this in)</li>
                </ul>
            </Grid>
        </Grid>
    )

}

export default PrivacySettings;