import React from 'react';
import {Menu, MenuItem} from "@mui/material";


const MeasurementOptionsMenu=({measurementID, measurementOptions, onClick, anchorEl, open, onClose, allowWeightMixer, openMixer })=>{
    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={()=>onClose()}
        >
            {measurementOptions.map((option, k)=>{
                const disabled = parseInt(option.id) === parseInt(measurementID);
                return <MenuItem disabled={disabled} key={k} onClick={()=>onClick(option.id)}>{option.name} ({option.abbreviation})</MenuItem>
            })}
            {
                allowWeightMixer === true ?
                <MenuItem onClick={()=>openMixer()}>Mixed Units</MenuItem>
                :
                    null
            }
        </Menu>
    )
}

export default MeasurementOptionsMenu;