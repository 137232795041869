import User from "fit/system/User";
import {isEmpty} from "fit/system/UtilityFunctions";
import {SharedPermissions, RoutePermissions, LoggedOutPermissions, DevicePermissions} from "./PermissionsList";
import UserOnboarding from "../components/User/UserOnboarding";
/*
    -
    -
 */
//PermissionMenuTranslator Translates permissions into menu items

const initRoute =(route)=>{
    const user = new User();
    const {deptID, label} = route;
    const hasRoutes = !isEmpty(route.routes);
    const reqManagerLevel = route.managerLevel != null ? parseInt(route.managerLevel) : 0;
    const strictDept = route.strictLevel != null ? route.strictLevel : false;
    const userInDept = user.managerSystemDeptLevel(deptID, reqManagerLevel, strictDept);
    return {
        deptID,
        label,
        userInDept,
        reqManagerLevel,
        strictDept,
        hasRoutes,
    }
}

export const PermissionMenuTranslator = (permissionsList) =>{
    //Translate the RouteLists into datastructure
    const user = new User();
    let menu = [];
    let menuSectionIDs = [];
    permissionsList.forEach((item, j)=>{
        //Determine if the user is active within the department
        let menuKey;
        const {deptID, label, userInDept, hasRoutes} = initRoute(item);
        if(userInDept && hasRoutes) {
            //User is active within dept and routes exist for the current dept
            item.routes.forEach((route, k) => {
                //Check whether the user is permitted to access each
                const hasPermission = user.managerSystemDeptLevel(deptID, route.managerLevel, route.strictLevel);
                if(route.menuDisplay === true && hasPermission){
                    //Menu is permitted to be displayed.
                    //Create the menu entry if it hasn't been displayed already.
                    if(menuSectionIDs.includes(j) === false){
                        //Initialize Menu item Entry
                        menu.push({
                            label: label,
                            type: "section",
                            children: []
                        });
                        menuKey = menu.length-1;
                        //Add the entry to list of menu items
                        menuSectionIDs.push(j);
                    }
                    const routeIsFolder = route.subRoutes != null && !isEmpty(route.subRoutes);
                    const folderType = 'collapsible';
                    const linkType = 'nav-item'
                    const itemType = routeIsFolder ? folderType : linkType;

                    const navItemObject = {
                        uri: route.path,
                        label: route.label,
                        type: itemType,
                        icon: route.icon
                    }

                    const navFolderObject = {
                        label: route.label,
                        type: itemType,
                        icon: route.icon,
                        children: []
                    }

                    //Is a Collapsible folder with subroutes

                    //Create the entry for the child
                    const currentKey = menu[menuKey].children.length;
                    menu[menuKey].children[currentKey] = routeIsFolder ? navFolderObject : navItemObject;


                    if(routeIsFolder){
                        //Create Entries for subRoutes within folder//
                        let childrenItems = [];
                        route.subRoutes.forEach(sr =>{
                            const permitted = user.managerSystemDeptLevel(deptID, sr.managerLevel);
                            if(sr.menuDisplay === true && permitted) {
                                childrenItems.push({
                                    uri: sr.path,
                                    label: sr.label,
                                    type: linkType
                                });
                            }
                        });
                        //Assign children Items to the children array of the folder
                        menu[menuKey].children[currentKey].children = childrenItems;
                    }
                }
            });
        }
    });
    return menu;
};


const translateElement=(deviceAccount, routeElement)=>{
    //Forces the user to onboard or execute any mandatory tasks before proceeding
    const user = new User();
    const onboarded = user.getOnboarded();
    if(deviceAccount){
        return routeElement;
    }
    return onboarded ? routeElement : <UserOnboarding/>;
}

export const PermissionRouteTranslator = (authorized, deviceAccount) =>{
    const user = new User();

    let routes = [];
    let permissionsList;
    if(authorized){
        //Standard user or device permissions based on device account status
        permissionsList = !deviceAccount ? RoutePermissions : DevicePermissions;
    } else{
        //Just pass permissions for logged out users
        return LoggedOutPermissions;
    }
    //AUTHORIZED USERS
    permissionsList.forEach((item)=>{
        const {deptID, userInDept, hasRoutes} = initRoute(item);
        if(userInDept && hasRoutes){
            item.routes.forEach((route)=>{
                const hasPermission = user.managerSystemDeptLevel(deptID, route.managerLevel, route.strictLevel);
                const hasSubRoutes = route.subRoutes != null && !isEmpty(route.subRoutes);
                if(hasPermission && route.path != null){
                    routes.push({path: route.path, element: translateElement(deviceAccount,route.element)});
                }
                if(hasSubRoutes){
                    route.subRoutes.forEach((sr)=>{
                        const permitted = user.managerSystemDeptLevel(deptID, sr.managerLevel, sr.strictLevel);
                        if(permitted) {
                            routes.push({path: sr.path, element: translateElement(deviceAccount, sr.element)});
                        }
                    });
                }
            });
        }
    });
    //Add Shared Permissions for Authorized Users that aren't device accounts
    if(authorized && deviceAccount === false){
        SharedPermissions.forEach((route)=>{
            const {deptIDs} = route;
            if(Array.isArray(deptIDs)){
                for(let k=0; k<deptIDs.length; k++){
                    const dept = deptIDs[k];
                    const permitted = user.managerSystemDeptLevel(dept.deptID, dept.managerLevel, dept.strictLevel);
                    if(permitted){
                        routes.push({path: route.path, element: translateElement(false, route.element)});
                        //Terminate loop. User Is Permitted
                        break;
                    }
                }
            } else{
                //Automatically include
                routes.push({path: route.path, element: translateElement(false, route.element)});
            }
        })
    }
    return routes;
}