import React from 'react';
import ColorCodedStatusBar from "fit/components/ColorCodedStatusBar";
import {getRPEColor} from "fit/system/UtilityFunctions";
const RPEStatusBar=({score})=>{
    const RPEScore = parseInt(score);
    const color = getRPEColor(RPEScore);
    const message = `Exertion: ${RPEScore}%`;
    return (
        <ColorCodedStatusBar
            score={RPEScore}
            color={color}
            message={message}
        />
    );
}

export default RPEStatusBar;
