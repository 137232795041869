import React from 'react';
import SoloFormWrapper from "../System/SoloFormWrapper";
import DeviceLogin from "./DeviceLogin";
import Link from 'fit/components/System/Link';
import {URL_LOGIN} from "fit/system/FITConstants";


const DeviceLoginPage=()=>{
    return (
        <SoloFormWrapper>
            <DeviceLogin requestCode={true}/>
            <Link to={URL_LOGIN}>Log In</Link>
        </SoloFormWrapper>
    )
}

export default DeviceLoginPage