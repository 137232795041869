import React from 'react';
import Header from "../Header";
import {Paper} from "@mui/material";
import {PAPER_STYLE} from "fit/system/FITConstants";
import QRCodeScanner from "../System/QRCodeScanner";
import {setPageTitle} from "fit/system/UtilityFunctions";

const UserQRScan=()=>{
    setPageTitle('Scan Device QR Code');
    return (
        <div>
            <Header header={'Scan Device QR Code'}/>
            <Paper style={PAPER_STYLE}>
                <QRCodeScanner userProfile={true}/>
            </Paper>
        </div>
    )

}

export default UserQRScan;