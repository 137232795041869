import React from 'react';
import {Button, Grid, TextField} from "@mui/material";
import Alert from "fit/components/Alert/index";
import Link from "../Link";
import * as yup from "yup";
import {Form, Formik} from "formik";

const RequestResetForm=({disabled, email, handleChange, requested, requestReset, serverResponse})=>{
    const {status, headline, message} = serverResponse;

    const validationSchema = yup.object().shape({
        email: yup.string()
            .required('Email required')
            .email('Email required')
            .min('5','Email required'),
    });

    return (

        <Formik
            initialValues={{email}}
            validationSchema={validationSchema}
            onSubmit={()=>requestReset()}
            enableReinitialize={true}
        >
            {({ errors, touched}) => (
                <Form>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label={'Your Email'}
                    fullWidth
                    name={'email'}
                    onChange={(e)=>handleChange(e)}
                    error={(errors.email && touched.email)}
                    helperText={errors.email || ' '}
                />
                <Alert
                    visible={requested}
                    status={status}
                    headline={headline}
                    message={message}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    type={'submit'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={disabled}
                >
                    Request New Password
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Link to="/login/">
                    Log In
                </Link>
            </Grid>
        </Grid>
                </Form>
            )}
        </Formik>
    )

}

export default RequestResetForm;