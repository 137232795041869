import React,{useState} from 'react';
import {AccordionDetails, Accordion, AccordionSummary, Button, Grid, Typography} from "@mui/material";
import {Security as Shield, Lock, Numbers, FitnessCenter, ExpandMore, ColorLens} from "@mui/icons-material";
import User from 'fit/system/User';
import UserPreferences from "fit/system/UserPreferences";
import DeviceSettings from "./DeviceSettings";
import Security from "./Security";
import UserFormats from "./UserFormats";
import WorkoutSettings from "./WorkoutSettings";
import ResetPassword from "fit/components/System/ResetPassword/";
import {getSpacing} from 'fit/system/UtilityFunctions';
import PrivacySettings from "./PrivacySettings";
import UserThemes from "./UserThemes/UserThemes";
import {ICON_SX} from "fit/system/FITConstants";
import PreferencesAccordion from "../../PreferencesAccordion";

const ProfilePrefs=()=>{
    const [displayPin, setDisplayPin] = useState(false);
    const userPrefs = new UserPreferences();
    const handleChange=(pref, value)=>{
        console.log('UPDATING ::', pref, value);
        userPrefs.updatePreference(pref, value);
    }
    const headerVariant = 'h3';
    const user = new User();
    const deviceAccount = user.getDeviceAccount();
    const sx={fontSize: 20, marginRight: getSpacing('iconAlignment')}

    const pinError = userPrefs.pin.length < 4;
    const pinErrorText = pinError ? 'Pin must be at least 4 characters' : ' ';

    return (
        <div>
            <PreferencesAccordion
                icon={<FitnessCenter sx={ICON_SX}/>}
                summaryText={'App/Workout Preferences'}
            >
                <WorkoutSettings
                    autoComplete={userPrefs.autoCompleteWorkout}
                    validateWeight={userPrefs.validateWeight}
                    lbUnit={userPrefs.lbUnit}
                    kgUnit={userPrefs.kgUnit}
                    onChange={(preference, value)=>handleChange(preference, value)}
                    headerVariant={headerVariant}
                />
            </PreferencesAccordion>
            <PreferencesAccordion
                icon={<Numbers sx={ICON_SX}/>}
                summaryText={'Data Formatting & Measurement Units'}
            >
                <UserFormats
                    unitType={userPrefs.unitType}
                    timeFormat={userPrefs.timeSetting}
                    dateFormat={userPrefs.dateSetting}
                    numberFormat={userPrefs.numberFormat}
                    onChange={(preference, value)=>handleChange(preference, value)}
                    headerVariant={headerVariant}
                />
            </PreferencesAccordion>
            <PreferencesAccordion
                icon={<Shield sx={ICON_SX}/>}
                summaryText={'Account Security Settings'}
            >
                <Security
                    displayPin={displayPin}
                    onboard={false}
                    twoFactor={userPrefs['2fa']}
                    text2fa={userPrefs.text2fa}
                    pin={userPrefs.pin}
                    pinError={pinErrorText}
                    error={pinError}
                    setDisplayPin={(display)=>setDisplayPin(display)}
                    onChange={(preference, value)=>handleChange(preference, value)}
                />
            </PreferencesAccordion>
            <PreferencesAccordion
                icon={<Lock sx={ICON_SX}/>}
                summaryText={'Password Reset'}
            >
                <Typography variant={'h3'}>Reset Your Password</Typography>
                <ResetPassword profileReset={true}/>
            </PreferencesAccordion>

            {/*
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>Privacy</AccordionSummary>
                <AccordionDetails>
                    <PrivacySettings/>
                    <Button
                        fullWidth
                        color={'primary'}
                        variant={'contained'}
                        onClick={()=>updatePrefs('privacy')}
                    >Update Privacy Preferences</Button>
                </AccordionDetails>
            </Accordion>
            */}

            {
            <PreferencesAccordion
                icon={<ColorLens sx={ICON_SX}/>}
                summaryText={'Themes & Appearance'}
            >
                <UserThemes/>
            </PreferencesAccordion>
            }
            {/*
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>Notes</AccordionSummary>
                <AccordionDetails>
                    <Typography variant={'h2'}>
                        User Preferences & Settings
                    </Typography>
                    <ul>
                        <li>Change Themes</li>
                        <li>Device Settings
                            <ul>
                                <li>Allow Device Accounts to AutoComplete Workout Fields (Coach Setting)</li>
                                <li>Allow Device Accounts to change Measurement Units</li>
                            </ul>

                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            */}
        </div>

    )

}

export default ProfilePrefs;