import React, {useEffect, useState} from 'react';
import User from 'fit/system/User';
import {Typography, Paper, Button, Grid, TextField, Tooltip} from "@mui/material";
import {
    MOMENT_DATE_FORMAT,
    PAPER_STYLE,
    TYPOGRAPHY_BODY_STYLE,
    URL_MY_PROFILE
} from "fit/system/FITConstants";
import {Stepper, Step, StepLabel, StepContent} from "@mui/material";
import Header from 'fit/components/Header';
import SecureConnect from "fit/system/SecureConnect";
import Security from "./Preferences/Security";
import {getSpacing, isEmpty} from "fit/system/UtilityFunctions";
import UserFormats from "./Preferences/UserFormats";
import PersonalAthleteInformation from "./Preferences/PersonalAthleteInformation";
import Alert from 'fit/components/Alert';
import Link from 'fit/components/System/Link';
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import PrivacySettings from "./Preferences/PrivacySettings";
import WorkoutSettings from "./Preferences/WorkoutSettings";
import TabSection from "../TabSection";
import {setPageTitle} from "fit/system/UtilityFunctions";

import {Formik, Form} from 'formik';
import * as yup from 'yup';
import MaskedInput from "../Form/MaskedInput";
import moment from "moment";
import {Navigate} from "react-router-dom";


const maxDate = moment().subtract(13, 'year').format(MOMENT_DATE_FORMAT); //13 Years old
const minDate = moment().subtract(100, 'year').format(MOMENT_DATE_FORMAT); //100 year old

const userSchema = yup.object().shape({
    firstName: yup.string()
        .trim()
        .min(2,'First name should at least 2 characters long')
        .max(50,'The first name is too long')
        .required('First name required'),
    lastName: yup.string()
        .trim()
        .min(2,'Last name should at least 2 characters long')
        .max(50,'The last name is too long')
        .required('Last name required'),
    email: yup.string()
        .trim()
        .email('Invalid email')
        .required('Email required'),
    phone: yup.string()
        .trim()
        .min(14, 'Phone number is incomplete')
        .max(16, 'Phone number is too long'),
    DOB: yup.date()
        .min(minDate, 'Valid date of birth required')
        .max(maxDate, 'You must be a minimum of 13 years old'),
});


const initUser=()=>{
    const user = new User();
    let d = user.getUserInfo();
    return {
        userID: d.userID,
        firstName: d.firstName,
        lastName: d.lastName,
        email: d.email,
        phone: d.phone,
        DOB: '',
        genderLabelID: 0,
        genderAtBirth: 0,
        //Security
        '2fa': false,
        text2fa: false,
        pin: '',
        displayPin: false,
        //Privacy
        userDirectory: true,
        globalDirectory: false,
        phoneAccess: false,
        emailAccess: true,
        //Workout Settings
        autoCompleteWorkout: true,
        validateWeight: true,
        lbUnit: 5,
        kgUnit: 1,
        //formats
        unitType: 1,
        dateFormat: 2,
        timeFormat: 1,
        numberFormat: 1,
    }
}


const SubmitButton=({nextFn, backFn, backDisabled= false, buttonText, buttonType = 'button', disabled=false})=>{
    const backMessage = backDisabled ? 'Disabled: can\'t go back' : 'Go back to previous section';
    return (
        <Grid container spacing={1} sx={{marginTop: getSpacing(), marginBottom: getSpacing()}}>
            <Grid item xs={3} sm={2}>
                <Tooltip title={backMessage}>
                    <span><Button fullWidth color={'secondary'} variant={'contained'} onClick={backFn} disabled={backDisabled}><ArrowBack/></Button></span>
                </Tooltip>

            </Grid>
            <Grid item xs={9} sm={10}>
                <Button fullWidth
                 color={'primary'} disabled={disabled} variant={'contained'} onClick={nextFn} type={buttonType}>{buttonText}</Button>
            </Grid>
        </Grid>
    )
}


const UserOnboarding=()=>{
    const [step, setStep] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [onboardData, setOnboardData] = useState(initUser);
    const [submittedPrefs, setSubmittedPrefs] = useState(initUser);

    const [genderLabelOptions, setGenderLabelOptions] = useState({birthGenderLabels: [], genderLabels: []})
    const [serverResponse, setServerResponse] = useState({display: false, status: 'info', headline: 'headline', message: 'message'});

    useEffect(()=>{
        let sc = new SecureConnect('system.php?action=getOnboardingLabels');
        sc.setDisplayNotifications(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                setGenderLabelOptions(sc.getData(json));
            }
        })
    }, genderLabelOptions)


    const resetForm=()=>{
        setOnboardData(initUser);
    }

    const backStep=()=>{
        setStep(step-1);
    }
    const nextStep=()=>{
        setStep(step+1);
    }
    const handleInput=(name, value)=>{
        let data = {...onboardData};
        data[name] = value;
        setOnboardData(data);
    }
    const submitValidated=()=>{
        nextStep();
    }
    const toggleDisplayPin=()=>{
        let oD = structuredClone(onboardData);
        oD.displayPin = !onboardData.displayPin;
        setOnboardData(oD);
    }
    const finalizePrefs=()=>{
        console.log('FINALIZING PREFS', submittedPrefs);
        user.updatePreferences(submittedPrefs, true);
    }

    const submitPreferences=()=>{
        let sc = new SecureConnect('user.php', 'post');
        sc.setAction('onboardUser');
        let od = structuredClone(onboardData);
        let p = structuredClone(onboardData);
        od['2fa'] = onboardData['2fa'] ? 1 : 0;
        od.autoCompleteWorkout = onboardData.autoCompleteWorkout ? 1: 0;
        od.validateWeight = onboardData.validateWeight ? 1 : 0;
        od.text2fa = onboardData.text2fa ? 1 : 0;
        sc.setFormData(od);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                //Update the user preferences within app state
                //setTabValue(1);
                const user = new User();
                //Update the preferences from the form
                console.log('FORM PREFS', p);
                let prefs = {
                    onboarded: true,
                    DOB: p.DOB,
                    genderAtBirth: p.genderAtBirth,
                    genderLabelID: p.genderLabelID,
                    autoCompleteWorkout: p.autoCompleteWorkout,
                    validateWeight: p.validateWeight,
                    lbUnit: p.lbUnit,
                    kgUnit: p.kgUnit,
                    unitType: p.unitType,
                    dateFormat: p.dateFormat,
                    timeFormat: p.timeFormat,
                    numberFormat: p.numberFormat,
                    '2fa': p['2fa'],
                    text2fa: p.text2fa,
                    pin: p.pin
                };
                //Update user preferences state
                setSubmittedPrefs(prefs);
                //Update interface
                setTabValue(1);
            } else{
                //error occurred.
                const data = sc.getData(json);
                console.log('DATA', data);
                setStep(3);
                const res = sc.getResponse(json);
                let headline, status;
                switch(sc.getResponseCode(json)){
                    case 2:
                        headline = 'Info? WTF ARE YOU DOING HERE?'
                        status = 'info';
                        break;
                    case 3:
                        headline = 'Something Unexpected Occurred';
                        status = 'warning';
                        break;
                    default:
                        headline = 'An Unexpected Failure Occurred';
                        status = 'error';


                        const errorSections = [
                            //user info
                            ['firstName','lastName','email','phone','genderLabelID','genderAtBirth','DOB','gender'],
                            //workout settings
                            ['autoCompleteWorkout','validateWeight','lbUnit','kgUnit'],
                            //security
                            ['2fa','pin'],
                            //measurement units
                            ['unitType','dateFormat','timeFormat','numberFormat']
                        ];

                        if(!isEmpty(data) && data.variableName != null){
                            const {variableName} = data;
                            const index = errorSections.findIndex(section => {console.log(variableName, '???', section); section.includes(variableName)});
                            console.log(data.variableName, '=>', index);
                        }
                        break;
                }
                const serverResponse = {
                    status: status,
                    display: true,
                    headline,
                    message: res.msg,
                }
                console.log('RESPONSE', res);
                setServerResponse(serverResponse);
            }
        })
    }

    const debug = true;
    const user = new User();
    if(user.getOnboarded() && debug === false){
        return (<Navigate to={URL_MY_PROFILE}/>);
    }



    const oD = onboardData;
    const securityPinError = oD.pin.length > 0 && oD.pin.length < 4;
    const securityDisabled = oD.pin.length < 4;
    const pinErrorMessage = securityPinError ? 'Pin must be at least 4 characters long': ' ';

    setPageTitle("Onboarding: Let's Finish Setting Up Your Account");
    return (
        <div>
            <Header header={'Onboarding'}/>
            <Paper style={PAPER_STYLE}>
                <Typography variant={'h2'}>Let's Finish Setting Up Your Account</Typography>
                {tabValue === 0 &&
                    <TabSection paddingTop={false}>
                        <Typography variant={'body1'} sx={TYPOGRAPHY_BODY_STYLE}>To get full access to Buteo's functions and features we need to set up your user preferences.</Typography>
                        <Alert
                            visible={serverResponse.display}
                            status={serverResponse.status}
                            headline={serverResponse.headline}
                            message={serverResponse.message}
                        />
                        <Stepper orientation={'vertical'} activeStep={step}>
                            <Step>
                                <StepLabel>User Account Information</StepLabel>
                                <StepContent>
                                    <div style={{marginBottom: getSpacing()}}>
                                        <Typography variant={'h3'}>Basic Account Information</Typography>
                                    </div>

                                    <Formik
                                        initialValues={oD}
                                        validationSchema={userSchema}
                                        onSubmit={(data)=>submitValidated(data)}
                                        enableReinitialize={true}
                                    >
                                        {({errors, touched}) => (
                                            <Form>

                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            name="firstName"
                                                            label="First Name"
                                                            error={errors.firstName && touched.firstName}
                                                            helperText={errors.firstName || ' '}
                                                            type="text"
                                                            placeholder="Robert"
                                                            onBlur={(e)=>handleInput('firstName',e.target.value)}
                                                            defaultValue={oD.firstName}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            name="lastName"
                                                            label="Last Name"
                                                            error={errors.lastName && touched.lastName}
                                                            helperText={errors.lastName || ' '}
                                                            type="text"
                                                            placeholder="Paulsen"
                                                            onBlur={(e)=>handleInput('lastName',e.target.value)}
                                                            defaultValue={oD.lastName}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            name="email"
                                                            label="Email"
                                                            error={errors.email && touched.email}
                                                            helperText={errors.email || ' '}
                                                            type="email"
                                                            placeholder="RobertPaulsen@bodjour.com"
                                                            onBlur={(e)=>handleInput('email',e.target.value)}
                                                            defaultValue={oD.email}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MaskedInput
                                                            fullWidth
                                                            pattern={"phone"}
                                                            name={"phone"}
                                                            label={"Phone"}
                                                            error={errors.phone}
                                                            helperText={errors.phone || ' '}
                                                            placeholder="(888) 867-5309"
                                                            onBlur={(e)=>handleInput('phone',e.target.value)}
                                                            defaultValue={oD.phone}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Typography variant={'h3'}>Athlete Information</Typography>
                                                <PersonalAthleteInformation
                                                    errors={errors}
                                                    touched={touched}
                                                    DOB={oD.DOB}
                                                    genderAtBirth={oD.genderAtBirth}
                                                    gender={oD.genderLabelID}
                                                    birthGenderLabels={genderLabelOptions.birthGenderLabels}
                                                    genderLabels={genderLabelOptions.genderLabels}
                                                    onChange={(name, value)=>handleInput(name, value)}
                                                />
                                                <SubmitButton backFn={null} backDisabled={true} nextFn={null} buttonType={'submit'} buttonText={'Continue'}/>
                                            </Form>
                                        )}
                                    </Formik>

                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Workout Settings & App Behaviors</StepLabel>
                                <StepContent>
                                    <WorkoutSettings
                                        autoComplete={oD.autoCompleteWorkout}
                                        validateWeight={oD.validateWeight}
                                        lbUnit={oD.lbUnit}
                                        kgUnit={oD.kgUnit}
                                        onChange={(name, value)=>handleInput(name, value)}
                                    />
                                    <SubmitButton
                                        buttonText={'continue'}
                                        buttonType={'button'}
                                        nextFn={()=>nextStep()}
                                        backFn={()=>backStep()}
                                    />
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Security</StepLabel>
                                <StepContent>
                                        <Typography variant={'h3'}>Security Settings</Typography>
                                        <Security
                                            onboard={true}
                                            twoFactor={oD['2fa']}
                                            text2fa={oD.text2fa}
                                            pin={oD.pin}
                                            error={securityPinError}
                                            pinError={pinErrorMessage}
                                            displayPin={oD.displayPin}
                                            setDisplayPin={()=>toggleDisplayPin()}
                                            onChange={(name, value)=>handleInput(name, value)}
                                        />

                                        <SubmitButton
                                            buttonText={'Continue'}
                                            nextFn={()=>nextStep()}
                                            disabled={securityDisabled}
                                            backFn={()=>backStep()}
                                        />
                                </StepContent>
                            </Step>
                            {/*
                    <Step>
                        <StepLabel>Privacy</StepLabel>
                        <StepContent>
                            <Typography variant={'h3'}>Privacy Preferences</Typography>
                            <PrivacySettings/>
                            {nextButton}

                        </StepContent>
                    </Step>
                    */}
                            <Step>
                                <StepLabel>Measurement Units & Data Formatting</StepLabel>
                                <StepContent>

                                    <Typography variant={'h3'}>Unit Preferences & Date, Time, Number Formatting</Typography>
                                    <UserFormats
                                        dateFormat={oD.dateFormat}
                                        timeFormat={oD.timeFormat}
                                        numberFormat={oD.numberFormat}
                                        unitType={oD.unitType}
                                        onChange={(name, value)=>handleInput(name, value)}
                                    />

                                    <SubmitButton backFn={()=>backStep()} nextFn={()=>submitPreferences()} buttonText={'Complete Setup'} buttonType={'button'}/>


                                </StepContent>
                            </Step>
                        </Stepper>

                    </TabSection>
                }
                {tabValue === 1 &&
                    <TabSection paddingTop={false}>
                        <Alert
                            status={'success'}
                            visible={true}
                            headline={'Onboarding Finished!'}
                            message={'Account setup completed.'}
                        />
                        <div style={{paddingTop: getSpacing()}}>
                            <Typography variant={'h3'}>You're All Done</Typography>
                            <Typography variant={'body1'} sx={TYPOGRAPHY_BODY_STYLE}>Your account is all set up and ready for use. You can visit your profile now. You can also update your settings later on as they can be found under the "<strong>App Settings</strong>" section of your profile.</Typography>
                            <Link to={URL_MY_PROFILE}><Button variant={'contained'} color={'primary'} onClick={()=>finalizePrefs()}>Take Me To My Profile <ArrowForward/></Button></Link>
                        </div>
                    </TabSection>
                }
            </Paper>
        </div>
    )
}

export default UserOnboarding;

