import React from 'react';
import {useState, useEffect} from "react";
import useDebounce from "../Functional/useDebounce";
import {Typography, Grid, Paper, Tabs, Tab, Button, TextField, MenuItem} from "@mui/material";
import {Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from "@mui/material";
import {Edit} from '@mui/icons-material';
import {PAPER_STYLE, ATHLETE_ASSIGNMENT_TYPES, SEARCH_DEBOUNCE_DELAY, SEARCH_MINIMUM_LENGTH} from "fit/system/FITConstants";
import {getSpacing, isEmpty} from "fit/system/UtilityFunctions";
import User from 'fit/system/User';
import AthleteRowSearch from "../AthleteRow/AthleteRowSearch";
import DynamicDataTable from "../DynamicDataTable";
import SecureConnect from "fit/system/SecureConnect";
import Alert from "../Alert";
import Header from 'fit/components/Header/';


const innerPaddingStyle = {paddingTop: getSpacing(), paddingLeft: getSpacing(), paddingRight: getSpacing()}

const SquadSelectedAlert=({assignmentID, groupTypeID, assignmentLabel})=>{
    const alertStyle= innerPaddingStyle;
    groupTypeID = parseInt(groupTypeID);
    const groupType = [ATHLETE_ASSIGNMENT_TYPES.squads,ATHLETE_ASSIGNMENT_TYPES.inactive].includes(groupTypeID) ? 'Squad' : 'Position';
    const inactiveSelected = groupTypeID === ATHLETE_ASSIGNMENT_TYPES.inactive;
    let headline = `"${assignmentLabel}" ${groupType} Selected`;
    let status = 'success';
    let message = `System is ready for handling "${assignmentLabel}" ${groupType.toLowerCase()} setting`;
    if(inactiveSelected){
        status = 'warning';
        message = `System is ready for toggling "${assignmentLabel}". Inactive athletes will not be included in statistical reports`;
    }
    return (
        <div style={alertStyle}>
            <Alert
                status={status}
                visible={isNaN(groupTypeID) === false && parseInt(assignmentID) > 0}
                headline={headline}
                message={message}
            >
            </Alert>
        </div>
    )
}



const SquadList=()=>{
    const [teamID, setTeamID] = useState('');
    const [assignmentTypeSelected, setAssignmentTypeSelected] = useState(null);
    const [visibilitySetting, setVisibilitySetting] = useState(0); //0 =>All, 1 (Selected), -1 (Not Selected)
    const [squadList, setSquadList] = useState([]); //List of squads (for filtering users)
    const [positionList, setPositionList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [roster, setRoster] = useState([]);
    const [assignmentID, setAssignmentID] = useState(-2);

    useEffect(()=>{
        //Onload
        const user = new User();
        const teams = user.getAthleticTeams();
        if(teams.length === 1){
            const teamID = teams[0].sectionID;
            setTeamID(teamID);
            loadTeam(teamID);
        }

    },[]);


    const handleTeamSelector=(e)=>{
        const {value} = e.target;
        setTeamID(value);
        loadTeam(value);
        setAssignmentID(null);
        setAssignmentTypeSelected(null);
    }

    const loadTeam=(teamID)=>{
        //Pull List of squads, list of positions, based on the sport
        //pull full roster of athletes
        let sc = new SecureConnect(`team.php?action=getTeamRosterAssignments&teamID=${teamID}`);
        sc.setDisplaySuccessMessages(false);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)) {
                const data = sc.getData(json);
                const {roster, squadPositions, sportPositions} = data;
                if(false) {
                    console.log('____LOADED TEAM_____');
                    console.log('DATASET', data);
                    console.log('ROSTER', roster);
                    console.log('SQUADS', squadPositions);
                    console.log('POSITIONS', sportPositions);
                }
                setRoster(roster);
                setSquadList(squadPositions);
                setPositionList(sportPositions);
            }
        })
    }
    const getGroupTypeID=(assignmentGroupID)=>{
        const item = squadList.find(item => parseInt(item.id) === parseInt(assignmentGroupID));
        return parseInt(item.groupTypeID);
    }

    const notifyUser=(groupTypeID, assignmentLabel)=>{
        const squadSelected = isSquadType(groupTypeID);
        const assignmentType = squadSelected ? 'Squad' : 'Position';
        let sc = new SecureConnect('');
        const message = `"${assignmentLabel}" ${assignmentType} Selected`;
        const inactiveSelected = squadSelected && parseInt(groupTypeID) === ATHLETE_ASSIGNMENT_TYPES.inactive;
        const code = inactiveSelected ? 3 : 2;
        sc.createSnackbarNotification(code, 'Info', message);
    }
    const handleGroupSetting=(e)=>{
        setAssignmentTypeSelected(parseInt(e.target.value));
        setAssignmentID(null);
    }
    const handleGroupClick=(assignmentGroupID, groupTypeID, assignmentLabel)=>{
        //When a coach clicks on a Squad or position within the table:
        //Sets the groupTypeID and AssignmentGroupID (assignmentID)
        //Method of quickly selecting or deselecting groups within the table without having to use the form elements
        setAssignmentID(assignmentGroupID);
        //Add Exception for Inactive Groups, Inactive Group has a groupSetting of 0
        const groupSetting = parseInt(groupTypeID); // === ATHLETE_ASSIGNMENT_TYPES.inactive ? ATHLETE_ASSIGNMENT_TYPES.squads : parseInt(groupTypeID);
        setAssignmentTypeSelected(groupSetting);
        notifyUser(groupSetting, assignmentLabel);
    }
    const handleAssignment=(userID, active)=>{
        let sc = new SecureConnect('team.php', 'post');
        sc.setAction('setAthleteAssignment');
        sc.setFormData({teamID, userID, assignmentID, groupTypeID: assignmentTypeSelected, active});
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                const roster = sc.getData(json);
                setRoster(roster);
            }
        })
    }
    const handleSelectedAssignment=(assignmentID, assignmentGroup)=>{
        assignmentGroup = parseInt(assignmentGroup);
        const groupTypeID = assignmentGroup === ATHLETE_ASSIGNMENT_TYPES.squads ? getGroupTypeID(assignmentID) : assignmentGroup;
        setAssignmentID(parseInt(assignmentID));
        setAssignmentTypeSelected(groupTypeID);
    }
    const handleUnfilteredAssignment=(mergedAssignmentGroupValue)=>{
        //When someone doesn't specify the assignment type filter and instead clicks straight from the selector
        //Receives assignmentID and groupTypeID split by Pipe ('|')
        //Split the vars and assign both assignmentID and groupTypeID
        const vars = mergedAssignmentGroupValue.split('|');
        setAssignmentTypeSelected(vars[1]);
        setAssignmentID(vars[0]);
    }
    const renderTeamSelector=()=>{
        const user = new User();
        const teams = user.getAthleticTeams();
        if(teams.length > 1) {
            return (
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        select
                        name={'teamID'}
                        label={'Select Team'}
                        onChange={(e)=>handleTeamSelector(e)}
                    >
                        {
                            teams.map((team, k) => {
                                return (
                                    <MenuItem key={k} value={team.sectionID}>{team.orgShortName} {team.label}</MenuItem>
                                )
                            })
                        }
                    </TextField>
                </Grid>
            )
        }
        return null;
    }
    const renderSquadPositionSelector=()=>{
        let label, disabled;
        let options = [];
        const noValue = -1;
        const assignmentType = parseInt(assignmentTypeSelected);
        const spacer = <MenuItem value={noValue} disabled={true}></MenuItem>;
        let fn;
        if(isSquadType(assignmentType)){
            //FILTERED FOR SQUADS
            label = 'Squads';
            fn = (e)=>handleSelectedAssignment(e.target.value, ATHLETE_ASSIGNMENT_TYPES.squads);
            //options.push(inactiveSquad);
            if(!isEmpty(squadList)) {
                squadList.forEach((item, k) => {
                    options.push(<MenuItem value={item.id} key={k}>{item.title}</MenuItem>);
                })
            }

        } else if(assignmentType === parseInt(ATHLETE_ASSIGNMENT_TYPES.positions)){
            //FILTERED FOR POSITIONS
            label = 'Positions';
            fn = (e)=>handleSelectedAssignment(e.target.value, ATHLETE_ASSIGNMENT_TYPES.positions);
            disabled = isEmpty(positionList);
            positionList.forEach((item, k) =>{
                options.push(<MenuItem value={item.id} key={k}>{item.positionLabel}</MenuItem>);
            })
        } else{
            //UNFILTERED - BOTH SQUADS AND POSITIONS
            label = 'Squad/Positions';
            fn=(e)=>handleUnfilteredAssignment(e.target.value);
            options.push(<MenuItem value={null} key={`squadDelimiter`} disabled={true}>--Squads---</MenuItem>);
            options.push(spacer);
            //options.push(inactiveSquad);
            if(!isEmpty('Squads')) {
                if(!isEmpty(squadList)) {
                    squadList.forEach((item, k) => {
                        options.push(<MenuItem value={`${item.id}|${item.groupTypeID}`} key={`s-${k}`}>{item.title}</MenuItem>);
                    })
                }
            }

            if(!isEmpty(positionList)) {
                options.push(spacer);
                options.push(<MenuItem value={noValue} key={'positionDelimiter'} disabled={true}>--Positions---</MenuItem>);
                options.push(spacer);
                positionList.forEach((item, k) => {
                    options.push(<MenuItem value={`${item.id}|${ATHLETE_ASSIGNMENT_TYPES.positions}`} key={`p_${k}`}>{item.positionLabel}</MenuItem>);
                });
            }
        }
        return (
            <TextField
                fullWidth
                select
                label={label}
                disabled={disabled}
                value={assignmentID}
                onChange={fn}
            >
                {options}
            </TextField>
        )

    }
    const isSquadType=(typeID)=>{
        return [ATHLETE_ASSIGNMENT_TYPES.inactive, ATHLETE_ASSIGNMENT_TYPES.squads].includes(parseInt(typeID));
    }

    const getSquadPositionText=()=>{
        if(assignmentID == null){
            //Die: No assignment ID or no position to select from
            //Exception made for inactive squadList
            return '';
        }
        let label = '';
        let item;
        if(isSquadType(assignmentTypeSelected)){ //SQUADS
            //console.log('FINDING SQUAD', assignmentID, '=>', squadList);
            item = squadList.find(item => parseInt(item.id) === parseInt(assignmentID));
            label = item.title;
        } else{ //POSITIONS
            item = positionList.find(item =>parseInt(item.id) === parseInt(assignmentID));
            label = item != null  && item.positionLabel != null ? item.positionLabel: '';
        }
        return label;
    }


    //Roster Table Formatting
    const completedWorkoutTableStructure = {
        tableID:"TeamAssignments",
        rowComponent:"SquadPositionAssignmentList",
        paginationEnabled: false,
        sortEnabled: false,
        searchEnabled: false,
        searchTips:"",
        tableHeaders:[
            {key: 'id', label: 'Active', style: '', sx: {}},
            {key: 'athlete', label: 'Athlete', style: '', sx: {}},
            {key: 'squads', label: 'Squads Assigned', style: '', sx: {}},
            {key: 'positions', label: 'Positions Assigned', style: '', sx: {}},
            {key: 'year', label: 'Year', style: '', sx: {}},
        ],
        defaultSort: 'name',
        defaultDirection:"up",
        dateRangeEnabled: false
    };
    //Filtering/Displaying Roster Table
    let athletesList = roster;
    const searching = searchText.length >= SEARCH_MINIMUM_LENGTH;
    const squadPositionSet = assignmentID != null && assignmentTypeSelected != null;
    if(searching){
        athletesList = roster.filter(a => `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()} ${a.squads.map(item => item.label).join('').toLowerCase()} ${a.positions.map(item => item.label).join('').toLowerCase()} ${a.yearStatus.toLowerCase()}`.includes(searchText));
    }
    if(searching === false && squadPositionSet && visibilitySetting !== 0){
        //Viewer isn't searching, athlete visibility based on visibility setting
        const groupSetting = isSquadType(assignmentTypeSelected) ? 'squads' : 'positions';
        const visibilityTest = visibilitySetting === 1;
        athletesList = roster.filter(a=> a[groupSetting].map(gs => parseInt(gs.assignmentGroupID)).includes(parseInt(assignmentID)) === visibilityTest);
    }


    const user = new User();
    const teamsList = user.getAthleticTeams();
    const hasTeam = teamsList.length > 0;
    const squadPositionLabel = getSquadPositionText();
    const noTeam = teamID === '';

    //console.log('TEAM LIST', teamsList);

    return (
        <div>
            <Header header={'Squads & Player Positions'}/>
            <Paper style={PAPER_STYLE}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper style={PAPER_STYLE}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={'h2'}>Player Squad/Position Assignments</Typography>
                                    <Alert
                                        status={'info'}
                                        visible={isEmpty(roster) && hasTeam}
                                        headline={'Select A Team To Get Started'}
                                        message={'Select a team from the list below to assign squads or positions to athletes'}
                                    />
                                    <Alert
                                        status={'error'}
                                        visible={hasTeam === false}
                                        headline={'No Teams Available'}
                                        message={'Managing squads and player positions require an active team. You need to be assigned to a team before you can handle player assignments.'}
                                    />
                                </Grid>
                                {renderTeamSelector()}
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel>Assignment Type</FormLabel>
                                        <RadioGroup
                                            row
                                            value={assignmentTypeSelected}
                                        >
                                            <FormControlLabel value={ATHLETE_ASSIGNMENT_TYPES.squads} control={<Radio disabled={noTeam} onChange={(e)=>handleGroupSetting(e)}/>} label="Squad" disabled={noTeam}/>
                                            <FormControlLabel value={ATHLETE_ASSIGNMENT_TYPES.positions} control={<Radio disabled={noTeam || isEmpty(positionList)} onChange={(e)=>handleGroupSetting(e)}/>} label="Position" disabled={noTeam || isEmpty(positionList)}/>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    {renderSquadPositionSelector()}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel>Assignments Filter</FormLabel>
                                        <RadioGroup
                                            row
                                            value={visibilitySetting}
                                        >
                                            <FormControlLabel value={0} label={'Show All'} control={<Radio onChange={()=>setVisibilitySetting(0)}/>}/>
                                            <FormControlLabel value={1} label={'Assigned Athletes'} control={<Radio onChange={()=>setVisibilitySetting(1)}/>}/>
                                            <FormControlLabel value={-1} label={'Unassigned Athletes'} control={<Radio onChange={()=>setVisibilitySetting(-1)}/>}/>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <div style={innerPaddingStyle}>
                                <AthleteRowSearch
                                    value={searchText}
                                    searchToolTip={'Name, Squad, Positions, Year'}
                                    onChangeFn={(value)=>setSearchText(value)}
                                    clearSearchFn={()=>setSearchText('')}
                                    placeHolderText={'Search'}
                                    label={'Athletes'}
                                />
                            </div>
                            <SquadSelectedAlert
                                assignmentID={assignmentID}
                                groupTypeID={assignmentTypeSelected}
                                assignmentLabel={squadPositionLabel}
                            />
                            <DynamicDataTable
                                formatURL={null}
                                format={completedWorkoutTableStructure}
                                serverData={athletesList}
                                loadData={false}
                                presetVariables={{
                                    assignmentTypeSelected: assignmentTypeSelected,
                                    assignmentID: assignmentID,
                                    assignmentText: squadPositionLabel
                                }}
                                rowFunctions={{
                                    handleAssignment: (userID, active)=>handleAssignment(userID, active),
                                    handleGroupClick: (assignmentGroupID, groupTypeID, assignmentLabel)=>handleGroupClick(assignmentGroupID, groupTypeID, assignmentLabel)
                                }}
                            />
                            <div style={{paddingBottom: getSpacing()}}>
                                <SquadSelectedAlert
                                    assignmentID={assignmentID}
                                    groupTypeID={assignmentTypeSelected}
                                    assignmentLabel={squadPositionLabel}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default SquadList;